import React from "react";
import "./pdf.scss";
import CoverPage from "./CoverPage";
import SecondPage from "./SecondPage";
import CampaignContainer from "containers/CampaignContainer";
import moment from "moment";

const PDF = ({ campaignId, start, end }) => {
  return (
    <CampaignContainer campaignId={campaignId} queryKey="campaign-basics">
      {({ campaign, loading }) => {
        const { user, companyInfo } = campaign;
        const todaysDate = moment().format("MM[/]DD[/]YYYY");

        return (
          <>
            <header className="pdf_header">
              <img
                src="https://cdn40.thomasnet.com/img40/thomas-logo-blue.svg"
                alt="Thomas For Industry Logo"
              />
              <h3>{companyInfo && companyInfo.name}</h3>
            </header>
            <div className="pdf_pages">
              <CoverPage campaignId={campaignId} start={start} end={end} />
              <SecondPage campaignId={campaignId} start={start} end={end} />
            </div>
            <div className="pdf_footer">
              <div className="pdf_created-by">
                Created by: {user} {todaysDate}
              </div>
              <div className="pdf_thomasnet-copyright">
                &copy;2019 Thomasnet.com
              </div>
            </div>
          </>
        );
      }}
    </CampaignContainer>
  );
};

export default PDF;
