const TypeNames = {
    ACTIVE_FILTERS_TYPE_NAME: "Filter",
    SESSION_CONFIG_TYPE_NAME: "SessionsConfig"
};

const FilterTypes = {
    ACTION: "ACTION",
    SEARCH: "SEARCH",
    REFERRER: "REFERRER",
    TARGET: "TARGET",
    BASE: "BASE"
};

const ALL_TARGETS = "ALL_TARGETS";

export { TypeNames, FilterTypes, ALL_TARGETS };
