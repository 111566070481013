import React from "react";
import ProgressSteps from "views/components/Registration/ProgressSteps";
import RouterLink from "views/components/RouterLink/RouterLink";

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = { password: "" };
  }

  render() {
    const {
      onSignIn,
      email,
      loading,
      onForgotPassword,
      error,
      clearError,
      renderFormValidationErrors
    } = this.props;

    return (
      <React.Fragment>
        <ProgressSteps step={1} />
        <div className="form-bg form-short">
          {error && (
            <p
              className="alert alert-danger hide"
              id="errMsg"
              style={{ display: "block" }}
            >
              {error}
            </p>
          )}
          <p className="text-center">
            <b>{email}</b>
            <br />
            <RouterLink
              urlClickHandler={() => {
                clearError();
                return `/register/login`;
              }}
            >
              {({ onExecuteLink }) => {
                return (
                  <span
                    className="forgot-password-link"
                    onClick={onExecuteLink}
                  >
                    Not You?
                  </span>
                );
              }}
            </RouterLink>
          </p>
          <div className="form-group" style={{ position: "relative" }}>
            <label htmlFor="exampleInputPassword1">Enter your password</label>
            <input
              name="password"
              type="password"
              className="form-control"
              placeholder="password"
              value={this.state.password}
              onChange={e =>
                this.setState({
                  password: e.target.value
                })
              }
              onKeyPress={e => {
                if (e.keyCode || e.which === 13) {
                  onSignIn(email, this.state.password);
                }
              }}
            />

            <a
              className="forgot-password-link"
              onClick={() => onForgotPassword()}
              href="#forgot-password"
            >
              Forgot Password?
            </a>
          </div>

          <div className="form-group flex-end-btn">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                if ((this.state.password || "").trim().length === 0) {
                  renderFormValidationErrors("Please enter a valid password");
                } else {
                  onSignIn(email, this.state.password);
                }
              }}
            >
              {loading ? (
                <div className="spinner loading-white">
                  <div className="rect1" />
                  <div className="rect2" />
                  <div className="rect3" />
                  <div className="rect4" />
                  <div className="rect5" />
                </div>
              ) : (
                "Sign In"
              )}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SignIn;
