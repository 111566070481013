const singleRefKeys = [
    {
        label: "Visitor",
        key: "visitorName"
    },
    {
        label: "Website",
        key: "pageUrl"
    },
    {
        label: "Date",
        key: "date"
    },
    {
        label: "Time",
        key: "time"
    },
    {
        label: "Duration",
        key: "duration"
    },
    {
        label: "Pages",
        key: "pageViews"
    }
];

export default singleRefKeys;
