import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";

const GET_COMPANY_INFO_QUERY = gql`
    query getCompanyInfoQuery($visitorId: String) {
        visitor(visitorId: $visitorId) {
            img
            title
            address
            userId
            companyIndustry
            companySubIndustry
            companyEmployees
            companyAnnualSales
            companyRevenueRange
            companyIsISP
            companySummary
            thomasnet
        }

        user {
            id
            targets {
                label
                targetType
                value
                sortOrder
            }
        }
    }
`;

const FilterContainer = ({ visitorId, children, ...props }) => (
    <Query query={GET_COMPANY_INFO_QUERY} variables={{ visitorId }}>
        {({ data: { visitor, user } = {} }) => {
            return children && children({ ...props, visitor, user });
        }}
    </Query>
);

export default FilterContainer;
