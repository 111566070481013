import React from "react";

const Cluster = ({ cluster, len }) => {
  return cluster.properties.cluster ? (
    <div className="map-item map-item__cluster">
      {cluster.properties.pointCount}
    </div>
  ) : (
    <div>
      <img
        className="map-item map-item__marker"
        src={process.env.PUBLIC_URL + "/img/ico-map-pin.svg"}
        alt="arrow"
      />
    </div>
  );
};

export default Cluster;
