import { gql } from "apollo-boost";
import { graphql, compose } from "react-apollo";

const MUTATION_UPDATE_SESSIONS_CONFIG = gql`
    mutation updateSessionsConfig($displayAmount: int, $viewType: int) {
        updateSessionsConfig(
            viewType: $viewType
            displayAmount: $displayAmount
        ) @client
    }
`;

const ResultsControlContainer = ({ children, ...props }) => {
    return children && children({ ...props });
};

export default compose(
    graphql(MUTATION_UPDATE_SESSIONS_CONFIG, {
        props: ({ mutate }) => ({
            onViewTypeChange: viewType =>
                mutate({
                    variables: { viewType }
                }),

            onDisplayAmountChange: displayAmount =>
                mutate({
                    variables: { displayAmount }
                }),

            onSessionsConfigChange: config =>
                mutate({
                    variables: config
                })
        })
    })
)(ResultsControlContainer);
