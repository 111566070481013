import React from "react";
import moment from "moment";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";

function titleCase(str) {
    if (!str) return str;

    return str
        .toString()
        .toLowerCase()
        .split(" ")
        .map(function(word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
}

const activityMap = type =>
    type === "Thomas"
        ? { img: "THOMASNET_ACTIVITY", txt: "The Thomas Network" }
        : { img: "CLIENT_SITE_ACTIVITY", txt: "Your Website" };

const actionClassification = {
    lead: "Sales Inquiry",
    solicitation: "Solicitation",
    other: "Other Inquiry",
    unclassified: "Unclassified"
};

const fieldMap = {
    location(content, prop) {
        return (
            <React.Fragment>
                <ThomasIcon attribute={activityMap(content[prop]).img} />
                <div>{activityMap(content[prop]).txt}</div>
            </React.Fragment>
        );
    },

    actionType(content, prop) {
        return <ThomasIcon attribute={content[prop]} />;
    },

    timestamp(content, prop, open) {
        return (
            <div>
                <ThomasIcon
                    attribute={open ? "COLLAPSE" : "OPEN"}
                    customClass="font-size-sm"
                    customIconClass="font-size-sm mr-2 ml-1"
                />
                {moment(content[prop]).format("MMM DD YYYY - hh:mm A")}
            </div>
        );
    },

    name(content, prop) {
        return content["company"]
            ? titleCase(content["company"])
            : "Not Available";
    },

    vetted(content, prop) {
        return (
            <div className={`hvActions--${content[prop]}`}>
                {actionClassification[content[prop]]}
            </div>
        );
    }
};

const hvActionsTableFactory = (content, prop, open) => {
    return fieldMap[prop] ? fieldMap[prop](content, prop, open) : content[prop];
};

export default hvActionsTableFactory;
