import * as React from "react";
import "./PageUtility.scss";
import { PDFContainer } from "containers/PDFContainer";
import { Spinner } from "views/components/Loading/Spinner";
import PDFExport from "views/components/ThomasIcon/ThomasIcon";

const PageUtility = ({ startDate, endDate, campaignId }: any) => {
  const renderPDFButton = ({
    onPDFExport,
    pdfDownload = {}
  }: {
    onPDFExport: any;
    pdfDownload: any;
  }) => (
    <span className="action-icons">
      {pdfDownload.status === "processing" ? (
        <Spinner />
      ) : (
        <div className="results-control__utility-icons" onClick={onPDFExport}>
          <PDFExport attribute="PDF_EXPORT" hideToolTip />
          Download
        </div>
      )}
    </span>
  );
  return (
    <section className="page-utility">
      <div className="breadcrumb">
        <a href="https://www.thomasnet.com" className="breadcrumb-item">
          Home
        </a>
        <a
          href="https://www.thomasnet.com/mythomas/"
          className="breadcrumb-item"
        >
          My Account
        </a>
        <a href="/campaigns" className="breadcrumb-item">
          Campaigns
        </a>
        <a className="breadcrumb-item">Dashboard</a>
      </div>
      <PDFContainer
        startDate={startDate}
        endDate={endDate}
        campaignId={campaignId}
      >
        {renderPDFButton}
      </PDFContainer>
    </section>
  );
};

export default PageUtility;
