const actionKeys = [
    {
        label: "Date / Time",
        key: "timestamp"
    },
    {
        label: "Type",
        key: "vetted"
    },
    {
        label: "Source",
        key: "actionType"
    },
    {
        label: "Where",
        key: "location"
    },

    {
        label: "From",
        key: "name"
    },
    {
        label: "Subject/Asset",
        key: "subject"
    }
];

export default actionKeys;
