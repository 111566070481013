import React from "react";
import "./ActiveItem.scss";

const ActiveItem = ({ label, item, onRemove, customMarkup, customClass }) => (
  <div
    className={`btn btn-outline-secondary active-item ${
      customClass ? customClass : ""
    }`}
  >
    {label && <div>{label}: </div>}

    {customMarkup ? customMarkup : item}

    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      onClick={() => {
        onRemove(item);
      }}
    >
      <title>ico-close</title>
      <path
        d="M11.213 16L4.414 9.2a1 1 0 0 1 0-1.413l3.373-3.373a1 1 0 0 1 1.414 0l6.799 6.8 6.8-6.8a1 1 0 0 1 1.413 0l3.373 3.373a1 1 0 0 1 0 1.414L20.786 16l6.8 6.8a1 1 0 0 1 0 1.413l-3.373 3.373a1 1 0 0 1-1.414 0L16 20.786l-6.8 6.8a1 1 0 0 1-1.413 0l-3.373-3.373a1 1 0 0 1 0-1.414l6.8-6.799z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </div>
);

export default ActiveItem;
