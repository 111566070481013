import React from "react";
import ChartCard from "views/components/ChartCard/ChartCard";
import PageHeader from "views/sections/PageHeader/PageHeader";
import ThomasTable from "views/components/ThomasTable/ThomasTable";

import durationKeys from "./tableKeys/durationKeys";
import depthKeys from "./tableKeys/depthKeys";

import analyticsTableFactory from "utils/tableFactories/analyticsTableFactory";

import AnalyticsDurationDepthContainer from "containers/AnalyticsDurationDepthContainer";
import RouterLink from "views/components/RouterLink/RouterLink";

class AnalyticsSessions extends React.Component {
    render() {
        const { title, page } = this.props;

        return (
            <AnalyticsDurationDepthContainer page={page} standardSort>
                {({ durations, depths }) => {
                    return (
                        <div
                            className="container"
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <PageHeader title={title} />

                            <section className="card-group analytics-sessions-cards">
                                <ChartCard
                                    title="Session Depth"
                                    data={[
                                        {
                                            id: "Sessions:",
                                            color: "#3cb4e8",
                                            data: depths.map(d => {
                                                return {
                                                    x: d.node.depth,
                                                    y: d.node.sessionsct
                                                };
                                            })
                                        }
                                    ]}
                                    customToolTip="depth"
                                />
                                <ChartCard
                                    title="Session Length"
                                    data={[
                                        {
                                            id: "Sessions:",
                                            color: "#3cb4e8",
                                            data: durations.map(d => {
                                                return {
                                                    x: d.node.durationRange,
                                                    y: d.node.sessionsct
                                                };
                                            })
                                        }
                                    ]}
                                    customToolTip="length"
                                />
                                <RouterLink
                                    urlClickHandler={t => {
                                        return `/sessions?page_depth=${t.node[
                                            "depth"
                                        ].replace("+", "m")}`
                                    }}
                                >
                                    {({
                                        onExecuteLink
                                    }) => {
                                        return (
                                            <ThomasTable
                                                tableCols={depthKeys}
                                                data={depths}
                                                contentFactory={analyticsTableFactory}
                                                gClass={"analytics-table grid-col-3"}
                                                customRowClick={onExecuteLink}
                                            />
                                        )
                                    }}
                                </RouterLink>

                                <RouterLink
                                    urlClickHandler={t => {
                                        const multiplier = t.node[
                                            "durationRange"
                                        ].includes("m")
                                            ? 60
                                            : 1;
                                        const range = t.node["durationRange"]
                                            .split("-")
                                            .map(
                                                r =>
                                                    parseInt(r, 10) * multiplier
                                            );
                                        return `/sessions?length=${
                                            range[0] >= 60
                                                ? range[0] + 1
                                                : range[0]
                                            }${range[1] ? "a" + range[1] : ""}`
                                    }}
                                >
                                    {({
                                        onExecuteLink
                                    }) => {
                                        return (
                                            <ThomasTable
                                                tableCols={durationKeys}
                                                data={durations}
                                                contentFactory={analyticsTableFactory}
                                                gClass={"analytics-table grid-col-3"}
                                                customRowClick={onExecuteLink}
                                            />
                                        )
                                    }}
                                </RouterLink>
                            </section>
                        </div>
                    );
                }}
            </AnalyticsDurationDepthContainer>
        );
    }
}

export default AnalyticsSessions;