import { gql } from "apollo-boost";
import moment from "moment";

const DATERANGE_TYPE_NAME = "DateRange";

const DATERANGE_QUERY = gql`
  query getDateRange {
    dateRange @client {
      startDate
      endDate
    }
  }
`;

const DATERANGE_MUTATION = gql`
  mutation updateDateRange($startDate: String, $endDate: String) {
    updateDateRange(startDate: $startDate, endDate: $endDate) @client
  }
`;

export { DATERANGE_MUTATION, DATERANGE_QUERY };

const dateRange = {
  defaults: {
    dateRange: {
      __typename: DATERANGE_TYPE_NAME,

      startDate: moment()
        .subtract(1, "month")
        .toISOString(true),
      endDate: moment().toISOString(true)

      // startDate: moment("2018-07-22").valueOf(),
      // endDate: moment("2018-08-11").valueOf()
    }
  },

  resolvers: {
    Mutation: {
      updateDateRange: (_, { startDate, endDate }, { cache }) => {
        const query = gql`
          query DateRange {
            dateRange {
              startDate
              endDate
            }
          }
        `;

        let { dateRange } = cache.readQuery({ query });
        if (startDate) dateRange.startDate = startDate; //1520720434000,
        if (endDate) dateRange.endDate = endDate; //1525382434000,

        cache.writeQuery({
          query,
          data: {
            dateRange
          }
        });

        return null;
      }
    }
  }
};

export default dateRange;
