import React from "react";

const TTRowClickableLink = ({
    row,
    tableCols,
    contentFactory,
    gClass,
    customRowClick
}) => {
    return (
        <div
            className={`thomas-table--row thomas-row-clickable thomas-table__grid ${gClass}`}
            onClick={() => customRowClick(row)}
        >
            {tableCols.map(col => {
                return (
                    <div key={col.key} className={`thomas-table__${col.key}`}>
                        {contentFactory
                            ? contentFactory(row, col.key)
                            : row[col.key]}
                    </div>
                );
            })}
        </div>
    );
};

export default TTRowClickableLink;
