import React from "react";
import PieChart from "views/components/PieChart/PieChart";
import colors from "views/components/PieChart/__mocks__/colors";
import { Link } from "react-router-dom";

const TopReferrers = ({ data, total }) => {
    var otherValue = 0;
    if (data && data.find(j => j.node.url === "")) {
        otherValue = data.find(j => j.node.url === "").node.sessionsCount;
    }

    const coloredData =
        (data &&
            data.filter(d => d.node.url !== "").map((d, i) => ({
                id: d.node.id,
                label: d.node.url,
                value: d.node.sessionsCount,
                fill: colors[i]
            }))) ||
        [];

    for (let k = 0; k < coloredData.length; k++) {
        if (k >= 10) {
            otherValue += coloredData[k].value;
        }
    }

    const slicedData = coloredData.slice(0, 10) || [];
    if (otherValue > 0) {
        slicedData.push({
            id: "other",
            label: "Other",
            value: otherValue,
            fill: colors[slicedData.length]
        });
    }

    return (
        <React.Fragment>
            <div className="funnel-analysis--referrers">
                <div className="funnel-analysis--sessions">
                    Top Referrers
                    <span>Sessions</span>
                </div>
                <div className="top-referrers">
                    <div className="top-countcard top-countcard__referrers">
                        {slicedData.map(r => (
                            <div key={r.id} className="top-countcard--row">
                                <div
                                    className="top-countcard--color"
                                    style={{ backgroundColor: r.fill }}
                                />
                                <div className="top-countcard--row-content">
                                    <div className="top-countcard--fill" />

                                    <div className="top-countcard--ref">
                                        {r.label === "Other" ? (
                                            r.label
                                        ) : (
                                            <Link
                                                to={`/sessions?ref=${
                                                    r.label === ""
                                                        ? "DTS"
                                                        : r.label
                                                }`}
                                            >
                                                {r.label === ""
                                                    ? "Direct To Site"
                                                    : r.label}
                                            </Link>
                                        )}
                                    </div>
                                    <div className="top-countcard--total">
                                        {r.value}/{total}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="funnel-analysis--pie">
                <PieChart
                    data={slicedData.filter(r => r.id !== "other")}
                    width={350}
                    height={350}
                    innerRadius={0}
                    customClass="large-pi"
                    interactive
                    customToolTip={d => <div>Sessions: {d}</div>}
                />
            </div>
        </React.Fragment>
    );
};

export default TopReferrers;
