import React from "react";
import TextBox from "views/components/TextBox/TextBox";

import "./TextBoxAdder.css";

export default class TextBoxAdder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items || [],
      placeholder: props.placeholder || [""]
    };

    this.updateItems = this.updateItems.bind(this);
    this.addTextBox = this.addTextBox.bind(this);
    this.removeTextBox = this.removeTextBox.bind(this);
    this.updateTextBox = this.updateTextBox.bind(this);
  }

  updateItems(items) {
    this.props.updateItems && this.props.updateItems(items);
  }

  addTextBox() {
    const items = [...(this.state.items || []), ""];
    const placeholder = [
      ...(this.state.placeholder || []),
      this.state.placeholder[this.state.placeholder.length - 1]
    ];
    this.setState(
      {
        items,
        placeholder
      },
      () => {
        this.updateItems(items);
      }
    );
  }

  updateTextBox(index, value) {
    const items = [...(this.state.items || [value])];
    items[index] = value;
    this.setState({ items }, () => {
      this.updateItems(items);
    });
  }

  removeTextBox(i) {
    let newItems = [...this.state.items];
    let newPlaceholder = [...this.state.placeholder];

    newItems.splice(i, 1);
    newPlaceholder.splice(i, 1);

    this.setState(
      {
        items: newItems,
        placeholder: newPlaceholder
      },
      this.updateItems(newItems)
    );
  }

  defaultItems() {
    if (!this.props.placeholder || this.props.placeholder.length === 0) {
      return [""];
    }
    return Array(this.props.placeholder.length).fill("");
  }

  addEmptyString() {
    return [...this.state.items, ""];
  }

  render() {
    let { items, placeholder } = this.state;

    if (!items || !items.length) {
      items = this.defaultItems();
    }

    if (items.length < placeholder.length) {
      items = this.addEmptyString();
    }

    const {
      label = "",
      subLabel = "",
      preContent,
      customTBClass = ""
    } = this.props;

    return (
      <div className="textbox-adder">
        {items.map((item, index) => (
          <div className="textbox-adder_box" key={`${index}`}>
            {index === 0 ? (
              <React.Fragment>
                {preContent}
                <TextBox
                  label={label}
                  subLabel={subLabel}
                  value={item}
                  customClass={`textbox-adder_textbox ${customTBClass}`}
                  customInputClass="close-box"
                  onChange={value => this.updateTextBox(index, value)}
                  placeholder={placeholder[index]}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {preContent}
                <TextBox
                  value={item}
                  customClass={`textbox-adder_textbox ${customTBClass}`}
                  customInputClass="close-box"
                  onChange={value => this.updateTextBox(index, value)}
                  placeholder={placeholder[placeholder.length - 1]}
                />
              </React.Fragment>
            )}

            {/* Only hide 'X' when length = 1*/}
            {items.length !== 1 && (
              <div
                className="textbox-adder_remove"
                onClick={() => this.removeTextBox(index)}
              >
                x
              </div>
            )}
          </div>
        ))}

        {/* Only show add button when last item has something typed */}
        {items[items.length - 1] !== "" && (
          <div
            className="textbox-adder_add"
            onClick={() => {
              this.addTextBox();
            }}
          >
            +
          </div>
        )}
      </div>
    );
  }
}
