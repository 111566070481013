import React from "react";
import { gql } from "apollo-boost";
import { graphql, compose, Query } from "react-apollo";
import { QUERY_PAGE_CONFIG } from "resolvers/sessions";

const FilterContainer = ({ children, page, ...props }) => (
    <Query query={QUERY_PAGE_CONFIG} variables={{ page }}>
        {({ data: { page: { activeFilters = [] } = {} } }) => {
            return (
                children &&
                children({
                    ...props,
                    activeFilters: {
                        referrers: activeFilters.filter(
                            f => f.filterType === "REFERRER"
                        ),
                        targets: activeFilters.filter(
                            f => f.filterType === "TARGET"
                        ),
                        actions: activeFilters.filter(
                            f => f.filterType === "ACTION"
                        ),
                        searches: activeFilters.filter(
                            f => f.filterType === "SEARCH"
                        ),
                        sortedTargets: sortTargets(activeFilters.filter(
                            f => f.filterType === "TARGET"
                        )),
                        base: activeFilters.filter(f => f.filterType === "BASE")
                    }
                })
            );
        }}
    </Query>
);

const ADD_FILTER = gql`
    mutation addFilter($filter: Filter, $page: String!) {
        addFilter(filter: $filter, page: $page) @client
    }
`;

const ADD_FILTERS = gql`
    mutation addFilters(
        $filters: [Filter]
        $searches: [string]
        $page: String!
    ) {
        addFilters(filters: $filters, searches: $searches, page: $page) @client
    }
`;

const ADD_SEARCH_FILTER = gql`
    mutation addSearchFilter($search: String, $page: String!) {
        addSearchFilter(search: $search, page: $page) @client
    }
`;

const REMOVE_FILTER = gql`
    mutation removeFilter($filter: Filter!, $page: String!) {
        removeFilter(filter: $filter, page: $page) @client
    }
`;

const REMOVE_FILTERS = gql`
    mutation removeFilters($filterType: String!, $page: String!) {
        removeFilters(filterType: $filterType, page: $page) @client
    }
`;

const sortTargets = (filterList) => {
    const mappedData = new Map();

    filterList.forEach(entry => {
        if (!mappedData.has(entry.targetType)) {
            mappedData.set(entry.targetType, [entry]);
        } else {
            mappedData.get(entry.targetType).push(entry);
        }
    });

    return mappedData
}

export default compose(
    graphql(ADD_FILTER, { name: "addFilter" }),
    graphql(ADD_FILTERS, { name: "addFilters" }),
    graphql(ADD_SEARCH_FILTER, { name: "addSearchFilter" }),
    graphql(REMOVE_FILTER, { name: "removeFilter" }),
    graphql(REMOVE_FILTERS, { name: "removeFilters" })
)(FilterContainer);
