import React from "react";
import { uniq } from "lodash";
import TextBox from "views/components/TextBox/TextBox";
import DropDown from "views/components/DropDown/DropDown";
import ActiveItem from "views/components/ActiveItem/ActiveItem";
import "./AdvancedSettings.css";

import countries from "data/countries";

const SettingsRow = ({
  label,
  value,
  onTextType,
  onAdd,
  onRemove,
  activeSettings
}) => {
  return (
    <div className="advanced-settings--row">
      <TextBox
        customClass="advanced-settings--textbox"
        label={label}
        value={value}
        onChange={onTextType}
      />

      <div
        onClick={() => {
          onAdd(value);
        }}
        className="advanced-settings--add"
      >
        +
      </div>

      <div className="advanced-settings--active-items">
        {activeSettings &&
          activeSettings.map((item, index) => (
            <ActiveItem key={index} item={item} onRemove={onRemove} />
          ))}
      </div>
    </div>
  );
};

export default class AdvancedSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blockedIpInput: "",
      blockedReferrerInput: "",
      excludedCountryInput: "",
      indexFileInput: "",
      ownReferrerInput: "",
      whitepageInput: "",
      shortQueryInput: ""
    };

    this.addDropdownSetting = this.addDropdownSetting.bind(this);
    this.addTextSetting = this.addTextSetting.bind(this);
    this.removeSetting = this.removeSetting.bind(this);
  }

  addDropdownSetting(key, values) {
    const { activeSettings } = this.props;

    const settingToUpdate = activeSettings[key]
      ? [...values.map(v => v.value), ...activeSettings[key]]
      : [...values.map(v => v.value)];

    this.props.updateSetting(key, settingToUpdate);
  }

  addTextSetting(field, key) {
    return () => {
      const val = this.state[field];

      if (!val) {
        return;
      }

      const { activeSettings } = this.props;
      const activeValues = activeSettings[key] || [];
      this.props.updateSetting(key, uniq([...activeValues, val]));

      this.setState({
        [field]: ""
      });
    };
  }

  removeSetting(key) {
    return val => {
      const { activeSettings } = this.props;

      this.props.updateSetting(key, activeSettings[key].filter(t => t !== val));
    };
  }

  render() {
    const {
      blockedIpInput,
      blockedReferrerInput,
      indexFileInput,
      ownReferrerInput,
      whitepageInput,
      shortQueryInput
    } = this.state;

    const { activeSettings } = this.props;

    return (
      <div className="advanced-settings">
        <h3>Advanced Settings</h3>

        <SettingsRow
          label="IP Blocking (Thomas related IP addresses are automatically added here)"
          value={blockedIpInput}
          onTextType={t => {
            this.setState({ blockedIpInput: t });
          }}
          onAdd={this.addTextSetting("blockedIpInput", "blockedIps")}
          onRemove={this.removeSetting("blockedIps")}
          activeSettings={activeSettings["blockedIps"]}
        />

        <SettingsRow
          label="Referrer Blocking"
          value={blockedReferrerInput}
          onTextType={t => {
            this.setState({ blockedReferrerInput: t });
          }}
          onAdd={this.addTextSetting(
            "blockedReferrerInput",
            "blockedReferrers"
          )}
          onRemove={this.removeSetting("blockedReferrers")}
          activeSettings={activeSettings["blockedReferrers"]}
        />

        <div className="advanced-settings--row">
          <DropDown
            customClass="advanced-settings--textbox"
            label="Countries to exclude"
            items={countries}
            value={null}
            onChange={items =>
              this.addDropdownSetting("excludedCountries", items)
            }
            title="Select Country"
            disabled={
              activeSettings["excludedCountries"] &&
              activeSettings["excludedCountries"].map(val =>
                countries.find(c => c.value === val)
              )
            }
            multi
          />
          <div className="advanced-settings--active-items">
            {activeSettings["excludedCountries"] &&
              activeSettings["excludedCountries"].map((item, index) => (
                <ActiveItem
                  key={index}
                  item={item}
                  customMarkup={
                    (countries.find(c => c.value === item) || { label: "" })
                      .label
                  }
                  onRemove={this.removeSetting("excludedCountries")}
                />
              ))}
          </div>
        </div>

        <SettingsRow
          label="Index.* --> /"
          value={indexFileInput}
          onTextType={t => {
            this.setState({ indexFileInput: t });
          }}
          onAdd={this.addTextSetting("indexFileInput", "indexFiles")}
          onRemove={this.removeSetting("indexFiles")}
          activeSettings={activeSettings["indexFiles"]}
        />

        <SettingsRow
          label="Own Referrers"
          value={ownReferrerInput}
          onTextType={t => {
            this.setState({ ownReferrerInput: t });
          }}
          onAdd={this.addTextSetting("ownReferrerInput", "ownReferrers")}
          onRemove={this.removeSetting("ownReferrers")}
          activeSettings={activeSettings["ownReferrers"]}
        />

        <SettingsRow
          label="White Page Search Terms"
          value={whitepageInput}
          onTextType={t => {
            this.setState({ whitepageInput: t });
          }}
          onAdd={this.addTextSetting("whitepageInput", "whitepages")}
          onRemove={this.removeSetting("whitepages")}
          activeSettings={activeSettings["whitepages"]}
        />

        <SettingsRow
          label="Short Query"
          value={shortQueryInput}
          onTextType={t => {
            this.setState({ shortQueryInput: t });
          }}
          onAdd={this.addTextSetting("shortQueryInput", "shortQueries")}
          onRemove={this.removeSetting("shortQueries")}
          activeSettings={activeSettings["shortQueries"]}
        />
      </div>
    );
  }
}
