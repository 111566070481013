import React from "react";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import SettingsTargetContainer from "containers/SettingsTargetContainer";
import VisitorDemographics from "views/components/VisitorDemographics/VisitorDemographics";
import PageSteps from "views/sections/WelcomeScreen/PageSteps";

class Screen3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      targets: null,
      madeChange: false
    };
  }

  render() {
    const editedTargets = this.state.targets;

    return (
      <SettingsTargetContainer>
        {({ targets = [], pages = [], saveTargets }) => {
          return (
            <React.Fragment>
              <header className="welcome-screen__header">
                <h1>
                  Set Up Your Target Audience{" "}
                  <ThomasIcon
                    attribute="HIGH_VALUE_AUDIENCE"
                    customClass="n"
                    hideToolTip
                  />
                </h1>
              </header>
              <div className="welcome-screen__body">
                <h3>This page enables you to set demographic targets</h3>
                <p>
                  Easily set up flags for Industry, Company Size, Revenue Range,
                  States, Provinces and Countries you want to target. These
                  targets will affect the display of data across the WebTrax
                  reporting site
                </p>
                <div className="welcome-screen--target-audience">
                  <VisitorDemographics
                    noHeader
                    activeTargets={editedTargets || targets}
                    updateTargets={t =>
                      this.setState({
                        targets: t,
                        madeChange: true
                      })
                    }
                  />
                </div>
              </div>
              <footer className="welcome-screen__footer">
                <PageSteps step={3} onNavClick={this.props.onNavPage} />
                <button
                  className="btn btn-primary btn-lg next-step-btn"
                  onClick={() => {
                    saveTargets({
                      variables: {
                        pages: pages.map(p => p.value),
                        targets: (editedTargets || targets).map(
                          ({ label, value, targetType }) => ({
                            label,
                            value,
                            targetType
                          })
                        )
                      }
                    });
                    this.props.onNextStep();
                  }}
                >
                  Set Up Target Audience
                </button>
                <div className="skip-step-link">
                  <a href="#next-step" onClick={() => this.props.onNextStep()}>
                    Set Up Later
                  </a>
                </div>
              </footer>
            </React.Fragment>
          );
        }}
      </SettingsTargetContainer>
    );
  }
}

export default Screen3;
