import React from "react";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import BlueprintTable from "views/components/BlueprintTable/BlueprintTable";
import CampaignContainer from "containers/CampaignContainer";

const mockHeadings = [
  {
    key: "companyName",
    title: "Organization"
  },
  {
    key: "industry",
    title: "Industry"
  },
  {
    key: "revenueRange",
    title: "Revenue Range"
  },
  {
    key: "employeeRange",
    title: "Company Size"
  },
  {
    key: "city",
    title: "City"
  },
  {
    key: "state",
    title: "State"
  }
];

const SecondPage = ({ campaignId, start, end }) => {
  return (
    <CampaignContainer
      campaignId={campaignId}
      start={start}
      end={end}
      queryKey="top-visitors"
    >
      {({ campaign, loading }) => {
        const { topVisitors = [] } = campaign;

        const [clickers, nonclickers] = topVisitors.reduce(
          ([clickers, nonclickers], curr) => {
            if (curr.clicks > 0) {
              clickers.push({
                ...curr,
                companyName: () => (
                  <div className="campaigns-dash-table__company">
                    <ThomasIcon attribute="CAMPAIGN" />
                    {curr.organization}
                  </div>
                )
              });
            } else {
              nonclickers.push({
                ...curr,
                companyName: curr.organization
              });
            }

            return [clickers, nonclickers];
          },
          [[], []]
        );

        nonclickers.sort((a, b) => {
          return b.organization - a.organization;
        });
        return (
          <div className="pdf-second-page-content page-break-before">
            <div className="pdf_second-page-subheader">
              <h2>Most Identifiable Campaign Audience</h2>
              <p>
                Sample of idenfified organizations that clicked or viewed your
                ad. Icon indicates Organizations that have clicked your ads.
              </p>
            </div>

            <table className="table-container">
              <thead>
                <tr>
                  <td>&nbsp;</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <BlueprintTable
                      data={clickers
                        .sort((a, b) => {
                          let textA = a.organization.toUpperCase();
                          let textB = b.organization.toUpperCase();
                          return textA < textB ? -1 : textA > textB ? 1 : 0;
                        })
                        .concat(
                          nonclickers.sort((a, b) => {
                            let textA = a.organization.toUpperCase();
                            let textB = b.organization.toUpperCase();
                            return textA < textB ? -1 : textA > textB ? 1 : 0;
                          })
                        )}
                      headings={mockHeadings}
                      onPageUpdate={() => {
                        console.log("updatingPage");
                      }}
                      customClass="advanced-table content-card pdf-table"
                    />
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td>&nbsp;</td>
                </tr>
              </tfoot>
            </table>
            <table>
              <tr>
                <td>
                  <div className="pdf_disclaimer">
                    <p
                      className="font-size-xxs"
                      style={{ marginTop: "2.5rem" }}
                    >
                      The use of this report and any information contained
                      herein is subject to the terms and conditions governing
                      use of ThomasNet.com, including ThomasNet.com/ClientCenter
                      and ThomasNet.com/SalesResource.
                    </p>
                    <div className="pdf_disclaimer-content">
                      <img
                        className="pdf_ad-pic"
                        src="/static/media/auditBureau.9b4fc99e.png"
                        alt="audit-bureau"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "column",
                          paddingTop: "10px"
                        }}
                      >
                        <p className="font-size-xxs">
                          Subsidiary of Audit Bureau of Circulations
                        </p>
                        <p className="font-size-xxs">
                          48 W. Seegers Road, Arlington Heights, IL 60005-3913
                          Tel: 847-605-0909 Web: www.auditedmedia.com
                        </p>
                        <p className="font-size-xxs">
                          The ThomasNet.com reports are audited monthly for User
                          Sessions (Visits) and Page Views.
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        );
      }}
    </CampaignContainer>
  );
};

export default SecondPage;
