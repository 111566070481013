import React from "react";
import VisitorCard from "views/components/VisitorCard/VisitorCard";
import VisitorLocation from "views/components/VisitorLocation/VisitorLocation";
import DataAttributesContainer from "containers/DataAttributesContainer";

import "./CardGroup.scss";
import LocationsContainer from "containers/LocationsContainer";

const CardGroup = ({ page }) => {
  return (
    <section className="card-group">
      <div className="card-group--left">
        <div className="card visitor-locations">
          <LocationsContainer page={page}>
            {({ data, onUpdate }) => {
              return (
                <VisitorLocation
                  center={{ lat: 38, lng: -95 }}
                  zoom={3}
                  title="US Visitor Locations"
                  data={data}
                  onUpdate={onUpdate}
                />
              );
            }}
          </LocationsContainer>
          <LocationsContainer page={page}>
            {({ data, onUpdate }) => {
              return (
                <VisitorLocation
                  center={{ lat: 24, lng: -5 }}
                  zoom={2}
                  international
                  title="International Visits"
                  data={data}
                  onUpdate={onUpdate}
                />
              );
            }}
          </LocationsContainer>
          <p className="visitor-pins-disclaimer">
            Pins displayed represent visitors with known geography.
          </p>
        </div>
      </div>
      <div className="card-group--right">
        <DataAttributesContainer queryKey="industries" page={page}>
          {({ loading, data }) => {
            const parsedData = data
              .reduce((prev, val) => {
                const match = prev.find(p => p.label === val.label);

                if (match) {
                  match.value += val.value; //This updates the matched value in `prev`.
                  return prev;
                }

                prev.push({ ...val });
                return prev;
              }, [])
              .sort((a, b) => b.value - a.value);

            return (
              <VisitorCard
                title="Visitors By Industry"
                data={parsedData}
                loading={loading}
              />
            );
          }}
        </DataAttributesContainer>

        <DataAttributesContainer queryKey="revenueRanges" page={page}>
          {({ data, loading }) => {
            return (
              <VisitorCard
                title="Visitors By Revenue Range"
                data={data}
                loading={loading}
              />
            );
          }}
        </DataAttributesContainer>

        <DataAttributesContainer queryKey="companySizes" page={page}>
          {({ data, loading }) => {
            return (
              <VisitorCard
                title="Visitors By Company Size"
                data={data}
                loading={loading}
              />
            );
          }}
        </DataAttributesContainer>
      </div>
    </section>
  );
};

export default CardGroup;
