import React from "react";
import "./ThomasIcon.css";

import { AppContext } from "views/components/App/app-context";
import { IconMap, Attribute } from "./IconMap";

interface Props {
  attribute: string;
  customClass?: string;
  customIconClass?: string;
  hideToolTip?: boolean;
  showName?: boolean;
  openHelpMenu?: Function;
  position?: string;
  raw?: boolean;
}

const ThomasIcon = ({
  attribute,
  customClass,
  customIconClass,
  hideToolTip,
  showName,
  openHelpMenu,
  position,
  raw
}: Props) => {
  let icon = IconMap.get(attribute as Attribute);

  return !raw ? (
    <AppContext.Consumer>
      {({ toggleHelp }) => {
        return (
          <span
            data-balloon={hideToolTip || !icon ? null : icon.name}
            data-balloon-pos={position || "right"}
            className={`${customClass ? customClass : "thomas-icon"}`}
            style={{ margin: 0 }}
          >
            {icon &&
              (icon.icon ? (
                <svg
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`icon ${customIconClass ? customIconClass : ""} ${
                    icon.color ? icon.color : ""
                  }`}
                  onClick={openHelpMenu && toggleHelp}
                >
                  {!icon ? null : icon.icon()}
                </svg>
              ) : (
                <img
                  className={`${customClass ? customClass : ""}`}
                  src={icon.img}
                  alt={attribute}
                />
              ))}

            {showName && (
              <div className="icon-name-description">
                {!icon ? null : icon.name}
              </div>
            )}
          </span>
        );
      }}
    </AppContext.Consumer>
  ) : (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon ${customIconClass ? customIconClass : ""}`}
    >
      {!icon ? null : icon.icon()}
    </svg>
  );
};

export default ThomasIcon;
