import React from "react";
import TermsAndConditions from "views/sections/WelcomeScreen/TermsAndConditions";
import PageSteps from "views/sections/WelcomeScreen/PageSteps";
import UserSettingsContainer from "containers/UserSettingsContainer";

class Screen1 extends React.Component {
    state = { earlyPress: false };
    render() {
        const { onCheckBox, onNextStep, accepted } = this.props;
        return (
            <React.Fragment>
                <header className="welcome-screen__header">
                    <h1>Welcome to WebTrax</h1>
                </header>
                <div className="welcome-screen__body">
                    <h3>Get Actionable Data to Engage In-Market Buyers</h3>
                    <div className="welcome-screen-terms-data">
                        <div className="db-block data-block-1">
                            <span className="data-block_header">
                                <b>Track</b>
                                <br />
                                All Your Opportunities
                            </span>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/visual-track-opportunities.png"
                                }
                                alt="track"
                            />
                        </div>
                        <div className="db-block data-block-2">
                            <span className="data-block_header">
                                <b>Identify</b>
                                <br />
                                Your High-Value Audience
                            </span>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/visual-high-value.png"
                                }
                                alt="high-value"
                            />
                        </div>
                        <div className="db-block data-block-3">
                            <span className="data-block_header">
                                <b>Engage</b>
                                <br />
                                Your Prospects
                            </span>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/visual-engage-prospects.png"
                                }
                                alt="engage"
                            />
                        </div>
                    </div>

                    <TermsAndConditions
                        accepted={accepted}
                        onAccept={onCheckBox}
                        earlyPress={this.state.earlyPress}
                    />
                </div>

                <footer className="welcome-screen__footer">
                    <PageSteps step={1} onNavClick={() => {}} />

                    <UserSettingsContainer>
                        {({ savePreferences, loading }) => {
                            if (loading) {
                                return null;
                            }

                            return (
                                <button
                                    className="btn btn-primary btn-lg next-step-btn"
                                    onClick={() => {
                                        this.setState({ earlyPress: true });
                                        if (accepted) {
                                            onNextStep();
                                            savePreferences({
                                                variables: {
                                                    prefs: {
                                                        hasAcceptedTerms: true
                                                    }
                                                }
                                            });
                                        }
                                    }}
                                >
                                    Accept and Continue
                                </button>
                            );
                        }}
                    </UserSettingsContainer>

                    {/* <button
                        className="btn btn-primary btn-lg next-step-btn"
                        onClick={() => {
                            this.setState({ earlyPress: true });
                            if (accepted) {
                                onNextStep();
                                
                            }
                        }}
                    >
                        Accept and Continue
                    </button> */}
                </footer>
            </React.Fragment>
        );
    }
}

export default Screen1;
