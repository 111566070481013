import React from "react";
import PageHeader from "views/sections/PageHeader/PageHeader";
// import hvActions from "views/pages/Home/data/hvActions";
// import normal from "img/ico-timeline-normal.svg";
import HvActionsTableSection from "views/sections/TableSection/HvActionsTableSection";
import ActivitiesContainer from "containers/ActivitiesContainer";
import EmptyHighValueActions from "views/sections/TableSection/EmptyHighValueActions";

// const actionDetailsTitle = type => {
// 	if (type === "RFI") return "RFI Detail";
// 	else if (type === "PHONE_CALL") return "Thomasnet: Phone Inquiry Detail";
// 	else if (type === "ASSET_DOWNLOAD") return "Asset Download Detail";
// 	else return "Details";
// };

class SessionsActions extends React.Component {
  render() {
    const { title } = this.props;

    return (
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <ActivitiesContainer limit={10} offset={0} defaultFilters={["lead"]}>
          {({
            data,
            user,
            startDate,
            endDate,
            vettedFilters,
            onUpdate,
            onFilterUpdate,
            totalPages,
            filteredActivitiesCount,
            allActivitiesCount
          }) => {
            const noData = !allActivitiesCount;

            return (
              <React.Fragment>
                <PageHeader title={title} />

                {noData ? (
                  <EmptyHighValueActions />
                ) : (
                  <React.Fragment>
                    <p className="page-header--sub">
                      Note that tracked Phone Calls are classified and
                      transcribed into summary notes before displaying here and
                      in Thomas Client Center.
                    </p>
                    <HvActionsTableSection
                      data={data}
                      user={user}
                      startDate={startDate}
                      endDate={endDate}
                      vettedFilters={vettedFilters}
                      onUpdate={onUpdate}
                      totalPages={totalPages}
                      filteredActivitiesCount={filteredActivitiesCount}
                      allActivitiesCount={allActivitiesCount}
                      onFilterUpdate={onFilterUpdate}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          }}
        </ActivitiesContainer>
      </div>
    );
  }
}

export default SessionsActions;
