var iconOrders = {
    HIGH_VALUE_AUDIENCE: 0,
    HIGH_VALUE_PAGE: 1,
    THOMASNET_ACTIVITY: 2,
    ASSET_DOWNLOAD: 3,
    PHONE_CALL: 4,
    RFI: 5,
    SHORTLISTED: 6,
    CLIENT_SITE_ACTIVITY: 7,
    KEYWORD_SEARCH: 8,
    ADVANCED_SEARCH: 9,
    PAGE_EMAILED: 10,
    PAGE_FAVORITED: 11,
    COMPARED_ITEMS: 12,
    CAD: 13,
    PRODUCT_VIEW: 14,
    PAGE_PRINTED: 15,
    THOMAS_REGISTERED_USER: 16
    // TOP_PAGES: 17,
    // ENTRY_PAGES: 18,
    // EXIT_PAGES: 19,
    // FUNNEL_ANALYSIS: 20,
    // EXTERNAL_LINK: 21
};

export default iconOrders;
