import React from "react";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import "./CompanyInfo.scss";
import RevenueRange from "views/components/RevenueRange/RevenueRange";

const checkForTargets = (userTargets, companyTargets, type) => {
  if (userTargets) {
    return userTargets
      .filter(target => target.targetType === type)
      .some(newTarget => companyTargets.includes(newTarget.value));
  }
};

const CompanyInfoRow = ({
  user,
  target,
  targetType,
  heading,
  formattedValue
}) => {
  const targetMatch = checkForTargets(user.targets, [target], targetType);
  return (
    <div className="company-info__details-row">
      <div className="company-info__details-icon">
        {targetMatch && (
          <ThomasIcon
            customClass="font-size-sm"
            customIconClass="font-size-md"
            attribute="HIGH_VALUE_AUDIENCE"
          />
        )}
      </div>
      <div
        className={`company-info__details-item ${
          targetMatch ? "" : "no-icon-row"
        }`}
      >
        <b>{heading}:</b>
        <div>{formattedValue || target}</div>
      </div>
    </div>
  );
};

//C4
const CompanyInfo = ({ company = {}, user = {} }) => {
  if (!company) {
    return null;
  }

  const salaryRange = RevenueRange({
    revenueRange: company.companyRevenueRange
  });

  const companyTitle = company.companyIsISP ? (
    <React.Fragment>
      Unknown{" "}
      <small style={{ fontWeight: "100" }}>{` (via ${company.title})`}</small>
    </React.Fragment>
  ) : (
    company.title
  );

  return (
    <div
      className={
        !company.img || company.img === "/img/image-placeholder.png"
          ? "company-info--logo-unavailable"
          : "company-info"
      }
    >
      <div className="company-info__logo">
        {company.img && (
          <img
            className={
              !company.img || company.img === "/img/image-placeholder.png"
                ? "company-info__logo--display-none"
                : "company-info__logo--display"
            }
            src={company.img}
            alt="company"
            width="174"
            height="161"
          />
        )}
      </div>

      <div className="company-info__name">
        <div className="company-info__title-box">
          <h4 style={{ marginBottom: 0 }}>{companyTitle}</h4>
          <span>{company.address}</span>
        </div>

        <div className="company-info__details-box">
          <CompanyInfoRow
            user={user}
            target={company.companyIndustry}
            targetType="Industry"
            heading="Industry"
          />

          <CompanyInfoRow
            user={user}
            target={company.companyEmployees}
            targetType="Size"
            heading="Company Size"
          />

          <CompanyInfoRow
            user={user}
            target={company.companyRevenueRange}
            formattedValue={salaryRange}
            targetType="Revenue"
            heading="Revenue Range"
          />

          {company.thomasnet && (
            <div className="company-info__details-row">
              <div className="company-info__details-icon">
                <ThomasIcon attribute="THOMASNET_ACTIVITY" />
              </div>
              <div className="company-info__details-item">
                <div>Thomasnet.com user</div>
              </div>
            </div>
          )}
        </div>
      </div>

      {company.companySummary && (
        <div className="company-info__description">
          {company.companySummary}
        </div>
      )}
    </div>
  );
};

export default CompanyInfo;
