import { gql } from "apollo-boost";
import { graphql, compose } from "react-apollo";
import App from "views/components/App/App";

const getNavQuery = gql`
    query getNav {
        nav {
            title
            link
            key
            children {
                title
                link
                key
            }
        }

        user {
            id
            name
            role
            email
            tinid
            prefs {
                hasAcceptedTerms
            }
            account {
                id
                name
                memberSince
                logo
                taggedUrls
            }
        }
    }
`;

export default compose(
    graphql(getNavQuery, {
        props: ({ data: { loading, error, nav, user } = {} }) => {
            // if (loading || error) {
            //     return {
            //         nav: null,
            //         user: null,
            //         account: null
            //     };
            // }

            return {
                loading,
                error,
                nav: nav,
                user: user,
                account: user && user.account
            };
        }
    })
)(App);
