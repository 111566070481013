import React from "react";
import { Query, Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import {
    QUERY_PAGE_CONFIG,
    MUTATION_UPDATE_PAGE_CONFIG
} from "resolvers/sessions";

class TargetsProvider extends React.Component {
    componentDidMount() {
        this.handleOnLoad();
    }

    componentDidUpdate() {
        // this.handleOnLoad();
    }

    handleOnLoad() {
        this.props.useAllTargets && this.props.onLoad && this.props.onLoad();
    }

    render() {
        const { children } = this.props;

        return children;
    }
}

const QUERY_TARGET_FILTERS = gql`
    query getTargetFilters {
        sessionsInfo {
            targets {
                shortLabel
                targetType
                value
                label
                filterType
            }
        }
    }
`;

const TargetsProviderContainer = ({ children, page }) => {
    return (
        <Query query={QUERY_PAGE_CONFIG} variables={{ page }}>
            {({ data: { page, lastSavedTimestamp }, client }) => {
                if (!page) {
                    return children;
                }

                return (
                    <Mutation mutation={MUTATION_UPDATE_PAGE_CONFIG}>
                        {updatePage => (
                            <TargetsProvider
                                useAllTargets={page && page.useAllTargets}
                                onLoad={async () => {
                                    const { data } = await client.query({
                                        query: QUERY_TARGET_FILTERS,
                                        fetchPolicy: "no-cache"
                                    });

                                    if (data && data.sessionsInfo.targets) {
                                        updatePage({
                                            variables: {
                                                page,
                                                data: {
                                                    ...page,
                                                    activeFilters: page.activeFilters.concat(
                                                        data.sessionsInfo
                                                            .targets
                                                    ),
                                                    useAllTargets: false
                                                }
                                            }
                                        });
                                    }
                                }}
                            >
                                {children}
                            </TargetsProvider>
                        )}
                    </Mutation>
                );
            }}
        </Query>
    );
};

export default TargetsProviderContainer;
