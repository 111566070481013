import React from "react";
import Legend from "./Legend";
import { Pie } from "@nivo/pie";
import colors from "./__mocks__/colors";
import "./PieChart.css";

export default class PDFPieChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: ""
    };
  }

  render() {
    const len = this.props.data.map(j => j.value).reduce((a, b) => a + b, 0);

    const data = this.props.data
      .filter(ind => (ind.value * 100) / len > 0.5) // Hide thiings that are 0 % (less than .5 on Piecharts Scale)
      .map((d, i) => ({ ...d, fill: colors[i] }));

    const {
      legend,
      customClass,
      loading,
      width = 150,
      height = 150,
      innerRadius = 0.4,
      interactive = false,
      customToolTip = null
    } = this.props;

    return (
      <div className={`${customClass ? customClass : ""}`}>
        {loading ? (
          <div className="animated-background piechart-loading-donut">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 110 110"
              className="no-marg"
            >
              <g fill="#FFF" fillRule="evenodd">
                <path d="M0 0h115v115H0V0zm57.5 109c28.443 0 51.5-23.057 51.5-51.5S85.943 6 57.5 6 6 29.057 6 57.5 29.057 109 57.5 109z" />
                <circle cx="57.66" cy="57.66" r="19.66" />
              </g>
            </svg>
          </div>
        ) : (
          <Pie
            data={data}
            margin={{
              right: 0,
              top: 0,
              bottom: 0,
              left: 0
            }}
            colorBy={d => d.fill}
            width={width}
            height={height}
            innerRadius={innerRadius}
            padAngle={0.7}
            //sliceLabel={d => `${d.value} ok`}
            enableRadialLabels={false}
            enableSlicesLabels={false}
            isInteractive={interactive}
            onClick={i => {}}
            tooltipFormat={customToolTip}
            // myFunc={(d) => {
            //     if (this.state.active !== d.data.id) {
            //         this.setState({
            //             active : d.data.id
            //         });
            //     }
            // }}
            myFunc={() => {}}
          />
        )}

        {legend && (
          <Legend data={data} active={this.state.active} loading={loading} />
        )}
      </div>
    );
  }
}
