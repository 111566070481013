import React from "react";
import PieChart from "views/components/PieChart/PieChart";
// import { Link } from "react-router-dom";
import "./VisitorCard.css";
// import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";

const VisitorCard = ({ title, data, loading }) => (
    <div className="card">
        <h4 className="chart-title">{title}</h4>
        {/* {data.length > 0 ? (
            <PieChart data={data} loading={loading} legend />
        ) : (
            <div className="empty-visitor-card">
                <p>
                    <small>
                        <ThomasIcon attribute="HELP" customClass="mr-1" />
                        <b>What is this?</b>
                        <br />
                        You can set targets for industries, company size,
                        revenue range, state/provinces, and countries. WebTrax
                        will reflect when your visitors match these demographic
                        selections.
                    </small>
                </p>
                <Link to="/targets">
                    <button className="btn btn-primary">
                        Customize Targets
                    </button>
                </Link>
            </div>
        )} */}
        <PieChart data={data} loading={loading} legend />
    </div>
);

export default VisitorCard;
