import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

momentDurationFormatSetup(moment);

const FormatDuration = duration => {
  if (!duration) {
    return "Single Page";
  }

  return moment.duration(duration, "s").format();

  // return moment.utc(moment.duration(duration, "s").as("ms")).format("HH:mm:ss")
};

export default FormatDuration;
