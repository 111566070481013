import React from "react";
import "./ResultsTable.css";
import Pagination from "../Pagination/Pagination";
import resultsTableCols from "./resultsTableCols.js";
import RouterLink from "views/components/RouterLink/RouterLink";
class ResultsTable extends React.Component {
  constructor() {
    super();

    this.state = {
      currentPage: 1,
      sortKey: "visitDate",
      sort: [
        {
          orderBy: "time_expanded",
          direction: "DESC"
        }
      ],
      direction: "desc"
    };
  }

  updateSort(key, sortBy, onUpdate) {
    const { direction, sortKey, currentPage } = this.state;
    const newDirection =
      sortKey === key ? (direction === "desc" ? "asc" : "desc") : "desc";

    this.setState(
      {
        sortKey: key,
        currentPage: newDirection === direction ? 1 : currentPage,
        direction: newDirection,
        sort: sortBy[newDirection]
      },
      onUpdate(sortBy[newDirection], currentPage)
    );
  }

  updatePage(page, onUpdate) {
    this.setState(
      {
        currentPage: page
      },
      onUpdate(this.state.sort, page)
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate ||
      this.props.displayAmount !== prevProps.displayAmount
    ) {
      this.setState({
        currentPage: 1
      });
    }
  }

  render() {
    const { sessions = {}, onUpdate, displayAmount } = this.props;
    const { edges = [], pageInfo } = sessions;
    const { direction, sortKey } = this.state;

    const totalPages = Math.ceil(
      pageInfo && pageInfo.totalCount / displayAmount
    );

    return (
      <div className="results-table">
        <table className="results-table__table table">
          <thead className="results-table__head">
            <tr>
              {resultsTableCols.map(col => (
                <th
                  key={col.key}
                  className={col.sort ? "results-table__sortable" : ""}
                  onClick={
                    col.sort
                      ? () => this.updateSort(col.key, col.sort, onUpdate)
                      : null
                  }
                >
                  {col.label}
                  {sortKey === col.key && (
                    <div
                      style={{ float: "right" }}
                      className={
                        direction === "desc" ? "arrow-desc" : "arrow-asc"
                      }
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/img/arrow.svg"}
                        alt="arrow"
                      />
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {edges.map(edge => {
              const session = edge.node;

              return (
                <RouterLink
                  urlClickHandler={() => {
                    return `/sessions/visitor/${session.id}`;
                  }}
                  key={edge.cursor}
                >
                  {({ onExecuteLink }) => {
                    return (
                      <tr
                        className="thomas-row-clickable"
                        onClick={onExecuteLink}
                      >
                        {resultsTableCols.map(col => {
                          return col.render ? (
                            col.render(session, col.key)
                          ) : (
                            <td
                              className={`results-table__${col.key}`}
                              key={col.key}
                            >
                              {session[col.prop]
                                ? session[col.prop]
                                : col.key === "referrer"
                                ? "Direct to Site"
                                : "Not Available"}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }}
                </RouterLink>
              );
            })}
          </tbody>
        </table>

        <Pagination
          totalPages={totalPages}
          currentPage={this.state.currentPage}
          onUpdate={p => this.updatePage(p, onUpdate)}
        />
      </div>
    );
  }
}

export default ResultsTable;
