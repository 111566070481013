const searchTermsKeys = [
    {
        label: "Search Term",
        key: "term",
        sort: {
            desc: [
                {
                    orderBy: "term",
                    direction: "DESC"
                },
                {
                    orderBy: "termct",
                    direction: "DESC"
                }
            ],
            asc: [
                {
                    orderBy: "term",
                    direction: "ASC"
                },
                {
                    orderBy: "termct",
                    direction: "DESC"
                }
            ]
        }
    },
    {
        label: "Bounce Rate",
        key: "bounceRate",
        sort: {
            desc: [
                {
                    orderBy: "bounceRate",
                    direction: "DESC"
                },
                {
                    orderBy: "termct",
                    direction: "DESC"
                },
                {
                    orderBy: "term",
                    direction: "ASC"
                }
            ],
            asc: [
                {
                    orderBy: "bounceRate",
                    direction: "ASC"
                },
                {
                    orderBy: "termct",
                    direction: "DESC"
                },
                {
                    orderBy: "term",
                    direction: "ASC"
                }
            ]
        }
    },
    {
        label: "Count",
        key: "termct",
        sort: {
            desc: [
                {
                    orderBy: "termct",
                    direction: "DESC"
                },
                {
                    orderBy: "term",
                    direction: "ASC"
                }
            ],
            asc: [
                {
                    orderBy: "termct",
                    direction: "ASC"
                },
                {
                    orderBy: "term",
                    direction: "ASC"
                }
            ]
        }
    }
];

export default searchTermsKeys;
