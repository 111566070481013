import React from "react";
import SiteHeader from "views/layouts/SiteHeader/SiteHeader";
import ErrorBoundary from "../../../errorBoundary";
import SiteFooter from "views/layouts/SiteFooter/SiteFooter";
import PageHeader from "views/sections/PageHeader/PageHeader";
import AccountsContainer from "containers/AccountsContainer";
import IndexPagination from "../Pagination/IndexPagination";
import DropDown from "../DropDown/DropDown";

// const accountsKeys = [
//     {
//         label: "Slug",
//         key: "slug"
//     },
//     {
//         label: "Name",
//         key: "name"
//     },
//     {
//         label: "Website",
//         key: "website"
//     }
// ];

class Accounts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            desc: false
        };

        this.accountClickHandler = this.accountClickHandler.bind(this);
    }

    accountClickHandler(accountId) {
        localStorage.setItem("wtid", accountId);

        window.location.href = "/";
    }

    render() {
        return (
            <div className="site-wrap logged-out">
                <SiteHeader />

                <ErrorBoundary>
                    <section className="site-content">
                        <main id="main" className="site-content__primary">
                            <div className="container">
                                <div className="card p-3 mb-0">
                                    <PageHeader
                                        title="Select an account"
                                        hideDatePicker={true}
                                        settingsIcon={false}
                                        hideHelpMenu
                                    />

                                    <AccountsContainer>
                                        {({ userAccounts }) => {
                                            /* 
                      The function sortOrder is a custom sort function to be passed into IndexPagination. It takes the indexes returned from IndexPagination,
                      then applies the sorting method in order to sort the items contained in tailOrdering into the end of the returned array result. The items
                      in tailOrdering are expected to be item indexes that result from indexing userAccounts. For example, in an IndexPagination result of 
                      [0-9, a, b, m, s, t, Other] from indexing userAccounts, a tailOrdering input of [m, 0-9, Other] should result in IndexPagination 
                      returning [a, b, s, t, m, 0-9, Other] as the index result.
                      */
                                            const tailOrdering = [
                                                "0-9",
                                                "Other"
                                            ];

                                            const sortOrder = (a, b) => {
                                                const aTailIndex = tailOrdering.indexOf(
                                                    a
                                                );
                                                const bTailIndex = tailOrdering.indexOf(
                                                    b
                                                );

                                                if (
                                                    aTailIndex > -1 &&
                                                    bTailIndex > -1
                                                ) {
                                                    return (
                                                        aTailIndex - bTailIndex
                                                    );
                                                }

                                                if (
                                                    aTailIndex === -1 &&
                                                    bTailIndex > -1
                                                ) {
                                                    return -1;
                                                }

                                                if (
                                                    bTailIndex === -1 &&
                                                    aTailIndex > -1
                                                ) {
                                                    return 1;
                                                }

                                                if (a < b) {
                                                    return -1;
                                                }

                                                if (a > b) {
                                                    return 1;
                                                }

                                                return 0;
                                            };

                                            return (
                                                <IndexPagination
                                                    source={userAccounts}
                                                    indexName={"name"}
                                                    sortOrder={sortOrder}
                                                >
                                                    {({
                                                        data = [],
                                                        keys = [],
                                                        filter,
                                                        selectedKey,
                                                        desc,
                                                        onKeyUpdate,
                                                        onFilterUpdate,
                                                        onOrderUpdate
                                                    }) => {
                                                        return (
                                                            <section className="thomas-table table">
                                                                <div className="results-filter">
                                                                    <div className="results-filter__options session-detail-row pl-0 pt-0 pr-0">
                                                                        <div className="results-filter--content">
                                                                            <div className="results-filter--index-pagination-filters active">
                                                                                <div className="results-filter--item pagination-index__list">
                                                                                    <div className="thomas-pagination">
                                                                                        {keys.map(
                                                                                            k => (
                                                                                                <div
                                                                                                    className={
                                                                                                        k ===
                                                                                                            selectedKey
                                                                                                            ? "pagination-item pagination-index__key active"
                                                                                                            : "pagination-item pagination-index__key"
                                                                                                    }
                                                                                                    key={
                                                                                                        k
                                                                                                    }
                                                                                                    onClick={() => {
                                                                                                        onKeyUpdate(
                                                                                                            k
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        k
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="results-filter--item pagination-index__dropdown">
                                                                                    <label className="results-filter__options_search-label">
                                                                                        Select
                                                                                        Filter
                                                                                    </label>
                                                                                    <DropDown
                                                                                        multi={
                                                                                            false
                                                                                        }
                                                                                        items={
                                                                                            keys
                                                                                        }
                                                                                        initValue={
                                                                                            "a"
                                                                                        }
                                                                                        onChange={data => {
                                                                                            onKeyUpdate(
                                                                                                data
                                                                                            );
                                                                                        }}
                                                                                        customClass="pagination-index__dropdown-custom"
                                                                                    />
                                                                                </div>
                                                                                <div className="results-filter--item pagination-index__search-bar mr-0">
                                                                                    <label className="results-filter__options_search-label">
                                                                                        Search
                                                                                        Term
                                                                                    </label>
                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        value={
                                                                                            filter
                                                                                        }
                                                                                        onChange={e => {
                                                                                            onFilterUpdate(
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="thomas-table--header thomas-table__grid analytics-table accounts-selection">
                                                                    <div
                                                                        onClick={() => {
                                                                            onOrderUpdate(
                                                                                !desc
                                                                            );
                                                                        }}
                                                                        className="thomas-table--title__sortable"
                                                                    >
                                                                        <div
                                                                            className={
                                                                                desc
                                                                                    ? "arrow-desc"
                                                                                    : "arrow-asc"
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    process
                                                                                        .env
                                                                                        .PUBLIC_URL +
                                                                                    "/img/arrow.svg"
                                                                                }
                                                                                alt="arrow"
                                                                            />
                                                                        </div>
                                                                        Name
                                                                    </div>
                                                                    <div className="thomas-table--title">
                                                                        WTID
                                                                    </div>
                                                                    <div className="thomas-table--title">
                                                                        Website
                                                                    </div>
                                                                </div>

                                                                {data.map(a => (
                                                                    <div
                                                                        key={
                                                                            a.id
                                                                        }
                                                                        onClick={e => {
                                                                            e.preventDefault();

                                                                            this.accountClickHandler(
                                                                                a.id
                                                                            );
                                                                        }}
                                                                        className="thomas-table--row thomas-row-clickable thomas-table__grid analytics-table accounts-selection"
                                                                    >
                                                                        <div>
                                                                            {
                                                                                a.name
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                a.slug
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                a.website
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </section>
                                                        );
                                                    }}
                                                </IndexPagination>
                                            );
                                        }}
                                    </AccountsContainer>
                                </div>
                            </div>
                        </main>
                    </section>
                    <SiteFooter />
                </ErrorBoundary>
            </div>
        );
    }
}

export default Accounts;
