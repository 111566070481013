import React from "react";
import GoogleMapReact from "google-map-react";

import Cluster from "./Cluster";

import "./VisitorLocation.scss";

const GOOGLE_MAPS_API_KEY = "AIzaSyAhwsyhXUjoNmcQ6MWaztfshoOht-Pte5A";

const VisitorLocation = ({
  title,
  data,
  center,
  zoom,
  international,
  onUpdate,
  customClass
}) => (
  <div className={customClass ? customClass : ""}>
    <h4>{title}</h4>

    <div className="map-display">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: GOOGLE_MAPS_API_KEY
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={international => ({ minZoom: international ? 2 : 3 })}
        onChange={({ center, zoom, bounds }) => {
          onUpdate({
            zoom,
            bounds
          });
        }}
      >
        {data.map((m, i, arr) => {
          return (
            <Cluster
              key={i}
              lat={m.geometry.coordinates[1]}
              lng={m.geometry.coordinates[0]}
              cluster={m}
              len={arr.length}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  </div>
);

export default VisitorLocation;
