import React from "react";
import { Prompt } from "react-router";

import PageHeader from "views/sections/PageHeader/PageHeader";
import DataIntegration from "views/components/DataIntegration/DataIntegration";
import BasicSettings from "views/components/BasicSettings/BasicSettings";
import ToggleSwitch from "views/components/ToggleSwitch/ToggleSwitch";
import AdvancedSettings from "views/components/AdvancedSettings/AdvancedSettings";
import SettingsTechnicalContainer from "containers/SettingsTechnicalContainer";

import { AppContext } from "views/components/App/app-context";

import "./Settings.scss";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import BotFiltering from "views/components/DataIntegration/BotFiltering";

const Settings = ({ title }) => (
  <SettingsTechnicalContainer>
    {({ user = {}, saveSettings, loading }) => {
      if (loading) {
        return null; //TODO: Add loading component
      }

      return (
        <SettingsTechnical
          loading={loading}
          title={title}
          settings={user.settings}
          user={user}
          saveSettings={saveSettings}
        />
      );
    }}
  </SettingsTechnicalContainer>
);

class SettingsTechnical extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: props.settings,
      madeChange: false,
      saved: false
    };

    this.saveSettings = this.saveSettings.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
  }

  componentDidUpdate = () => {
    window.onbeforeunload = () =>
      this.state.madeChange
        ? "You have made changes to your settings. Are you sure you want to leave the site?"
        : null;
  };

  saveSettings() {
    let settings = { ...this.state.settings };

    delete settings["__typename"];
    delete settings["dateImplemented"];

    this.props.saveSettings({
      variables: {
        settings
      },

      optimisticResponse: {
        __typename: "Mutation",
        saveSettings: {
          ...this.props.user,

          settings: this.state.settings
        }
      }
    });

    this.setState({ madeChange: false, saved: true });
  }

  updateSettings(field, val) {
    this.setState({
      settings: {
        ...this.state.settings,
        [field]: val
      },

      madeChange: true
    });
  }

  render() {
    const { title } = this.props;
    const { hits, loading, madeChange, settings, saved } = this.state;

    return (
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Prompt
          when={madeChange}
          message="Do you want to leave this page? Changes you made may not be saved."
        />

        <PageHeader title={title} hideDatePicker={true} />

        {!loading && settings && (
          <section className="card card-content">
            <DataIntegration
              dateImplemented={settings.dateImplemented}
              savedActive={this.props.settings.reportType === "E"}
              active={settings.reportType === "E"}
              onToggle={active => {
                this.updateSettings("reportType", active ? "E" : "T");
              }}
            />

            <BotFiltering
              savedActive={this.props.settings.botFiltering}
              active={settings.botFiltering}
              onToggle={active => {
                this.updateSettings("botFiltering", active);
              }}
            />

            <AppContext.Consumer>
              {({ hideForDemo }) =>
                !hideForDemo && (
                  <BasicSettings
                    urls={settings.urls}
                    email={settings.email}
                    defaultSessionsCount={settings.defaultSessionsCount}
                    timezoneOffset={settings.timezoneOffset}
                    updateSetting={this.updateSettings}
                  />
                )
              }
            </AppContext.Consumer>

            <AdvancedSettings
              activeSettings={settings}
              updateSetting={this.updateSettings}
            />

            <div style={{ padding: "1rem" }}>
              <ToggleSwitch
                label="Cookie"
                subLabel="Toggle to yes to have your hits counted"
                text="Count hits on my site"
                active={hits}
                onToggle={active => {
                  this.setState({
                    hits: active,
                    madeChange: true
                  });
                }}
              />
            </div>
          </section>
        )}

        <div className="card save-settings">
          <div className="save-settings--button">
            <button
              disabled={!madeChange}
              style={{ marginLeft: "1rem" }}
              className="btn btn-lg btn-primary"
              onClick={this.saveSettings}
            >
              Save Account Information
            </button>
            {saved && !madeChange && (
              <p className="mx-2">
                <ThomasIcon attribute="CHECKMARK" />
                Your account information has been saved
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
