const visitorInfo = {
    companyName: "Tata Communications",
    companyDomain: "Titan.co.in",
    location: "Bangalore, 19",
    zip: "10123",
    country: "India",
    industry: "Aerospace & Defense",
    subIndustry: "Aircraft",
    b2bOb2c: "B2B",
    audience: "Enterprise Business",
    revenue: "$1B - $2.5B",
    employeeRange: "",
    fortune1: false,
    fortune2: false
};

export default visitorInfo;
