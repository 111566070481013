import React from "react";

const ProgressSteps = ({ step }) => {
  return (
    <ul className="progress-steps">
      <li
        className={`progress-steps__step step-1 ${step <= 1 ? "active" : ""} ${
          step > 1 ? "completed" : ""
        }`}
      >
        <div className="progress-steps__step__icon">
          <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
          >
            <title>ico-account</title>
            <g fill="currentColor" fillRule="evenodd">
              <path d="M20.605 18.138c1.252-1.539 1.941-3.598 1.941-5.795 0-4.47-2.885-8.107-6.433-8.107-3.547 0-6.432 3.637-6.432 8.107 0 2.197.69 4.256 1.941 5.796 1.21 1.489 2.806 2.308 4.491 2.308 1.686 0 3.281-.82 4.492-2.309z" />
              <path d="M21.041 19.802c-.083.09-.166.178-.25.263a6.526 6.526 0 0 1-.321.293c-.089.078-.176.158-.267.23-.111.09-.226.17-.342.253-.093.066-.184.135-.279.196-.119.076-.24.144-.363.212-.096.054-.19.11-.286.16-.127.062-.257.115-.387.17-.097.04-.191.086-.29.12-.137.052-.277.09-.418.13-.092.027-.185.06-.278.083-.158.038-.318.061-.478.087-.08.013-.159.032-.239.042a6.205 6.205 0 0 1-1.46-.002c-.079-.01-.155-.029-.233-.04-.162-.026-.324-.05-.483-.089-.093-.022-.181-.054-.272-.08-.143-.042-.286-.08-.426-.132-.096-.035-.188-.079-.282-.118-.133-.056-.265-.11-.394-.174-.095-.047-.187-.103-.28-.156-.125-.07-.25-.138-.37-.216-.094-.06-.183-.126-.274-.192-.117-.083-.233-.164-.346-.256-.09-.072-.177-.15-.265-.228a7.953 7.953 0 0 1-.322-.294c-.086-.085-.168-.175-.252-.265-.067-.073-.138-.139-.203-.214-4.175 1.319-6.925 4.963-7.167 8.078h24.598c-.242-3.114-2.992-6.76-7.166-8.076-.066.076-.137.142-.205.215z" />
            </g>
          </svg>
        </div>
        <span
          className={`progress-steps__step__label ${
            step <= 1 ? "active" : ""
          } ${step > 1 ? "label-completed" : ""}`}
        >
          User Info
        </span>
      </li>

      <li
        className={`progress-steps__step step-2 ${step === 2 ? "active" : ""} ${
          step > 2 ? "completed" : ""
        }`}
      >
        <div className="progress-steps__step__icon">
          <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
          >
            <title>ico-supplier</title>
            <path
              d="M18.354 5.9l4.83-1.294a1 1 0 0 1 1.224.707l3.624 13.523a1 1 0 0 1-.707 1.225l-8.774 2.35a7.35 7.35 0 0 0-7.075-3.542L8.953 9.454A1 1 0 0 1 9.66 8.23l4.83-1.295 1.035 3.864 3.864-1.035L18.354 5.9zM6.905 21.13L3.347 7.85l-1.932.517a1 1 0 0 1-1.224-.707l-.26-.966A1 1 0 0 1 .64 5.47l1.932-.517 1.932-.518a1 1 0 0 1 1.224.707L9.534 19.35a7.33 7.33 0 0 0-2.629 1.78zM19.34 24.27l9.47-2.537a1 1 0 0 1 1.224.707l.259.966a1 1 0 0 1-.707 1.225l-10.088 2.703a7.306 7.306 0 0 0-.158-3.064zm-9.566-3.11a5.59 5.59 0 1 1 4.942 10.028 5.59 5.59 0 0 1-4.942-10.027zm3.41 6.921a2.128 2.128 0 0 0 .968-2.846 2.128 2.128 0 0 0-2.847-.967 2.128 2.128 0 0 0-.967 2.846 2.128 2.128 0 0 0 2.846.967z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
        </div>
        <span
          className={`progress-steps__step__label ${
            step === 2 ? "active" : ""
          } ${step > 2 ? "label-completed" : ""}`}
        >
          Company Info
        </span>
      </li>

      <li
        className={`progress-steps__step step-3 ${step > 2 ? "completed" : ""}`}
      >
        <div className="progress-steps__step__icon">
          <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
          >
            <title>ico-check-mark</title>
            <path
              d="M27.604 3.372a1.155 1.155 0 0 0-1.628.112L11.614 19.958 6.477 17.15a1.19 1.19 0 0 0-1.503 1.784l5.457 6.865a2.473 2.473 0 0 0 4.014-.198l.179-.278L27.816 4.867a1.153 1.153 0 0 0-.212-1.495z"
              fill="currentColor"
              fillRule="nonzero"
            />
          </svg>
        </div>
        <span
          className={`progress-steps__step__label ${
            step > 2 ? "label-completed" : ""
          }`}
        >
          Request Submitted
        </span>
      </li>
    </ul>
  );
};

export default ProgressSteps;
