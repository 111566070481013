import React from "react";
import PDFRechartsCard from "views/components/ChartCard/PDFRechartsCard";
import moment from "moment";
import PDFTopCountCardPie from "views/components/TopCountCard/PDFTopCountCardPie";
import CampaignContainer from "containers/CampaignContainer";

const CoverPage = ({ campaignId, start, end }) => {
  return (
    <CampaignContainer
      campaignId={campaignId}
      start={start}
      end={end}
      queryKey={"campaign-basics"}
    >
      {({ campaign, loading, reportStartDate, reportEndDate }) => {
        const {
          campaignActivity,
          visitorsByDevice,
          startDate,
          endDate,
          campaignName,
          companyInfo,
          verticalName,
          creatives
        } = campaign;

        creatives &&
          creatives.map(pvs => {
            pvs.width = pvs.platform_id === 1 ? 80 : 250;
            pvs.height = pvs.platform_id === 1 ? 180 : 60;
          });

        const campaignCreatives = () => {
          return (
            creatives &&
            creatives.map((pvs, i) => {
              return (
                <img
                  key={pvs.pvs_creative_id}
                  height={pvs.height}
                  width={pvs.width}
                  style={{ padding: "0.3rem" }}
                  src={`${pvs.pvsCreativeUrl}`}
                />
              );
            })
          );
        };

        const formatReportStartDate = moment(start).format("L");
        const formatReportEndDate = moment(end).format("L");
        const name = companyInfo && companyInfo.name;
        return (
          <>
            <div className="pdf_caption-background">
              <img
                src="https://cdn40.thomasnet.com/img40/thomas-logo-blue.svg"
                alt="Thomas For Industry Logo"
              />
              <h3>{name}</h3>
            </div>
            <div className="pdf_first-page-content page-break-after">
              <div className="pdf_row-container_medium display-flex-col">
                <h2>Premium Vertical Sponsorship: {verticalName}</h2>
                <h3>
                  Campaign Period {`${startDate} - ${endDate}`}{" "}
                  <b className="pipe">|</b> Report Date{" "}
                  {`${formatReportStartDate} - ${formatReportEndDate}`}
                </h3>
                <div className="pdf_pvs-creatives-container">
                  {campaignCreatives()}
                  <p className="pdf_description">
                    Limited inventory display ad that positions you as a premium
                    suppler to in-market buyers in all of the categories on
                    Thomasnet.com related to the vertical.
                  </p>
                </div>
              </div>

              <div className="pdf_row-container_small display-flex-row gray-border">
                <div className="pdf_impressions-and-clicks-container display-flex-col">
                  <div className="pdf_impressions">
                    <h2>
                      {(campaignActivity && campaignActivity.adImpressions) ||
                        0}
                    </h2>
                    <h3>Impressions</h3>
                  </div>
                  <div className="pdf_clicks">
                    <h2>
                      {(campaignActivity && campaignActivity.clicks) || 0}
                    </h2>
                    <h3>Clicks</h3>
                  </div>
                </div>
                <CampaignContainer
                  campaignId={campaignId}
                  start={start}
                  end={end}
                  queryKey="campaign-activity"
                >
                  {({ campaign, loading }) => {
                    const { campaignActivity } = campaign;
                    const activityByDate =
                      campaignActivity && campaignActivity.activityByDate;
                    activityByDate &&
                      activityByDate.map(cs => {
                        cs.Impressions = cs.adImpressions;
                      });

                    return (
                      <PDFRechartsCard
                        customClass="pdf_custom-session-graph--wrapper"
                        data={activityByDate}
                        loading={loading}
                        customToolTip="byDate"
                      />
                    );
                  }}
                </CampaignContainer>
              </div>

              <div
                className="pdf_row-container_small display-flex-row gray-border"
                style={{ padding: "1rem" }}
              >
                <div className="pdf_audience-by-industry__container display-flex-col">
                  <CampaignContainer
                    campaignId={campaignId}
                    start={start}
                    end={end}
                    queryKey="visitors-by-device"
                  >
                    {({ campaign, loading }) => {
                      const { visitorsByDevice = [] } = campaign;
                      return (
                        <PDFTopCountCardPie
                          link={false}
                          showFraction={false}
                          loading={false}
                          data={visitorsByDevice}
                          title="Audience by Device"
                          customPieProps={{}}
                        />
                      );
                    }}
                  </CampaignContainer>
                </div>
              </div>
              {/* <div className="pdf_disclaimer">
                <p className="font-size-xxs">
                  The use of this report and any information contained herein is
                  subject to the terms and conditions governing use of
                  ThomasNet.com, including ThomasNet.com/ClientCenter and
                  ThomasNet.com/SalesResource.
                </p>
                <div className="pdf_disclaimer-content">
                  <img
                    className="pdf_ad-pic"
                    style={{ height: "100px", marginRight: "1rem" }}
                    src="/static/media/auditBureau.9b4fc99e.png"
                    alt="audit-bureau"
                  />

                  <div
                    style={{
                      display: "flex",
                      flexFlow: "column",
                      paddingTop: "10px"
                    }}
                  >
                    <p className="font-size-xxs">
                      Subsidiary of Audit Bureau of Circulations
                    </p>
                    <p className="font-size-xxs">
                      48 W. Seegers Road, Arlington Heights, IL 60005-3913 Tel:
                      847-605-0909 Web: www.auditedmedia.com
                    </p>
                    <p className="font-size-xxs">
                      The ThomasNet.com reports are audited monthly for User
                      Sessions (Visits) and Page Views.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </>
        );
      }}
    </CampaignContainer>
  );
};

export default CoverPage;
