import React from "react";
import ResultsControl from "views/components/ResultsControl/ResultsControl";
import ResultsTimeline from "views/components/ResultsTimeline/ResultsTimeline";
import ResultsTable from "views/components/ResultsTable/ResultsTable";
import ResultsControlContainer from "containers/ResultsControlContainer";

/**
 * PageResults
 * Design Reference: "S3 section.page-results"
 */
const PageResults = ({
  visitorId,
  displayAmount,
  viewType,
  sessions,
  visitorSession,
  onLoadMore,
  onUpdate,
  loading,
  startDate,
  endDate,
  onClearAllFilters,
  page
}) => {
  return (
    <section className="page-results">
      <ResultsControlContainer>
        {({ onDisplayAmountChange, onViewTypeChange }) => (
          <ResultsControl
            displayAmount={displayAmount}
            viewType={viewType}
            onDisplayAmountChange={onDisplayAmountChange}
            onViewTypeChange={onViewTypeChange}
            hideView={visitorSession}
            visitorId={visitorId}
            page={page}
            showExport
            downloadType="SESSIONS"
          />
        )}
      </ResultsControlContainer>

      {
        {
          timeline: (
            <ResultsTimeline
              page={page}
              sessions={sessions}
              isVisitorSession={visitorSession}
              onLoadMore={onLoadMore}
              loading={loading}
              startDate={startDate}
              onClearAllFilters={onClearAllFilters}
            />
          ),
          table: (
            <ResultsTable
              sessions={sessions}
              isVisitorSession={visitorSession}
              loading={loading}
              onUpdate={onUpdate}
              startDate={startDate}
              endDate={endDate}
              displayAmount={displayAmount}
            />
          )
        }[viewType]
      }
    </section>
  );
};

export default PageResults;
