import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import DateRangeContainer from "containers/DateRangeContainer";

//Handle query params in the following format:
//?start=2019-01-01&end=2019-02-01

class CampaignContainer extends React.Component {
  state = {
    skip: true,
    mapProps: {
      // This is the starting point of US map
      top_left: {
        lat: 72.791675,
        lon: -137.98828
      },
      bottom_right: {
        lat: -5.471945000000002,
        lon: -37.79297
      },
      zoom: 3
    }
  };

  render() {
    const {
      start = "",
      end = "",
      children,
      campaignId,
      queryKey,
      ...props
    } = this.props;

    return (
      <DateRangeContainer>
        {({ startDate, endDate }) => {
          return (
            <Query
              query={campaignQueryMap[queryKey].query}
              variables={{
                startDate: start || startDate,
                endDate: end || endDate,
                campaignId: campaignId,
                mapProps: this.state.mapProps
              }}
            >
              {({ data: { campaign } = {}, loading, error }) => {
                if (error) {
                  console.log("Error", error);
                  // return <h1>Not authorized to access campaign.</h1>;
                }

                const onUpdate = ({ bounds, zoom }) => {
                  if (bounds && zoom) {
                    this.setState({
                      skip: false,
                      mapProps: {
                        top_left: {
                          lat: bounds.nw.lat,
                          lon: bounds.nw.lng
                        },
                        bottom_right: {
                          lat: bounds.se.lat,
                          lon: bounds.se.lng
                        },
                        zoom: zoom
                      }
                    });
                  }
                };

                return (
                  children &&
                  children({
                    ...props,
                    reportStartDate: startDate,
                    reportEndDate: endDate,
                    campaign: campaign || {},
                    loading,
                    onUpdate
                  })
                );
              }}
            </Query>
          );
        }}
      </DateRangeContainer>
    );
  }
}

const campaignQueryMap = {
  "campaign-basics": {
    query: gql`
      query getCampaignBasics(
        $startDate: String
        $endDate: String
        $campaignId: ID
      ) {
        campaign(
          startDate: $startDate
          endDate: $endDate
          campaignId: $campaignId
        ) {
          campaignName
          startDate
          endDate
          statusId
          status
          user
          verticalName
          creatives {
            pvs_creative_id
            pvsCreativeUrl
            platform_id
          }
          campaignActivity {
            adImpressions
            clicks
          }
          companyInfo {
            id
            name
          }
        }
      }
    `
  },
  "campaign-activity": {
    query: gql`
      query getCampaignActivity(
        $startDate: String
        $endDate: String
        $campaignId: ID
      ) {
        campaign(
          startDate: $startDate
          endDate: $endDate
          campaignId: $campaignId
        ) {
          campaignActivity {
            activityByDate {
              date
              adImpressions
              clicks
            }
          }
        }
      }
    `
  },
  "campaign-locations": {
    query: gql`
      query getCampaignVisitorLocations(
        $startDate: String
        $endDate: String
        $campaignId: ID
        $mapProps: MapBounds
      ) {
        campaign(
          startDate: $startDate
          endDate: $endDate
          campaignId: $campaignId
          mapProps: $mapProps
        ) {
          visitorLocations {
            geometry {
              coordinates
            }
            properties {
              cluster
              clusterId
              pointCount
            }
          }
        }
      }
    `
  },
  "visitors-by-industry": {
    query: gql`
      query getVisitorsByIndustry(
        $startDate: String
        $endDate: String
        $campaignId: ID
      ) {
        campaign(
          startDate: $startDate
          endDate: $endDate
          campaignId: $campaignId
        ) {
          visitorsByIndustry {
            id
            label
            value
          }
        }
      }
    `
  },
  "visitors-by-revenue": {
    query: gql`
      query getVisitorsByRevenueRange(
        $startDate: String
        $endDate: String
        $campaignId: ID
      ) {
        campaign(
          startDate: $startDate
          endDate: $endDate
          campaignId: $campaignId
        ) {
          visitorsByRevenueRange {
            id
            label
            value
          }
        }
      }
    `
  },
  "visitors-by-employee-range": {
    query: gql`
      query getVisitorsByEmployeeRange(
        $startDate: String
        $endDate: String
        $campaignId: ID
      ) {
        campaign(
          startDate: $startDate
          endDate: $endDate
          campaignId: $campaignId
        ) {
          visitorsByEmployeeRange {
            id
            label
            value
          }
        }
      }
    `
  },
  "visitors-by-device": {
    query: gql`
      query getVisitorsByDevice(
        $startDate: String
        $endDate: String
        $campaignId: ID
      ) {
        campaign(
          startDate: $startDate
          endDate: $endDate
          campaignId: $campaignId
        ) {
          visitorsByDevice {
            id
            label
            value
          }
        }
      }
    `
  },
  "top-visitors": {
    query: gql`
      query getTopVisitors(
        $startDate: String
        $endDate: String
        $campaignId: ID
      ) {
        campaign(
          startDate: $startDate
          endDate: $endDate
          campaignId: $campaignId
        ) {
          topVisitors {
            organization
            industry
            revenueRange
            employeeRange
            city
            state
            clicks
            impressions
          }
        }
      }
    `
  }
};

export default CampaignContainer;
