import React from "react";
import "./ChartHero.css";

import { Link } from "react-router-dom";
import SessionsGraph from "views/components/SessionsGraph/SessionsGraph";
import FormatDuration from "views/components/FormatDuration";

import ChartHeroLoader from "./ChartHeroLoader";

const ChartHero = ({ loading, hideTotal, data = {} }) => {
    return (
        <section className="chart-hero card">
            <header className="section-header">
                <div className="chart-hero--info">
                    <div className="chart-hero--title">
                        {!hideTotal && (
                            <div>
                                Total Sessions: <span>{data.totalCount}</span>
                            </div>
                        )}
                        <div>
                            Avg. Duration:{" "}
                            <span>{FormatDuration(data.avgDuration)}</span>
                        </div>
                        <div>
                            Avg. Pages:{" "}
                            <span>
                                {data.avgPages
                                    ? Math.round(data.avgPages * 10) / 10
                                    : 0}
                            </span>
                        </div>
                    </div>
                    <div className="chart-hero--legend">
                        <div className="chart-hero--legend-item">
                            <div
                                className="legend-item--square"
                                style={{ backgroundColor: "#72c7ef" }}
                            />
                            <div className="legend-item--label">
                                All Sessions
                            </div>
                        </div>
                        <div className="chart-hero--legend-item">
                            <div
                                className="legend-item--square"
                                style={{ backgroundColor: "#346f92" }}
                            />
                            <div className="legend-item--label">
                                Sessions Matching Your Targets
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chart-hero--customize">
                    <Link
                        to="/targets"
                        style={{
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none"
                        }}
                    >
                        <button className="btn btn-primary">
                            Customize Targets
                        </button>
                    </Link>
                </div>
            </header>
            {loading ? <ChartHeroLoader /> : <SessionsGraph data={data} />}
        </section>
    );
};

export default ChartHero;
