import React from "react";
import { Link } from "react-router-dom";

import "./TopCountCard.css";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import LoadingCountCard from "views/components/TopCountCard/LoadingCountCard";

class TopCountCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAll: false
    };
  }
  render() {
    const {
      data = [],
      loading,
      title,
      link,
      noCard,
      customClass,
      itemProp = "url",
      valueProp = "sessionsCount",
      showMoreLimit = 0
    } = this.props;

    const { showAll } = this.state;
    const canShowMore =
      !showAll && showMoreLimit !== 0 && data.length > showMoreLimit;

    var items = data;

    if (canShowMore) {
      items = data.slice(0, showMoreLimit);
    }

    return (
      <div
        className={`top-countcard ${noCard ? "" : "card"} ${
          customClass ? customClass : ""
        }`}
      >
        {title && <h4>{title}</h4>}

        {loading ? (
          <LoadingCountCard />
        ) : (
          <React.Fragment>
            <ol className="top-countcard--list">
              {items &&
                items.map((r, i) => (
                  <li key={i} className="top-countcard--row">
                    <div className="top-countcard--order">{i + 1}.</div>
                    <div className="top-countcard--row-content">
                      <div className="top-countcard--fill" />
                      <div className="top-countcard--ref">
                        {r.node[itemProp] === ""
                          ? "Direct to Site"
                          : r.node[itemProp]}
                      </div>
                      <div className="top-countcard--total">
                        {r.node[valueProp]}
                      </div>
                    </div>
                  </li>
                ))}
            </ol>

            {canShowMore && (
              <div
                className="search-term-show-more"
                onClick={() => {
                  this.setState({ showAll: true });
                }}
              >
                Show More <ThomasIcon attribute="OPEN" customClass="test" />
              </div>
            )}

            {link && (
              <Link to={link} className="top-countcard--view-all">
                View All {title} <ThomasIcon attribute="ARROW_RIGHT" />
              </Link>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default TopCountCard;
