import React from "react";
import { Link } from "react-router-dom";

import "./TopLinks.css";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import LoadingCountCard from "views/components/TopCountCard/LoadingCountCard";

const TopLinks = ({
    data,
    title,
    loading,
    link,
    itemProp = "url",
    valueProp = "count"
}) => {
    var mockData = [
        { node: { url: "/", count: 4971 } },
        { node: { url: "/dimensional/", count: 223 } },
        { node: { url: "/dime/buyers", count: 76 } },
        {
            node: {
                url: "/dimensiional-air-gauge-buying-guide.html",
                count: 33
            }
        },
        { node: { url: "/dimen/contact-us/", count: 7 } }
    ];

    if (!data) {
        data = mockData;
    }

    return (
        <div className="top-links card">
            <h3>{title}</h3>

            {loading ? (
                <LoadingCountCard />
            ) : (
                <React.Fragment>
                    <ol className="list top-countcard--list">
                        {data.map((r, i) => (
                            <li key={i} className="top-links--row">
                                <div className="top-links--order">{i + 1}.</div>
                                <a
                                    href={`http://${r.node["domain"]}${
                                        r.node[itemProp]
                                    }`}
                                    className="top-links--url"
                                >
                                    {r.node[itemProp]}
                                </a>
                                <div className="top-links--total">
                                    {r.node[valueProp]}
                                </div>
                            </li>
                        ))}
                    </ol>

                    {link && (
                        <Link to={link} className="top-countcard--view-all">
                            View All {title}{" "}
                            <ThomasIcon attribute="ARROW_RIGHT" />
                        </Link>
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

export default TopLinks;
