import React from "react";
import { gql } from "apollo-boost";
import { graphql, compose, Query } from "react-apollo";
import PageResults from "views/sections/PageResults/PageResults";
import SessionQueryContainer from "./SessionQueryContainer";
import { QUERY_SESSIONS } from "../resolvers/sessions";

const PageResultsWithData = ({
  params = {},
  visitorId,
  page,
  removeFilters,
  ...props
}) => (
  <SessionQueryContainer visitorId={visitorId} page={page}>
    {({ query, sessionsViewType, sessionsDisplayAmount }) => {
      if (params && params.page_depth) {
        query.depth = params.page_depth;
      }

      if (params && params.length) {
        const range = params.length.split("a");
        query.length = {
          min: range[0],
          max: range[1] || null
        };
      }

      return (
        <Query
          query={QUERY_SESSIONS}
          variables={{
            query,
            last: sessionsDisplayAmount,
            paginationType: "CURSOR"
          }}
          notifyOnNetworkStatusChange
        >
          {({ loading, error, fetchMore, data: { sessions } }) => {
            if (error)
              return (
                <div className="card">
                  <h3>An error has occurred. Please try again later.</h3>
                </div>
              );

            return (
              <PageResults
                visitorId={visitorId}
                page={page}
                displayAmount={sessionsDisplayAmount}
                viewType={sessionsViewType}
                sessions={sessions}
                visitorSession={!!visitorId}
                loading={loading}
                startDate={query.startDate}
                endDate={query.endDate}
                onClearAllFilters={() => {
                  removeFilters({
                    variables: { page }
                  });
                }}
                onLoadMore={endCursor =>
                  fetchMore({
                    variables: {
                      before: endCursor
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      const newEdges = fetchMoreResult.sessions.edges;
                      const { pageInfo } = fetchMoreResult.sessions;

                      if (!newEdges) return prev;

                      return {
                        ...prev,

                        sessions: {
                          ...prev.sessions,

                          pageInfo,

                          edges: [...prev.sessions.edges, ...newEdges]
                        }
                      };
                    }
                  })
                }
                onUpdate={(sort, p) => {
                  fetchMore({
                    variables: {
                      offset: (p - 1) * sessionsDisplayAmount,
                      limit: sessionsDisplayAmount,
                      paginationType: "OFFSET",
                      ordering: sort,
                      last: null
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      return fetchMoreResult;
                    }
                  });
                }}
                {...props}
              />
            );
          }}
        </Query>
      );
    }}
  </SessionQueryContainer>
);

const MUTATION_UPDATE_SESSIONS_CONFIG = gql`
  mutation updateSessionsConfig($displayAmount: int, $viewType: int) {
    updateSessionsConfig(viewType: $viewType, displayAmount: $displayAmount)
      @client
  }
`;

const REMOVE_FILTERS = gql`
  mutation removeFilters($filterType: String!, $page: String!) {
    removeFilters(filterType: $filterType, page: $page) @client
  }
`;

export default compose(
  graphql(MUTATION_UPDATE_SESSIONS_CONFIG, {
    props: ({ mutate }) => ({
      onViewTypeChange: viewType =>
        mutate({
          variables: { viewType }
        }),

      onDisplayAmountChange: displayAmount =>
        mutate({
          variables: { displayAmount }
        }),

      onSessionsConfigChange: config =>
        mutate({
          variables: config
        })
    })
  }),

  graphql(REMOVE_FILTERS, { name: "removeFilters" })
)(PageResultsWithData);
