import React from "react";

import "./TextBox.css";

const TextBox = ({
	label,
	subLabel,
	placeholder,
	customClass,
	customInputClass,
	value,
	onChange
}) => (
	<div className={`thomas-textbox ${customClass ? customClass : ""}`}>
		{label && (
			<div className="thomas-textbox__label">
				{label} {subLabel && <div className="thomas-textbox__sublabel">({subLabel})</div>}
			</div>
		)}
		<input
			className={`thomas-textbox__input form-control ${customInputClass ? customInputClass : ""}`}
			onChange={e => {
				onChange(e.target.value);
			}}
			value={value}
			placeholder={placeholder}
		/>
	</div>
);

export default TextBox;
