import React from "react";
import { debounce } from "lodash";

/** 
 * The class IndexPagination is used for creating an index of array data. First, IndexPagination will ingest the data. Then it will create a map of that data sorted into
 * indexes based upon the indexName parameter input. Lastly, IndexPagination will return the data for the user selected index, selectedKey. IndexPagination stores 
 * the state of the selected active index key as well as a search result filter.
 * 
 * @param {Object[]} source An array of objects containing the data to be sorted with one of the object attributes matching the indexName. 
 * @param {String} indexName The name of the data object key to create the indexes off of. 
 * @param {Function} [sortOrder] A function that custom sorts the order of the indexes of the resulting map.
 * @param {String} [defaultKey] The default index key to be returned from the dataset.
 * 
 * @returns {Object[]} data An array of objects containing the data under the selectedKey index. 
 * @returns {String[]} keys An array of all possible index keys, sorted by the sortOrder function.
 * @returns {String} filter The current string keyword filter that is being applied to the dataset.
 * @returns {String} selectedKey The current index key being used to select one of the data indexes.
 * @returns {Boolean} desc The current sort order of the data.
 * @returns {Function} onKeyUpdate A callback function to set the selectedKey.
 * @returns {Function} onFilterUpdate A callback function to set the search filter string.
 * @returns {Function} onOrderUpdate A callback function to set the data ordering.
 * 
 * */
export default class IndexPagination extends React.Component {
  constructor(props) {
    super(props);

    this.source = this.props.source;
    this.sortOrder = this.props.sortOrder;
    this.indexName = this.props.indexName;
    this.map = this.indexData(this.source);

    this.state = {
      selectedKey: this.props.defaultKey ? this.props.defaultKey : Array.from(this.map.keys()).sort(this.sortOrder)[0],
      resultFilter: "",
      filteredData: [],
      desc: false
    };
  }

  componentDidMount() {
    this.getData()
  }

  onKeyUpdate = key => {
    this.setState({
      selectedKey: key,
      resultFilter: ""
    }, () => { this.debounceData() });
  };

  onFilterUpdate = filter => {
    this.setState({
      resultFilter: filter.toLowerCase()
    }, () => { this.debounceData() });
  };

  onOrderUpdate = order => {
    this.setState({
      desc: order
    }, () => { this.debounceData() });
  }

  getIndexKey(entryName) {
    /* Returns the value to be used for the index. By default, getIndexKey will try to use the first character in the string passed into entryName.
    If the first character is a letter, getIndexKey returns that letter. Otherwise, it runs through a list of edgecases and returns predetermined values accordingly.
    */
    if (!entryName) {
      return "Other";
    }

    const inputKey = entryName[0];

    if (inputKey.match(/^[A-Z]+$/i)) {
      return inputKey.toUpperCase();
    }

    if (!isNaN(parseInt(inputKey, 10))) {
      return "0-9";
    }

    return "Other";
  };

  indexData(source) {
    /* indexData creates an indexed map of the passed in source data when IndexPagination mounts. It iterates through each entry in the source array, generates an index for that entry
    and then sorts those entries into their proper indexes.
    */
    const mappedData = new Map();

    source.forEach(entry => {
      const entryKey = this.getIndexKey(entry[this.indexName]);
      entry.json = JSON.stringify(entry).toLowerCase();
      if (!mappedData.has(entryKey)) {
        mappedData.set(entryKey, [entry]);
      } else {
        mappedData.get(entryKey).push(entry);
      }
    });

    return mappedData;
  };

  getData = () => {
    /* getData gets the array of data values for a specified index. It also calls filterResults on the resultant set, as well as reverses the results when this.state.desc is true. 
    */
    let data = [];

    if (this.map.size) {
      data = this.map.get(this.state.selectedKey);
    }
    if (this.state.resultFilter && this.state.resultFilter.length > 1) {
      data = this.source
    }

    let returnData = data;

    if (this.state.desc) {
      returnData = [...returnData].reverse();
    }
    if (this.state.resultFilter && this.state.resultFilter.length > 1) {
      returnData = this.filterResults(returnData);
    }

    this.setState({ filteredData: returnData });
  };

  debounceData = debounce(this.getData, 250);

  filterResults = (data) => {
    /* filterResults searches the stringified data for a string match to this.state.resultFilter. If it finds a match, that entry is returned in the dataset, otherwise it is omitted.  
    */
    if (!this.state.resultFilter || !data) {
      return []
    }

    let filteredData = [];
    const hasAmpersand = this.state.resultFilter.indexOf("&") !== -1;

    for (var i = 0; i < data.length; i++) {
      if (filteredData.length > 100) {
        return filteredData
      }
      let jsonString = !hasAmpersand ? data[i].json.replace("&", "and") : data[i].json;
      if (jsonString.indexOf(this.state.resultFilter) !== -1) {
        filteredData.push(data[i]);
      }
    }

    return filteredData
  };

  render() {
    const { filteredData } = this.state;
    return this.props.children({
      data: filteredData,
      keys: Array.from(this.map.keys()).sort(this.sortOrder),
      filter: this.state.resultFilter,
      selectedKey: this.state.selectedKey,
      desc: this.state.desc,
      onKeyUpdate: this.onKeyUpdate,
      onFilterUpdate: this.onFilterUpdate,
      onOrderUpdate: this.onOrderUpdate
    });
  }
}
