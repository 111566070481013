import React from "react";
import { Query } from "react-apollo";
import SessionQueryContainer from "./SessionQueryContainer";
import { QUERY_SESSIONS_INFO, QUERY_SESSIONS } from "../resolvers/sessions";

const NETWORK_STATUS_READY = 7;

const SessionInfoContainer = ({
    params = {},
    visitorId,
    children,
    page,
    ...props
}) => (
    <SessionQueryContainer visitorId={visitorId} page={page}>
        {({ query, sessionsDisplayAmount }) => {
            if (params && params.page_depth) {
                query.depth = params.page_depth;
            }

            if (params && params.length) {
                const range = params.length.split("a");
                query.length = {
                    min: range[0],
                    max: range[1] || null
                };
            }

            return (
                <Query
                    query={QUERY_SESSIONS}
                    notifyOnNetworkStatusChange
                    variables={{ query, last: sessionsDisplayAmount }}
                >
                    {({ loading, networkStatus, data: { sessions } }) => (
                        <Query
                            query={QUERY_SESSIONS_INFO}
                            variables={{ query }}
                            skip={networkStatus !== NETWORK_STATUS_READY}
                        >
                            {({
                                data: {
                                    sessionsInfo: {
                                        referrers,
                                        targets,
                                        actions,
                                        base
                                    } = {}
                                } = {}
                            }) =>
                                children &&
                                children({
                                    ...props,
                                    query,
                                    loading,
                                    total:
                                        sessions &&
                                        sessions.pageInfo.totalCount,
                                    filters: {
                                        referrers,
                                        targets,
                                        actions,
                                        base
                                    }
                                })
                            }
                        </Query>
                    )}
                </Query>
            );
        }}
    </SessionQueryContainer>
);

export default SessionInfoContainer;
