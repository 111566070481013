import React from "react";
import ThomasTableRow from "./ThomasTableRow";
import { Link } from "react-router-dom";

import "./ThomasTable.css";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import TTRowClickableLink from "views/components/ThomasTable/TTRowClickableLink";

const RegThomasTable = ({
    data, //[edges]
    hasNextPage,
    onLoadMore,
    showAllLink,
    tableCols,
    gClass = "",
    contentFactory,
    contentModifier,
    customRowClick,
    link,
    openKey,
    detailsTitle,
    detailsComponent,
    sortTable,
    sort,
    direction
}) => {
    return (
        <section className="thomas-table table">
            <div
                className={`thomas-table--header thomas-table__grid ${gClass}`}
            >
                {tableCols.map(col => (
                    <div
                        key={col.key}
                        className={`thomas-table--title${
                            col.sort ? "__sortable" : ""
                        }`}
                        onClick={
                            col.sort ? () => sortTable(col.key, col.sort) : null
                        }
                    >
                        {col.label}
                        {sort === col.key && (
                            <div
                                className={
                                    direction === "desc"
                                        ? "arrow-desc"
                                        : "arrow-asc"
                                }
                            >
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/img/arrow.svg"
                                    }
                                    alt="arrow"
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {data.map(
                (row, i) =>
                    !customRowClick ? (
                        <ThomasTableRow
                            key={row.cursor || i}
                            row={row}
                            tableCols={tableCols}
                            contentFactory={contentFactory}
                            contentModifier={contentModifier}
                            link={link}
                            gClass={gClass}
                            openKey={openKey}
                            detailsTitle={detailsTitle}
                            detailsComponent={detailsComponent}
                        />
                    ) : (
                        <TTRowClickableLink
                            key={row.cursor || i}
                            row={row}
                            tableCols={tableCols}
                            contentFactory={contentFactory}
                            gClass={gClass}
                            customRowClick={customRowClick}
                        />
                    )
            )}

            {onLoadMore &&
                !showAllLink && (
                    <div
                        className={`thomas-table--row thomas-row-clickable thomas-table__grid ${gClass}`}
                        onClick={() => {
                            onLoadMore(data[data.length - 1].cursor);
                        }}
                    >
                        {hasNextPage && (
                            <div className="thomas-table--show-more">
                                <span>SHOW MORE</span>
                                <ThomasIcon
                                    attribute="OPEN"
                                    customClass="test"
                                />
                            </div>
                        )}
                    </div>
                )}

            {showAllLink && (
                <Link
                    className={`thomas-table--row thomas-row-clickable thomas-table__grid ${gClass}`}
                    to={showAllLink}
                >
                    <div className="thomas-table--show-more">
                        <span>SHOW MORE</span>
                        <ThomasIcon attribute="OPEN" customClass="test" />
                    </div>
                </Link>
            )}
        </section>
    );
};

const MobileTableSection = ({
    data,
    onLoadMore,
    tableCols,
    showMore = false,
    gClass = "",
    contentFactory
}) => (
    <section className="thomas-table__mobile" style={{ marginTop: "20px" }}>
        {data.map(row => (
            <div className="thomas-table__mobile--item">
                {tableCols.map(col => (
                    <div key={col.key} className="thomas-table__mobile--row">
                        <div>{col.label}: </div>
                        <div>
                            {contentFactory
                                ? contentFactory(row, col.key)
                                : row[col.key]}
                        </div>
                    </div>
                ))}
            </div>
        ))}

        <div className="thomas-table__mobile--item" />
    </section>
);

class ThomasTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
            sort: "",
            direction: "desc"
        };

        this.handleSort = this.handleSort.bind(this);
    }

    handleSort(key, sortBy) {
        const { direction, sort } = this.state;
        const newDirection =
            sort === key ? (direction === "desc" ? "asc" : "desc") : "desc";

        this.setState({
            sort: key,
            direction: newDirection
        });
        this.props.onSort(sortBy[newDirection]);
    }

    componentWillMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const {
            data,
            hasNextPage,
            showAllLink,
            tableCols,
            gClass,
            contentFactory = false,
            contentModifier,
            onLoadMore,
            link,
            openKey,
            detailsTitle,
            detailsComponent,
            customRowClick
        } = this.props;

        const mobile = this.state.width < 500;

        return mobile ? (
            <MobileTableSection
                data={data}
                hasNextPage={hasNextPage}
                onLoadMore={onLoadMore}
                contentFactory={contentFactory}
                gClass={gClass}
                tableCols={tableCols}
            />
        ) : (
            <RegThomasTable
                data={data}
                hasNextPage={hasNextPage}
                onLoadMore={onLoadMore}
                showAllLink={showAllLink}
                contentFactory={contentFactory}
                contentModifier={contentModifier}
                gClass={gClass}
                tableCols={tableCols}
                link={link}
                openKey={openKey}
                detailsTitle={detailsTitle}
                detailsComponent={detailsComponent}
                sortTable={this.handleSort}
                sort={this.state.sort}
                direction={this.state.direction}
                customRowClick={customRowClick}
            />
        );
    }
}

export default ThomasTable;
