import React from "react";
import ProgressSteps from "views/components/Registration/ProgressSteps";

const REGEX_EMAIL = /^\S+@\S+$/g;

class Login extends React.Component {
  constructor() {
    super();
    this.state = { email: "", error: "" };

    this.checkForValidEmail = this.checkForValidEmail.bind(this);
  }

  checkForValidEmail() {
    if (!this.state.email.match(REGEX_EMAIL) || this.state.email === "") {
      this.setState({
        error: "Please enter a valid email address"
      });
    } else {
      this.props.onContinue(this.state.email);
    }
  }

  render() {
    const { error } = this.state;
    return (
      <React.Fragment>
        <ProgressSteps step={1} />
        <div className="form-bg form-short">
          {error && (
            <p
              className="alert alert-danger hide"
              id="errMsg"
              style={{ display: "block" }}
            >
              {error}
            </p>
          )}
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              Enter your email to get started:
            </label>
            <input
              name="email"
              autoComplete="username"
              className="form-control"
              placeholder="Enter your email to get started"
              value={this.state.email}
              onChange={e =>
                this.setState({
                  email: e.target.value
                })
              }
              onKeyPress={e => {
                if (e.keyCode || e.which === 13) {
                  this.checkForValidEmail();
                }
              }}
            />
          </div>

          <div className="form-group flex-end-btn">
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.checkForValidEmail}
            >
              Continue
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
