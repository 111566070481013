import React from "react";

import cookie from "react-cookies";
const $ = window.$;

class CompanyInfoIn extends React.Component {
    componentDidMount() {
        let myemail = this.props.email;
        let tinid = cookie.load("tinid");

        const scriptlegacy = document.createElement("script");
        scriptlegacy.src = "https://js.hsforms.net/forms/v2-legacy.js";
        scriptlegacy.async = true;
        document.body.appendChild(scriptlegacy);

        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/v2.js";
        script.async = true;
        document.body.appendChild(script);

        script.addEventListener("load", () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: "242200",
                    formId: "8939c9ff-938a-45f1-904e-5f68f015052e",
                    target: "#hubspotForm1",
                    cssClass: "form--webtrax-reg__url",
                    submitButtonClass: "btn btn-primary continue-button",
                    inlineMessage: "",
                    css: "",
                    onFormReady() {
                        $('input[name="tinid"]')
                            .val(tinid)
                            .change();
                        $('input[name="thomas_user_email"]')
                            .val(myemail)
                            .change();
                    },
                    onFormSubmit($form) {
                        setTimeout(function() {
                            var formData = $form.serialize();
                            // this.props.history.push(
                            //     `/register/thankyou?thomas_user_email=${myemail}&${formData}`
                            // );
                            window.location.href = `/register/thankyou-in?thomas_user_email=${myemail}&${formData}`;
                        }, 250);
                    }
                });
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                {/* <button onClick={() => this.props.onLogOut("/register/login")}>
                    Log Out
                </button> */}
                <div id="hubspotForm1" />
            </React.Fragment>
        );
    }
}

export default CompanyInfoIn;
