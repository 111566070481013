const data = [
    {
        id: "Page Views",
        color: "#3cb4e8",
        data: [
            {
                x: 1,
                y: 1200
            },
            {
                x: 2,
                y: 200
            },
            {
                x: 3,
                y: 150
            },
            {
                x: 4,
                y: 100
            },
            {
                x: 5,
                y: 75
            },
            {
                x: 6,
                y: 50
            },
            {
                x: 7,
                y: 25
            },
            {
                x: 8,
                y: 10
            },
            {
                x: 9,
                y: 10
            }
        ]
    }
];

export default data;
