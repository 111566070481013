import React, { Component, ReactNode, Children } from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";

const QUERY_GET_ALL_CAMPAIGNS = gql`
  query listCampaigns {
    campaigns {
      campaignId
      tgramsId
      productId
      campaignName
      startDate
      endDate
      createdDate
      statusId
      status
      companyInfo {
        name
        id
      }
    }
  }
`;

//for sorting in ASC/DESC of Campaign name...need to add this feature at some point.
type sortFunction = (ordering: Ordering) => any;

interface Ordering {
  direction: string;
  orderBy: string;
}

interface CampaignsContainerProps {
  children: (props: any) => any;
}

interface GetCampaignsResult {
  campaigns: any;
}

const CampaignsContainer: React.SFC<CampaignsContainerProps> = ({
  children
}) => {
  return (
    <Query<GetCampaignsResult> query={QUERY_GET_ALL_CAMPAIGNS}>
      {({ error, loading, data: { campaigns } = { campaigns: {} } }) => {
        if (error) {
          console.log(error);
          return <div>ERROR Retrieving your campaigns...</div>;
        }
        const totalCampaigns = campaigns && campaigns.length;
        return children({
          campaigns,
          loading,
          totalCampaigns
        });
      }}
    </Query>
  );
};

export { CampaignsContainer };
