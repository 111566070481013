import React from "react";
import { gql } from "apollo-boost";
import { Query, Mutation } from "react-apollo";

const QUERY_ACTIVITIES_DOWNLOAD = gql`
  query sessionsDownload($id: ID) {
    sessionsDownload(id: $id) {
      id
      status
      url
    }
  }
`;

const MUTATION_CREATE_ACTIVITIES_DOWNLOAD = gql`
  mutation createActivitiesDownload($query: ActivityQueryInput) {
    download: createActivitiesDownload(query: $query) {
      id
      status
      url
    }
  }
`;

interface DownloadContainerProps {
  query: any;
  children(props: any): JSX.Element;
}

interface ActivitiesResults {
  download: any;
}

interface DownloadResults {
  sessionsDownload: any;
}

const DownloadContainer: React.FunctionComponent<DownloadContainerProps> = ({
  children,
  query
}) => {
  let loading = false;

  function MutationComponent({ query }: { query: any }) {
    return (
      <Mutation<ActivitiesResults>
        mutation={MUTATION_CREATE_ACTIVITIES_DOWNLOAD}
        variables={{
          query
        }}
      >
        {(
          createActivitiesDownload,
          { data: { download } = { download: undefined } }
        ) => {
          const onSessionExport = () => {
            loading = true;

            createActivitiesDownload();
          };

          if (!download) {
            return children({
              onSessionExport
            });
          }

          return (
            <Query<DownloadResults>
              query={QUERY_ACTIVITIES_DOWNLOAD}
              variables={{
                id: download.id
              }}
              pollInterval={loading ? 1000 : 0}
            >
              {({
                stopPolling,
                data: { sessionsDownload } = { sessionsDownload: undefined }
              }) => {
                if (
                  loading &&
                  sessionsDownload &&
                  sessionsDownload.status === "done"
                ) {
                  stopPolling();

                  loading = false;

                  if (sessionsDownload.url) {
                    window.location.href = sessionsDownload.url;
                  }
                }

                return children({
                  download: sessionsDownload,

                  onSessionExport
                });
              }}
            </Query>
          );
        }}
      </Mutation>
    );
  }

  return <MutationComponent query={query} />;
};

export { DownloadContainer };
