import React from "react";
import { withRouter } from "react-router";

class LinkComponent extends React.Component {
    onExecuteLink = (input) => {
        this.props.history.push(
            this.props.urlClickHandler(input)
        )
    }
    render() {
        return (
            this.props.children({
                onExecuteLink: this.onExecuteLink
            })
        )
    }
}

const RouterLink = withRouter(LinkComponent);

export default RouterLink