import React from "react";
import { Switch, Route } from "react-router-dom";
import "../Registration/Registration.css";
import BgLeftImage from "views/components/Registration/BgLeftImage";
import Reset from "views/components/Login/pages/Reset";
import SignIn from "views/components/Login/pages/SignIn";
import Legacy from "views/components/Login/pages/Legacy";
import LegacySignIn from "views/components/Login/pages/LegacySignIn";
import LoginHome from "views/components/Login/pages/LoginHome";
import CopyrightFooter from "views/components/Registration/CopyrightFooter";
import CreateThomasAccount from "views/components/Registration/pages/Create";
import authService from "services/authServices";
import AuthContainer from "containers/AuthContainer";

const REGEX_EMAIL = /^\S+@\S+$/g;

class Login extends React.Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",
      tinid: "",
      wtusername: "",
      wtpassword: "",
      error: false,
      loading: false
    };

    this.nextPage = this.nextPage.bind(this);
    this.renderError = this.renderError.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.checkEmailForAccount = this.checkEmailForAccount.bind(this);
    this.checkWebtraxIdSignIn = this.checkWebtraxIdSignIn.bind(this);
  }

  handleInputChange(e) {
    const target = e.target;

    this.setState({
      [target.name]: target.value
    });
  }

  success() {
    window.location.href = "/";
  }

  checkEmailForAccount(email) {
    if (email.trim().length === 0) {
      this.setState({
        error: "Please enter an email address"
      });
    } else if (!email.match(REGEX_EMAIL)) {
      this.setState({
        wtusername: email
      });
      this.nextPage("login/legacy");
    } else {
      authService
        .checkUsername(email)
        .then(r => {
          this.setState(
            {
              email: email,
              tinid: r.userId
            },
            this.nextPage("/login/signin")
          );
        })
        .catch(err => {
          if (err === "Cant Find User") {
            this.setState(
              {
                email: email
              },
              this.nextPage("/login/create")
            );
            return;
          }

          console.error(`[checkUsername] err:`, err);
        });
    }
  }

  checkWebtraxIdSignIn(username, password) {
    authService
      .legacyAccountLogin(username, password)
      .then(res => {
        if (!res || !res.token) {
          throw new Error("Username or password is incorrect.");
        }
        window.sessionStorage.setItem("legacy_token", res.token);

        this.setState(
          {
            wtusername: username,
            email: "",
            error: false
          },
          this.nextPage("/login/legacy-l")
        );
      })
      .catch(err => this.cleanUpError(err));
  }

  nextPage(route) {
    this.setState(
      {
        loading: false,
        error: ""
      },
      this.props.history.push(route)
    );
  }

  cleanUpError(err) {
    let error;

    if (err.body && typeof err.body === "object") {
      error = err.body.message;
    } else if (err.body) {
      error = err.body;
    } else if (err.message) {
      error = err.message;
    } else {
      error = err;
    }

    this.renderError(error);
  }

  renderError(err) {
    const errorMessages = {
      "AUTHENTICATION FAILED": "Incorrect Password",
      "Attempt limit exceeded": "Attempt limit exceeded",
      "Invalid verification code": "Invalid verification code",
      "The request had some validation errors": "Error resetting password",
      "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
        "Password must have length greater than or equal to 6",
      "Invalid code provided, please request a code again.":
        "Invalid code provided",
      '401 - "Account not authorized."': "Incorrect Password",
      "Invalid verification code provided, please try again.":
        "Invalid confirmation code"
    };

    this.setState({
      loading: false,
      error: errorMessages[err] ? errorMessages[err] : "There was an error"
    });
  }

  resetPasswordValidation(code, password, confirmPw) {
    const passwordConfirmation = confirmPw ? confirmPw : "";
    if (code.trim().length === 0) {
      this.setState({
        loading: false,
        error: "Please enter the confirmation code"
      });
      return false;
    } else if (password.trim().length === 0 || confirmPw.trim().length === 0) {
      this.setState({
        loading: false,
        error: "Passwords cannot be blank"
      });
      return false;
    } else if (password !== passwordConfirmation) {
      this.setState({
        loading: false,
        error: "Passwords do not match"
      });
      return false;
    }
    return true;
  }

  render() {
    const { email, tinid, error, loading, wtusername } = this.state;
    return (
      <AuthContainer>
        {({
          signIn,
          signUp,
          logOut,
          forgotPassword,
          confirmForgotPassword,
          isSignedIn
        }) => {
          if (isSignedIn) {
            this.success();
            return null;
          }

          return (
            <div className="site-wrap logged-out main-page">
              <BgLeftImage />
              <div className="reg-page__content-wrap">
                <div className="reg-page__primary">
                  <div className="container reg-container">
                    <div className="auth-container">
                      <Switch>
                        <Route
                          exact
                          path="/login"
                          component={() => (
                            <LoginHome
                              checkEmailForAccount={email =>
                                this.checkEmailForAccount(email)
                              }
                              error={error}
                              wtusername={wtusername}
                            />
                          )}
                        />

                        <Route
                          path="/login/signin"
                          component={() => (
                            <SignIn
                              loginPage
                              renderFormValidationErrors={err => {
                                this.setState({
                                  loading: false,
                                  error: err
                                });
                              }}
                              onSignIn={(u, p) => {
                                this.setState({
                                  loading: true
                                });
                                signIn(u, p)
                                  .then(() => this.success())
                                  .catch(err => this.cleanUpError(err));
                              }}
                              email={email}
                              error={error}
                              loading={loading}
                              history={this.props.history}
                              onForgotPassword={() => {
                                forgotPassword(email)
                                  .then(() => {
                                    this.nextPage("/login/reset");
                                  })
                                  .catch(err => this.cleanUpError(err));
                              }}
                              clearError={() => {
                                this.setState({
                                  error: ""
                                });
                              }}
                            />
                          )}
                        />
                        <Route
                          path="/login/reset"
                          component={() => (
                            <Reset
                              email={email}
                              error={error}
                              loading={loading}
                              clearError={() => {
                                this.setState({
                                  error: ""
                                });
                              }}
                              resetPasswordValidation={(code, pw, confPw) =>
                                this.resetPasswordValidation(code, pw, confPw)
                              }
                              onConfirmForgotPassword={(u, p, c) => {
                                this.setState({
                                  loading: true
                                });

                                confirmForgotPassword(u, p, c, tinid)
                                  .then(() =>
                                    signIn(u, p)
                                      .then(this.success())
                                      .catch(err => this.cleanUpError(err))
                                  )
                                  .catch(err => this.cleanUpError(err));
                              }}
                            />
                          )}
                        />
                        <Route
                          path="/login/legacy"
                          component={() => (
                            <Legacy
                              renderFormValidationErrors={err =>
                                this.setState({
                                  loading: false,
                                  error: err
                                })
                              }
                              onSignIn={(u, p) => {
                                this.setState({
                                  loading: true
                                });
                                this.checkWebtraxIdSignIn(u, p);
                              }}
                              email={wtusername}
                              error={error}
                              history={this.props.history}
                              loading={loading}
                              clearError={() => {
                                this.setState({
                                  error: ""
                                });
                              }}
                            />
                          )}
                        />
                        <Route
                          path="/login/legacy-l"
                          component={() => (
                            <LegacySignIn
                              onContinue={this.checkEmailForAccount}
                              wtusername={wtusername}
                              loading={loading}
                            />
                          )}
                        />
                        <Route
                          path="/login/create"
                          component={() => (
                            <CreateThomasAccount
                              header={() => (
                                <>
                                  <h2 className="text-center pt-0">
                                    Create a Thomas Account
                                  </h2>
                                  <p className="text-center">
                                    For full access to 500k+ Supplier Profiles,
                                    thousands of catalogs CAD Models and all
                                    search, save, and shortlist tools.
                                  </p>
                                </>
                              )}
                              buttonText={"Create FREE Account"}
                              email={email}
                              onSignUp={params => {
                                this.setState({
                                  loading: true
                                });
                                signUp(params)
                                  .then(() => this.nextPage("/login"))
                                  .catch(err => this.cleanUpError(err));
                              }}
                              loading={loading}
                            />
                          )}
                        />
                      </Switch>

                      <CopyrightFooter />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </AuthContainer>
    );
  }
}

export default Login;
