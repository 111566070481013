import React from "react";
import normal from "img/ico-timeline-normal.svg";

const EmptyHighValueActions = () => (
    <div className="empty-high-value-actions">
        <div className="hVActions-header">
            You have not received any RFIs or Phone Calls from Thomas users
            within the selected time frame.
        </div>
        <div>
            <img src={normal} alt="n" />
            Note that you must be a Thomas client to receive RFIs and tracked
            Phone Calls made through the Thomas Network
        </div>
        <div>
            <img src={normal} alt="n" />
            Phone Call tracking includes sales classification and transcribed
            summary notes as free value-added services for Thomas clients
        </div>
    </div>
);

export default EmptyHighValueActions;
