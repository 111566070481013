import * as React from "react";
import DateRangePicker from "views/components/DateRangePicker/DateRangePicker";
import DateRangeContainer from "containers/DateRangeContainer";

import "./CampaignActivity.scss";
import { Spinner } from "views/components/Loading/Spinner";

interface Props {
  verticalName: string;
  campaignDuration: string;
  adImpressions: string;
  clicks: string;
  loading: boolean;
  pvsCreatives: PvsCreative[];
}

interface PvsCreative {
  pvs_creative_id: string;
  pvsCreativeUrl: string;
  platform_id: number;
  width?: number;
  height?: number;
}

const CampaignActivity = ({
  verticalName,
  campaignDuration,
  adImpressions,
  clicks,
  loading,
  pvsCreatives
}: Props) => {
  //platform id mobile is 2
  //platform id web is 1
  pvsCreatives &&
    pvsCreatives.map(pvs => {
      pvs.width = pvs.platform_id === 1 ? 50 : 200;
      pvs.height = pvs.platform_id === 1 ? 150 : 50;
    });

  const campaignCreatives = () => {
    return (
      pvsCreatives &&
      pvsCreatives.map(pvs => {
        return (
          <span
            key={pvs.pvs_creative_id}
            className="pvs-creatives"
            style={{ margin: "0.5rem" }}
          >
            <img
              height={pvs.height}
              width={pvs.width}
              src={`${pvs.pvsCreativeUrl}`}
            />
          </span>
        );
      })
    );
  };
  return (
    <section className="metrics">
      <div className="card content-card">
        <div className="campaigns-dash-header">
          {loading ? (
            <Spinner />
          ) : (
            <h3 className="campaigns-dash_companyName">{verticalName}</h3>
          )}

          <DateRangeContainer>
            {({ onDateRangeApply, startDate, endDate }: any) => (
              <DateRangePicker
                hideTitle
                customClass="campaign-metrics--datepicker"
                startDate={startDate}
                endDate={endDate}
                onDateRangeApply={onDateRangeApply}
              />
            )}
          </DateRangeContainer>
        </div>
        <div className="campaigns-dash-metrics" style={{ maxWidth: "100%" }}>
          {pvsCreatives ? (
            <div className="metrics-items item-1">{campaignCreatives()}</div>
          ) : (
            ""
          )}

          <div
            className="metrics-items item-2"
            style={{
              textAlign: "left",
              margin: "0 1rem 1rem 1rem"
            }}
          >
            Campaign Period {campaignDuration} <br /> Limited inventory display
            ad that positions you as a premium supplier to in-market buyers in
            all of the categories on Thomasnet.com related to the vertical
          </div>
          <div className="metrics-items item-3">
            <div className="metrics-item-line" />
            <div className="metrics-item">
              <h3>{adImpressions.toLocaleString()}</h3>
              <p>Impressions</p>
            </div>
            <div className="metrics-item-line" />
            <div className="metrics-item">
              <h3>{clicks.toLocaleString()}</h3>
              <p>Clicks</p>
            </div>
            <div className="metrics-item-line" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CampaignActivity;
