import React from "react";
import PageHeader from "views/sections/PageHeader/PageHeader";
import VideoCard from "views/components/VideoCard/VideoCard";
import HubSpotInviteForm from "views/pages/Settings/HubSpotInviteForm";
import CheckMark from "views/pages/Settings/CheckMark";

const pageConfig = (page, user) => {
  switch (true) {
    case page === "settings-webtraxtutorial":
      return (
        <section className="card webtraxs-tutorials">
          <VideoCard />
          <VideoCard />
          <VideoCard />
          <VideoCard />
          <VideoCard />
          <VideoCard />
        </section>
      );
    case page === "settings-calltracking":
      return (
        <section className="card settings-calltracking">
          <div className="card--header">
            Set up your content to get leads for you
          </div>
          <div className="card--content">
            Want to know who is downloading the content you have on your
            profile? Set up tracking on this content so you can market
            intelligently. Contact WebTrax support to get started today
          </div>
          <div className="card--button">
            <button className="btn-lg btn-primary">
              Add Download Tracking
            </button>
          </div>
        </section>
      );
    case page === "settings-tagging":
      const acctid = (user && user.account && user.account.id) || "none";

      return (
        <section className="card settings-calltracking">
          <div className="card--content">
            <p className="mt-0">
              {`Place this tracking snippet on all website pages that you would like to track with WebTrax. You can place this snippet within a website component that dynamically populates on all of your website pages, such as your website footer, or you can place it individually on each page. If placed individually, we recommend that you place the tracking snippet just before the closing </body> tags on each page. Thomas clients can contact WebTrax support for free professional implementation of WebTrax tracking across their online presence.`}
            </p>
            <div className="card--codeblock">
              {`<!-- Begin WebTrax -->`}
              <br />
              {'<script type="text/javascript">'}
              <br />
              {"var wto = wto || [];"}
              <br />
              {`wto.push(['setWTID', '${acctid}']);`}
              <br />
              {`wto.push(['webTraxs']);`}
              <br />
              {`(function() {`}
              <br />
              {`var wt = document.createElement('script');`}
              <br />
              {`wt.src = document.location.protocol + '//www.webtraxs.com/wt.php';`}
              <br />
              {`wt.type = 'text/javascript';`}
              <br />
              {`wt.async = true;`}
              <br />
              {`var s = document.getElementsByTagName('script')[0];`}
              <br />
              {`s.parentNode.insertBefore(wt, s);`}
              <br />
              {`})();`}
              <br />
              {`</script>`}
              <br />
              {`<noscript><img src="https://www.webtraxs.com/webtraxs.php?id=${acctid}&st=img" alt="" /></noscript>`}
              <br />
              {`<!-- End WebTrax -->`}
              <br />
            </div>
            Alternatively, AMP Tagging
            <div className="card--codeblock">
              {`<amp-pixel src="https://www.webtraxs.com/webtraxs.php?id=${acctid}&referer=DOCUMENT_REFERRER&r=SCREEN_WIDTHxSCREEN_HEIGHT&c=SCREEN_COLOR_DEPTH&title=TITLE&url=SOURCE_URL&st=img"></amp-pixel>`}
            </div>
            <b>Tracking Downloads:</b>
            <br />
            Copy and paste onClick="LogClick(this);" into your HTML where your
            download link appears.
            <div className="card--codeblock">
              {`<A HREF="/test.zip" onClick="LogClick(this);">Click Here</A>`}
            </div>
          </div>
        </section>
      );

    case page === "settings-invite":
      return (
        <section className="card settings-invite">
          <p className="pl-3 pr-3">
            <small>
              <b>
                Please note that any users you invite will have full access to
                your WebTrax reporting suite. This includes setting targets,
                high-value pages, and all technical settings.
              </b>
            </small>
          </p>
          <HubSpotInviteForm user={user} />
        </section>
      );

    case page === "settings-invite-thankyou":
      return (
        <section className="card settings-invite">
          <div className="p-3">
            <h3>
              <CheckMark /> Your Invite has been sent
            </h3>
            <p>
              The team member you invited should receive an email with a link to
              the WebTrax sign-in page within one business day.
            </p>
            <p>
              All WebTrax users will be required to register with Thomasnet if
              they are not already a registered user.
            </p>
          </div>
        </section>
      );

    default:
      return null;
  }
};

class SettingsSingleCard extends React.Component {
  render() {
    const { title, page, user } = this.props;

    return (
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <PageHeader
          title={title}
          hideDatePicker={true}
          subHeader={
            page === "settings-invite" || page === "settings-invite-thankyou"
              ? "Share access to your Thomas WebTrax account with one or more team members below."
              : null
          }
        />

        {pageConfig(page, user)}
      </div>
    );
  }
}

export default SettingsSingleCard;
