import React from "react";
import FormatDuration from "views/components/FormatDuration";
import moment from "moment";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";

const VisitorNameCell = (session, key) => {
  const attributes = session.attributes || [];

  return (
    <td className="results-table__visitorName" key={key}>
      {session.visitorName}
      {session.visitorISP && <small>(via ISP {session.visitorISP})</small>}

      <div className="results-timeline__card-icons">
        {attributes.map(attribute => (
          <ThomasIcon key={attribute} attribute={attribute} />
        ))}
      </div>
    </td>
  );
};

const VisitDateCellFactory = format => {
  return function VisitDateCell(session, key) {
    return (
      <td className={`results-table__visitDate`} key={key}>
        {moment(session.visitDate).format(format)}
      </td>
    );
  };
};

const VisitDurationCell = (session, key) => {
  return (
    <td className={`results-table__sessionDuration`} key={key}>
      {FormatDuration(session.sessionDuration)}
    </td>
  );
};

const resultsTableCols = [
  {
    label: "Visitor Name",
    prop: "visitorName",
    key: "visitorName",
    render: VisitorNameCell,
    sort: {
      desc: [
        {
          orderBy: "sortable_company",
          direction: "DESC"
        },
        {
          orderBy: "ip_company",
          direction: "DESC"
        },
        {
          orderBy: "time_expanded",
          direction: "DESC"
        }
      ],
      asc: [
        {
          orderBy: "sortable_company",
          direction: "ASC"
        },
        {
          orderBy: "ip_company",
          direction: "ASC"
        },
        {
          orderBy: "time_expanded",
          direction: "DESC"
        }
      ]
    }
  },
  {
    label: "Date",
    prop: "visitDate",
    key: "visitDate",
    render: VisitDateCellFactory("M/D/YYYY"),
    sort: {
      desc: [
        {
          orderBy: "time_expanded",
          direction: "DESC"
        }
      ],
      asc: [
        {
          orderBy: "time_expanded",
          direction: "ASC"
        }
      ]
    }
  },
  {
    label: "Time",
    prop: "visitTime",
    key: "visitTime",
    render: VisitDateCellFactory("LT")
  },
  {
    label: "Pages / Session",
    prop: "pagesInSession",
    key: "pagesInSession",

    sort: {
      desc: [
        {
          orderBy: "pagect",
          direction: "DESC"
        },
        {
          orderBy: "time_expanded",
          direction: "DESC"
        }
      ],
      asc: [
        {
          orderBy: "pagect",
          direction: "ASC"
        },
        {
          orderBy: "time_expanded",
          direction: "DESC"
        }
      ]
    }
  },
  {
    label: "Session Duration",
    prop: "sessionDuration",
    key: "sessionDuration",
    render: VisitDurationCell,
    sort: {
      desc: [
        {
          orderBy: "online",
          direction: "ASC"
        },
        {
          orderBy: "time_expanded",
          direction: "DESC"
        }
      ],
      asc: [
        {
          orderBy: "online",
          direction: "DESC"
        },
        {
          orderBy: "time_expanded",
          direction: "DESC"
        }
      ]
    }
  },
  { label: "Referrer", prop: "referrer", key: "referrer" }
];

export default resultsTableCols;
