import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./Registration.css";

import cookie from "react-cookies";
import authService from "services/authServices";
import BgLeftImage from "views/components/Registration/BgLeftImage";
import AuthContainer from "containers/AuthContainer";

import Login from "views/components/Registration/pages/Login";
import SignIn from "views/components/Registration/pages/SignIn";
import Reset from "views/components/Registration/pages/Reset";
import CompanyInfo from "views/components/Registration/pages/CompanyInfo";
import CompanyInfoIn from "views/components/Registration/pages/signedin/CompanyInfoIn";
import ThankYou from "views/components/Registration/pages/ThankYou";
import ThankYou2 from "views/components/Registration/pages/ThankYou2";
import ThankYouIn from "views/components/Registration/pages/signedin/ThankYouIn";
import CreateThomasAccount from "views/components/Registration/pages/Create";
import CopyrightFooter from "views/components/Registration/CopyrightFooter";
import ProgressSteps from "./ProgressSteps";

class Register extends React.Component {
  constructor() {
    super();
    this.state = {
      localSignedIn: false,
      email: "",
      tinid: "",
      error: false,
      triedClick: false,
      emptyuser: false,
      loading: false
    };

    this.nextPage = this.nextPage.bind(this);
    this.renderError = this.renderError.bind(this);
    this.signUpHandler = this.signUpHandler.bind(this);
    this.checkEmailForAccount = this.checkEmailForAccount.bind(this);
  }

  signUpHandler(properties) {
    authService
      .signUp(properties)
      .then(async res => {
        cookie.save("tinid", res.userId, { path: "/" });

        await this.signIn(properties.email, properties.password);

        this.fetchTokenFromSessionCache();

        this.setState({
          tinid: res.userId,
          onlySignUp: true,
          userProps: { ...properties }
        });
      })
      .catch(err => console.error(err));
  }

  checkEmailForAccount(email) {
    authService
      .checkUsername(email)
      .then(r => {
        this.setState(
          {
            email: email,
            tinid: r.userId
          },
          this.nextPage("/register/signin")
        );
      })
      .catch(err => {
        if (err === "Cant Find User") {
          this.setState(
            {
              email: email
            },
            this.nextPage("/register/create")
          );
          return;
        }

        console.error(`[checkUsername] err:`, err);
      });
  }

  nextPage(route) {
    this.setState(
      {
        loading: false,
        error: ""
      },
      this.props.history.push(route)
    );
  }

  cleanUpError(err) {
    let error;

    if (err.body && typeof err.body === "object") {
      error = err.body.message;
    } else if (err.body) {
      error = err.body;
    } else if (err.message) {
      error = err.message;
    } else {
      error = err;
    }

    this.renderError(error);
  }

  renderError(err) {
    const errorMessages = {
      "AUTHENTICATION FAILED": "Incorrect Password",
      "Attempt limit exceeded": "Attempt limit exceeded",
      "Invalid verification code": "Invalid verification code",
      "The request had some validation errors": "Error resetting password",
      "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
        "Password must have length greater than or equal to 6",
      "Invalid code provided, please request a code again.":
        "Invalid code provided",
      '401 - "Account not authorized."': "Incorrect Password"
    };

    this.setState({
      loading: false,
      error: errorMessages[err] ? errorMessages[err] : "There was an error"
    });
  }

  resetPasswordValidation(code, password, confirmPw) {
    const passwordConfirmation = confirmPw ? confirmPw : "";
    if (code.trim().length === 0) {
      this.setState({
        loading: false,
        error: "Please enter the confirmation code"
      });
      return false;
    } else if (password.trim().length === 0 || confirmPw.trim().length === 0) {
      this.setState({
        loading: false,
        error: "Passwords cannot be blank"
      });
      return false;
    } else if (password !== passwordConfirmation) {
      this.setState({
        loading: false,
        error: "Passwords do not match"
      });
      return false;
    }
    return true;
  }

  render() {
    const { email, loading, error, tinid } = this.state;

    return (
      <div className="site-wrap logged-out main-page">
        <BgLeftImage />
        <div className="reg-page__content-wrap">
          <div className="reg-page__primary">
            <h1 className="mt-3">Request WebTrax Account</h1>
            <p className="text-center font-size-sm">
              Thomas WebTrax is being made available for free for companies who
              qualify.
            </p>
            <p className="text-center font-size-xs">
              (Already have a WebTrax account? <a href="/login">Sign In</a>)
            </p>

            <div className="container reg-container">
              <div className="auth-container">
                <Switch>
                  <Route
                    path="/register/login"
                    component={() => (
                      <AuthContainer>
                        {({ isSignedIn }) => {
                          return isSignedIn ? (
                            <Redirect
                              to={{
                                pathname: "/register/companyinfo-in"
                              }}
                            />
                          ) : (
                            <Login onContinue={this.checkEmailForAccount} />
                          );
                        }}
                      </AuthContainer>
                    )}
                  />
                  <Route
                    path="/register/signin"
                    component={() => {
                      return (
                        <AuthContainer>
                          {({ signIn, forgotPassword }) => (
                            <SignIn
                              renderFormValidationErrors={err => {
                                this.setState({
                                  loading: false,
                                  error: err
                                });
                              }}
                              clearError={() => {
                                this.setState({
                                  error: ""
                                });
                              }}
                              onSignIn={(u, p) => {
                                this.setState({
                                  loading: true
                                });
                                signIn(u, p)
                                  .then(() =>
                                    this.nextPage("/register/companyinfo")
                                  )
                                  .catch(
                                    err => this.cleanUpError(err)

                                    // this.nextPage("/register/companyinfo")
                                  );
                              }}
                              email={email}
                              error={error}
                              loading={loading}
                              onForgotPassword={() => {
                                forgotPassword(email)
                                  .then(() => {
                                    this.nextPage("/register/reset");
                                  })
                                  .catch(err => this.cleanUpError(err));
                              }}
                            />
                          )}
                        </AuthContainer>
                      );
                    }}
                  />

                  <Route
                    path="/register/reset"
                    component={() => (
                      <AuthContainer>
                        {({ confirmForgotPassword, signIn }) => (
                          <Reset
                            resetPasswordValidation={(code, pw, confPw) =>
                              this.resetPasswordValidation(code, pw, confPw)
                            }
                            clearError={() => {
                              this.setState({
                                error: ""
                              });
                            }}
                            email={email}
                            error={error}
                            loading={loading}
                            onConfirmForgotPassword={(u, p, c) => {
                              this.setState({
                                loading: true
                              });
                              confirmForgotPassword(u, p, c, tinid)
                                .then(() =>
                                  signIn(u, p)
                                    .then(
                                      this.nextPage("/register/companyinfo")
                                    )
                                    .catch(err => this.cleanUpError(err))
                                )

                                .catch(err => this.cleanUpError(err));
                            }}
                          />
                        )}
                      </AuthContainer>
                    )}
                  />
                  <Route
                    path="/register/create"
                    component={() => (
                      <AuthContainer>
                        {({ signUp }) => (
                          <CreateThomasAccount
                            header={() => <ProgressSteps step={1} />}
                            email={email}
                            onSignUp={params => {
                              this.setState({
                                loading: true
                              });
                              signUp(params)
                                .then(() =>
                                  this.nextPage("/register/companyinfo")
                                )
                                .catch(err => this.cleanUpError(err));
                            }}
                            loading={loading}
                          />
                        )}
                      </AuthContainer>
                    )}
                  />
                  <Route
                    path="/register/companyinfo"
                    component={() => <CompanyInfo email={email} />}
                  />
                  <Route
                    path="/register/thankyou"
                    component={() => <ThankYou />}
                  />

                  <PrivateRoute
                    path="/register/companyinfo-in"
                    component={({ logOut }) => (
                      <CompanyInfoIn
                        email={email}
                        onLogOut={logOut}
                        testClick={() =>
                          this.props.history.push("/register/thankyou-in")
                        }
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/register/thankyou-in"
                    component={() => <ThankYouIn />}
                  />

                  <Route
                    path="/register/thankyou2"
                    component={() => <ThankYou2 />}
                  />

                  <Redirect from="/register" to="/register/login" />
                </Switch>
              </div>
            </div>

            <CopyrightFooter />
          </div>
        </div>
      </div>
    );
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return (
        <AuthContainer>
          {({ loading, isSignedIn, ...authRest }) => {
            if (loading) {
              return <div>Loading...</div>;
            }

            return isSignedIn ? (
              <Component {...props} {...authRest} />
            ) : (
              <Redirect
                to={{
                  pathname: "/register/login"
                }}
              />
            );
          }}
        </AuthContainer>
      );
    }}
  />
);

export default Register;
