import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";

const QUERY_ACCOUNTS = gql`
  query getAccounts {
    userAccounts {
      slug
      id
      name
      website
      url
    }
  }
`;

const AccountsContainer = ({ children, ...props }) => (
  <Query query={QUERY_ACCOUNTS}>
    {({ data: { userAccounts }, loading }) => {
      if (loading) {
        return null;
      }
      return (
        children &&
        children({
          ...props,
          userAccounts
        })
      );
    }}
  </Query>
);

export default AccountsContainer;
