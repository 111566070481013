import React from "react";

const LegendCol = ({ data, active, total, loading }) =>
    loading ? (
        <div className="animated-background piechart-legend legend-loading">
            <div className="background-masker header-top" />
            <div className="background-masker header-left" />
            <div className="background-masker header-right" />
            <div className="background-masker header-bottom" />
            <div className="background-masker subheader-left" />
            <div className="background-masker subheader-right" />
            <div className="background-masker subheader-bottom" />
            <div className="background-masker content-top" />
            <div className="background-masker content-first-end" />
            <div className="background-masker content-second-line" />
            <div className="background-masker content-second-end" />
            <div className="background-masker content-third-line" />
            <div className="background-masker content-third-end" />
        </div>
    ) : (
        <div className="piechart-legend">
            {data.map((item, index) => (
                <div key={index} className="legend-item">
                    <div
                        className="legend-item--square"
                        style={{ backgroundColor: item.fill }}
                    />
                    <div
                        className={`legend-item--label ${
                            item.id === active ? "active" : ""
                        }`}
                    >
                        <div>
                            {item.label.charAt(0).toUpperCase() +
                                item.label.slice(1)}
                            <b>{` ${Math.round(
                                (item.value * 100) / total
                            )}%`}</b>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

const Legend = ({ data, active, loading }) => {
    const total = data.map(i => i.value).reduce((a, b) => a + b, 0);
    const half = Math.round(data.length / 2);

    return (
        <React.Fragment>
            <LegendCol
                data={data.slice(0, Math.max(half, 6))}
                active={active}
                total={total}
                loading={loading}
            />
            <LegendCol
                data={data.slice(Math.max(half, 6))}
                active={active}
                total={total}
                loading={loading}
            />
        </React.Fragment>
    );
};

export default Legend;
