import React from "react";
import ToggleSwitch from "views/components/ToggleSwitch/ToggleSwitch";
import moment from "moment";

import "./DataIntegration.css";

const DataIntegration = ({
    active,
    onToggle,
    dateImplemented,
    savedActive
}) => {
    const MIN_IMPLEMENTATION_DATE = "2017-08-01";

    const calculatedDateImplemented = moment(
        dateImplemented,
        moment.ISO_8601
    ).isValid()
        ? moment
              .max(moment(dateImplemented), moment(MIN_IMPLEMENTATION_DATE))
              .format("MMMM D, YYYY")
        : "";

    return (
        <div className="data-integration">
            <h3>Include Thomasnet.com-only Sessions in WebTrax?</h3>

            {/* <p className="data-integration__subheader">
				Integrate Thomasnet activity into WebTrax session paths and reporting?
			</p> */}

            <div className="data-integration__subheader-info">
                {dateImplemented && (
                    <React.Fragment>
                        WebTrax captures activity on both Thomasnet.com and on
                        your website. Currently, your Thomasnet.com program
                        activity will only show in WebTrax when a user visits
                        both Thomasnet.com and your website in the same session.
                        By enabling this setting, WebTrax will also report on
                        those sessions that never left Thomasnet.com. This
                        Thomasnet.com activity for your account is available in
                        WebTrax starting on {calculatedDateImplemented}.
                    </React.Fragment>
                )}

                {!dateImplemented && (
                    <React.Fragment>
                        Thomasnet.com activity will begin collecting shortly
                        after WebTrax tags are implemented on your own site.
                    </React.Fragment>
                )}
            </div>

            <ToggleSwitch
                text="Enable these Thomasnet-only sessions in WebTrax?"
                active={active}
                onToggle={onToggle}
            />
        </div>
    );
};

export default DataIntegration;
