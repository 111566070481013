import React from "react";
import { Prompt } from "react-router";

import PageHeader from "views/sections/PageHeader/PageHeader";
import VisitorDemographics from "views/components/VisitorDemographics/VisitorDemographics";
import HighValuePages from "views/components/HighValuePages/HighValuePages";
import SettingsTargetContainer from "containers/SettingsTargetContainer";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";

class SettingsTargetedAudience extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            targets: null,
            pages: null,
            madeChange: false,
            saved: false
        };
    }

    componentDidUpdate = () => {
        window.onbeforeunload = () =>
            this.state.madeChange
                ? "You have made changes to your settings. Are you sure you want to leave the site?"
                : null;
    };

    render() {
        const { title } = this.props;
        const editedTargets = this.state.targets;
        const editedPages = this.state.pages;
        const { saved, madeChange } = this.state;

        return (
            <SettingsTargetContainer>
                {({ loading, targets = [], pages = [], saveTargets }) => {
                    if (loading) {
                        return null; //TODO: Add loading component
                    }

                    return (
                        <div
                            className="container"
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <Prompt
                                when={madeChange}
                                message="Do you want to leave this page? Changes you made may not be saved."
                            />

                            <PageHeader
                                title={title}
                                hideDatePicker={true}
                                settingsIcon
                            />

                            <section className="card card-content">
                                <VisitorDemographics
                                    activeTargets={editedTargets || targets}
                                    updateTargets={t => {
                                        this.setState({
                                            targets: t,
                                            madeChange: true
                                        });
                                    }}
                                />

                                <HighValuePages
                                    urls={
                                        editedPages ||
                                        pages.map(page => page.value)
                                    }
                                    updatePages={p => {
                                        this.setState({
                                            pages: p,
                                            madeChange: true
                                        });
                                    }}
                                />
                            </section>

                            <div className="card save-settings">
                                <div className="save-settings--button">
                                    <button
                                        disabled={!madeChange}
                                        style={{ marginLeft: "1rem" }}
                                        className="btn btn-lg btn-primary"
                                        onClick={() => {
                                            saveTargets({
                                                variables: {
                                                    pages:
                                                        (editedPages &&
                                                            (editedPages.length >
                                                            0
                                                                ? editedPages.filter(
                                                                      u =>
                                                                          u !==
                                                                          ""
                                                                  )
                                                                : editedPages)) ||
                                                        pages.map(p => p.value),

                                                    targets: (
                                                        editedTargets || targets
                                                    ).map(
                                                        ({
                                                            label,
                                                            value,
                                                            targetType
                                                        }) => ({
                                                            label,
                                                            value,
                                                            targetType
                                                        })
                                                    )
                                                }
                                            });
                                            this.setState({
                                                madeChange: false,
                                                saved: true
                                            });
                                        }}
                                    >
                                        Save Account Information
                                    </button>
                                    {saved &&
                                        !madeChange && (
                                            <p className="mx-2">
                                                <ThomasIcon attribute="CHECKMARK" />
                                                Your account information has
                                                been saved
                                            </p>
                                        )}
                                </div>
                            </div>
                        </div>
                    );
                }}
            </SettingsTargetContainer>
        );
    }
}

export default SettingsTargetedAudience;
