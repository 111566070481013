import React from "react";
import { gql } from "apollo-boost";
import { Query, Mutation } from "react-apollo";
import SessionQueryContainer from "containers/SessionQueryContainer";

const QUERY_SESSIONS_DOWNLOAD = gql`
  query sessionsDownload($id: ID) {
    sessionsDownload(id: $id) {
      id
      status
      url
    }
  }
`;

const MUTATION_CREATE_SESSIONS_DOWNLOAD = gql`
  mutation createSessionsDownload(
    $query: SessionQueryInput
    $downloadType: DownloadType
  ) {
    createSessionsDownload(query: $query, downloadType: $downloadType) {
      id
      status
      url
    }
  }
`;

const SessionsExportContainer = ({
  visitorId,
  page,
  downloadType,
  children,
  query
}) => {
  let loading = false;

  function MutationComponent({ query }) {
    return (
      <Mutation
        mutation={MUTATION_CREATE_SESSIONS_DOWNLOAD}
        variables={{
          query,
          downloadType
        }}
      >
        {(createSessionsDownload, { data = {} }) => {
          let download = data.createSessionsDownload;

          const onSessionExport = () => {
            loading = true;

            console.log(query);

            createSessionsDownload();
          };

          if (!download) {
            return children({
              onSessionExport
            });
          }

          return (
            <Query
              query={QUERY_SESSIONS_DOWNLOAD}
              variables={{
                id: download.id
              }}
              pollInterval={loading ? 1000 : 0}
            >
              {({ stopPolling, data: { sessionsDownload } = {} }) => {
                if (
                  loading &&
                  sessionsDownload &&
                  sessionsDownload.status === "done"
                ) {
                  stopPolling();

                  loading = false;

                  if (sessionsDownload.url) {
                    window.location.href = sessionsDownload.url;
                  }
                }

                return children({
                  download: sessionsDownload,

                  onSessionExport
                });
              }}
            </Query>
          );
        }}
      </Mutation>
    );
  }

  if (query) {
    return <MutationComponent query={query} />;
  }

  return (
    <SessionQueryContainer visitorId={visitorId} page={page}>
      {({ query }) => <MutationComponent query={query} />}
    </SessionQueryContainer>
  );
};

export default SessionsExportContainer;
