import React from "react";
import { Link } from "react-router-dom";

import "./TopCountCard.scss";
import PieChart from "views/components/PieChart/PieChart";
import colors from "views/components/PieChart/__mocks__/colors";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import LoadingCountCard from "views/components/TopCountCard/LoadingCountCard";

const TopCountCardPie = ({
  data = [],
  title,
  link,
  loading,
  showFraction,
  customPieProps
}) => {
  const totalCount = data.map(e => e.value).reduce((a, b) => a + b, 0);

  return (
    <>
      {title && <h4>{title}</h4>}

      {loading ? (
        <LoadingCountCard pie />
      ) : (
        <React.Fragment>
          <div className="topcountcard-pie-desc">
            <PieChart
              data={data}
              customClass="topcountcard-piechart"
              {...customPieProps}
            />

            {data.map((r, i) => (
              <div key={i} className="top-countcard--row">
                <div
                  className="top-countcard--order-color"
                  style={{ backgroundColor: colors[i] }}
                />
                <div className="top-countcard--row-content">
                  <div className="top-countcard--fill" />
                  <div className="top-countcard--ref">{r.label}</div>
                  <div className="top-countcard--total">
                    {showFraction
                      ? `${r.value}/${totalCount}`
                      : r.value === 0
                      ? 0
                      : `${((r.value * 100) / totalCount).toFixed(0)}%`}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {link && (
            <Link to={link} className="top-countcard--view-all">
              View All Devices <ThomasIcon attribute="ARROW_RIGHT" />
            </Link>
          )}
        </React.Fragment>
      )}
    </>
  );
};

export default TopCountCardPie;
