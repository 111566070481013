import React from "react";
import moment from "moment";

function titleCase(str) {
    return str
        .toLowerCase()
        .split(" ")
        .map(function(word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
}

const InLineRfiDetail = ({
    data,
    noTitle,
    customClass,
    customKeys,
    loading
}) => {
    return (
        <div className={`rfi-submission ${customClass ? customClass : ""}`}>
            {loading ? (
                <div className="dotter">
                    <div className="dot1" />
                    <div className="dot2" />
                    <div className="dot3" />
                    <div className="dot4" />
                </div>
            ) : (
                data.map((rfi, i) => (
                    <div
                        className={`rfi-submission--rows ${
                            customClass ? customClass + "-rows" : ""
                        }`}
                        key={rfi.id || i}
                    >
                        <div
                            className={`rfi-submission--row ${
                                customClass ? customClass + "-row" : ""
                            }`}
                        >
                            <b>Submission Time</b>
                            <div>
                                {moment(rfi["timestamp"]).format(
                                    "MMM D, YYYY hh:mm A"
                                )}
                            </div>
                        </div>

                        <div
                            className={`rfi-submission--row ${
                                customClass ? customClass + "-row" : ""
                            }`}
                        >
                            <b>Name</b>
                            <div>{titleCase(rfi["company"])}</div>
                        </div>

                        <div
                            className={`rfi-submission--row ${
                                customClass ? customClass + "-row" : ""
                            }`}
                        >
                            <b>Email Address</b>
                            <div>
                                {rfi["name"].toString().split("@").length > 1
                                    ? `*@${
                                          rfi["name"].toString().split("@")[1]
                                      }`
                                    : rfi["name"]}
                            </div>
                        </div>

                        <div
                            className={`rfi-submission--row ${
                                customClass ? customClass + "-row" : ""
                            }`}
                        >
                            <b>RFI Subject</b>
                            <div>{rfi["subject"]}</div>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default InLineRfiDetail;
