import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./DateRangePicker.scss";
import ThomasIcon from "../ThomasIcon/ThomasIcon";

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment(props.startDate) || moment(),
      endDate: moment(props.endDate) || moment(),
      startDateBox: moment(props.startDate).format("MM/DD/YYYY"),
      endDateBox: moment(props.endDate).format("MM/DD/YYYY"),
      startSelected: false,
      dropdown: false,
      startDateBoxError: false,
      endDateBoxError: false,
      orderError: false,
      rangeError: false
    };

    this.handleDateSelect = this.handleDateSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onDateRangeApply = this.onDateRangeApply.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  onDateRangeApply() {
    const { startDateBox, endDateBox } = this.state;
    const startError = !moment(startDateBox, "MM/DD/YYYY", true).isValid();
    // || moment(startDateBox, "MM/DD/YYYY").isBefore("2018-01-01");
    const endError = !moment(endDateBox, "MM/DD/YYYY", true).isValid();
    const orderError = moment(endDateBox, "MM/DD/YYYY").isBefore(
      moment(startDateBox, "MM/DD/YYYY")
    );

    const rangeError = !moment(endDateBox).isBetween(
      moment(startDateBox),
      moment(startDateBox).add(1, "years"),
      null,
      []
    );

    if (startError || endError) {
      if (startError) {
        this.setState({ startDateBoxError: true });
      }
      if (endError) {
        this.setState({ endDateBoxError: true });
      }
    } else if (orderError) {
      this.setState({ orderError: true });
    } else if (rangeError) {
      this.setState({ rangeError: true });
    } else {
      this.setState({
        startDateBoxError: false,
        endDateBoxError: false,
        startSelected: false,
        orderError: false,
        rangeError: false
      });
      this.toggleDropdown();
      // this.props.onDateRangeApply(moment(startDateBox).valueOf(), moment(endDateBox).valueOf());
      this.props.onDateRangeApply(
        moment(startDateBox, "MM/DD/YYYY").toISOString(true),
        moment(endDateBox, "MM/DD/YYYY").toISOString(true)
      );
    }
  }

  handleInputChange(event) {
    const {
      startDateBoxError,
      endDateBoxError,
      rangeError,
      startDateBox,
      endDateBox
    } = this.state;

    const target = event.target;

    const validDate = moment(target.value, "MM/DD/YYYY", true).isValid();

    this.setState({
      [target.name]: target.value
    });

    if (startDateBoxError && target.name === "startDateBox" && validDate) {
      this.setState({ startDateBoxError: false });
    }
    if (endDateBoxError && target.name === "endDateBox" && validDate) {
      this.setState({ endDateBoxError: false });
    }
    if (rangeError) {
      if (
        moment(endDateBox).isBetween(
          moment(startDateBox),
          moment(startDateBox).add(1, "years"),
          null,
          []
        )
      ) {
        this.setState({ rangeError: false });
      }
    }

    if (
      moment(startDateBox, "MM/DD/YYYY").isBefore(
        moment(endDateBox, "MM/DD/YYYY")
      )
    ) {
      this.setState({ orderError: false });
    }

    // if (moment(startDateBox).isBefore(moment())) {
    //     this.setState({ startDateBoxError: false, beforeTodayError: true });
    // }
    // if (moment(endDateBox).isBefore(moment())) {
    //     this.setState({ endDateBoxError: false, beforeTodayError: true });
    // }
  }

  toggleDropdown() {
    this.setState({
      dropdown: !this.state.dropdown
    });
  }

  handleDateSelect(date) {
    const { startSelected, startDate } = this.state;
    const formattedDate = moment(date).format("MM/DD/YYYY");

    if (!startSelected) {
      this.setState({
        startDate: date,
        startDateBox: formattedDate,
        startSelected: true,
        endDate: date,
        endDateBox: formattedDate
      });
    } else {
      if (date.isBefore(startDate)) {
        this.setState({
          startDate: date,
          startDateBox: formattedDate,
          endDate: startDate,
          endDateBox: moment(startDate).format("MM/DD/YYYY")
        });
      } else {
        this.setState({
          endDate: date,
          endDateBox: formattedDate
        });
      }
      this.setState({ startSelected: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { startDate, endDate } = this.state;
    const formattedStart = moment(nextProps.startDate);
    const formattedEnd = moment(nextProps.endDate);

    if (!formattedStart.isSame(startDate)) {
      this.setState({ startDate: formattedStart });
      return true;
    }
    if (!formattedEnd.isSame(endDate)) {
      this.setState({ endDate: formattedEnd });
    }
  }

  render() {
    const {
      dropdown,
      startDate,
      endDate,
      startDateBox,
      endDateBox,
      startDateBoxError,
      endDateBoxError,
      orderError,
      rangeError
    } = this.state;

    const { customClass, hideTitle } = this.props;

    return (
      <div className={`date-picker ${customClass ? customClass : ""}`}>
        <div className="date-picker--header">
          {!hideTitle && (
            <div className="date-picker--header-title">Set Time Period</div>
          )}
          <div className={`date-picker--display ${dropdown ? "active" : ""}`}>
            <div
              className={`date-picker--display-range ${
                dropdown ? "active" : ""
              }`}
              onClick={this.toggleDropdown}
            >
              <ThomasIcon attribute="CALENDAR" raw />{" "}
              {moment(this.props.startDate).format("L")} -{" "}
              {moment(this.props.endDate).format("L")}
            </div>
            <div
              className={`date-picker--display-arrow ${
                dropdown ? "active" : ""
              }`}
              onClick={this.toggleDropdown}
            >
              <img
                src={process.env.PUBLIC_URL + "/img/arrow.svg"}
                alt="arrow"
              />
            </div>
          </div>
        </div>
        <div className={`date-picker--dropdown ${dropdown ? "active" : ""}`}>
          <div className="date-picker--months">
            <div>Choose single date or select date range</div>
            <DatePicker
              inline
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleDateSelect}
              monthsShown={2}
              shouldCloseOnSelect={false}
              // minDate={moment()}
              maxDate={moment()}
            />
          </div>
          <div className="date-picker--actions">
            <div className="date-picker--actions-dates">
              <label className="date-picker--label grid1">Start date:</label>
              <input
                className={`date-picker--input grid2 ${
                  startDateBoxError || rangeError ? "input-error" : ""
                }`}
                name="startDateBox"
                value={startDateBox}
                onChange={this.handleInputChange}
              />
              <label className="date-picker--label">End date:</label>
              <input
                className={`date-picker--input ${
                  endDateBoxError || rangeError ? "input-error" : ""
                }`}
                name="endDateBox"
                value={endDateBox}
                onChange={this.handleInputChange}
              />
            </div>
            {(startDateBoxError || endDateBoxError) && (
              <div className="date-picker--actions-error">
                Please enter a valid date format MM/DD/YYYY
              </div>
            )}
            {orderError && (
              <div className="date-picker--actions-error">
                Please enter your dates in the correct order
              </div>
            )}
            {rangeError && (
              <div className="date-picker--actions-error">
                Please enter a range of 1 year or less
              </div>
            )}
            <div className="date-picker--actions-buttons">
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  this.toggleDropdown();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.onDateRangeApply();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DateRangePicker.defaultProps = {
  onDateRangeApply: () => {}
};

DateRangePicker.propTypes = {
  // startDate: PropTypes.number,

  // endDate: PropTypes.number,

  onDateRangeApply: PropTypes.func
};

export default DateRangePicker;
