import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import SessionQueryContainer from "containers/SessionQueryContainer";

import pageConfig from "views/pages/Analytics/pageConfig";

const analyticsMap = {
  "analytics-referrers": {
    query: gql`
      query getReferrers(
        $query: SessionQueryInput!
        $limit: Int
        $offset: Int
        $ordering: [Ordering]
      ) {
        connection: referrers(
          query: $query
          limit: $limit
          offset: $offset
          ordering: $ordering
        ) {
          edges {
            cursor
            node {
              url
              sessionsCount
              avgDuration
              avgPages
              bounceRate
            }
          }
          pageInfo {
            hasNextPage
            totalCount
          }
        }
      }
    `,
    downloadType: "REFERRERS",
    ordering: pageConfig["analytics-referrers"].sort
  },

  "analytics-toppages": {
    query: gql`
      query getTopPages(
        $query: SessionQueryInput!
        $limit: Int
        $offset: Int
        $ordering: [Ordering]
      ) {
        connection: topPages(
          query: $query
          limit: $limit
          offset: $offset
          ordering: $ordering
        ) {
          edges {
            cursor
            node {
              url
              id
              pageCount
              domain
              params
            }
          }
          pageInfo {
            hasNextPage
            totalCount
          }
        }
      }
    `,
    downloadType: "TOP_PAGES",
    ordering: pageConfig["analytics-toppages"].sort
  },

  "analytics-searchterms": {
    query: gql`
      query getSearchTerms(
        $query: SessionQueryInput!
        $limit: Int
        $offset: Int
        $ordering: [Ordering]
      ) {
        connection: searchTerms(
          query: $query
          limit: $limit
          offset: $offset
          ordering: $ordering
        ) {
          edges {
            cursor
            node {
              id
              term
              termct
              bounces
              bounceRate
            }
          }
          pageInfo {
            hasNextPage
            totalCount
          }
        }
      }
    `,
    downloadType: "SEARCH_TERMS",
    ordering: pageConfig["analytics-searchterms"].sort
  },

  "analytics-entrypages": {
    query: gql`
      query getEntryPages(
        $query: SessionQueryInput!
        $limit: Int
        $offset: Int
        $ordering: [Ordering]
      ) {
        connection: entryPages(
          query: $query
          limit: $limit
          offset: $offset
          ordering: $ordering
        ) {
          edges {
            cursor
            node {
              id
              url
              bounces
              bounceRate
              params
              entryCount
              domain
            }
          }
          pageInfo {
            hasNextPage
            totalCount
          }
        }
      }
    `,
    downloadType: "ENTRY_PAGES",
    ordering: pageConfig["analytics-entrypages"].sort
  },

  "analytics-exitpages": {
    query: gql`
      query getExitPages(
        $query: SessionQueryInput!
        $limit: Int
        $offset: Int
        $ordering: [Ordering]
      ) {
        connection: exitPages(
          query: $query
          limit: $limit
          offset: $offset
          ordering: $ordering
        ) {
          edges {
            cursor
            node {
              id
              url
              params
              exitCount
              domain
            }
          }
          pageInfo {
            hasNextPage
            totalCount
          }
        }
      }
    `,
    downloadType: "EXIT_PAGES",
    ordering: pageConfig["analytics-exitpages"].sort
  },

  "analytics-browsers": {
    query: gql`
      query getBrowsers(
        $query: SessionQueryInput!
        $limit: Int
        $offset: Int
        $ordering: [Ordering]
      ) {
        connection: browsers(
          query: $query
          limit: $limit
          offset: $offset
          ordering: $ordering
        ) {
          edges {
            cursor
            node {
              id
              sessionsct
              browserIndex
              percentage
              browser
              platform
              version
            }
          }
          pageInfo {
            hasNextPage
            totalCount
          }
        }
      }
    `,
    downloadType: "BROWSERS",
    ordering: pageConfig["analytics-browsers"].sort
  },

  "analytics-sessionduration-single": {
    query: gql`
      query getDurationsAndDepth(
        $query: SessionQueryInput!
        $limit: Int
        $offset: Int
        $ordering: [Ordering]
      ) {
        durations(
          query: $query
          limit: $limit
          offset: $offset
          ordering: $ordering
        ) {
          edges {
            cursor
            node {
              id
              durationRange
              duration
              sessionsct
              percentage
            }
          }
        }
        depths(
          query: $query
          limit: $limit
          offset: $offset
          ordering: $ordering
        ) {
          edges {
            cursor
            node {
              id
              depth
              pagect
              sessionsct
              percentage
            }
          }
        }
      }
    `,
    downloadType: "DURATIONS",
    ordering: pageConfig["analytics-sessionduration-single"].sort
  }
};

const AnalyticsContainer = ({
  children,
  page,
  queryKey,
  hardLimit,
  referrer,
  ...props
}) => (
  <SessionQueryContainer page={page}>
    {({ query, sessionsDisplayAmount }) => {
      if (referrer) {
        query.referrers = [referrer];
      }

      return (
        <Query
          query={analyticsMap[queryKey].query}
          variables={{
            query,
            limit: hardLimit ? hardLimit : sessionsDisplayAmount,
            ordering: analyticsMap[queryKey].ordering
          }}
        >
          {({ loading, error, fetchMore, data: { connection = {} } }) => {
            if (error) {
              console.error(error);
              return;
            }

            const totalPages = Math.ceil(
              ((connection.pageInfo && connection.pageInfo.totalCount) || 0) /
                sessionsDisplayAmount
            );

            const totalCount =
              connection.pageInfo && connection.pageInfo.totalCount;

            return (
              children &&
              children({
                ...props,
                sessionsDisplayAmount,
                data: connection.edges || [],
                totalCount,
                totalPages,
                loading,
                query,
                downloadType: analyticsMap[queryKey].downloadType,
                // initalSort: analyticsMap[page].ordering,
                onUpdate: (sort, p) => {
                  fetchMore({
                    variables: {
                      ordering: sort,
                      offset: (p - 1) * sessionsDisplayAmount
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      return fetchMoreResult;
                    }
                  });
                }
              })
            );
          }}
        </Query>
      );
    }}
  </SessionQueryContainer>
);

export default AnalyticsContainer;
