import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { QUERY_PAGE_CONFIG } from "resolvers/sessions";

const GET_GLOBAL_SETTINGS = gql`
    query getGlobalSettings {
        sessionsDisplayAmount @client

        sessionsViewType @client

        dateRange @client {
            startDate
            endDate
        }

        lastSavedTimestamp @client
    }
`;

const SessionQueryContainer = ({ visitorId, children, page }) => {
    return (
        <Query query={GET_GLOBAL_SETTINGS}>
            {({
                data: {
                    lastSavedTimestamp,
                    sessionsDisplayAmount,
                    sessionsViewType,
                    dateRange: { startDate, endDate } = {}
                }
            }) => (
                <Query
                    query={QUERY_PAGE_CONFIG}
                    variables={{ page }}
                    skip={page && page.useAllTargets}
                >
                    {({ error, data: { page } }) => {
                        if (error) {
                            console.error("SessionQueryContainer ERROR", error);
                        }

                        const activeFilters = page ? page.activeFilters : [];

                        const query = {
                            timestamp: lastSavedTimestamp,
                            startDate,
                            endDate,
                            visitorId,
                            ...filtersMap(activeFilters)
                        };

                        //If visitorId is present, show in timeline format.
                        //This could be moved to a prop, but it would require
                        //re-designing the component structure.
                        if (visitorId) {
                            sessionsViewType = "timeline";
                        }

                        return (
                            children &&
                            children({
                                query,
                                sessionsViewType,
                                sessionsDisplayAmount
                            })
                        );
                    }}
                </Query>
            )}
        </Query>
    );
};

const filtersMap = filters => {
    const lookup = {
        REFERRER: "referrers",
        ACTION: "actions",
        TARGET: "targets",
        SEARCH: "searches",
        BASE: "base"
    };

    //Create object that separates filters into individual
    //query elements.
    let result = filters.reduce((map, filter) => {
        if (!(lookup[filter.filterType] in map)) {
            map[lookup[filter.filterType]] = [];
        }

        const val =
            filter.filterType === "TARGET"
                ? { value: filter.value, targetType: filter.targetType }
                : filter.value;

        map[lookup[filter.filterType]].push(val);

        return map;
    }, {});

    return result;
};

export default SessionQueryContainer;
