import React from "react";
import SiteNavItem from "./SiteNavItem";

import { AppContext } from "views/components/App/app-context";

const SiteSubNav = ({
    subnav,
    link,
    level,
    active,
    activeOpen,
    updateNav,
    user
}) => {
    const escapedPath = link.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");
    const match = /\/(\w*)/g.exec(escapedPath);

    return (
        <ul
            className={`subbb site-nav--side-nav__level-${level} ${
                active.includes(match[1]) || activeOpen ? `active` : ""
            }`}
        >
            <AppContext.Consumer>
                {({ hideForProd }) => {
                    var newNav = subnav || [];
                    if (hideForProd) {
                        newNav = subnav.filter(
                            n =>
                                n.link !== "/settings/webtraxtutorial" &&
                                n.link !== "/settings/calltracking"
                        );
                    }

                    return (
                        <React.Fragment>
                            {newNav.map((item, index) => (
                                <SiteNavItem
                                    key={item.link}
                                    level={level}
                                    subnav={item.children}
                                    title={item.title}
                                    link={item.link}
                                    active={active}
                                    changeNav={updateNav}
                                    user={user}
                                />
                            ))}
                        </React.Fragment>
                    );
                }}
            </AppContext.Consumer>
        </ul>
    );
};

export default SiteSubNav;
