import * as React from "react";
import { Switch, Route } from "react-router-dom";
import "./Campaigns.scss";
import SiteHeader from "views/layouts/SiteHeader/SiteHeader";
import SiteFooter from "views/layouts/SiteFooter/SiteFooter";
import ViewCampaign from "views/pages/Campaigns/ViewCampaign";
import AuthContainer from "containers/AuthContainer";
import AllCampaigns from "views/pages/Campaigns/AllCampaigns";
import PDF from "views/pages/Campaigns/print/PDF";
import qs from "qs";

class Campaigns extends React.Component<any, any> {
  render() {
    return (
      <div className="site-wrap">
        <SiteHeader thomasLogo hideLogOut />

        <Switch>
          <Route exact path="/campaigns" render={() => <AllCampaigns />} />
          <Route
            exact
            path="/campaigns/:campaignId/view"
            render={({ match }) => (
              <ViewCampaign campaignId={match.params.campaignId} />
            )}
          />
          <Route
            exact
            path="/campaigns/:campaignId/view/print"
            render={({ match, location }) => {
              const { start, end } = qs.parse(location.search, {
                ignoreQueryPrefix: true
              });

              return (
                <PDF
                  campaignId={match.params.campaignId}
                  start={start}
                  end={end}
                />
              );
            }}
          />
        </Switch>

        <SiteFooter />
      </div>
    );
  }
}

export default Campaigns;
