import React from "react";
import SiteNavItem from "./SiteNavItem";

const SiteNav = ({ subnav, active, updateNav, user }) => {
    return (
        <ul className={`site-nav--side-nav__level-1`}>
            {subnav &&
                subnav.map((item, index) => (
                    <SiteNavItem
                        key={item.link}
                        level={1}
                        subnav={item.children}
                        title={item.title}
                        link={item.link}
                        active={active}
                        changeNav={updateNav}
                        user={user}
                    />
                ))}
        </ul>
    );
};

export default SiteNav;
