import React from "react";
import { gql } from "apollo-boost";
import { Mutation } from "react-apollo";

const MUTATION_SAVE_PREFERENCES = gql`
    mutation savePreferences($prefs: PreferencesInput) {
        savePreferences(preferences: $prefs) {
            prefs {
                hasAcceptedTerms
            }
        }
    }
`;

const UserSettingsContainer = ({ children }) => (
    // <Query query={QUERY_PREFERENCES}>
    //     {({ loading, data: { user } }) => (
    <Mutation mutation={MUTATION_SAVE_PREFERENCES}>
        {savePreferences =>
            children &&
            children({
                savePreferences
            })
        }
    </Mutation>
    //     )}
    // </Query>
);

export default UserSettingsContainer;
