import React from "react";
import ProgressSteps from "../ProgressSteps";

const ThankYou2 = () => (
  <React.Fragment>
    <ProgressSteps step={3} />
    <div className="text-center">
      <h2>Thank You!</h2>
      <p>
        You've officially requested to join Thomas WebTrax release, and our team
        will be in touch shortly with more information.
      </p>
    </div>
  </React.Fragment>
);

export default ThankYou2;
