import React from "react";

import { Link } from "react-router-dom";
import RouterLink from "views/components/RouterLink/RouterLink";

class SignIn extends React.Component {
  constructor(...props) {
    super(...props);
    this.state = {
      password: "",
      email: this.props.email
    };

    this.refPassword = React.createRef();
  }

  componentDidMount() {
    this.refPassword.current.focus();
  }

  // add lifecycle method to redirect user to /login page if first point of entry is not /login. Do this because {email} is blank on top of 'Not You?' and it looks weird. =(

  render() {
    const {
      onSignIn,
      email,
      loading,
      onForgotPassword,
      clearError,
      error,
      renderFormValidationErrors
    } = this.props;

    return (
      <React.Fragment>
        <h2 className="text-center pt-0">WebTrax sign in</h2>
        <p className="text-center">
          Need to create an account? <Link to="/register">Request Access</Link>
        </p>
        <div className="form-bg form-short">
          {error && (
            <p
              className="alert alert-danger hide"
              id="errMsg"
              style={{ display: "block" }}
            >
              {error}
            </p>
          )}
          <p className="text-center">
            <b>{email}</b>
            <br />
            <RouterLink
              urlClickHandler={() => {
                clearError();
                return `/login`;
              }}
            >
              {({ onExecuteLink }) => {
                return (
                  <span
                    className="forgot-password-link"
                    onClick={onExecuteLink}
                  >
                    Not You?
                  </span>
                );
              }}
            </RouterLink>
          </p>
          <div className="form-group" style={{ position: "relative" }}>
            <label htmlFor="exampleInputPassword1">Enter your password</label>
            <input
              name="password"
              type="password"
              className="form-control"
              placeholder="password"
              value={this.state.password}
              ref={this.refPassword}
              onChange={e =>
                this.setState({
                  password: e.target.value
                })
              }
              onKeyPress={e => {
                if (e.keyCode || e.which === 13) {
                  onSignIn(email, this.state.password);
                }
              }}
            />

            <a
              className="forgot-password-link"
              onClick={() => onForgotPassword()}
              href="#forgot-password"
            >
              Forgot Password?
            </a>
          </div>

          <div className="form-group flex-end-btn">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                if ((this.state.password || "").trim().length === 0) {
                  renderFormValidationErrors("Please enter a valid password");
                } else {
                  onSignIn(email, this.state.password);
                }
              }}
            >
              {loading ? (
                <div className="spinner loading-white">
                  <div className="rect1" />
                  <div className="rect2" />
                  <div className="rect3" />
                  <div className="rect4" />
                  <div className="rect5" />
                </div>
              ) : (
                "Sign In"
              )}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SignIn;
