import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div
                    style={{
                        width: "100vw",
                        height: "calc(100vh - 4rem)",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <div style={{ margin: "auto 5em" }}>
                        <h1>Whoops!</h1>
                        <h2>Something went wrong.</h2>
                        <p>Please try one of these pages</p>
                        <p>
                            <a href="/">Home</a>
                        </p>
                        <p>
                            <a href="/audience">Audience</a>
                        </p>
                        <p>
                            <a href="/sessions">Sessions</a>
                        </p>
                        <p>
                            <a href="/analytics">Analytics</a>
                        </p>
                        <p>
                            <a href="/settings">Settings and Tutorials</a>
                        </p>
                    </div>
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/img/ico-global-error-webtrax.svg"
                        }
                        alt="error"
                    />
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
