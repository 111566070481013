import React from "react";
import { gql } from "apollo-boost";
import { Query, Mutation } from "react-apollo";

const QUERY_USER_SETTINGS = gql`
  query getUserSettings {
    user {
      id
      settings {
        botFiltering
        reportType
        urls
        email
        timezoneOffset
        defaultSessionsCount
        blockedIps
        blockedReferrers
        excludedCountries
        indexFiles
        ownReferrers
        whitepages
        shortQueries
        dateImplemented
        lastmod
      }
    }
  }
`;

const MUTATION_SAVE_SETTINGS = gql`
  mutation saveAccountSettings($settings: SettingsInput) {
    saveSettings(settings: $settings) {
      id
      settings {
        botFiltering
        reportType
        urls
        email
        timezoneOffset
        defaultSessionsCount
        blockedIps
        blockedReferrers
        excludedCountries
        indexFiles
        ownReferrers
        whitepages
        shortQueries
      }
    }
  }
`;

const SettingsTechnicalContainer = ({ children }) => (
  <Query query={QUERY_USER_SETTINGS}>
    {({ loading, data: { user } }) => (
      <Mutation
        mutation={MUTATION_SAVE_SETTINGS}
        update={(cache, result) => {
          cache.writeData({
            data: {
              lastSavedTimestamp: new Date().getTime()
            }
          });
        }}
      >
        {saveSettings =>
          children &&
          children({
            user,
            saveSettings,
            loading
          })
        }
      </Mutation>
    )}
  </Query>
);

export default SettingsTechnicalContainer;
