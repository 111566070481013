import React from "react";
import SessionInfoContainer from "containers/SessionInfoContainer";
import SessionInfo from "views/components/SessionInfo/SessionInfo";
import ResultsFilter from "views/components/ResultsFilter/ResultsFilter";
import CompanyInfo from "views/components/CompanyInfo/CompanyInfo";
import "./SessionSummary.css";

// import myActiveFilters from "views/components/ResultsFilter/__mocks__/activeFilters";

import FilterContainer from "containers/FilterContainer";
import CompanyInfoContainer from "containers/CompanyInfoContainer";

const SessionSummary = ({
    page,
    title,
    visitorId,
    showCompany,
    showFilters,
    viewSessionsButton = false,
    backToLink = false,
    showBorder = false,
    params,
    customClass
}) => {
    return (
        <section
            className={`session-detail card ${customClass ? customClass : ""}`}
        >
            {showCompany && (
                <CompanyInfoContainer visitorId={visitorId}>
                    {({ visitor, user }) => (
                        <CompanyInfo company={visitor} user={user} />
                    )}
                </CompanyInfoContainer>
            )}

            <SessionInfoContainer
                visitorId={visitorId}
                page={page}
                params={params}
            >
                {({ filters, total, query, loading }) => (
                    <React.Fragment>
                        <SessionInfo
                            title={title}
                            total={total}
                            loading={loading}
                            startDate={query.startDate}
                            endDate={query.endDate}
                            viewSessionsButton={viewSessionsButton}
                            backToLink={backToLink}
                            showBorder={showBorder}
                        />

                        {showFilters && (
                            <FilterContainer page={page}>
                                {({
                                    activeFilters,
                                    addFilters,
                                    removeFilter,
                                    removeFilters
                                }) => {
                                    return (
                                        <ResultsFilter
                                            filters={filters}
                                            activeFilters={activeFilters}
                                            onApplyClick={({
                                                referrer,
                                                target,
                                                action,
                                                search
                                            }) => {
                                                let targets = [target];

                                                if (
                                                    target &&
                                                    target.value ===
                                                        "ALL_TARGETS"
                                                ) {
                                                    targets = filters.targets;
                                                }

                                                addFilters({
                                                    variables: {
                                                        filters: [
                                                            referrer,
                                                            action,
                                                            ...targets
                                                        ].filter(f => f),
                                                        searches: [
                                                            search
                                                        ].filter(f => f),
                                                        page
                                                    }
                                                });
                                            }}
                                            onFilterRemove={filter => {
                                                removeFilter({
                                                    variables: { filter, page }
                                                });
                                            }}
                                            onFiltersRemove={filterType => {
                                                removeFilters({
                                                    variables: {
                                                        filterType,
                                                        page
                                                    }
                                                });
                                            }}
                                        />
                                    );
                                }}
                            </FilterContainer>
                        )}
                    </React.Fragment>
                )}
            </SessionInfoContainer>
        </section>
    );
};

export default SessionSummary;
