import React from "react";

import "./Pagination.css";

export default class Pagination extends React.Component {
    constructor(props) {
        super(props);

        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange(page) {
        this.props.onUpdate(page);
        window.scrollTo(0, 0);
    }

    render() {
        const { totalPages, currentPage } = this.props;
        const firstPage = currentPage === 1;
        const lastPage = totalPages <= currentPage;

        var Pages = [];
        var init, range;

        // pages 7 and up
        if (currentPage > 6) {
            // pages (4 less than total) - total
            if (currentPage + 4 >= totalPages) {
                init = Math.max(totalPages - 9, 1);
                range = totalPages;
            }
            // pages 7 - (4 less than total)
            // MAJORITY
            else {
                init = currentPage - 5;
                range = Math.min(currentPage + 4, totalPages);
            }
        } else {
            // pages 1-6
            init = 1;
            range = Math.min(10, totalPages);
        }

        for (let i = init; i <= range; i++) {
            Pages.push(
                <div
                    key={i}
                    className={`pagination-item pagination-item__num ${
                        currentPage === i ? "active" : ""
                    }`}
                    onClick={() => {
                        this.handlePageChange(i);
                    }}
                >
                    {i}
                </div>
            );
        }

        return (
            <div className="pagination">
                <div className="thomas-pagination">
                    <div
                        className={`pagination-item pagination-item__prev ${
                            firstPage ? "active" : ""
                        }`}
                        onClick={() => {
                            if (!firstPage) {
                                this.handlePageChange(currentPage - 1);
                            }
                        }}
                    >
                        Previous
                    </div>
                    {Pages}
                    <div
                        className={`pagination-item pagination-item__next ${
                            lastPage ? "active" : ""
                        }`}
                        onClick={() => {
                            if (!lastPage) {
                                this.handlePageChange(currentPage + 1);
                            }
                        }}
                    >
                        Next
                    </div>
                </div>
            </div>
        );
    }
}
