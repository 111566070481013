import React from "react";
import moment from "moment";
import { NavLink } from "react-router-dom";
import "./SessionInfo.css";
import "./Loading.css";

const LoadingBlock = () => (
  <div className="spinner">
    <div className="rect1" />
    <div className="rect2" />
    <div className="rect3" />
    <div className="rect4" />
    <div className="rect5" />
  </div>
);

const SessionInfo = ({
  title,
  total,
  loading,
  startDate,
  endDate,
  showBorder,
  viewSessionsButton,
  backToLink
}) => (
  <div className="session-info session-detail-row">
    <div className={`row-content${showBorder ? "" : "__borderless"}`}>
      <div className="session-info__count">
        {loading ? <LoadingBlock /> : total && total.toLocaleString()}
      </div>
      <div className="session-info__header">
        <div className="session-info__title">{title}</div>
        <div className="session-info__dates">
          {moment(startDate).format("MMM D, YYYY")} -{" "}
          {moment(endDate).format("MMM D, YYYY")}
        </div>
      </div>

      {viewSessionsButton && (
        <NavLink
          to={viewSessionsButton.link}
          style={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            textDecoration: "none"
          }}
        >
          <button className="btn btn-primary">{viewSessionsButton.text}</button>
        </NavLink>
      )}
    </div>

    {/* {backToLink && (
            <div className="session-info__return">
                <NavLink to={backToLink.nav}>{`<< back to ${
                    backToLink.label
                }`}</NavLink>
            </div>
        )} */}
  </div>
);

export default SessionInfo;
