import React from "react";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import DropDown from "views/components/DropDown/DropDown";
import ActiveItem from "views/components/ActiveItem/ActiveItem";
import { intersectionBy } from "lodash";

import companysizes from "data/companysizes";
import industries from "data/industries";
import countries from "data/countries";
import revenues from "data/revenues";
import states from "data/states";

import "./VisitorDemographics.scss";

const TargetsRow = ({
  title,
  label,
  type,
  items,
  onAdd,
  onRemove,
  activeTargets
}) => {
  return (
    <div className="visitor-demo-settings__row">
      <DropDown
        customClass="advanced-settings--textbox"
        title={title}
        label={label}
        items={items}
        value={null}
        onChange={target => {
          onAdd(target, type);
        }}
        disabled={intersectionBy(items, activeTargets, "label")}
        multi
      />
      <div className="advanced-settings--active-items">
        {activeTargets
          .filter(s => s.targetType === type)
          .map((item, index) => (
            <ActiveItem
              key={index}
              customMarkup={
                <React.Fragment>
                  <ThomasIcon
                    attribute="HIGH_VALUE_AUDIENCE"
                    customClass="target-audience-icon"
                    hideToolTip
                  />
                  <div className="vd--label">{item.label}</div>
                </React.Fragment>
              }
              customClass="visitor-demo--active-item"
              onRemove={() => onRemove(item)}
            />
          ))}
      </div>
    </div>
  );
};

class VisitorDemographics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTargets: props.activeTargets
    };

    this.addTarget = this.addTarget.bind(this);
    this.removeTarget = this.removeTarget.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      activeTargets: nextProps.activeTargets
    };
  }

  addTarget(target, type) {
    const activeTargets = [
      ...this.state.activeTargets,
      ...target.map(t => ({
        ...t,
        targetType: type
      }))
    ];

    this.setState({
      activeTargets
    });

    this.props.updateTargets(activeTargets);
  }

  removeTarget(target) {
    const newTargets = this.state.activeTargets.filter(
      t => !(t.value === target.value && t.targetType === target.targetType)
    );
    this.setState({
      activeTargets: newTargets
    });
    this.props.updateTargets(newTargets);
  }

  render() {
    const { activeTargets } = this.state;

    return (
      <div className="visitor-demo-settings">
        {!this.props.noHeader && (
          <React.Fragment>
            <h3 style={{ marginBottom: 0 }}>Visitor Demographics</h3>
            <p className="visitor-demo-settings__subheader">
              Whenever a visitor matches these demographics selections, you will
              see this icon
              <ThomasIcon attribute="HIGH_VALUE_AUDIENCE" />
            </p>
          </React.Fragment>
        )}

        <div className="NEWvisitor-demo-settings__categories">
          <TargetsRow
            title="Select Industry"
            label="Add Industry to target"
            type="Industry"
            items={industries}
            onAdd={this.addTarget}
            onRemove={this.removeTarget}
            activeTargets={activeTargets}
          />

          <TargetsRow
            title="Select Company Size"
            label="Add Company Size to target"
            type="Size"
            items={companysizes}
            onAdd={this.addTarget}
            onRemove={this.removeTarget}
            activeTargets={activeTargets}
          />

          <TargetsRow
            title="Select Revenue Range"
            label="Add Revenue Range to target"
            type="Revenue"
            items={revenues}
            onAdd={this.addTarget}
            onRemove={this.removeTarget}
            activeTargets={activeTargets}
          />

          <TargetsRow
            title="Select State / Province"
            label="Add State / Province to target"
            type="State"
            items={states}
            onAdd={this.addTarget}
            onRemove={this.removeTarget}
            activeTargets={activeTargets}
          />

          <TargetsRow
            title="Select Country"
            label="Add Country to target"
            type="Country"
            items={countries}
            onAdd={this.addTarget}
            onRemove={this.removeTarget}
            activeTargets={activeTargets}
          />
        </div>
      </div>
    );
  }
}

export default VisitorDemographics;
