import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import SessionQueryContainer from "containers/SessionQueryContainer";

const VISITOR_QUERY = gql`
  query getVisitors($query: SessionQueryInput, $last: Int, $before: ID) {
    visitors(query: $query, last: $last, before: $before) {
      edges {
        cursor
        node {
          title
          city
          state
          companyIndustry
          companyEmployees
          companyAnnualSales
          attributes
          sessionCount
          pagesViewed
          id
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const TableSectionContainer = ({ children, page, ...props }) => (
  <SessionQueryContainer page={page}>
    {({ query }) => (
      <Query query={VISITOR_QUERY} variables={{ query, last: 10 }}>
        {({ fetchMore, loading, error, data: { visitors = {} } }) => {
          if (error) {
            console.error(error);
            return;
          }

          console.log(visitors.edges);
          return (
            children &&
            children({
              ...props,
              data: visitors.edges || [],
              loading,
              hasNextPage: visitors.pageInfo && visitors.pageInfo.hasNextPage,
              onLoadMore: endCursor => {
                fetchMore({
                  variables: {
                    before: endCursor,
                    query,
                    last: 10
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.visitors.edges;
                    const pageInfo = fetchMoreResult.visitors.pageInfo;

                    if (!newEdges) return prev;

                    return {
                      ...prev,

                      visitors: {
                        ...prev.visitors,

                        pageInfo,

                        edges: [...prev.visitors.edges, ...newEdges]
                      }
                    };
                  }
                });
              }
            })
          );
        }}
      </Query>
    )}
  </SessionQueryContainer>
);

export default TableSectionContainer;
