import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import DateRangeContainer from "containers/DateRangeContainer";

const QUERY_ACTIVITIES = gql`
  query getHighValueActivities(
    $startDate: Date
    $endDate: Date
    $limit: Int
    $offset: Int
    $vettedFilters: [String]
  ) {
    filteredActivities: highValueActivities(
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      offset: $offset
      vettedFilters: $vettedFilters
    ) {
      edges {
        node {
          id
          timestamp
          location
          name
          email
          subject
          company
          actionType
          calltracking
          mirrorsite
          source
          vetted
        }
        cursor
      }
      pageInfo {
        hasNextPage
        totalCount
      }
    }
    allActivities: highValueActivities(
      startDate: $startDate
      endDate: $endDate
      limit: 1
      vettedFilters: ["lead", "solicitation", "other", "unclassified"]
    ) {
      pageInfo {
        totalCount
      }
    }
  }
`;

const ActivitiesContainer = ({
  children,
  limit,
  offset,
  page,
  defaultFilters,
  ...props
}) => (
  <DateRangeContainer>
    {({ startDate, endDate }) => {
      return (
        <Query
          query={QUERY_ACTIVITIES}
          variables={{
            startDate,
            endDate,
            limit,
            offset,
            vettedFilters: defaultFilters
          }}
        >
          {({
            data: { filteredActivities, allActivities },
            fetchMore,
            refetch,
            variables: { vettedFilters }
          }) => {
            const filteredActivitiesCount =
              filteredActivities &&
              filteredActivities.pageInfo &&
              filteredActivities.pageInfo.totalCount;

            const allActivitiesCount =
              allActivities &&
              allActivities.pageInfo &&
              allActivities.pageInfo.totalCount;

            const totalPages = Math.ceil(filteredActivitiesCount / limit);

            return (
              children &&
              children({
                ...props,
                data: filteredActivities,
                user: { role: "CTP" },
                startDate: startDate,
                endDate: endDate,
                vettedFilters,
                totalPages: totalPages,
                filteredActivitiesCount: filteredActivitiesCount,
                allActivitiesCount: allActivitiesCount,
                onFilterUpdate: vettedFilters => {
                  refetch({
                    vettedFilters
                  });
                },
                onUpdate: p => {
                  fetchMore({
                    variables: {
                      offset: (p - 1) * limit
                    },
                    updateQuery: (_, { fetchMoreResult }) => fetchMoreResult
                  });
                }
              })
            );
          }}
        </Query>
      );
    }}
  </DateRangeContainer>
);

export default ActivitiesContainer;
