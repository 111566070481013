import React from "react";
import "./WelcomeScreen.css";

import Screen1 from "views/sections/WelcomeScreen/screens/Screen1";
import Screen2 from "views/sections/WelcomeScreen/screens/Screen2";
import Screen3 from "views/sections/WelcomeScreen/screens/Screen3";
import Screen4 from "views/sections/WelcomeScreen/screens/Screen4";
import Screen5 from "views/sections/WelcomeScreen/screens/Screen5";

function PageMap({ page, onCheck, onNext, onNav, accepted, user }) {
    switch (page) {
        case 1:
            return (
                <Screen1
                    onCheckBox={onCheck}
                    onNextStep={onNext}
                    accepted={accepted}
                />
            );
        case 2:
            return (
                <Screen2 user={user} onNextStep={onNext} onNavPage={onNav} />
            );
        case 3:
            return <Screen3 onNextStep={onNext} onNavPage={onNav} />;
        case 4:
            return <Screen4 onNextStep={onNext} onNavPage={onNav} />;
        case 5:
            return <Screen5 onNextStep={onNext} onNavPage={onNav} />;
        default:
            return (
                <Screen1
                    onCheckBox={onCheck}
                    onNextStep={onNext}
                    accepted={accepted}
                />
            );
    }
}

class WelcomeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
            hide: false,
            accepted: false,
            step: 1
        };
    }

    componentWillUnmount() {
        document.body.classList.remove("noscroll");
    }

    render() {
        const { accepted, step, hide } = this.state;
        const { user } = this.props;

        const showWelcomeScreen =
            user && user.prefs && !user.prefs.hasAcceptedTerms;

        if (showWelcomeScreen && !hide) {
            // $(".js").addClass("noscroll");
            document.body.classList.add("noscroll");
        } else {
            // $(".js").removeClass("noscroll");
            document.body.classList.remove("noscroll");
        }

        return (
            !hide && (
                <React.Fragment>
                    <div
                        className={`welcome-screen-overlay ${
                            showWelcomeScreen ? "active" : ""
                        }`}
                    />

                    {showWelcomeScreen && (
                        <section className="welcome-screen">
                            <PageMap
                                page={step}
                                onCheck={e => {
                                    this.setState({
                                        accepted: e.target.checked
                                    });
                                }}
                                onNext={() => {
                                    this.setState(
                                        {
                                            step: step + 1,
                                            hide: step >= 5
                                        },
                                        () => {
                                            if (step >= 5) {
                                                document.body.classList.remove(
                                                    "noscroll"
                                                );
                                            }
                                        }
                                    );
                                }}
                                onNav={p => {
                                    this.setState({ step: p });
                                }}
                                accepted={accepted}
                                user={user}
                            />
                            {/* <button
                                style={{ float: "left", width: "250px" }}
                                onClick={() => {
                                    this.setState({ hide: true });
                                }}
                            >
                                Skip Welcome Screen (for test)
                            </button> */}
                        </section>
                    )}
                </React.Fragment>
            )
        );
    }
}

export default WelcomeScreen;
