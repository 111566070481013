import React from "react";
import SessionsExportContainer from "containers/Export/SessionsExportContainer";
import { DownloadContainer } from "containers/Export/DownloadContainer";
import { Spinner } from "../Loading/Spinner";

interface Props {
  visitorId: any;
  page: any;
  downloadType: any;
  query: any;
}

const Export = ({ visitorId, page, downloadType, query }: Props) => {
  const renderIcon = ({
    onSessionExport,
    download = {}
  }: {
    onSessionExport: any;
    download: any;
  }) => (
    <span
      data-balloon={download.status || "export"}
      data-balloon-pos="left"
      style={{ margin: 0 }}
    >
      {download.status === "processing" ? (
        <Spinner />
      ) : (
        <img
          src={process.env.PUBLIC_URL + "/img/ico-download-excell.svg"}
          alt="export"
          onClick={onSessionExport}
        />
      )}
    </span>
  );

  return downloadType === "HIGH_VALUE_ACTIVITIES" ? (
    <DownloadContainer query={query}>{renderIcon}</DownloadContainer>
  ) : (
    <SessionsExportContainer
      visitorId={visitorId}
      page={page}
      downloadType={downloadType}
      query={query}
    >
      {renderIcon}
    </SessionsExportContainer>
  );
};

export { Export };
