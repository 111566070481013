import React, { Children } from "react";
import { gql } from "apollo-boost";
import { Mutation, Query } from "react-apollo";

const QUERY_GET_PDF_DOWNLOAD = gql`
  query pdfDownload($id: ID, $campaignId: ID) {
    pdf: pdfDownload(id: $id, campaignId: $campaignId) {
      id
      campaignId
      status
      url
    }
  }
`;

const MUTATION_CREATE_PDF_DOWNLOAD = gql`
  mutation createPDFDownload($campaignPDFInputs: CampaignPDFInputs) {
    pdf: createPDFDownload(campaignPDFInputs: $campaignPDFInputs) {
      id
      campaignId
      status
      url
    }
  }
`;

interface PDFDownloadResult {
  pdf: any;
}

interface PDFContainerProps {
  children?: any;
  startDate: any;
  endDate: any;
  campaignId: any;
}

export const PDFContainer: React.SFC<PDFContainerProps> = ({
  children,
  startDate,
  endDate,
  campaignId
}) => {
  let loading = false;

  const BASE_URL =
    process.env.REACT_APP_PRINT_URL_BASE ||
    `${location.protocol}//${location.host}`;

  const getCognitoTokens = () => ({
    refresh_token: window.localStorage["refresh_token"],
    token_expiration_time: window.localStorage["token_expiration_time"],
    clock_drift: window.localStorage["clock_drift"],
    token_issue_time: window.localStorage["token_issue_time"],
    access_token: window.localStorage["access_token"],
    id_token: window.localStorage["id_token"]
  });

  const campaignPDFInputs = {
    storage: getCognitoTokens(),
    url: `${BASE_URL}/campaigns/${campaignId}/view/print?start=${startDate}&end=${endDate}`,
    campaignId: campaignId
  };

  console.log(campaignPDFInputs.url);

  function MutationComponent() {
    return (
      <Mutation<PDFDownloadResult>
        mutation={MUTATION_CREATE_PDF_DOWNLOAD}
        variables={{ campaignPDFInputs }}
      >
        {(createPDFDownload, { data: { pdf } = { pdf: null } }) => {
          const onPDFExport = () => {
            loading = true;
            createPDFDownload();
          };

          if (!pdf) {
            return children({
              onPDFExport
            });
          }
          return (
            <Query<PDFDownloadResult>
              query={QUERY_GET_PDF_DOWNLOAD}
              variables={{ id: pdf.id, campaignId: campaignId }}
              pollInterval={loading ? 1000 : 0}
            >
              {({ stopPolling, data: { pdf } = { pdf: undefined } }) => {
                if (loading && pdf && pdf.status === "done") {
                  stopPolling();
                  loading = false;

                  if (pdf.url) {
                    window.location.href = pdf.url;
                  }
                }
                return children({
                  pdfDownload: pdf,
                  onPDFExport
                });
              }}
            </Query>
          );
        }}
      </Mutation>
    );
  }

  return <MutationComponent />;
};
