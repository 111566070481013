import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import normal from "img/ico-timeline-normal.svg";
import SessionDetail from "./SessionDetail/SessionDetail";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import FormatDuration from "views/components/FormatDuration";

import countries from "data/countries";

const TimeLineAttributes = ({ attributes }) => {
    if (attributes.includes("HIGH_VALUE_PAGE")) {
        if (attributes.includes("HIGH_VALUE_AUDIENCE")) {
            return (
                <React.Fragment>
                    <ThomasIcon
                        attribute={"HIGH_VALUE_PAGE"}
                        customClass="results-timeline__card-icon"
                    />
                    <ThomasIcon
                        attribute={"HIGH_VALUE_AUDIENCE"}
                        customClass="results-timeline__card-icon-2"
                    />
                </React.Fragment>
            );
        } else
            return (
                <ThomasIcon
                    attribute={"HIGH_VALUE_PAGE"}
                    customClass="results-timeline__card-icon"
                />
            );
    } else if (attributes.includes("HIGH_VALUE_AUDIENCE")) {
        return (
            <ThomasIcon
                attribute={"HIGH_VALUE_AUDIENCE"}
                customClass="results-timeline__card-icon"
            />
        );
    } else
        return (
            <img
                className="results-timeline__card-icon-n"
                src={normal}
                alt="n"
            />
        );
};

class ResultsTimelineCard extends React.Component {
    constructor() {
        super();
        this.state = {
            showDetails: false
        };
        this.toggleDetails = this.toggleDetails.bind(this);
    }

    toggleDetails() {
        this.setState({ showDetails: !this.state.showDetails });
    }

    render() {
        const { session = {}, isVisitorSession } = this.props;
        const attributes = session.attributes || [];
        const { showDetails } = this.state;
        const HIGH_VALUE_ACTIONS = ["HIGH_VALUE_PAGE", "HIGH_VALUE_AUDIENCE"];

        let highValueActions = [];
        let otherActions = [];

        attributes.forEach(action => {
            (HIGH_VALUE_ACTIONS.includes(action)
                ? highValueActions
                : otherActions
            ).push(action);
        });

        const { city, state, country, referrer, referrerFull } = session;
        const newCountry = countries.find(c => c.value === country);

        const location = city
            ? state
                ? `${city}, ${state} (${country})`
                : `${city}, ${country}`
            : state
                ? `${state}, ${country}`
                : `${newCountry ? newCountry.label : country}`;

        return (
            <div className="results-timeline__row">
                <div style={{ position: "absolute" }}>
                    <TimeLineAttributes attributes={attributes} />
                </div>
                <div className="results-timeline__card card">
                    <div className="results-timeline__card-header">
                        {!isVisitorSession ? (
                            <Link
                                className="results-timeline__card-title"
                                to={`/sessions/visitor/${session.id}`}
                            >
                                {session.visitorName}

                                {location && (
                                    <span className="timeline--location">{` - ${location}`}</span>
                                )}

                                {session.visitorISP && (
                                    <small>
                                        (via ISP {session.visitorISP})
                                    </small>
                                )}
                            </Link>
                        ) : (
                            <div className="results-timeline__card-date">
                                {moment(session.visitDate).format(
                                    "dddd MMMM DD, YYYY"
                                )}
                            </div>
                        )}

                        <div className="results-timeline__card-icons">
                            {otherActions.map((attribute, i) => (
                                <ThomasIcon
                                    key={i}
                                    attribute={attribute}
                                    customClass="date-icon"
                                />
                            ))}
                        </div>

                        <div
                            className={`results-timeline__card--details-show`}
                            onClick={this.toggleDetails}
                        >
                            <span className="pr-2">Details</span>
                            <ThomasIcon
                                attribute={showDetails ? "COLLAPSE" : "OPEN"}
                            />
                        </div>
                    </div>

                    <div
                        className={`results-timeline__card-details ${
                            isVisitorSession ? "details-visitor" : ""
                        }`}
                    >
                        <div className="results-timeline__card-stat">
                            <div>Time of visit: </div>
                            <b>{moment(session.visitDate).format("LT")}</b>
                        </div>

                        <div className="results-timeline__card-stat">
                            <div>Pages/ Session: </div>
                            <b>{session.pagesInSession}</b>
                        </div>
                        <div className="results-timeline__card-stat">
                            <div>Session Duration: </div>
                            <b>{FormatDuration(session.sessionDuration)}</b>
                        </div>

                        <div className="results-timeline__card-stat">
                            <div>Referrer: </div>
                            {referrerFull === "" ? (
                                <b>
                                    {referrer === ""
                                        ? "Direct to Site"
                                        : referrer}
                                </b>
                            ) : (
                                <a
                                    className="referrer-link"
                                    title={referrerFull}
                                    href={
                                        referrerFull !== "" ? referrerFull : ""
                                    }
                                >
                                    {referrer === ""
                                        ? "Direct to Site"
                                        : referrer}
                                </a>
                            )}
                        </div>

                        <div className="results-timeline__card-stat">
                            <div>Search Term/ Category: </div>
                            <b>
                                {session.searchTerm === null
                                    ? "Not Available"
                                    : session.searchTerm}
                            </b>
                        </div>
                    </div>

                    {this.state.showDetails && (
                        <SessionDetail
                            pageViews={session.pageViews}
                            searchTerm={session.searchTerm}
                            formSubmissions={session.formSubmissions}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default ResultsTimelineCard;
