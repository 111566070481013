import "./polyfills";

import React from "react";
import { render } from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink
} from "apollo-boost";
import { withClientState } from "apollo-link-state";
// import { persistCache } from "apollo-cache-persist";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { merge } from "lodash";
import AppWithData from "./containers/AppWithData";
import dateRange from "./resolvers/dateRange";
import sessions from "./resolvers/sessions";
import ScrollToTop from "./views/components/ScrollToTop";
import Register from "./views/components/Registration/Register";
// import { BatchHttpLink } from "apollo-link-batch-http";

import Login from "views/components/Login/Login.js";
import Accounts from "views/components/Accounts/Accounts.js";
import NoClaims from "views/components/Accounts/NoClaims.js";

import { authErrorLink, authLink } from "./auth/auth";
import Campaigns from "views/pages/Campaigns/Campaigns";
const cache = new InMemoryCache();

// persistCache({
// 	cache,
// 	storage: window.sessionStorage
// });

const stateLink = withClientState({
  cache,

  ...merge(dateRange, sessions)
});

const client = new ApolloClient({
  cache,

  link: ApolloLink.from([
    authLink,

    authErrorLink,

    stateLink,

    new HttpLink({
      uri:
        process.env.REACT_APP_GRAPHQL_ENDPOINT ||
        "http://localhost:3001/graphql"
    })

    // new BatchHttpLink({
    //     uri:
    //         process.env.REACT_APP_GRAPHQL_ENDPOINT ||
    //         "http://localhost:3001/graphql"
    // })
  ])
});

render(
  <BrowserRouter>
    <ScrollToTop>
      <ApolloProvider client={client}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/accounts" component={Accounts} />
          <Route path="/noclaims" component={NoClaims} />
          <Route path="/campaigns" component={Campaigns} />
          <Route component={AppWithData} />
        </Switch>
      </ApolloProvider>
    </ScrollToTop>
  </BrowserRouter>,

  document.getElementById("root")
);

registerServiceWorker();
