const API_BASE_URL =
  process.env.REACT_APP_AUTH_ENDPOINT || "https://test-auth.thomasnet.com";
const API_SIGNUP_PATH = "/a/signup";
const API_SIGNIN_PATH = "/a/signin";
const API_AMISIGNEDIN_PATH = "/a/amisignedin";
const API_CHECKUSER_PATH = "/a/check-user";
const API_FORGOTPASSWORD_PATH = "/a/forgotpassword";
const API_RESETPASSWORD_PATH = "/a/resetpassword";
const API_REFRESHTOKEN_PATH = "/v2/refresh-token";

const LEGACY_ACCOUNT_LOGIN_URL =
  process.env.REACT_APP_LEGACY_ACCOUNT_ENDPOINT ||
  `https://l5i9pc0881.execute-api.us-east-1.amazonaws.com/prod/legacy-account`;

// const request = require("superagent");
const request = require("request-promise");

/**
 * 
 input SignupModel {
    email: String!
    firstName: String
    lastName: String
    jobFunction: String
    jobDiscipline: String
    jobLevel: String
    industry: String
    companyName: String
    companyUrl: String
    country: String
    zipCode: String
    tiu: Boolean
    ien: Boolean
    password: String
    registrationType: String
    origin: String
}
 */
const signUp = ({ ...props }) => {
  var path = `${API_BASE_URL}${API_SIGNUP_PATH}`;

  const info = {
    ...props,
    origin: "auth.thomasnet.com test",
    registrationType: "full"
  };

  const options = {
    method: "POST",
    uri: path,
    body: info,
    json: true
  };

  return new Promise((resolve, reject) => {
    request(options)
      //   .post(path)
      //   .send(info)
      //   .set("Content-Type", "application/json")
      // .set('Accept', 'application/json')
      .then(res => resolve(res))
      .catch(err => reject(CatchErrorResponse(err)));
  });
};

/**
 input SigninModel {
    username: String!
    password: String!
}
 */
const signIn = (username, password) => {
  var path = `${API_BASE_URL}${API_SIGNIN_PATH}`;

  var model = {
    username: username,
    password: password
  };

  const options = {
    method: "POST",
    uri: path,
    body: model,
    json: true
  };

  return new Promise((resolve, reject) => {
    request(options)
      .then(res => resolve(res))
      .catch(err => reject(CatchErrorResponse(err)));
  });
};

const forgotPassword = username => {
  const path = `${API_BASE_URL}${API_FORGOTPASSWORD_PATH}`;

  const model = {
    username: username
  };

  const options = {
    method: "POST",
    uri: path,
    body: model,
    json: true
  };

  console.log("** authService.forgotPassword **");

  return new Promise((resolve, reject) => {
    request(options)
      .then(res => resolve(res))
      .catch(err => reject(CatchErrorResponse(err)));
  });
};

const confirmForgotPassword = (username, password, confirmationcode, tinid) => {
  const path = `${API_BASE_URL}${API_RESETPASSWORD_PATH}`;

  const model = {
    username: username,
    password: password,
    confirmationcode: confirmationcode,
    tinid: tinid
  };

  const options = {
    method: "POST",
    uri: path,
    body: model,
    json: true
  };

  console.log("** authService.confirmForgotPassword **");

  return new Promise((resolve, reject) => {
    request(options)
      .then(res => resolve(res))
      .catch(err => reject(CatchErrorResponse(err)));
  });
};

const refreshAccessToken = (refreshToken) => {
  const path = `${API_BASE_URL}${API_REFRESHTOKEN_PATH}`;

  const model = {
    refreshToken: refreshToken,
  };

  const options = {
    method: "POST",
    uri: path,
    body: model,
    json: true
  };

  console.log("** authService.refreshAccessToken **");

  return new Promise((resolve, reject) => {
    request(options)
      .then(res => resolve(res))
      .catch(err => reject(CatchErrorResponse(err)));
  });
};

const amISignedIn = (tinid, corex, coreu) => {
  var model = {
    userid: tinid,
    core_x: corex,
    core_u: coreu
  };

  var path = `${API_BASE_URL}${API_AMISIGNEDIN_PATH}`;

  const options = {
    method: "POST",
    uri: path,
    body: model,
    json: true
  };

  return new Promise((resolve, reject) => {
    request(options)
      .then(res => resolve(res))
      .catch(err => reject(CatchErrorResponse(err)));
  });
};

const legacyAccountLogin = (username, password) => {
  const model = {
    username,
    password
  };

  const options = {
    method: "POST",
    uri: LEGACY_ACCOUNT_LOGIN_URL,
    body: model,
    json: true
  };

  return new Promise((resolve, reject) => {
    request(options)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const checkUsername = username => {
  const path = `${API_BASE_URL}${API_CHECKUSER_PATH}`;
  var model = {
    username: username
  };

  const options = {
    method: "POST",
    uri: path,
    body: model,
    json: true
  };

  return new Promise((resolve, reject) => {
    request(options)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject("Cant Find User");
      });
  });
};

const CatchErrorResponse = err => {
  var errorModel = {
    status: 500,
    message: "Server Error",
    body: null,
    text: ""
  };

  if (err && err.status) {
    errorModel.status = err.status;
    errorModel.message = err.message;
  }

  if (err && err.response) {
    var res = err.response;
    if (res.body) {
      errorModel.body = res.body;
    }
    if (res.text) {
      errorModel.text = res.text;
    }
  }

  return errorModel;
};

export default {
  signUp,
  signIn,
  amISignedIn,
  checkUsername,
  legacyAccountLogin,
  forgotPassword,
  confirmForgotPassword,
  refreshAccessToken
};
