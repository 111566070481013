import React from "react";
import TextBoxAdder from "views/components/TextBoxAdder/TextBoxAdder";
import "./HighValuePages.css";

import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";

const HighValuePages = ({ urls, updatePages }) => (
  <div className="high-value-page-settings">
    <h3 style={{ marginBottom: 0 }}>High Value Pages</h3>

    <p className="high-value-page-settings__subheader">
      Enter the URL path of the high-value pages you want to flag. eg.
      https://www.yourwebsite.com/contact.html should be entered as
      /contact.html ONLY. Whenever a user visits a High Value Page, their action
      will be highlighted with the <ThomasIcon attribute="HIGH_VALUE_PAGE" />{" "}
      icon across your WebTrax dashboards.
    </p>

    <div className="high-value-page-settings__categories">
      <TextBoxAdder
        items={urls}
        preContent={<ThomasIcon attribute="HIGH_VALUE_PAGE" />}
        placeholder={["Add High Value page link (e.g. /contact.html)"]}
        customTBClass="hvp-textbox"
        updateItems={updatePages}
      />
    </div>
  </div>
);

export default HighValuePages;
