interface ISomeObject {
  [key: string]: any;
}

const campaignsSortMap: ISomeObject = {
  campaignName: {
    desc: {
      fields: ["campaignNameRaw"],
      directions: ["desc"]
    },
    asc: {
      fields: ["campaignNameRaw"],
      directions: ["asc"]
    }
  },
  companyName: {
    desc: {
      fields: ["companyName"],
      directions: ["desc"]
    },
    asc: {
      fields: ["companyName"],
      directions: ["asc"]
    }
  },
  status: {
    desc: {
      fields: ["status"],
      directions: ["desc"]
    },
    asc: {
      fields: ["status"],
      directions: ["asc"]
    }
  },
  campaignPeriod: {
    desc: {
      fields: ["startDate", "campaignNameRaw"],
      directions: ["desc", "desc"]
    },
    asc: {
      fields: ["startDate", "campaignNameRaw"],
      directions: ["asc", "desc"]
    }
  }
};

export default campaignsSortMap;
