import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
// import revenues from "views/sections/CardGroup/__mocks__/revenues";
import SessionQueryContainer from "containers/SessionQueryContainer";

const getQuery = (queryKey, needCount = false) => gql`
	query get_${queryKey}($query: SessionQueryInput) {
		sessionsInfo(query: $query) {
			${queryKey} {
				id
				label
				value
			}
        }
        ${
          needCount
            ? `sessions(query: $query) {
            pageInfo {
                totalCount
            }
        }`
            : ""
        }
	}
`;

const DataAttributesContainer = ({
  children,
  queryKey,
  page,
  needCount,
  ...props
}) => (
  <SessionQueryContainer page={page}>
    {({ query }) => (
      <Query query={getQuery(queryKey, needCount)} variables={{ query }}>
        {({ loading, data: { sessionsInfo = {}, sessions = {} } }) => {
          return (
            children &&
            children({
              ...props,
              loading,
              data: sessionsInfo[queryKey] || [],
              info: sessions.pageInfo || {}
            })
          );
        }}
      </Query>
    )}
  </SessionQueryContainer>
);

export default DataAttributesContainer;
