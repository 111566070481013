import React from "react";

import "./ToggleSwitch.css";

export default class ToggleSwitch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: props.active
        }

        this.toggleSwitch = this.toggleSwitch.bind(this);
    }

    toggleSwitch() {
        this.props.onToggle(!this.state.active);
        this.setState({
            active : !this.state.active
        })
    }

	render() {
        const { active } = this.state;
        const { label, subLabel, text} = this.props;

		return (

            <div>
                { label && 
                    <div className="thomas-textbox__label">
                        {label} { subLabel &&
                            <div className="thomas-textbox__sublabel">({subLabel})</div>
                        }
                    </div>
                }

                <div className="toggleswitch-box">

                    <div className="toggleswitch-title">{text}</div>

                    <div className='thomas-toggleswitch' onClick={this.toggleSwitch}>

                        <div className="thomas-toggleswitch-white-l"></div>                        
                        <div className={`toggleswitch-background ${active ? 'active' : ''}`}></div>                        
                        <div className={`toggleswitch-yes ${active ? 'active' : ''}`}>Yes</div>
                        <span className={`toggleswitch-button ${active ? 'active' : ''}`}>
                            <img src={process.env.PUBLIC_URL + "/img/ico-wht-select.svg"} alt="arrow" />
                        </span>
                        <div className={`toggleswitch-no ${active ? 'active' : ''}`}>No</div>
                        <div className="thomas-toggleswitch-white-r"></div>

                    </div>
                </div>

            </div>

            
		);
	}
}