import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { isValid, setAuthTokens, getAuthCookies, logOut } from "./utils";
import authServices from "services/authServices";

function getSessionHeaders() {
  let results = {};

  const wtid = localStorage.getItem("wtid");
  if (wtid) {
    results["x-wt-wtid"] = wtid;
  }

  const legacyToken = sessionStorage.getItem("legacy_token");
  if (legacyToken) {
    results["x-wt-legacy_token"] = legacyToken;
  }

  const authorizationToken = localStorage.getItem("id_token");
  if (authorizationToken) {
    results["authorization"] = authorizationToken;
  }

  return results;
}

const authLink = setContext((req, { headers = {} }) => {
  const refreshToken = localStorage.getItem("refresh_token");
  const idToken = localStorage.getItem("id_token");
  if (isValid()) {
    return {
      headers: {
        ...headers,
        ...getSessionHeaders(),
      },
    };
  }

  if (refreshToken) {
    return authServices
      .refreshAccessToken(refreshToken)
      .then((newIdTokens) => {
        setAuthTokens({
          accessToken: newIdTokens.access_token,
          idToken: newIdTokens.id_token,
          refreshToken: refreshToken,
          issueTime: newIdTokens.issuedAtTime,
          expirationTime: newIdTokens.expirationTime,
        });

        return {
          headers: {
            ...headers,
            ...getSessionHeaders(),
          },
        };
      })
      .catch((err) => {
        console.error(`authLink error:`, err);
      });
  }

  // Session and refresh are not valid, check sso
  let { tinid, coreX, coreU } = getAuthCookies();

  if (tinid && coreX && coreU) {
    return authServices
      .amISignedIn(tinid, coreX, coreU)
      .then((newIdTokens) => {
        console.log("Setting sso tokens:", newIdTokens);
        setAuthTokens({
          accessToken: newIdTokens.access_token,
          idToken: newIdTokens.id_token,
          refreshToken: newIdTokens.refresh_token,
          issueTime: newIdTokens.issuedAtTime,
          expirationTime: newIdTokens.expirationTime,
        });

        return {
          headers: {
            ...headers,
            ...getSessionHeaders(),
          },
        };
      })
      .catch((err) => {
        console.error(`sso check error:`, err);
        return err;
      });
  }

  return {
    headers,
  };
});

const onAuthErrorHandler = ({ networkError = {}, graphQLErrors = [] }) => {
  if (
    graphQLErrors
      .map((err) => err.message)
      .some((message) => message.includes("DENIED_ACCOUNT_ACCESS"))
  ) {
    window.location.href = "/campaigns";
    return;
  }

  if (
    graphQLErrors
      .map((err) => err.message)
      .some((message) => message.includes("ACCOUNT_NOT_FOUND"))
  ) {
    window.location.href = "/accounts";
    return;
  }

  if (networkError.bodyText && networkError.bodyText.includes("NO_CLAIMS")) {
    window.location.href = "/noclaims";
    return;
  }

  if (
    networkError.statusCode === 401 ||
    networkError.statusCode === 403 ||
    (networkError.bodyText && networkError.bodyText.includes("UNAUTHORIZED"))
  ) {
    logOut("/login");
    return;
  }
};

const authErrorLink = onError(onAuthErrorHandler);

export { authLink, authErrorLink, onAuthErrorHandler };
