import React from "react";
import { Link } from "react-router-dom";

class LoginHome extends React.Component {
  constructor() {
    super();
    this.state = { email: "" };
    this.refEmail = React.createRef();
  }

  componentDidMount() {
    this.refEmail.current.focus();
  }

  render() {
    const { error, checkEmailForAccount } = this.props;
    return (
      <React.Fragment>
        <h2 className="text-center pt-0">WebTrax sign in</h2>
        <p className="text-center">
          Need to create an account? <Link to="/register">Request Access</Link>
        </p>
        <div className="form-bg form-short">
          {error && (
            <p
              className="alert alert-danger hide"
              id="errMsg"
              style={{ display: "block" }}
            >
              {error}
            </p>
          )}

          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              Enter your email to get started:
            </label>
            <input
              name="email"
              autoComplete="username"
              className="form-control"
              placeholder="Enter your email to get started"
              value={this.state.email}
              ref={this.refEmail}
              onChange={e =>
                this.setState({
                  email: e.target.value
                })
              }
              onKeyPress={e => {
                if (e.keyCode || e.which === 13) {
                  checkEmailForAccount(this.state.email);
                }
              }}
            />
          </div>

          <div className="form-group flex-end-btn">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => checkEmailForAccount(this.state.email)}
            >
              Continue
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LoginHome;
