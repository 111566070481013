import React from "react";
import ProgressSteps from "../ProgressSteps";

import cookie from "react-cookies";
const $ = window.$;

class ThankYou extends React.Component {
  componentDidMount() {
    let tinid = cookie.load("tinid");

    const scriptlegacy = document.createElement("script");
    scriptlegacy.src = "https://js.hsforms.net/forms/v2-legacy.js";
    scriptlegacy.async = true;
    document.body.appendChild(scriptlegacy);

    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    script.async = true;
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "242200",
          formId: "5ed9b46d-4d3c-43ee-8620-5fe085bfb9b6",
          target: "#hubspotForm2",
          cssClass: "form--webtrax-reg__url",
          submitButtonClass: "btn btn-primary continue-button",
          redirectUrl: "/register/thankyou2",
          css: "",
          onFormReady() {
            $('input[name="tinid"]')
              .val(tinid)
              .change();
          }
        });
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <ProgressSteps step={3} />
        <div>
          <h2 className="text-center">Thank You!</h2>
          <p className="lead">
            You've officially requested to join Thomas WebTrax release, and our
            team will be in touch shortly with more information.
          </p>
          <p>
            To help us expedite your request and better classify your company
            for inclusion in the Thomas Network, please provide the additional
            information below.
          </p>
        </div>
        <div id="hubspotForm2" />
      </React.Fragment>
    );
  }
}

export default ThankYou;
