import React from "react";
import "./ResultsControl.css";
import { Export } from "../Export/Export";

const displayAmountOptions = [
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 }
];

const viewTypeOptions = [
  { label: "Timeline view", value: "timeline" },
  { label: "Table view", value: "table" }
];

const Options = ({ options, separator, value, onChange }) => {
  return options.map((option, index) => (
    <React.Fragment key={index}>
      {index > 0 && separator}
      <span
        onClick={() => {
          onChange(option.value);
        }}
        className={option.value === value ? "active" : ""}
      >
        {option.label}
      </span>
    </React.Fragment>
  ));
};

const ResultsControl = ({
  displayAmount,
  onDisplayAmountChange,
  viewType,
  onViewTypeChange,
  hideView,
  customClass,
  visitorId,
  showExport,
  page,
  downloadType,
  query
}) => (
  <div className={`results-control ${customClass ? customClass : ""}`}>
    <div className="results-control__display-amount">
      Display:{" "}
      <Options
        options={displayAmountOptions}
        separator=" | "
        value={displayAmount}
        onChange={onDisplayAmountChange}
      />
    </div>

    {!hideView && (
      <div className="results-control__view-type">
        <Options
          options={viewTypeOptions}
          separator=" | "
          value={viewType}
          onChange={onViewTypeChange}
        />
      </div>
    )}

    <div className="results-control__utility-icons">
      <span
        data-balloon={"print"}
        data-balloon-pos="left"
        style={{ margin: 0 }}
      >
        <img
          src={process.env.PUBLIC_URL + "/img/ico-print-page.svg"}
          alt="print"
          onClick={() => {
            window.print();
          }}
        />
      </span>

      {showExport && (
        <Export
          visitorId={visitorId}
          page={page}
          downloadType={downloadType}
          query={query}
        />
      )}
    </div>
  </div>
);

export default ResultsControl;
