import React from "react";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import RfiSubmission from "../RfiSubmission/RfiSubmission";
import moment from "moment";

import detailTableCols from "./detailTableCols";
import "./SessionDetail.css";

const HIGH_VALUE_PAGE = "HIGH_VALUE_PAGE";

const CellContent = ({ col, page, id }) => {
  switch (col.key) {
    case "actions":
      return (
        <div className="results-timeline__card-icons">
          {page.actions
            .filter(a => a !== HIGH_VALUE_PAGE)
            .map(attribute => (
              <ThomasIcon
                key={attribute}
                attribute={attribute}
                position="left"
              />
            ))}
        </div>
      );
    case "timestamp":
      return <div>{moment(page[col.prop]).format("M/D/YYYY LT")}</div>;
    case "where":
      return (
        <React.Fragment>
          <ThomasIcon
            attribute={
              page.where === "Thomas"
                ? "THOMASNET_ACTIVITY"
                : "CLIENT_SITE_ACTIVITY"
            }
            customClass="where-icon"
          />
          {page[col.prop]}
        </React.Fragment>
      );
    case "pageUrl":
      return (
        <React.Fragment>
          {page.actions.includes(HIGH_VALUE_PAGE) && (
            <ThomasIcon
              customClass="hv-page-icon"
              attribute={HIGH_VALUE_PAGE}
            />
          )}
          <a
            href={`http://${page["domain"]}${page[col.prop]}`}
            target="_blank"
            rel="noopener noreferrer"
            title={page[col.prop]}
          >
            <div>{page[col.prop]}</div>
          </a>
        </React.Fragment>
      );
    case "id":
      return <div>{id}</div>;
    default:
      return <div>{page[col.prop]}</div>;
  }
};

const SessionDetail = ({ pageViews, searchTerm, formSubmissions }) => {
  pageViews = pageViews || [];

  const tableCols = detailTableCols(
    pageViews.every(row => row.pageType === "")
  );

  return (
    <React.Fragment>
      <div className="results-timeline__card-header session-detail__header">
        Session Details:
        {searchTerm && (
          <small style={{ fontWeight: 400 }}>
            Search Term/Category:
            <b>{searchTerm}</b>
          </small>
        )}
      </div>
      <table className="session-detail__table table">
        <thead>
          <tr>
            {tableCols.map(col => (
              <th key={col.key} style={{ width: col.width }}>
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pageViews.length > 0 &&
            pageViews.map((page, ind) => {
              return (
                <tr key={page.id}>
                  {tableCols.map(col => {
                    return (
                      <td
                        className={`session-detail__table-${col.key}`}
                        key={col.key}
                        style={{ width: col.width }}
                      >
                        <CellContent col={col} page={page} id={ind + 1} />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
      {formSubmissions.length > 0 && (
        <RfiSubmission formSubmissions={formSubmissions} />
      )}
    </React.Fragment>
  );
};

export default SessionDetail;
