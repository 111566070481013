import React from "react";

class HubSpotInviteForm extends React.Component {
    componentDidMount() {
        const scriptlegacy = document.createElement("script");
        scriptlegacy.src = "https://js.hsforms.net/forms/v2-legacy.js";
        scriptlegacy.async = true;
        document.body.appendChild(scriptlegacy);

        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/v2.js";
        script.async = true;
        document.body.appendChild(script);

        script.addEventListener("load", () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: "242200",
                    formId: "65f4be47-f390-46a5-8256-4011763dbe34",
                    target: "#hubspotFormEmbed",
                    cssClass: "p-3",
                    submitButtonClass: "btn btn-primary continue-button",
                    redirectUrl: "/settings/invite/thankyou",
                    css: ""
                });
            }
        });
    }

    render() {
        return <div id="hubspotFormEmbed" />;
    }
}

export default HubSpotInviteForm;
