import React from "react";

const LoadingCountCard = ({ pie }) => (
    <div className="topcountcard-loading">
        {pie && (
            <div className="animated-background piechart-loading-donut">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 110 110"
                    className="no-marg"
                >
                    <g fill="#FFF" fillRule="evenodd">
                        <path d="M0 0h115v115H0V0zm57.5 109c28.443 0 51.5-23.057 51.5-51.5S85.943 6 57.5 6 6 29.057 6 57.5 29.057 109 57.5 109z" />
                        <circle cx="57.66" cy="57.66" r="19.66" />
                    </g>
                </svg>
            </div>
        )}
        <div className="animated-background piechart-legend legend-loading">
            <div className="loading-2line-block">
                <div className="background-masker header-top" />
                <div className="background-masker header-left" />
                <div className="background-masker header-right" />
                <div className="background-masker header-bottom" />
                <div className="background-masker subheader-left" />
                <div className="background-masker subheader-right" />
                <div className="background-masker subheader-bottom" />
            </div>
            <div className="loading-2line-block" style={{ top: "45px" }}>
                <div className="background-masker header-top" />
                <div className="background-masker header-left" />
                <div className="background-masker header-right" />
                <div className="background-masker header-bottom" />
                <div className="background-masker subheader-left" />
                <div className="background-masker subheader-right" />
                <div className="background-masker subheader-bottom" />
            </div>
            <div className="loading-2line-block" style={{ top: "90px" }}>
                <div className="background-masker header-top" />
                <div className="background-masker header-left" />
                <div className="background-masker header-right" />
                <div className="background-masker header-bottom" />
                <div className="background-masker subheader-left" />
                <div className="background-masker subheader-right" />
                <div className="background-masker subheader-bottom" />
            </div>
        </div>
    </div>
);

export default LoadingCountCard;
