import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import qs from "qs";

import Home from "views/pages/Home/Home";
import Sessions from "views/pages/Sessions/Sessions";
import VisitorSessions from "views/pages/Sessions/VisitorSessions";

import Settings from "views/pages/Settings/Settings";
import SettingsTargetedAudience from "views/pages/Settings/SettingsTargetedAudience";
import SettingsSingleCard from "views/pages/Settings/SettingsSingleCard";

import Audience from "views/pages/Audience/Audience";

import Analytics from "views/pages/Analytics/Analytics";
import AnalyticsHome from "views/pages/Analytics/AnalyticsHome";
import AnalyticsSessions from "views/pages/Analytics/AnalyticsSessions";
import SessionsActions from "views/pages/Sessions/SessionsActions";

import TargetsProvider from "containers/TargetsProvider";
import ParamsFilterProvider from "containers/ParamsFilterProvider";

const parseLocationQueryString = ({ location, key }) => {
  const params = qs.parse(location.search.split("?").pop());
  return key ? params[key] : params;
};

const Content = ({ user }) => (
  <section className="site-content">
    <main id="main" className="site-content__primary">
      {/* <div className="alert alert-warning">
                We&rsquo;re experiencing heavy demand for Thomas WebTrax, and
                some accounts are seeing delays in populating data. Stay tuned!
            </div> */}

      <Switch>
        <Route exact path="/" component={Home} />

        {/* Sessions pages */}

        <Route
          exact
          path="/sessions"
          render={({ location }) => (
            <ParamsFilterProvider
              page="all"
              params={parseLocationQueryString({
                location
              })}
            >
              <Sessions
                title="All Sessions"
                page="all"
                params={parseLocationQueryString({
                  location
                })}
              />
            </ParamsFilterProvider>
          )}
        />

        <Route
          exact
          path="/sessions/visitor/:visitorId"
          render={({ match }) => (
            <VisitorSessions
              title="Visitor Detailed Sessions"
              showFilters={false}
              showCompany={true}
              visitorId={match.params.visitorId}
            />
          )}
        />
        <Route
          exact
          path="/sessions/targeted"
          render={() => (
            <TargetsProvider page="targeted">
              <Sessions title="Targeted Sessions" page="targeted" />
            </TargetsProvider>
          )}
        />
        <Route
          exact
          path="/sessions/thomasnet"
          render={() => (
            <Sessions title="Thomasnet Sessions" page="thomasnet" />
          )}
        />
        <Route
          exact
          path="/sessions/navigator"
          render={() => (
            <Sessions title="Navigator Sessions" page="navigator" />
          )}
        />
        <Route
          exact
          path="/sessions/actions"
          render={() => (
            <SessionsActions title="Thomas RFIs & Phone Calls" page="actions" />
          )}
        />
        <Redirect from="/sessions" to="/sessions" />

        {/* Audience pages */}
        <Route
          exact
          path="/audience"
          render={() => <Audience page="audience-all" title="Audience" />}
        />
        <Route
          exact
          path="/audience/thomasnet"
          render={() => (
            <Audience page="audience-thomasnet" title="Audience: Thomasnet" />
          )}
        />
        <Route
          exact
          path="/audience/targeted"
          render={() => (
            <TargetsProvider page="audience-targeted">
              <Audience page="audience-targeted" title="Audience: Targeted" />
            </TargetsProvider>
          )}
        />
        <Route
          exact
          path="/audience/navigator"
          render={() => (
            <Audience page="audience-navigator" title="Audience: Navigator" />
          )}
        />
        <Redirect from="/audience" to="/audience" />

        {/* Analytics pages */}
        <Route
          exact
          path="/analytics"
          render={() => (
            <AnalyticsHome page="analytics-all" title="Analytics" />
          )}
        />
        <Route
          exact
          path="/analytics/referrers"
          component={() => (
            <Analytics
              title="Analytics: Referrers"
              page="analytics-referrers"
            />
          )}
        />

        {/* TODO: DOnt think this exists, goes straight to sessions by referrer 
                <Route
                    exact
                    path="/analytics/referrers/:referrer"
                    component={({ match }) => (
                        <Analytics
                            title="Analytics: Referrers"
                            page="analytics-referrer"
                            referrer={match.params.referrer}
                        />
                    )}
                /> */}
        <Route
          exact
          path="/analytics/toppages"
          component={({ location }) => {
            return (
              <Analytics
                title="Analytics: Top Pages"
                page="analytics-toppages"
                referrer={parseLocationQueryString({
                  location
                })}
              />
            );
          }}
        />

        <Route
          exact
          path="/analytics/entrypages"
          component={({ location }) => (
            <Analytics
              title="Analytics: Entry Pages"
              page="analytics-entrypages"
              referrer={parseLocationQueryString({ location })}
            />
          )}
        />

        <Route
          exact
          path="/analytics/exitpages"
          component={({ location }) => (
            <Analytics
              title="Analytics: Exit Pages"
              page="analytics-exitpages"
              referrer={parseLocationQueryString({ location })}
            />
          )}
        />

        <Route
          exact
          path="/analytics/searchterms"
          component={() => (
            <Analytics
              title="Analytics: Search Terms"
              page="analytics-searchterms"
            />
          )}
        />

        <Route
          exact
          path="/analytics/sessionduration"
          component={() => (
            <AnalyticsSessions
              title="Analytics: Session Duration"
              page="analytics-sessionduration"
            />
          )}
        />
        <Route
          exact
          path="/analytics/sessionduration/:pageDepth"
          component={({ match }) => (
            <Analytics
              title="Analytics: Session Depth"
              page="analytics-sessionduration-single"
              titleHardCount={match.params.pageDepth}
            />
          )}
        />

        <Route
          exact
          path="/analytics/browsers"
          component={() => (
            <Analytics
              title="Analytics: Browsers/ OS"
              page="analytics-browsers"
            />
          )}
        />

        <Redirect from="/analytics" to="/analytics" />

        <Route
          exact
          path="/targets"
          render={() => (
            <SettingsTargetedAudience title="Your Target Audience & Pages" />
          )}
        />

        {/* Settings pages */}
        <Route
          exact
          path="/settings"
          render={() => <Settings title="WebTrax Technical Settings" />}
        />
        <Route
          exact
          path="/settings/webtraxtutorial"
          render={() => (
            <SettingsSingleCard
              title="WebTrax Tutorials"
              page="settings-webtraxtutorial"
            />
          )}
        />
        <Route
          exact
          path="/settings/calltracking"
          render={() => (
            <SettingsSingleCard
              title="Call Tracking & DNI"
              page="settings-calltracking"
            />
          )}
        />
        <Route
          exact
          path="/settings/tagging"
          render={() => (
            <SettingsSingleCard
              title="Your Customized WebTrax Tracking Code"
              page="settings-tagging"
              user={user}
            />
          )}
        />
        <Route
          exact
          path="/settings/invite"
          render={() => (
            <SettingsSingleCard
              title="Invite Team Members"
              page="settings-invite"
              user={user}
            />
          )}
        />
        <Route
          exact
          path="/settings/invite/thankyou"
          render={() => (
            <SettingsSingleCard
              title="Invite Team Members"
              page="settings-invite-thankyou"
              user={user}
            />
          )}
        />
      </Switch>

      <div
        ub-in-page="5ba56998ccf42d66c70e3690"
        className="container tet-class pt-0 pb-2"
      />
    </main>
  </section>
);

export default Content;
