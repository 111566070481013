import React from "react";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import moment from "moment";
import "./RfiSubmission.css";

const rfiProps = [
  {
    value: "timestamp",
    label: "Submission Time",
    format: val => moment(val).format("MMM D, YYYY hh:mm A")
  },
  {
    value: "name",
    label: "Name"
  },
  {
    value: "email",
    label: "Email Address"
  },
  {
    value: "subject",
    label: "RFI Subject"
  }
];

const RfiSubmission = ({
  formSubmissions,
  noTitle,
  customClass,
  customKeys,
  loading
}) => {
  const keyProps = customKeys ? customKeys : rfiProps;
  return (
    <div className={`rfi-submission ${customClass ? customClass : ""}`}>
      {!noTitle && (
        <div className="rfi-submission--title">
          <ThomasIcon attribute="RFI" customClass="rfi-submission--logo" />
          <div>Session Form Submissions</div>
        </div>
      )}
      {loading ? (
        <div className="dotter">
          <div className="dot1" />
          <div className="dot2" />
          <div className="dot3" />
          <div className="dot4" />
        </div>
      ) : (
        formSubmissions.map((rfi, i) => (
          <div
            className={`rfi-submission--rows ${
              customClass ? customClass + "-rows" : ""
            }`}
            key={rfi.id || i}
          >
            {keyProps.map(prop => {
              if (prop.value === "permanentFile" && rfi["permanentFile"]) {
                return (
                  <div key={prop.value} className={`rfi-submission--row`}>
                    <audio controls>
                      <source src={rfi["permanentFile"]} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                );
              } else {
                return (
                  <div
                    key={prop.value}
                    className={`rfi-submission--row ${
                      customClass ? customClass + "-row" : ""
                    }`}
                  >
                    <b>{prop.label}</b>
                    <div>
                      {prop.value === "callerPhone"
                        ? `${(prop.format || (val => val))(
                            rfi[prop.value]
                          )} \u00A0\u00A0\u00A0\u00A0${
                            rfi["callerName"]
                          } \u00A0\u00A0\u00A0\u00A0${rfi["callerLocation"]}`
                        : (prop.format || (val => val))(rfi[prop.value])}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        ))
      )}
    </div>
  );
};

export default RfiSubmission;

// id: ID
// timestamp: Date
// name: String
// email: String
// subject: String
