import React from "react";
import { Link } from "react-router-dom";
import "./TopCountCard.scss";
import PieChart from "views/components/PieChart/PieChart";
import colors from "views/components/PieChart/__mocks__/colors";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import LoadingCountCard from "views/components/TopCountCard/LoadingCountCard";

const CampaignTopCountCardPie = ({
  data = [],
  title,
  link,
  loading,
  showFraction,
  customPieProps
}) => {
  /*  the below logic is so that the pie chart always amounts to 100%. Standard rounding does not work because it can amount to 101%. To fix this, we retrieve the percentage of all data points rounded down. So if the percentage is 20.86 the percentage would be 20 and the decimal would be .86. Then sort the data by decimals from DESC order. Finally add up the percentages to see if they amount to 100. If it doesn't add 1 to the percentages until pointsNeeded === 100; */
  //get the total count for the devices
  const totalCount = data.map(e => e.value).reduce((a, b) => a + b, 0);
  //map through the values of each device and calculate the percentage floored and decimals
  data.map(e => {
    e.percentage = Math.floor(parseFloat(e.value * 100) / totalCount);
    e.decimals = (parseFloat(e.value * 100) / totalCount).toFixed(4) % 1;
  });
  //When you add all of the percentages, do they amount to 100%? If not, then pointsNeeded will not equal 0;
  const pointsNeeded = data.map(e => e.percentage).reduce((a, b) => a + b, 0);
  //sort through the decimals and order them from descending order;
  data.sort((a, b) => b.decimals - a.decimals);
  //Finally if there are points needed to make the percentage equal to zero, then map through the decimals from desc order and add 1 to the percentage.
  if (pointsNeeded !== 0) {
    let points = pointsNeeded;
    data.map(e => {
      if (points !== 100) {
        e.percentage++;
        points++;
      }
    });
  }
  //finally sort back from greatest to least for percentage.
  data.sort((a, b) => b.percentage - a.percentage);

  return (
    <>
      {title && <h4>{title}</h4>}

      {loading ? (
        <LoadingCountCard pie />
      ) : (
        <React.Fragment>
          <div className="topcountcard-pie-desc">
            <PieChart
              data={data}
              customClass="topcountcard-piechart"
              {...customPieProps}
            />

            {data.map((r, i) => (
              <div key={i} className="top-countcard--row">
                <div
                  className="top-countcard--order-color"
                  style={{ backgroundColor: colors[i] }}
                />
                <div className="top-countcard--row-content">
                  <div className="top-countcard--fill" />
                  <div className="top-countcard--ref">{r.label}</div>
                  <div className="top-countcard--total">
                    {showFraction
                      ? `${r.value}/${totalCount}`
                      : r.value === 0
                      ? 0
                      : `${r.percentage}%`}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {link && (
            <Link to={link} className="top-countcard--view-all">
              View All Devices <ThomasIcon attribute="ARROW_RIGHT" />
            </Link>
          )}
        </React.Fragment>
      )}
    </>
  );
};

export default CampaignTopCountCardPie;
