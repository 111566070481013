import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import SessionQueryContainer from "containers/SessionQueryContainer";

const PHONE_CALL_QUERY = gql`
  query getPhoneCall($query: SessionQueryInput!, $callId: ID, $source: String) {
    phoneCall(query: $query, callId: $callId, source: $source) {
      callerPhone
      callerName
      callerLocation
      dateTime
      targetPhone
      duration
      classification
      permanentFile
    }
  }
`;

const PhoneCallContainer = ({ children, page, callid, source, ...props }) => (
  <SessionQueryContainer page={page}>
    {({ query }) => {
      return (
        <Query
          query={PHONE_CALL_QUERY}
          variables={{
            query,
            callId: callid,
            source: source
          }}
        >
          {({ loading, error, data: { phoneCall = {} } }) => {
            if (error) {
              console.error(error);
              return;
            }

            return (
              children &&
              children({
                ...props,
                data: phoneCall,
                loading
              })
            );
          }}
        </Query>
      );
    }}
  </SessionQueryContainer>
);

export default PhoneCallContainer;
