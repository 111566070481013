// import { gql } from "apollo-boost";
import { graphql, compose } from "react-apollo";
import { DATERANGE_MUTATION, DATERANGE_QUERY } from "resolvers/dateRange";

const DateRangeContainer = ({ children, ...props }) =>
    children && children({ ...props });

export default compose(
    graphql(DATERANGE_QUERY, {
        props: ({ data: { dateRange } }) => ({ ...dateRange })
    }),

    graphql(DATERANGE_MUTATION, {
        props: ({ mutate }) => ({
            onDateRangeApply: (startDate, endDate) =>
                mutate({
                    variables: {
                        startDate,
                        endDate
                    }
                })
        })
    })
)(DateRangeContainer);
