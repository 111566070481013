const revenues = [
    {
        label: "Under $1 Million",
        value: "$1 - $1M",
        targetType: "Revenue"
    },
    {
        label: "$1 Million - $5 Million",
        value: "$1M - $5M",
        targetType: "Revenue"
    },
    {
        label: "$5 Million - $10 Million",
        value: "$5M - $10M",
        targetType: "Revenue"
    },
    {
        label: "$10 Million - $25 Million",
        value: "$10M - $25M",
        targetType: "Revenue"
    },
    {
        label: "$25 Million - $50 Million",
        value: "$25M - $50M",
        targetType: "Revenue"
    },
    {
        label: "$50 Million - $100 Million",
        value: "$50M - $100M",
        targetType: "Revenue"
    },
    {
        label: "$100 Million - $250 Million",
        value: "$100M - $250M",
        targetType: "Revenue"
    },
    {
        label: "$250 Million - $500 Million",
        value: "$250M - $500M",
        targetType: "Revenue"
    },
    {
        label: "$500 Million - $1 Billion",
        value: "$500M - $1B",
        targetType: "Revenue"
    },
    {
        label: "$1 Billion - $2.5 Billion",
        value: "$1B - $2.5B",
        targetType: "Revenue"
    },
    {
        label: "$2.5 Billion - $5 Billion",
        value: "$2.5B - $5B",
        targetType: "Revenue"
    },
    {
        label: "Over $5 Billion",
        value: "Over $5B",
        targetType: "Revenue"
    }
];

export default revenues;
