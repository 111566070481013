import React from "react";
import PageHeader from "views/sections/PageHeader/PageHeader";
import SessionSummary from "views/sections/SessionSummary/SessionSummary";
import PageResultsWithData from "containers/PageResultsWithData";

class VisitorSessions extends React.Component {
    render() {
        const {
            title,
            showFilters = true,
            showCompany = false,
            visitorId
        } = this.props;

        const page = "sessions-visitor-details";

        return (
            <div
                className="container"
                style={{ display: "flex", flexDirection: "column" }}
            >
                <PageHeader title={title} />

                <SessionSummary
                    page={page}
                    title="Sessions"
                    showFilters={showFilters}
                    showCompany={showCompany}
                    visitorId={visitorId}
                    backToLink={{ label: "all sessions", nav: "/sessions" }}
                />

                <PageResultsWithData visitorId={visitorId} page={page} />
            </div>
        );
    }
}

export default VisitorSessions;
