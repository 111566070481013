import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import SessionQueryContainer from "containers/SessionQueryContainer";

const DURATION_DEPTH_QUERY = gql`
    query getDurationsAndDepth(
        $query: SessionQueryInput!
        $limit: Int
        $offset: Int
        $ordering1: [Ordering]
        $ordering2: [Ordering]
    ) {
        durations(
            query: $query
            limit: $limit
            offset: $offset
            ordering: $ordering1
        ) {
            edges {
                node {
                    id
                    durationRange
                    duration
                    percentage
                    sessionsct
                }
            }
        }
        depths(
            query: $query
            limit: $limit
            offset: $offset
            ordering: $ordering2
        ) {
            edges {
                node {
                    id
                    depth
                    pagect
                    percentage
                    sessionsct
                }
            }
        }
    }
`;

const AnalyticsDurationDepthContainer = ({
    children,
    page,
    standardSort,
    ...props
}) => (
    <SessionQueryContainer page={page}>
        {({ query }) => (
            <Query
                query={DURATION_DEPTH_QUERY}
                variables={{
                    query,
                    limit: 100,
                    ordering1: [
                        {
                            orderBy: "duration",
                            direction: "ASC"
                        }
                    ],
                    ordering2: [
                        {
                            orderBy: "pagect_expanded",
                            direction: "ASC"
                        }
                    ]
                }}
            >
                {({
                    error,
                    loading,
                    data: { durations = {}, depths = {} }
                }) => {
                    if (error) {
                        console.error(error);
                        return;
                    }

                    return (
                        children &&
                        children({
                            ...props,
                            loading,
                            durations: durations.edges || [],
                            // standardSort && durations.edges
                            //     ? durations.edges[0].node.id === "30m+"
                            //         ? durations.edges
                            //               .map(i => {
                            //                   return { node: i.node };
                            //               })
                            //               .reverse()
                            //         : durations.edges
                            //     : durations.edged || [],
                            depths: depths.edges || []
                            // standardSort && depths.edges
                            //     ? depths.edges[0].node.id === "20+"
                            //         ? depths.edges
                            //               .map(i => {
                            //                   return { node: i.node };
                            //               })
                            //               .reverse()
                            //         : depths.edges
                            //     : depths.edged || []
                        })
                    );
                }}
            </Query>
        )}
    </SessionQueryContainer>
);

export default AnalyticsDurationDepthContainer;
