import companysizes from "data/companysizes";
import industries from "data/industries";
import countries from "data/countries";
import revenues from "data/revenues";
import states from "data/states";

const targetLabels = {
    sizes: [
        {
            label: "Company Sizes",
            value: "Company Sizes",
            targetType: "label",
            filterType: "TARGET_NOT"
        }
    ],
    revenues: [
        {
            label: "Revenue Range",
            value: "Revenue Range",
            targetType: "label",
            filterType: "TARGET_NOT"
        }
    ],
    industries: [
        {
            label: "Industries",
            value: "Industries",
            targetType: "label",
            filterType: "TARGET_NOT"
        }
    ],
    countries: [
        {
            label: "Countries",
            value: "Countries",
            targetType: "label",
            filterType: "TARGET_NOT"
        }
    ],
    states: [
        {
            label: "States",
            value: "States",
            targetType: "label",
            filterType: "TARGET_NOT"
        }
    ]
};

const targetsTotal = targetLabels.sizes
    .concat(
        companysizes.map(i => {
            return { ...i, shortLabel: null, filterType: "TARGET_NOT" };
        })
    )
    .concat(targetLabels.revenues)
    .concat(
        revenues.map(i => {
            return { ...i, shortLabel: null, filterType: "TARGET_NOT" };
        })
    )
    .concat(targetLabels.industries)
    .concat(
        industries.map(i => {
            return { ...i, shortLabel: null, filterType: "TARGET_NOT" };
        })
    )
    .concat(targetLabels.countries)
    .concat(
        countries.map(i => {
            return { ...i, shortLabel: null, filterType: "TARGET_NOT" };
        })
    )
    .concat(targetLabels.states)
    .concat(
        states.map(i => {
            return { ...i, shortLabel: null, filterType: "TARGET_NOT" };
        })
    );

export { targetLabels, targetsTotal };
