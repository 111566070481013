import React from "react";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import SettingsTargetContainer from "containers/SettingsTargetContainer";
import PageSteps from "views/sections/WelcomeScreen/PageSteps";
import HighValuePages from "views/components/HighValuePages/HighValuePages";

class Screen4 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pages: null,
      madeChange: false
    };
  }

  render() {
    const editedPages = this.state.pages;

    return (
      <SettingsTargetContainer>
        {({ targets = [], pages = [], saveTargets }) => {
          return (
            <React.Fragment>
              <header className="welcome-screen__header">
                <h1>
                  Add Your High Value Pages{" "}
                  <ThomasIcon
                    attribute="HIGH_VALUE_PAGE"
                    customIconClass="font-size-40"
                    hideToolTip
                  />
                </h1>
              </header>
              <div className="welcome-screen__body">
                <h3>
                  Actions on these pages are highlighted across the WebTrax
                  reporting site
                </h3>
                <p>
                  Setting high value pages allows you to easily report on only
                  those visitors that interacted with pages that you set. It is
                  recommended that you add your key contact pages such as
                  Contact Us, Request a Quote, and Submit and Inquiry
                </p>
                <div className="welcome-screen--high-value-pages">
                  <HighValuePages
                    urls={editedPages || pages.map(page => page.value)}
                    updatePages={p => {
                      this.setState({
                        pages: p,
                        madeChange: true
                      });
                    }}
                  />
                </div>
              </div>
              <footer className="welcome-screen__footer">
                <PageSteps step={4} onNavClick={this.props.onNavPage} />
                <button
                  className="btn btn-primary btn-lg next-step-btn"
                  onClick={() => {
                    saveTargets({
                      variables: {
                        pages: editedPages || pages.map(p => p.value),
                        targets: targets.map(
                          ({ label, value, targetType }) => ({
                            label,
                            value,
                            targetType
                          })
                        )
                      }
                    });
                    this.props.onNextStep();
                  }}
                >
                  Add High Value Pages
                </button>
                <div className="skip-step-link">
                  <a href="#next-step" onClick={() => this.props.onNextStep()}>
                    Set Up Later
                  </a>
                </div>
              </footer>
            </React.Fragment>
          );
        }}
      </SettingsTargetContainer>
    );
  }
}

export default Screen4;
