const colors = [
    '#28618E',
    '#6CB33F',
    '#F6921E',
    '#50ADB5',
    '#C4D7DD',
    '#FFD400',
    '#57518E',
    '#6C97C8',
    '#49B0E4',
    '#A19EBF',
    '#B9E548',
    '#979797',
    '#135B31',
    '#83288E',
    '#A0B33F',
    '#DFF61E',
    '#B56C50',
    '#DDC4D9',
    '#FF6A00',
    '#09D9EE',
    '#5CA3EE',
    '#49E49E',
    '#EE8F8F',
    '#447A2A',
    '#EA21B8',
    '#80A7E0',
    '#3AA567'
];

export default colors;