const entryPagesKeys = [
    {
        label: "Page",
        key: "url",
        sort: {
            desc: [
                {
                    orderBy: "url",
                    direction: "DESC"
                },
                {
                    orderBy: "entryCount",
                    direction: "DESC"
                }
            ],
            asc: [
                {
                    orderBy: "url",
                    direction: "ASC"
                },
                {
                    orderBy: "entryCount",
                    direction: "DESC"
                }
            ]
        }
    },
    {
        label: "Entrances",
        key: "entryCount",
        sort: {
            desc: [
                {
                    orderBy: "entryCount",
                    direction: "DESC"
                },
                {
                    orderBy: "url",
                    direction: "ASC"
                }
            ],
            asc: [
                {
                    orderBy: "entryCount",
                    direction: "ASC"
                },
                {
                    orderBy: "url",
                    direction: "ASC"
                }
            ]
        }
    },
    {
        label: "Bounces",
        key: "bounces",
        sort: {
            desc: [
                {
                    orderBy: "bounces",
                    direction: "DESC"
                },
                {
                    orderBy: "entryCount",
                    direction: "DESC"
                }
            ],
            asc: [
                {
                    orderBy: "bounces",
                    direction: "ASC"
                },
                {
                    orderBy: "entryCount",
                    direction: "DESC"
                }
            ]
        }
    },
    {
        label: "Bounce Rate",
        key: "bounceRate",
        sort: {
            desc: [
                {
                    orderBy: "bounceRate",
                    direction: "DESC"
                },
                {
                    orderBy: "entryCount",
                    direction: "DESC"
                }
            ],
            asc: [
                {
                    orderBy: "bounceRate",
                    direction: "ASC"
                },
                {
                    orderBy: "entryCount",
                    direction: "DESC"
                }
            ]
        }
    }
];

export default entryPagesKeys;
