import * as React from "react";
import { untyped } from "utils/untyped";
import PageHeader from "views/sections/PageHeader/PageHeader";
import SessionSummary from "views/sections/SessionSummary/SessionSummary";
import CardGroup from "views/sections/CardGroup/CardGroup";
import TableSection from "views/sections/TableSection/TableSection";
import audienceTableFactory from "utils/tableFactories/audienceTableFactory";
import audienceKeys from "./data/audienceKeys";
import pageConfig from "./pageConfig";

import "./Audience.scss";
import { Export } from "views/components/Export/Export";

const PageHeaderGeneric = untyped(PageHeader);
const SessionSummaryGeneric = untyped(SessionSummary);
const TableSectionGeneric = untyped(TableSection);
const ExportGeneric = untyped(Export);

class Audience extends React.Component<any, any> {
  render() {
    const { page = "", title = "" } = this.props;

    return (
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <PageHeaderGeneric title={title} />

        <SessionSummaryGeneric
          title={(pageConfig as any)[page].summaryTitle}
          showFilters={true}
          showCompany={false}
          page={page}
          viewSessionsButton={(pageConfig as any)[page].button}
        />

        <CardGroup page={page} />

        <div className="section-header--with-export">
          <h2>Your Most Active Identifiable Audience</h2>

          <div className="results-control">
            <div className="results-control__utility-icons">
              <ExportGeneric page={page} downloadType="ACTIVE_AUDIENCES" />
            </div>
          </div>
        </div>

        <TableSectionGeneric
          tableCols={audienceKeys}
          contentFactory={audienceTableFactory}
          link={true}
          page={page}
        />
      </div>
    );
  }
}

export default Audience;
