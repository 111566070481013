import React from "react";
import ActiveItem from "views/components/ActiveItem/ActiveItem";

const ActiveCategory = ({
  activeFilters,
  targetTypes,
  title,
  type,
  onFilterRemove,
  onFiltersRemove,
  hasPrevious
}) =>
  (activeFilters.length > 0 || activeFilters.size > 0) && (
    <div className="results-filter__category">
      <div className={"results-filter__category-title "}>
        {hasPrevious && <hr />}
        <div className="d-flex">
          {hasPrevious && <div>and&nbsp;</div>}
          <div className="results-filter__category-title-bold">{title}:</div>
          <span
            onClick={() => {
              onFiltersRemove(type);
            }}
          >
            &nbsp;Clear All
          </span>
        </div>
      </div>
      {type === "TARGET" ? (
        <div className="results-filter__active-filters">
          {targetTypes.map((targetType, tIdx) => {
            let filters = [];
            if (targetType) {
              filters = activeFilters.get(targetType);
            }
            return (
              <React.Fragment key={tIdx}>
                <div className="results-filter__active-item__label">
                  {targetType}:
                </div>
                {filters.map((filter, fIdx) => {
                  return (
                    <React.Fragment key={fIdx}>
                      <ActiveItem
                        customMarkup={
                          <React.Fragment>
                            <div className="results-filter__active-item">{`${filterDisplay(
                              filter
                            )}`}</div>
                          </React.Fragment>
                        }
                        customClass="smaller-item"
                        item={filter}
                        onRemove={onFilterRemove}
                      />
                      {(tIdx + 1 < targetTypes.length ||
                        fIdx + 1 < filters.length) && (
                        <div className="results-filter__active-item-append">
                          or
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        <div className="results-filter__active-filters">
          {activeFilters.map((filter, i) => {
            return (
              <div
                key={`${filter.filterType}:${filter.value}`}
                className="results-filter__active-item-container"
              >
                <ActiveItem
                  key={`${filter.filterType}:${filter.value}`}
                  customMarkup={
                    <React.Fragment>
                      <div className="results-filter__active-item">{`${filterDisplay(
                        filter
                      )}`}</div>
                    </React.Fragment>
                  }
                  customClass="smaller-item"
                  item={filter}
                  onRemove={onFilterRemove}
                />
                {i + 1 < activeFilters.length && <span>or</span>}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );

const filterDisplay = filter => {
  if (filter.label) {
    return filter.shortLabel ? filter.shortLabel : filter.label;
  } else return filter.value;
};

const ResultsFiltersActive = ({
  activeFilters: {
    referrers = [],
    targets = [],
    actions = [],
    searches = [],
    sortedTargets = new Map()
  },
  onFilterRemove,
  onFiltersRemove
}) => {
  return (
    <div className="results-filter__active session-detail-row">
      <div className="results-filter--content">
        <h4 className="mb-0">Filters Applied</h4>
        <div className="results-filter__active_items active">
          <ActiveCategory
            activeFilters={referrers}
            title="Referrers"
            type="REFERRER"
            onFilterRemove={onFilterRemove}
            onFiltersRemove={onFiltersRemove}
            hasPrevious={false}
          />

          <ActiveCategory
            activeFilters={sortedTargets}
            targetTypes={Array.from(sortedTargets.keys())}
            title="Targets"
            type="TARGET"
            onFilterRemove={onFilterRemove}
            onFiltersRemove={onFiltersRemove}
            hasPrevious={!!referrers.length}
          />

          <ActiveCategory
            activeFilters={actions}
            title="Actions"
            type="ACTION"
            onFilterRemove={onFilterRemove}
            onFiltersRemove={onFiltersRemove}
            hasPrevious={!!referrers.length || !!targets.length}
          />

          <ActiveCategory
            activeFilters={searches}
            title="Searches"
            type="SEARCH"
            onFilterRemove={onFilterRemove}
            onFiltersRemove={onFiltersRemove}
            hasPrevious={
              !!referrers.length || !!targets.length || !!actions.length
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ResultsFiltersActive;
