import React from "react";
import { gql } from "apollo-boost";
import { Query, Mutation } from "react-apollo";

const QUERY_USER_TARGETS = gql`
    query getUserTargetsAndPages {
        user {
            id
            targets {
                label
                targetType
                value
                sortOrder
            }
            pages {
                label
                targetType
                value
                sortOrder
            }
        }
    }
`;

const MUTATION_SAVE_TARGETS = gql`
    mutation saveTargets($targets: [TargetInput], $pages: [String]) {
        saveTargets(targets: $targets, pages: $pages) {
            id
            targets {
                label
                targetType
                value
                sortOrder
            }
            pages {
                label
                targetType
                value
                sortOrder
            }
        }
    }
`;

const MUTATION_RESET_SESSION_STATE = gql`
    mutation resetSessionsState {
        resetSessionsState @client
    }
`;

const SettingsTargetContainer = ({ children }) => (
    <Query query={QUERY_USER_TARGETS}>
        {({ loading, data: { user } }) => (
            <Mutation mutation={MUTATION_RESET_SESSION_STATE}>
                {resetSessionStates => (
                    <Mutation
                        mutation={MUTATION_SAVE_TARGETS}
                        update={(cache, { data: { saveTargets } }) => {
                            const { user } = cache.readQuery({
                                query: QUERY_USER_TARGETS
                            });

                            cache.writeQuery({
                                query: QUERY_USER_TARGETS,
                                data: { ...user, user: saveTargets }
                            });

                            cache.writeData({
                                data: {
                                    lastSavedTimestamp: new Date().getTime()
                                }
                            });

                            resetSessionStates();
                        }}
                    >
                        {saveTargets => {
                            return (
                                children &&
                                children({
                                    ...user,
                                    saveTargets,
                                    loading
                                })
                            );
                        }}
                    </Mutation>
                )}
            </Mutation>
        )}
    </Query>
);

export default SettingsTargetContainer;
