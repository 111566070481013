import React from "react";

const version = `cra:${process.env.NODE_ENV},build:${process.env
  .REACT_APP_BUILDER_VERSION || ""}`;

const year = new Date().getFullYear();

const SiteFooter = () => (
  <footer className="site-footer" role="contentinfo">
    <div className="container">
      <div className="site-footer__copyright">
        <p>
          Copyright© {year}{" "}
          <a href="https://www.thomasnet.com">Thomas Publishing Company</a>. All
          Rights Reserved. See{" "}
          <a href="https://www.thomasnet.com/terms.html">
            Terms and Conditions
          </a>
          ,{" "}
          <a href="https://www.thomasnet.com/privacy.html" rel="nofollow">
            Privacy Statement
          </a>
          .<br />
          ThomasNet Is A Registered Trademark Of Thomas Publishing Company.{" "}
          {version}
        </p>
        <p />
      </div>
    </div>
  </footer>
);

export default SiteFooter;
