import React from "react";
import DateRangeContainer from "containers/DateRangeContainer";
import DateRangePicker from "views/components/DateRangePicker/DateRangePicker";

import "./PageHeader.css";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import HelpMenu from "views/components/HelpMenu/HelpMenu";

const PageHeader = ({
    title,
    subHeader,
    hideDatePicker,
    hideHelpMenu,
    settingsIcon
}) => (
    <React.Fragment>
        <header className="page-header">
            <h1 className="page-title" style={{ float: "left" }}>
                {settingsIcon && (
                    <ThomasIcon
                        attribute="HIGH_VALUE_AUDIENCE"
                        customClass="page-header--icon"
                    />
                )}
                {title}
                {subHeader && <p className="page-header--sub">{subHeader}</p>}
            </h1>

            {!hideDatePicker && (
                <DateRangeContainer>
                    {({ onDateRangeApply, startDate, endDate }) => (
                        <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            onDateRangeApply={onDateRangeApply}
                        />
                    )}
                </DateRangeContainer>
            )}
        </header>
        {!hideHelpMenu && <HelpMenu />}
    </React.Fragment>
);

export default PageHeader;
