import React from "react";
import { Query, Mutation } from "react-apollo";
import {
    QUERY_PAGE_CONFIG,
    MUTATION_UPDATE_PAGE_CONFIG
} from "resolvers/sessions";
import { Filter } from "../data/pages";

class ParamsFilterProvider extends React.Component {
    componentDidMount() {
        // console.log("[TargetsProvider] componentDidMount.");
        this.handleOnLoad();
    }

    componentDidUpdate() {
        // console.log("[TargetsProvider] componentDidUpdate.");
        // this.handleOnLoad();
    }

    handleOnLoad() {
        this.props.onLoad && this.props.onLoad();
    }

    render() {
        return this.props.children;
    }
}

const ParamsFilterProviderContainer = ({ children, page, params }) => {
    return (
        <Query query={QUERY_PAGE_CONFIG} variables={{ page }}>
            {({ data: { page } }) => {
                if (!page) {
                    return children;
                }

                var { ref } = params;

                if (!ref) {
                    // console.log(
                    //     "[ParamsFilterProviderContainer] No params found."
                    // );
                    return children;
                }

                if (ref === "DTS") {
                    ref = "Direct to Site";
                }

                return (
                    <Mutation mutation={MUTATION_UPDATE_PAGE_CONFIG}>
                        {updatePage => (
                            <ParamsFilterProvider
                                onLoad={() => {
                                    // console.log(
                                    //     "[ParamsFilterProvider] onLoad."
                                    // );

                                    updatePage({
                                        variables: {
                                            page,
                                            data: {
                                                ...page,
                                                activeFilters: [
                                                    new Filter({
                                                        value: ref,
                                                        shortLabel: ref,
                                                        filterType: "REFERRER",
                                                        label: ref
                                                    })
                                                ]
                                            }
                                        }
                                    });
                                }}
                            >
                                {children}
                            </ParamsFilterProvider>
                        )}
                    </Mutation>
                );
            }}
        </Query>
    );
};

export default ParamsFilterProviderContainer;
