import React from "react";

import { ResponsiveLine } from "@nivo/line";
import mdata from "./data";

import "./ChartCard.scss";
import toolTip from "./ToolTips";
import ChartHeroLoader from "views/sections/ChartHero/ChartHeroLoader";

const ChartCard = ({
  title,
  data,
  loading,
  customToolTip = null,
  customClass,
  legend,
  customXAxis
}) => {
  var bigData = false;

  if (data[0] && data[0].data[0] && data[0].data[0].y > 10000) {
    bigData = true;
  }

  return (
    <div
      className={`session-depth ${
        customToolTip === "length" ? "session-length" : ""
      } card ${customClass ? customClass : ""}`}
    >
      <h4>{title}</h4>

      {loading ? (
        <ChartHeroLoader customClass="depth-length-loader" />
      ) : (
        <div className="session-graph">
          <div className="session-graph--wrapper">
            <ResponsiveLine
              data={data ? data : mdata}
              colorBy={d => d.color}
              margin={{
                top: 10,
                right: 15,
                bottom: legend ? 50 : 30,
                left: bigData ? 55 : 40
              }}
              axisBottom={{
                orient: "bottom",
                tickSize: 0,
                tickPadding: 10,
                ...customXAxis
              }}
              axisLeft={{
                orient: "left",
                tickCount: 5,
                tickSize: 0,
                tickPadding: 12
              }}
              enableDots={true}
              enableDotLabel={false}
              dotSize={5}
              dotColor="#1b5070"
              dotBorderWidth={0}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
              tooltipFormat={customToolTip ? toolTip[customToolTip] : null}
              legends={legend}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartCard;
