import React from "react";

import PageHeader from "views/sections/PageHeader/PageHeader";
import ChartHero from "views/sections/ChartHero/ChartHero";
import ChartCard from "views/components/ChartCard/ChartCard";
import TopCountCard from "views/components/TopCountCard/TopCountCard";
import TopCountCardPie from "views/components/TopCountCard/TopCountCardPie";
import TopLinks from "views/components/TopLinks/TopLinks";
import ChartHeroContainer from "containers/ChartHeroContainer";
import AnalyticsContainer from "containers/AnalyticsContainer";
import AnalyticsDurationDepthContainer from "containers/AnalyticsDurationDepthContainer";
import SessionSummary from "views/sections/SessionSummary/SessionSummary";

const mapData = data => {
  return data.length > 0
    ? [
        {
          id: "Desktop: Windows",
          label: "Desktop: Windows",
          value: data
            .filter(f => f.node.platform.includes("Win"))
            .map(n => n.node.sessionsct)
            .reduce((a, b) => a + b, 0)
        },
        {
          id: "Desktop: Mac OS",
          label: "Desktop: Mac OS",
          value: data
            .filter(f => f.node.platform === "MacOSX")
            .map(n => n.node.sessionsct)
            .reduce((a, b) => a + b, 0)
        },

        {
          id: "Mobile: iOS",
          label: "Mobile: iOS",
          value: data
            .filter(f => f.node.platform === "iOS")
            .map(n => n.node.sessionsct)
            .reduce((a, b) => a + b, 0)
        },
        {
          id: "Mobile: Android",
          label: "Mobile: Android",
          value: data
            .filter(f => f.node.platform === "Android")
            .map(n => n.node.sessionsct)
            .reduce((a, b) => a + b, 0)
        },
        {
          id: "Other",
          label: "Other",
          value: data
            .filter(
              f => f.node.platform === "unknown" || f.node.platform === "Linux"
            )
            .map(n => n.node.sessionsct)
            .reduce((a, b) => a + b, 0)
        }
      ].sort((a, b) => b.value - a.value)
    : [];
};

class Analytics extends React.Component {
  // printPage() {
  //     var content = document.getElementById("printablestuff");
  //     var pri = document.getElementById("ifmcontentstoprint").contentWindow;
  //     pri.document.open();
  //     pri.document.write(content.innerHTML);
  //     pri.document.close();
  //     pri.focus();
  //     pri.print();
  // }
  render() {
    const { page, title } = this.props;

    return (
      <div
        id="printablestuff"
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <PageHeader title={title} />

        <SessionSummary
          title="Sessions"
          showFilters={true}
          showCompany={false}
          page={page}
          customClass="mb-4"
        />

        <ChartHeroContainer page={page} hideTotal={true}>
          {({ data, loading }) => {
            return (
              <ChartHero
                hideTotal={true}
                title="Top Referrers"
                data={data}
                loading={loading}
                link
              />
            );
          }}
        </ChartHeroContainer>

        <section className="card-group analytics-home-cards">
          <AnalyticsDurationDepthContainer page={page} standardSort>
            {({ loading, durations, depths }) => {
              return (
                <React.Fragment>
                  <ChartCard
                    title="Session Depth"
                    data={[
                      {
                        id: "Sessions:",
                        color: "#3cb4e8",
                        data: depths.map(d => {
                          return {
                            x: d.node.depth,
                            y: d.node.sessionsct
                          };
                        })
                      }
                    ]}
                    customToolTip="depth"
                    loading={loading}
                  />
                  <ChartCard
                    title="Session Length"
                    data={[
                      {
                        id: "Sessions:",
                        color: "#3cb4e8",
                        data: durations.map(d => {
                          return {
                            x: d.node.durationRange,
                            y: d.node.sessionsct
                          };
                        })
                      }
                    ]}
                    customToolTip="length"
                    loading={loading}
                  />
                </React.Fragment>
              );
            }}
          </AnalyticsDurationDepthContainer>
          <AnalyticsContainer
            page={page}
            queryKey="analytics-browsers"
            hardLimit={100}
          >
            {({ data, loading }) => {
              return (
                <div className="top-countcard card">
                  <TopCountCardPie
                    title="Top 5 Devices"
                    link={"/analytics/browsers"}
                    data={mapData(data)}
                    loading={loading}
                  />
                </div>
              );
            }}
          </AnalyticsContainer>
          <AnalyticsContainer
            page={page}
            queryKey="analytics-referrers"
            hardLimit={5}
          >
            {({ data, loading }) => {
              return (
                <TopCountCard
                  title="Top Referrers"
                  link={"/analytics/referrers"}
                  data={data}
                  itemProp="url"
                  valueProp="sessionsCount"
                  loading={loading}
                />
              );
            }}
          </AnalyticsContainer>
          <AnalyticsContainer
            queryKey="analytics-toppages"
            page={page}
            hardLimit={5}
          >
            {({ data, loading }) => {
              return (
                <TopLinks
                  title="Top Pages"
                  link={"/analytics/toppages"}
                  data={data}
                  itemProp="url"
                  valueProp="pageCount"
                  loading={loading}
                />
              );
            }}
          </AnalyticsContainer>
          <AnalyticsContainer
            page={page}
            queryKey="analytics-searchterms"
            hardLimit={5}
          >
            {({ data, loading }) => {
              return (
                <TopCountCard
                  title="Top Search Terms/ Categories"
                  link={"/analytics/searchterms"}
                  data={data}
                  itemProp="term"
                  valueProp="termct"
                  loading={loading}
                />
              );
            }}
          </AnalyticsContainer>
          <AnalyticsContainer
            page={page}
            queryKey="analytics-entrypages"
            hardLimit={5}
          >
            {({ data, loading }) => {
              return (
                <TopLinks
                  title="Entry Pages"
                  link={"/analytics/entrypages"}
                  data={data}
                  itemProp="url"
                  valueProp="entryCount"
                  loading={loading}
                />
              );
            }}
          </AnalyticsContainer>
          <AnalyticsContainer
            page={page}
            queryKey="analytics-exitpages"
            hardLimit={5}
          >
            {({ data, loading }) => {
              return (
                <TopLinks
                  title="Exit Pages"
                  link={"/analytics/exitpages"}
                  data={data}
                  itemProp="url"
                  valueProp="exitCount"
                  loading={loading}
                />
              );
            }}
          </AnalyticsContainer>
        </section>
      </div>
    );
  }
}

export default Analytics;
