import React from "react";
import NavLink from "react-router-dom/NavLink";
import SiteSubNav from "./SiteSubNav";

class SiteNavItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeOpen: false
    };

    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen() {
    const escapedPath = this.props.link.replace(
      /([.+*?=^!:${}()[\]|/\\])/g,
      "\\$1"
    );
    const match = /\/(\w*)/g.exec(escapedPath);

    if (!this.props.active.includes(match[1])) {
      this.setState({
        activeOpen: !this.state.activeOpen
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      this.setState({
        activeOpen: false
      });
    }
  }

  render() {
    const { link, title, subnav, level, changeNav, active, user } = this.props;

    const accountId = user.account && user.account.id;
    const companyName = user.account && user.account.name;
    const tinid = user.tinid;
    const inviterEmail = user.email;

    const { activeOpen } = this.state;
    const hasChildren = subnav && subnav.length > 0;

    //TODO: Add active state to <li />. This is not straightforward using react-router;
    //one options is to use the same logic from NavLink [source](https://github.com/ReactTraining/react-router/blob/6d9c4250dd0b88e029e0698599b44aa8b0d8d90e/packages/react-router-dom/modules/NavLink.js).

    return (
      <li
        className={`site-nav--side-nav__li-l${level} ${
          hasChildren ? `menu-item-has-children` : ""
        } ${link === active ? "active" : ""}`}
      >
        {link === "http://help.thomasnet.com/webtrax" ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        ) : (
          <NavLink
            to={
              level === 2 && link === "/settings/invite"
                ? `${link}?account_slug=${accountId}&company=${companyName}&account_tinid=${tinid}&inviter_email=${inviterEmail}`
                : link
            }
            onClick={() => {
              this.props.changeNav(link);
            }}
          >
            {title}
          </NavLink>
        )}

        {hasChildren && (
          <SiteSubNav
            active={active}
            activeOpen={activeOpen}
            subnav={subnav}
            link={link}
            level={level + 1}
            updateNav={changeNav}
            user={user}
          />
        )}

        {hasChildren && (
          <span
            onClick={this.toggleOpen}
            className={`m-subnav-arrow ${activeOpen ? `active` : ""}`}
          />
        )}
      </li>
    );
  }
}

export default SiteNavItem;
