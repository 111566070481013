import React from "react";
import authService from "services/authServices";
import {
  setAuthTokens,
  getAuthTokens,
  setAuthCookies,
  getAuthCookies,
  logOut,
} from "auth/utils";

class AuthContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSignedIn: false, loading: true };

    this.signIn = this.signIn.bind(this);
    this.signUp = this.signUp.bind(this);
    this.logOut = this.logOut.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.confirmForgotPassword = this.confirmForgotPassword.bind(this);
  }

  success() {
    this.setState({
      isSignedIn: true,
      loading: false,
    });
  }

  componentDidMount() {
    this.setState(
      {
        loading: true,
      },
      async () => {
        let accessToken = null;
        let idToken = null;
        let refreshToken = null;
        let issueTime = null;
        let expirationTime = null;

        const browserAuthResult = await this.checkBrowserAuthTokens();
        if (browserAuthResult) {
          accessToken = browserAuthResult.access_token;
          idToken = browserAuthResult.id_token;
          refreshToken = browserAuthResult.refresh_token;
          issueTime = browserAuthResult.issuedAtTime;
          expirationTime = browserAuthResult.expirationTime;
          setAuthTokens({
            accessToken,
            idToken,
            refreshToken,
            issueTime,
            expirationTime,
          });
          this.success();
          return;
        }

        const coreAuthResult = await this.getTokensFromCore();
        if (coreAuthResult) {
          accessToken = coreAuthResult.access_token;
          idToken = coreAuthResult.id_token;
          refreshToken = coreAuthResult.refresh_token;
          issueTime = coreAuthResult.issuedAtTime;
          expirationTime = coreAuthResult.expirationTime;
          setAuthTokens({
            accessToken,
            idToken,
            refreshToken,
            issueTime,
            expirationTime,
          });
          this.success();
          return;
        }
        return;
      }
    );
  }

  isSignedIn() {
    return this.getTokensFromCore().then((tokens) => tokens !== null);
  }

  checkBrowserAuthTokens() {
    let { refreshToken } = getAuthTokens();
    if (!refreshToken) {
      return Promise.resolve(null);
    }

    return authService
      .refreshAccessToken(refreshToken)
      .then((res) => {
        console.log("res ", res);
        if (!res) {
          return null;
        }
        return { ...res, refresh_token: refreshToken };
      })
      .catch((err) => {
        console.log(
          `[AuthContainer.checkBrowserAuthTokens] authService err:`,
          err
        );

        return null;
      });
  }

  getTokensFromCore() {
    let { tinid, coreX, coreU } = getAuthCookies();

    //Both of these cookies are required to initiate a
    //cookie-token trade.
    if (!coreX || !coreU) {
      return Promise.resolve(null);
    }

    return authService
      .amISignedIn(tinid, coreX, coreU)
      .then((res) => {
        if (!res) {
          return null;
        }

        return res;
      })
      .catch((err) => {
        console.log(`[AuthContainer.getTokensFromCore] authService err:`, err);

        return null;
      });
  }

  signIn(username, password) {
    return authService
      .signIn(username, password)
      .then((res) => {
        setAuthCookies({
          tinid: res.userId,
          coreX: res.core_x,
          coreU: res.core_u,
        });

        setAuthTokens({
          accessToken: res.access_token,
          idToken: res.id_token,
          refreshToken: res.refresh_token,
          issueTime: res.issuedAtTime,
          expirationTime: res.expirationTime,
        });

        this.success();
      })
      .catch((err) => {
        err.status = "401";
        err.message = "AUTHENTICATION FAILED";
        console.error(`[signIn] err:`, err);
        throw err;
      });
  }

  signUp(properties) {
    return authService
      .signUp(properties)
      .then(async (res) => {
        await this.signIn(properties.email, properties.password);
      })
      .catch((err) => {
        console.error(`[signUp] err:`, err);

        throw err;
      });
  }

  logOut(homepath) {
    logOut(homepath);
  }

  forgotPassword(username) {
    return authService
      .forgotPassword(username)
      .then((res) => res)
      .catch((err) => {
        console.error(err);
        throw err;
      });
  }

  confirmForgotPassword(username, password, confirmcode, tinid) {
    return authService
      .confirmForgotPassword(username, password, confirmcode, tinid)
      .then((res) => res)
      .catch((err) => {
        console.error(err);
        throw err;
      });
  }

  render() {
    const { isSignedIn, loading } = this.state;

    return this.props.children({
      signIn: this.signIn,
      signUp: this.signUp,
      logOut: this.logOut,
      forgotPassword: this.forgotPassword,
      confirmForgotPassword: this.confirmForgotPassword,
      isSignedIn,
      loading,
    });
  }
}

export default AuthContainer;
