import React from "react";

const Spinner = () => (
  <div className="spinner">
    <div className="rect1" />
    <div className="rect2" />
    <div className="rect3" />
  </div>
);

export { Spinner };
