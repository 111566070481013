import React from "react";
import { Link } from "react-router-dom";

import TableRowDetails from "views/components/ThomasTable/TableRowDetails/TableRowDetails";

class ThomasTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    toggleOpen(flag) {
        // if (!this.state.open) {
        // if (flag === this.props.openKey) {
        this.setState({ open: !this.state.open });
        // }
        // }
    }

    render() {
        const {
            row,
            tableCols,
            // openKey,
            contentFactory,
            // contentModifier = null,
            link,
            gClass,
            detailsTitle,
            detailsComponent
        } = this.props;

        return link ? (
            <Link
                to={`/sessions/visitor/${row.node.id}`}
                key={row.cursor}
                className={`thomas-table--row thomas-row-clickable thomas-table__grid ${gClass} ${
                    this.state.open ? "row-open" : ""
                }`}
            >
                {tableCols.map(col => (
                    <div key={col.key} className={`thomas-table__${col.key}`}>
                        {contentFactory
                            ? contentFactory(row, col.key)
                            : row[col.key]}
                    </div>
                ))}
            </Link>
        ) : (
            <div
                className={`thomas-table--row ${
                    detailsComponent ? "thomas-row-clickable" : ""
                } thomas-table__grid ${gClass} ${
                    this.state.open ? "row-open" : ""
                }`}
                // onClick={() => {
                //     if (detailsComponent) this.toggleOpen(openKey);
                // }}
            >
                {tableCols.map(col => {
                    return (
                        <div
                            key={col.key}
                            className={`thomas-table__${col.key}`}
                            onClick={() => {
                                if (detailsComponent) this.toggleOpen(col.key);
                            }}
                        >
                            {contentFactory
                                ? contentFactory(row, col.key, this.state.open) // used to be contentModifier
                                : row[col.key]}
                        </div>
                    );
                })}

                {this.state.open && (
                    <TableRowDetails
                        data={row}
                        title={detailsTitle(row["actionType"])}
                        detailsComponent={detailsComponent}
                        onClose={() => this.setState({ open: false })}
                    />
                )}
            </div>
        );
    }
}

export default ThomasTableRow;
