import React from "react";
import "./SiteAsideCompany.css";
import moment from "moment";
import { Link } from "react-router-dom";
import { AppContext } from "views/components/App/app-context";
import AccountsContainer from "containers/AccountsContainer";

/* */

const SiteAsideCompany = ({ company }) => {
  const { logo, name, memberSince, taggedUrls = [] } = company;

  return (
    <div className="account-info">
      <AppContext.Consumer>
        {({ hideForDemo, hideForProd }) => (
          <React.Fragment>
            {!hideForProd && (
              <figure className="account-info__avatar">
                {logo ? (
                  <img src={logo} alt={name} width="50" height="50" />
                ) : (
                  <div className="add-logo">
                    <a href="#add-logo">Add Your Logo Now.</a>
                    <p>
                      Logo you add will appear on your company profile on
                      Thomasnet
                    </p>
                  </div>
                )}
              </figure>
            )}

            {!hideForDemo && (
              <React.Fragment>
                <AccountsContainer>
                  {({ userAccounts }) => {
                    if (userAccounts && userAccounts.length > 1) {
                      return (
                        <Link
                          to="/accounts"
                          style={{
                            fontSize: "12px",
                            float: "right"
                          }}
                        >
                          Switch Accounts
                        </Link>
                      );
                    }
                    return null;
                  }}
                </AccountsContainer>

                <h3
                  className="account-info__title"
                  style={{ wordWrap: "break-word" }}
                >
                  {name}
                </h3>
                <ul className="list-unstyled">
                  {/* <li>User ID: {id}</li> */}
                  <li>
                    Member Since: {moment(memberSince).format("MM/DD/YYYY")}
                  </li>
                </ul>
                <hr />
                <h4 className="small">Tagged URLs:</h4>
                <ul className="list-unstyled">
                  {taggedUrls &&
                    taggedUrls.map(url => (
                      <li key={url}>
                        <a href={url}>{url}</a>
                      </li>
                    ))}
                </ul>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </AppContext.Consumer>
    </div>
  );
};

export default SiteAsideCompany;
