const audienceKeys = [
    {
        label: "Organization",
        key: "title"
    },
    {
        label: "Industry",
        key: "companyIndustry"
    },
    {
        label: "Revenue Range",
        key: "companyAnnualSales"
    },
    {
        label: "Company Size",
        key: "companyEmployees"
    },
    {
        label: "City",
        key: "city"
    },
    {
        label: "State",
        key: "state"
    },
    {
        label: "Session Count",
        key: "sessionCount"
    },
    {
        label: "Pages Viewed",
        key: "pagesViewed"
    }
];

export default audienceKeys;
