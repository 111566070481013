import React from "react";

const PageSteps = ({ step, onNavClick }) => (
    <div className="welcome-screen__steps">
        <div className={`ws-step ${step === 1 ? "active" : ""}`}>
            <div className="ws-step-ball" />
        </div>
        <div
            onClick={() => {
                onNavClick(2);
            }}
            className={`ws-step ${step === 2 ? "active" : ""} ${
                step === 1 ? "" : "clickable"
            }`}
        >
            <div className="ws-step-ball" />
        </div>
        <div
            onClick={() => {
                onNavClick(3);
            }}
            className={`ws-step ${step === 3 ? "active" : ""} ${
                step === 1 ? "" : "clickable"
            }`}
        >
            <div className="ws-step-ball" />
        </div>
        <div
            onClick={() => {
                onNavClick(4);
            }}
            className={`ws-step ${step === 4 ? "active" : ""} ${
                step === 1 ? "" : "clickable"
            }`}
        >
            <div className="ws-step-ball" />
        </div>
        <div
            onClick={() => {
                onNavClick(5);
            }}
            className={`ws-step ${step === 5 ? "active" : ""} ${
                step === 1 ? "" : "clickable"
            }`}
        >
            <div className="ws-step-ball" />
        </div>
    </div>
);

export default PageSteps;
