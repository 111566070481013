import React from "react";
import moment from "moment";
import { ResponsiveLine } from "@nivo/line";

import "./SessionsGraph.css";

// Both are in days
const TIMEFRAME_FOR_WEEKS = 45;
const TIMEFRAME_FOR_MONTHS = 120;

const SessionsGraph = ({
    data: { allSessions = [], targetedSessions = [] }
}) => {
    const useWeeks = allSessions.length > TIMEFRAME_FOR_WEEKS;
    const useMonths = allSessions.length > TIMEFRAME_FOR_MONTHS;
    const spanYear = allSessions.length > 365;

    const theData = [
        {
            id: "Targeted Sessions",
            color: "#1b5070",
            data: useMonths
                ? generateMonthAxes(targetedSessions, spanYear)
                : targetedSessions.map(d => {
                      return {
                          x: d.ydt,
                          y: d.sessionsCount
                      };
                  })
        },
        {
            id: "All Sessions",
            color: "#3cb4e8",
            data: useMonths
                ? generateMonthAxes(allSessions, spanYear)
                : allSessions.map(d => {
                      return {
                          x: d.ydt,
                          y: d.sessionsCount
                      };
                  })
        }
    ];

    return (
        <div className="total-sessions-graph">
            <div className="graph-wrapper">
                <ResponsiveLine
                    data={theData}
                    colorBy={d => d.color}
                    margin={{
                        top: 10,
                        right: 30,
                        bottom: 60,
                        left: useMonths ? 75 : 50
                    }}
                    minY="auto"
                    stacked={false}
                    axisBottom={{
                        orient: "bottom",
                        format: v =>
                            useMonths ? v : `${moment(v).format("M/DD")}`,
                        tickRotation: useMonths && !spanYear ? -25 : -65,
                        tickValues:
                            useWeeks && !useMonths
                                ? theData[0].data
                                      .filter((e, i) => {
                                          return i % 7 === 0;
                                      })
                                      .map(d => d.x)
                                : null
                    }}
                    axisLeft={{
                        orient: "left"
                    }}
                    enableDots={true}
                    enableDotLabel={false}
                    dotSize={8}
                    dotColor="inherit:darker(0.3)"
                    dotBorderWidth={0}
                    enableArea={true}
                    areaOpacity={0.85}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    enableGridX={false}
                    enableGridY={false}
                    tooltipFormat={(d, h, i) => {
                        return (
                            <div>
                                <b>
                                    {i === 0
                                        ? useMonths
                                            ? h.id
                                            : moment(h.id).format(
                                                  "MMM DD, YYYY"
                                              )
                                        : ""}
                                </b>
                                <div
                                    className={`legend-item ${
                                        i === 0 ? "pt-2" : ""
                                    }`}
                                >
                                    <div
                                        className="legend-item--square"
                                        style={{
                                            backgroundColor: d.color
                                        }}
                                    />
                                    <span>
                                        {i === 0
                                            ? `All Sessions: `
                                            : `Targeted Sessions: `}
                                        <b> {d.value}</b>
                                    </span>
                                </div>
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
};

const generateMonthAxes = (data = [], spanYear) => {
    var months = [];

    data.forEach(e => {
        let m = spanYear
            ? moment(e.ydt).format("MMM-YY")
            : moment(e.ydt).format("MMMM");

        let monthIndex = months.findIndex(i => {
            return i.x === m;
        });
        if (monthIndex !== -1) {
            months[monthIndex].y += e.sessionsCount;
        } else {
            months.push({ x: m, y: e.sessionsCount });
        }
    });
    return months;
};

export default SessionsGraph;
