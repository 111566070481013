import React from "react";

import referrersKeys from "./tableKeys/referrersKeys";
import toppagesKeys from "./tableKeys/toppagesKeys";
import singleRefKeys from "./tableKeys/singleRefKeys";
import entryPagesKeys from "./tableKeys/entryPagesKeys";
import browsersKeys from "./tableKeys/browsersKeys";
import exitPagesKeys from "./tableKeys/exitPagesKeys";
import searchTermsKeys from "./tableKeys/searchTermsKeys";
import singleDurationKeys from "./tableKeys/singleDurationKeys";

import analyticsTableFactory from "utils/tableFactories/analyticsTableFactory";
import referrersFactory from "utils/tableFactories/analytics/referrersFactory";

import FunnelAnalysis from "views/components/FunnelAnalysis/FunnelAnalysis";
import SessionDepthDetails from "views/components/SessionDepthDetails/SessionDepthDetails";

import FunnelAnalysisContainer from "containers/FunnelAnalysisContainer";

const AnalyticsPageMap = {
    "analytics-referrers": {
        pageContent: "Referrers",
        tableFactory: referrersFactory,
        keys: referrersKeys,
        sort: [
            {
                orderBy: "sessionsCount",
                direction: "DESC"
            }
        ]
    },
    "analytics-referrer": {
        pageContent: "Referrer: ",
        tableFactory: analyticsTableFactory,
        keys: singleRefKeys
        // data: singlerefs
    },
    "analytics-toppages": {
        pageContent: "Pages",
        tableFactory: analyticsTableFactory,
        keys: toppagesKeys,
        // data: toppages,
        openKey: "funnelAnalysis",
        detailsTitle: () => "Funnel Analysis",
        detailsComponent: d => (
            <FunnelAnalysisContainer
                page="funnel-analysis"
                pageid={d.node.id}
                url={d.node.url}
            >
                {({ data, query, referrers, searchTerms }) => (
                    <FunnelAnalysis
                        data={data}
                        query={query}
                        referrers={referrers}
                        searchTerms={searchTerms}
                    />
                )}
            </FunnelAnalysisContainer>
        ),
        sort: [
            {
                orderBy: "pageCount",
                direction: "DESC"
            },
            {
                orderBy: "url",
                direction: "ASC"
            }
        ]
    },
    "analytics-entrypages": {
        pageContent: "Pages",
        tableFactory: analyticsTableFactory,
        keys: entryPagesKeys,
        sort: [
            {
                orderBy: "entryCount",
                direction: "DESC"
            },
            {
                orderBy: "url",
                direction: "ASC"
            }
        ]
    },
    "analytics-exitpages": {
        pageContent: "Pages",
        tableFactory: analyticsTableFactory,
        keys: exitPagesKeys,
        sort: [
            {
                orderBy: "exitCount",
                direction: "DESC"
            },
            {
                orderBy: "url",
                direction: "ASC"
            }
        ]
    },
    "analytics-searchterms": {
        pageContent: "Search Terms",
        tableFactory: analyticsTableFactory,
        keys: searchTermsKeys,
        sort: [
            {
                orderBy: "termct",
                direction: "DESC"
            },
            {
                orderBy: "term",
                direction: "ASC"
            }
        ]
    },
    "analytics-sessionduration": {
        pageContent: "Pages",
        tableFactory: analyticsTableFactory,
        keys: entryPagesKeys
        // data: entryPages
    },
    "analytics-sessionduration-single": {
        pageContent: "Pages All Referrers",
        tableFactory: analyticsTableFactory,
        keys: singleDurationKeys,
        // data: singleDurations,
        openKey: "openDetails",
        detailsTitle: () => "Visitor Information",
        detailsComponent: data => <SessionDepthDetails />,
        sort: [
            {
                orderBy: "percentage",
                direction: "DESC"
            }
        ]
    },
    "analytics-browsers": {
        pageContent: "Browsers",
        tableFactory: analyticsTableFactory,
        keys: browsersKeys,
        sort: [
            {
                orderBy: "sessionsct",
                direction: "DESC"
            },
            {
                orderBy: "browserIndex",
                direction: "ASC"
            }
        ]
    }
};

export default AnalyticsPageMap;
