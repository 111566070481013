import * as React from "react";
import BlueprintTable from "views/components/BlueprintTable/BlueprintTable";
import { CampaignsContainer } from "containers/CampaignsContainer";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import { Link } from "react-router-dom";
import orderBy from "lodash.orderby";
import campaignsSortMap from "./campaignsSortMap";

const formatCampaignName = (campaignName: string) => {
  let pattern = /- (.*) -/gm;
  let result = pattern.exec(campaignName);
  return result && result.length > 0 ? result[1] : campaignName;
};

const AllCampaigns = () => {
  return (
    <CampaignsContainer>
      {({ campaigns, loading }) => {
        const allCampaigns = campaigns
          ? campaigns.map((row: any) => {
              return {
                campaignName: () => (
                  <a href={`/campaigns/${row.campaignId}/view`}>
                    {`Premium Vertical Sponsorship: ${formatCampaignName(
                      row.campaignName
                    )}`}
                  </a>
                ),
                startDate: new Date(row.startDate).getTime(),
                verticalName: formatCampaignName(row.campaignName),
                campaignNameRaw: row.campaignName.toLowerCase(),
                companyName: row.companyInfo.name,
                status: row.status,
                tgramsId: row.tgramsId,
                button: () => (
                  <Link
                    className="btn btn-outline-secondary btn-sm"
                    to={`/campaigns/${row.campaignId}/view`}
                  >
                    View
                  </Link>
                ),
                campaignPeriod: `${row.startDate} - ${row.endDate}`
              };
            })
          : [];

        let onlyOneCompany = allCampaigns.every(
          (i: any) => i.tgramsId === allCampaigns[0].tgramsId
        );

        let campaignHeaders = [
          {
            key: "campaignName",
            title: "Campaign Name ",
            sort: "desc"
          },
          {
            key: "companyName",
            title: "Company Name",
            sort: "desc"
          },
          {
            key: "status",
            title: "Status ",
            sort: "desc"
          },
          {
            key: "campaignPeriod",
            title: "Campaign Period ",
            sort: "desc"
          },
          {
            key: "button",
            title: "",
            sort: ""
          }
        ];

        if (onlyOneCompany) {
          campaignHeaders.splice(1, 1);
        }

        return (
          !loading && (
            <>
              <section className="page-utility">
                <div className="breadcrumb">
                  <a
                    href="https://www.thomasnet.com"
                    className="breadcrumb-item"
                  >
                    Home
                  </a>

                  <a
                    href="https://www.thomasnet.com/mythomas/"
                    className="breadcrumb-item"
                  >
                    My Account
                  </a>

                  <a className="breadcrumb-item">Campaigns</a>
                </div>
              </section>

              <CampaignsSorter
                campaigns={allCampaigns || []}
                campaignHeaders={campaignHeaders}
                onlyOneCompany={
                  onlyOneCompany && allCampaigns && allCampaigns[0]
                    ? allCampaigns[0].companyName
                    : false
                }
              />
            </>
          )
        );
      }}
    </CampaignsContainer>
  );
};

class CampaignsSorter extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      campaigns: props.campaigns,
      campaignHeaders: props.campaignHeaders,
      sortField: "campaignPeriod",
      sortDir: "desc",
      searchTerm: "",
      currentPage: 1
    };

    this.onSearch = this.onSearch.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
  }

  onSearch(e: any) {
    this.setState({
      currentPage: 1,
      searchTerm: e.target.value
    });
  }

  onSort(key: any) {
    const { sortField, sortDir } = this.state;

    const newDirection =
      key === sortField ? (sortDir === "desc" ? "asc" : "desc") : "asc";

    this.setState({
      sortField: key,
      sortDir: newDirection,
      currentPage: 1
    });
  }

  onPage(page: number) {
    this.setState({
      currentPage: page
    });
  }

  render() {
    const {
      campaigns,
      campaignHeaders,
      searchTerm,
      currentPage,
      sortField,
      sortDir
    } = this.state;

    // filter campaigns based on search term
    const loweredSearchTerm = searchTerm.toLowerCase();
    const filteredCampaigns = campaigns.filter((campaign: any) => {
      return searchTerm === ""
        ? campaign
        : campaign.campaignNameRaw.includes(loweredSearchTerm);
    });

    // generating heading numbers
    const displayAmount = 25;
    const totalAmount = filteredCampaigns.length;
    const totalPages = Math.ceil(totalAmount / displayAmount);

    // sorting campaigns
    const sortInfo: any = campaignsSortMap[sortField][sortDir];
    const sortedCampaigns = orderBy(
      filteredCampaigns,
      sortInfo.fields,
      sortInfo.directions
    );

    // index's for page filtering
    const beginPageItems = 1 + displayAmount * (currentPage - 1);
    const endPageItems = Math.min(totalAmount, currentPage * displayAmount);
    const pagedCampaigns = sortedCampaigns.slice(
      beginPageItems - 1,
      endPageItems
    );

    return (
      <section className="page-content page-content--single-column">
        <div className="container">
          <header className="section-header__header-left">
            <h2 className="section-header__header">
              {/* <ThomasIcon attribute="CAMPAIGN" /> did courtney tickets */}
              {`${
                this.props.onlyOneCompany ? this.props.onlyOneCompany : "Thomas"
              } Ad Campaigns`}
              <div className="section-header__subheader font-size-sm font-weight-light">
                Displaying {beginPageItems} to {endPageItems} of{" "}
                <b className="font-weight-bold">{totalAmount} </b>
                Campaigns
              </div>
            </h2>
          </header>
          <div className="content-card">
            <div className="results-filter--item pagination-index__search-bar mt-2 mb-4">
              <label className="results-filter__options_search-label">
                Search Term
              </label>
              <input
                className="form-control"
                style={{ width: "50%" }}
                type="text"
                value={searchTerm}
                onChange={this.onSearch}
              />
            </div>
            <BlueprintTable
              data={pagedCampaigns || []}
              headings={campaignHeaders}
              pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageUpdate={this.onPage}
              onSort={this.onSort}
              customClass="campaign-dash-table"
              currentSortField={sortField}
              currentSortDir={sortDir}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default AllCampaigns;
