import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import SessionQueryContainer from "containers/SessionQueryContainer";

const FUNNEL_ANALYSIS_QUERY = gql`
    query getFunnelAnalysis(
        $pageId: ID
        $query: SessionQueryInput!
        $limit: Int
        $limitR: Int
        $ordering: [Ordering]
        $orderingR: [Ordering]
    ) {
        page(pageId: $pageId, query: $query) {
            id
            entryCount
            exitCount
            requestsCount
            sessionsCount
            bounces
            bounceRate
        }
        searchTerms(query: $query, limit: $limit, ordering: $ordering) {
            edges {
                node {
                    id
                    term
                    termct
                }
            }
        }
        referrers(query: $query, limit: $limitR, ordering: $orderingR) {
            edges {
                node {
                    id
                    url
                    sessionsCount
                }
            }
        }
    }
`;

const FunnelAnalysisContainer = ({ children, page, pageid, url, ...props }) => (
    <SessionQueryContainer page={page}>
        {({ query }) => {
            if (url !== "") {
                query.pages = [pageid];
            }

            return (
                <Query
                    query={FUNNEL_ANALYSIS_QUERY}
                    variables={{
                        query,
                        pageId: pageid,
                        limit: 100,
                        limitR: 100,
                        ordering: [
                            {
                                orderBy: "termct",
                                direction: "DESC"
                            },
                            {
                                orderBy: "term",
                                direction: "ASC"
                            }
                        ],
                        orderingR: [
                            {
                                orderBy: "sessionsCount",
                                direction: "DESC"
                            }
                        ]
                    }}
                >
                    {({
                        loading,
                        error,
                        data: { page = {}, searchTerms = {}, referrers = {} }
                    }) => {
                        if (error) {
                            console.error(error);
                            return;
                        }

                        return (
                            children &&
                            children({
                                ...props,
                                data: page,
                                referrers,
                                searchTerms,
                                loading,
                                query
                            })
                        );
                    }}
                </Query>
            );
        }}
    </SessionQueryContainer>
);

export default FunnelAnalysisContainer;
