import React from "react";
import normal from "img/ico-timeline-normal.svg";
import "./ResultsTimelineLoadingCard.css";

const ResultsTimelineLoadingCard = () => (
	<div className="results-timeline__row">
		<div style={{ position: "absolute" }}>
			<img className="results-timeline__card-icon-n" src={normal} alt="n" />
		</div>
		<div className="results-timeline__card results-timeline__card-loader card">
			<div className="animated-background">
				<div className="background-masker header-top" />
				<div className="background-masker header-left" />
				<div className="background-masker header-right" />
				<div className="background-masker header-bottom" />
				<div className="background-masker subheader-left" />
				<div className="background-masker subheader-right" />
				<div className="background-masker subheader-bottom" />
				<div className="background-masker content-top" />
				<div className="background-masker content-first-end" />
				<div className="background-masker content-second-line" />
				<div className="background-masker content-second-end" />
				<div className="background-masker content-third-line" />
				<div className="background-masker content-third-end" />
			</div>
		</div>
	</div>
);

export default ResultsTimelineLoadingCard;
