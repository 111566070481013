import { gql } from "apollo-boost";
import { uniqWith } from "lodash";
import pages from "../data/pages";
import { TypeNames } from "../data/constants";

// Queries
export const QUERY_SESSIONS_INFO = gql`
  query sessionsInfoQuery($query: SessionQueryInput) {
    sessionsInfo(query: $query) {
      referrers {
        shortLabel
        targetType
        value
        label
        filterType
      }

      actions {
        shortLabel
        targetType
        value
        label
        filterType
      }

      targets {
        shortLabel
        targetType
        value
        label
        filterType
      }
    }
  }
`;

export const QUERY_PAGE_CONFIG = gql`
  query getPageConfigData($page: String!) {
    page: getPage(page: $page) @client {
      id
      useAllTargets
      activeFilters {
        shortLabel
        targetType
        value
        label
        filterType
      }
    }
  }
`;

export const MUTATION_UPDATE_PAGE_CONFIG = gql`
  mutation updatePageConfigData($page: String!, $data: SessionsConfig) {
    page: updatePage(page: $page, data: $data) @client {
      id
      useAllTargets
      activeFilters {
        shortLabel
        targetType
        value
        label
        filterType
      }
    }
  }
`;

export const FRAGMENT_SESSION_FRAGMENT = gql`
  fragment currentSessionsConfig on SessionsConfig {
    id
    activeFilters
    useAllTargets
  }
`;

export const QUERY_SESSIONS = gql`
  query getSessions(
    $query: SessionQueryInput
    $last: Int
    $before: ID
    $limit: Int
    $offset: Int
    $ordering: [Ordering]
    $paginationType: PaginationType
  ) {
    sessions(
      query: $query
      last: $last
      before: $before
      limit: $limit
      offset: $offset
      ordering: $ordering
      paginationType: $paginationType
    ) {
      edges {
        cursor
        node {
          id
          visitorName
          visitorIp
          visitorISP
          visitDate
          attributes
          city
          state
          country
          pagesInSession
          sessionDuration
          referrer
          referrerFull
          searchTerm
          pageViews {
            id
            timestamp
            where
            pageUrl
            domain
            pageType
            actions
            isHighValue
          }
          formSubmissions {
            id
            timestamp
            name
            email
            subject
          }
        }
      }
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`;

//Defaults and Resolvers
const sessions = {
  defaults: {
    sessionsViewType: "timeline", //table|timeline

    sessionsDisplayAmount: 25, //25|50|100

    sessionsActiveFilters: [],

    lastSavedTimestamp: new Date().getTime(),

    pages: [...pages]
  },

  resolvers: {
    Query: {
      getPage: (_, { page = "all" }, { cache }) => {
        const fragmentId = `${TypeNames.SESSION_CONFIG_TYPE_NAME}:${page}`;

        const data = cache.readFragment({
          id: fragmentId,
          fragment: FRAGMENT_SESSION_FRAGMENT
        });

        return data;
      },

      getPageActiveFilters: (_, { page = "all" }, { cache }) => {
        const fragmentId = `${TypeNames.SESSION_CONFIG_TYPE_NAME}:${page}`;

        const fragment = gql`
          fragment currentSessionsConfig on SessionsConfig {
            id
            activeFilters
          }
        `;

        const { activeFilters } =
          cache.readFragment({
            id: fragmentId,
            fragment: fragment
          }) || {};

        return activeFilters;
      }
    },

    Mutation: {
      resetSessionsState: (obj, args, { cache }, info) => {
        const data = {
          pages: [...pages]
        };

        cache.writeData({
          data
        });

        return null;
      },

      updatePage: (_, { page = "all", data }, { cache }) => {
        const fragmentId = `${TypeNames.SESSION_CONFIG_TYPE_NAME}:${page}`;

        const oldData = cache.readFragment({
          id: fragmentId,
          fragment: FRAGMENT_SESSION_FRAGMENT
        });

        const newData = {
          ...oldData,
          ...data
        };

        cache.writeFragment({
          id: fragmentId,
          fragment: FRAGMENT_SESSION_FRAGMENT,
          data: newData
        });

        return newData;
      },
      updateSessionsConfig: (_, { viewType, displayAmount }, { cache }) => {
        viewType && cache.writeData({ data: { sessionsViewType: viewType } });
        displayAmount &&
          cache.writeData({
            data: { sessionsDisplayAmount: displayAmount }
          });

        return null;
      },

      addFilter: (_, { filter, page }, { cache }) => {
        addFiltersToStore([filter], cache, page);
        return filter;
      },

      addSearchFilter: (_, { search, page }, { cache }) => {
        const filter = stringToSearchFilter(search);

        addFiltersToStore([filter], cache, page);
        return filter;
      },

      addFilters: (_, { filters, searches, page }, { cache }) => {
        addFiltersToStore(
          [...filters, ...searches.map(search => stringToSearchFilter(search))],
          cache,
          page
        );
        return filters;
      },

      //removeFilter removes all filters matching a given type and value.
      removeFilter: (_, { filter, page }, { cache }) => {
        return removeFiltersFromStore(
          f =>
            !(f.value === filter.value && f.filterType === filter.filterType),
          cache,
          page
        );
      },

      //removeFilters removes all filters of a given type.
      removeFilters: (_, { filterType, page }, { cache }) => {
        const includedFilters = filterType
          ? f => f.filterType !== filterType //Include all other filter types
          : () => false; //Removing all filters

        return removeFiltersFromStore(includedFilters, cache, page);
      }

      //removeAllFilters removes all filters of a given type.
      // removeAllFilters: (_, { page }, { cache }) => {
      //     return removeFiltersFromStore(f => f, cache, page);
      // }
    }
  }
};

const stringToSearchFilter = search => ({
  __typename: TypeNames.ACTIVE_FILTERS_TYPE_NAME,

  filterType: "SEARCH",
  value: search,
  label: search,
  shortLabel: null,
  targetType: null
});

const removeFiltersFromStore = (filterFn, cache, page) => {
  const fragmentId = `${TypeNames.SESSION_CONFIG_TYPE_NAME}:${page}`;

  const fragment = gql`
    fragment removeFiltersFromStore on SessionsConfig {
      id
      activeFilters {
        label
        filterType
        shortLabel
        targetType
        value
      }
    }
  `;

  const data = cache.readFragment({ id: fragmentId, fragment: fragment });
  const activeFilters = data.activeFilters.filter(filterFn);

  cache.writeFragment({
    id: fragmentId,

    fragment: fragment,

    data: {
      ...data,

      activeFilters
    }
  });

  return null;
};

const addFiltersToStore = (filters, cache, page) => {
  const fragmentId = `${TypeNames.SESSION_CONFIG_TYPE_NAME}:${page}`;

  const fragment = gql`
    fragment addFiltersToStore on SessionsConfig {
      id
      activeFilters {
        label
        filterType
        shortLabel
        targetType
        value
      }
    }
  `;

  const data = cache.readFragment({ id: fragmentId, fragment: fragment });

  cache.writeFragment({
    id: fragmentId,
    fragment: fragment,
    data: {
      ...data,

      activeFilters: uniqWith(
        data.activeFilters.concat(filters).map(f => ({
          ...f,
          __typename: TypeNames.ACTIVE_FILTERS_TYPE_NAME
        })),
        (a, b) => a.filterType === b.filterType && a.value === b.value
      )
    }
  });
};

export default sessions;
