import React from "react";

const CheckMark = () => (
    <div className="green-checkmark">
        <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
        >
            <title>ico-check-mark</title>
            <path
                d="M27.604 3.372a1.155 1.155 0 0 0-1.628.112L11.614 19.958 6.477 17.15a1.19 1.19 0 0 0-1.503 1.784l5.457 6.865a2.473 2.473 0 0 0 4.014-.198l.179-.278L27.816 4.867a1.153 1.153 0 0 0-.212-1.495z"
                fill="currentColor"
                fillRule="nonzero"
            />
        </svg>
    </div>
);

export default CheckMark;
