import React from "react";

const disciplines = {
    "": [],
    engineering: [
        "Electrical",
        "Mechanical",
        "Industrial",
        "Design",
        "Civil",
        "Architectural",
        "Computer Science",
        "Aerospace",
        "Environmental",
        "Chemical",
        "Consulting",
        "Other"
    ],
    supply: [
        "Supply Chain",
        "Purchasing",
        "Procurement",
        "Logistics",
        "Transportation",
        "Warehousing",
        "Distribution",
        "Inventory",
        "Materials",
        "Consulting",
        "Other"
    ],
    manufacturing: [
        "Manufacturing Engineering",
        "Production Management",
        "Quality Control",
        "Operations",
        "Maintenance & Repair",
        "Consulting",
        "Other"
    ],
    management: [
        "Engineering Management",
        "Procurement Management",
        "Operations Management",
        "IT",
        "Other"
    ],
    sales: [
        "Sales",
        "Marketing",
        "Sales & Marketing",
        "Agencies",
        "Consulting",
        "Other"
    ],
    other: ["Consultant", "Student", "Retired", "Educator", "Other"]
};

const JobDisciplineOptions = ({ jobFunction }) =>
    disciplines[jobFunction].map(d => <option value={d}>{d}</option>);

export default JobDisciplineOptions;
