import React from "react";

const terms = (
    <div className="termsandconditions">
        <p>
            By logging into the <u>Thomas WebTrax</u> application, clicking on
            the “I Accept” button or implementing <u>Thomas WebTrax</u> tracking
            code on the Client site, the Client hereby agrees to the{" "}
            <u>Thomas WebTrax</u> terms of service and consents to the
            following:
        </p>
        <p>
            If Client elects to implement or directs Publisher to implement{" "}
            <u>Thomas WebTrax</u> using the JavaScript tags provided by
            Publisher, Client’s website will be tracked by Publisher on behalf
            of Client. The information collected by <u>Thomas WebTrax</u> may
            include, among other information, total website sessions, average
            visit duration, average number of web pages viewed, site referrers,
            search terms, visitor domains, pages viewed, entry and exit pages.
            If Client’s product catalog is hosted by Publisher, product pages,
            search pages and other catalog related activity may be tracked by
            Publisher and reported.
        </p>
        <p>
            <b>Reports: </b> Information collected by <u>Thomas WebTrax</u> will
            be made available to Client through the <u>Thomas WebTrax</u>{" "}
            application. Except as required by law, in cooperation with legal
            authorities, as permitted hereunder or in connection with a sale of
            all or part of its (or its affiliate’s) assets, neither Client nor
            Publisher will disclose personally identifiable information
            collected or reported by <u>Thomas WebTrax</u>.
        </p>
        <p>
            <b>Data: </b>
            Publisher is providing <u>Thomas WebTrax</u> as a service to the
            Client. Publisher or third parties, including Client’s or
            Publisher’s affiliates, contractors or agents or the service
            providers of any of the foregoing (collectively, “Agents”) may
            monitor, store, review and use the <u>Thomas WebTrax</u> activity
            data and any other data or information collected by{" "}
            <u>Thomas WebTrax</u> (collectively, “Data”). Publisher may use Data
            in connection with the provision of services to Client or for
            statistical purposes, when used in aggregate, for Publisher’s sales
            and marketing efforts. Publisher may combine data from this service
            with information from our other services, and with information we
            receive from Agents. Publisher reserves the right to use Data for
            other legal purposes even if not specifically described herein.
            Publisher will not sell or share Data of Client with other clients
            of Publisher. Client hereby agrees to reference such monitoring,
            storage, review, access and use in its corporate policies as
            appropriate, including those governing its website. Publisher and
            Client will use the Data and reports in compliance with applicable
            laws and regulations. Each of Client and Publisher shall be
            responsible for its use of the Data. Notwithstanding anything
            herein, Publisher may remove all or any Data at any time from its
            servers, databases and files.
        </p>
        <p>
            <b>Tag Maintenance: </b>
            Client agrees that Publisher and its Agents may access Client’s
            website for the purpose of tag maintenance including fixing broken
            tags, updating the tracking tags or adding tracking tags to all
            website pages to facilitate the proper tracking of Client’s website.
        </p>
        <p>
            <b>Website Form Submission & Data Collection: </b>
            <u>Thomas WebTrax</u> includes the capability to capture website
            form submissions, including a configurable set of form field values.
            This form Data is only intended for reporting purposes.
        </p>
        <p>
            <b>Restrictions: </b>
            <u>Thomas WebTrax</u> is only available to Publishers Client’s and
            is provided and maintained by Publisher for the purpose of tracking
            and reporting website activity at the request and on behalf of
            Client. Publisher may terminate <u>Thomas WebTrax</u>, Client’s
            participation in
            <u>Thomas WebTrax</u> or any of the services with written notice. If
            Client is implementing <u>Thomas WebTrax</u> tags, Client agrees to
            implement tags within 60 days of receipt of the tags from Publisher.
        </p>
        <p>
            <b>Representations: </b>
            Client represents and warrants to Publisher that Client has all
            necessary rights, authority and power to execute and enter into the
            <u>Thomas WebTrax</u> program and use the Data.
        </p>
        <p>
            <b>Term and Termination: </b>
            Client’s participation in the <u>Thomas WebTrax</u> program will
            begin upon receipt of an executed Thomas Services Contract
            indicating that
            <u>Thomas WebTrax</u> has been selected by Client and end upon the
            earlier of (i) ten days’ written notice of withdrawal from Client or
            (ii) notice of termination of the <u>Thomas WebTrax</u> program by
            Publisher. Upon notice to Client, Publisher may terminate, suspend
            or alter the
            <u>Thomas WebTrax</u> program or terminate or suspend Client’s
            participation therein. Notwithstanding the foregoing, Publisher may
            not alter <u>Thomas WebTrax</u> to include Client in any service
            offered by the <u>Thomas WebTrax</u> program without Client’s
            express prior consent. Upon termination or suspension of the{" "}
            <u>Thomas WebTrax</u> program or Client’s participation therein, the{" "}
            <u>Thomas WebTrax</u> functionality and access to reporting shall
            cease.
        </p>
        <p>
            <b>Assignment: </b>
            Client may assign the <u>Thomas WebTrax</u> program with the prior
            written consent of Publisher. Any of the services offered or to be
            performed by Publisher in connection with <u>Thomas WebTrax</u> may
            be performed by Publisher or its Agents provided Publisher will be
            responsible for the performance by its Agents.
        </p>
        <p>
            <b>Limitation of Liability: </b>
            PUBLISHER, ITS AGENTS (COLLECTIVELY, “PUBLISHER PARTIES”) SHALL NOT
            BE LIABLE FOR ANY DAMAGES, WHETHER ORDINARY, PUNITIVE, SPECIAL,
            CONSEQUENTIAL, INCIDENTAL, EXEMPLARY OR INDIRECT, INCLUDING LOST
            PROFITS, EVEN IF A PUBLISHER PARTY HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>
            <b>Disclaimer of Warranties: </b>
            <u>THOMAS WEBTRAX</u> IS PROVIDED “AS IS” AND “AS AVAILABLE”.
            PUBLISHER DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING
            THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
            PURPOSE. PUBLISHER DOES NOT WARRANT THAT ANY INFORMATION COLLECTED
            BY <u>THOMAS WEBTRAX</u> OR MADE AVAILABLE TO CLIENT WILL BE
            ACCURATE, COMPLETE OR CURRENT.
        </p>
    </div>
);

export default terms;
