const industries = [
  {
    label: "Aerospace & Defense",
    value: "Aerospace & Defense",
    targetType: "Industry"
  },
  { label: "Agriculture", value: "Agriculture", targetType: "Industry" },
  { label: "Automotive", value: "Automotive", targetType: "Industry" },
  { label: "Biotech", value: "Biotech", targetType: "Industry" },
  {
    label: "Business Services",
    value: "Business Services",
    targetType: "Industry"
  },
  { label: "Construction", value: "Construction", targetType: "Industry" },
  {
    label: "Consumer Goods & Services",
    value: "Consumer Goods & Services",
    targetType: "Industry"
  },
  { label: "Education", value: "Education", targetType: "Industry" },
  {
    label: "Energy & Utilities",
    value: "Energy & Utilities",
    targetType: "Industry"
  },
  {
    label: "Engineering Services",
    value: "Engineering Services",
    targetType: "Industry"
  },
  {
    label: "Facilities & Property Management",
    value: "Facilities & Property Management",
    targetType: "Industry"
  },
  {
    label: "Food & Beverage",
    value: "Food & Beverage",
    targetType: "Industry"
  },
  {
    label: "Government & Military",
    value: "Government & Military",
    targetType: "Industry"
  },
  {
    label: "Healthcare & Medical",
    value: "Healthcare & Medical",
    targetType: "Industry"
  },
  {
    label: "Hospitality & Travel",
    value: "Hospitality & Travel",
    targetType: "Industry"
  },
  { label: "Manufacturing", value: "Manufacturing", targetType: "Industry" },
  { label: "Marine", value: "Marine", targetType: "Industry" },
  { label: "Mining", value: "Mining", targetType: "Industry" },
  {
    label: "Pharmaceuticals",
    value: "Pharmaceuticals",
    targetType: "Industry"
  },
  {
    label: "Printing & Publishing",
    value: "Printing & Publishing",
    targetType: "Industry"
  },
  {
    label: "Retail & Distribution",
    value: "Retail & Distribution",
    targetType: "Industry"
  },
  {
    label: "Software & Technology",
    value: "Software & Technology",
    targetType: "Industry"
  },
  {
    label: "Telecommunications",
    value: "Telecommunications",
    targetType: "Industry"
  },
  { label: "Textiles", value: "Textiles", targetType: "Industry" },
  {
    label: "Transportation & Logistics",
    value: "Transportation & Logistics",
    targetType: "Industry"
  },
  { label: "Other", value: "Other", targetType: "Industry" }
];

export default industries;
