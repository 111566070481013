const pageConfig = {
    "audience-all": {
        summaryTitle: "Sessions",
        button: {
            link: "/sessions",
            text: "View All Sessions"
        }
    },
    "audience-thomasnet": {
        summaryTitle: "Thomasnet Sessions: ",
        button: {
            link: "/sessions/thomasnet",
            text: "View All Thomasnet Sessions"
        }
    },
    "audience-targeted": {
        summaryTitle: "Targeted Sessions",
        button: {
            link: "/sessions/targeted",
            text: "View All Targeted Sessions"
        }
    },
    "audience-navigator": {
        summaryTitle: "Navigator Sessions",
        button: {
            link: "/sessions/navigator",
            text: "View All Navigator Sessions"
        }
    }
};

export default pageConfig;
