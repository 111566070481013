const toppagesKeys = [
    {
        label: "Page",
        key: "url",
        sort: {
            desc: [
                {
                    orderBy: "url",
                    direction: "DESC"
                },
                {
                    orderBy: "pageCount",
                    direction: "DESC"
                }
            ],
            asc: [
                {
                    orderBy: "url",
                    direction: "ASC"
                },
                {
                    orderBy: "pageCount",
                    direction: "DESC"
                }
            ]
        }
    },
    {
        label: "Funnel Analysis",
        key: "funnelAnalysis"
    },
    {
        label: "Count",
        key: "pageCount",
        sort: {
            desc: [
                {
                    orderBy: "pageCount",
                    direction: "DESC"
                },
                {
                    orderBy: "url",
                    direction: "ASC"
                }
            ],
            asc: [
                {
                    orderBy: "pageCount",
                    direction: "ASC"
                },
                {
                    orderBy: "url",
                    direction: "ASC"
                }
            ]
        }
    }
];

export default toppagesKeys;
