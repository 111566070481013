import React from "react";

import ThomasTable from "views/components/ThomasTable/ThomasTable";
import ResultsControl from "views/components/ResultsControl/ResultsControl";
import Pagination from "views/components/Pagination/Pagination";
import SessionInfo from "views/components/SessionInfo/SessionInfo";

import AnalyticsContainer from "containers/AnalyticsContainer";
// import AnalyticsTopPagesContainer from "containers/AnalyticsTopPagesContainer";
import LoadingThomasTable from "views/components/ThomasTable/LoadingThomasTable";
import ResultsControlContainer from "containers/ResultsControlContainer";

class AnalyticsPageResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: props.defaultSort || [],
      currentPage: 1
    };

    this.updateSort = this.updateSort.bind(this);
    this.updatePage = this.updatePage.bind(this);
  }

  updateSort(sort, onUpdate) {
    // Whenever we sort, reset to page 1
    this.setState(
      {
        sort: sort,
        currentPage: 1
      },
      onUpdate(sort, 1)
    );
  }

  updatePage(page, onUpdate) {
    this.setState(
      {
        currentPage: page
      },
      onUpdate(this.state.sort, page)
    );
  }

  render() {
    const {
      page,
      keys,
      tableFactory,
      openKey,
      detailsTitle,
      detailsComponent,
      mock,
      title,
      backToLink,
      referrer
    } = this.props;

    const { sort, currentPage } = this.state;

    return (
      <AnalyticsContainer queryKey={page} page={page} referrer={referrer}>
        {({
          data,
          query,
          loading,
          onUpdate,
          totalPages,
          totalCount,
          sessionsDisplayAmount,
          downloadType
        }) => {
          return (
            <React.Fragment>
              <section className="session-detail card">
                <SessionInfo
                  title={title}
                  loading={loading}
                  // total={titleHardCount ? titleHardCount : total}
                  total={totalCount || 0}
                  startDate={query.startDate}
                  endDate={query.endDate}
                  backToLink={backToLink}
                />
              </section>

              <section className="page-results">
                <ResultsControlContainer>
                  {({ onDisplayAmountChange }) => (
                    <ResultsControl
                      displayAmount={sessionsDisplayAmount}
                      onDisplayAmountChange={onDisplayAmountChange}
                      hideView
                      showExport
                      customClass="analytics-control"
                      downloadType={downloadType}
                      query={query}
                    />
                  )}
                </ResultsControlContainer>

                {loading ? (
                  <LoadingThomasTable tableCols={keys} rows={25} />
                ) : (
                  <ThomasTable
                    page={page}
                    data={mock ? mock : data}
                    tableCols={keys}
                    gClass={`analytics-table ${page}`}
                    contentFactory={tableFactory}
                    openKey={openKey}
                    detailsTitle={detailsTitle}
                    detailsComponent={detailsComponent}
                    onSort={s => this.updateSort(s, onUpdate)}
                    currentSort={{ sort }}
                  />
                )}

                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onUpdate={p => this.updatePage(p, onUpdate)}
                />
              </section>
            </React.Fragment>
          );
        }}
      </AnalyticsContainer>
    );
  }
}

export default AnalyticsPageResults;
