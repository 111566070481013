import React from "react";
import moment from "moment";

const toolTip = {
  depth: (d, h) => {
    return (
      <div>
        <b>{`${h.id} Page${h.id === 1 ? "" : "s"}`} </b>
        <div className="legend-item">
          <div
            className="legend-item--square"
            style={{
              backgroundColor: d.color
            }}
          />
          <span>{d.value} Sessions</span>
        </div>
      </div>
    );
  },
  length: (d, h) => {
    return (
      <div>
        <b>{h.id}</b>
        <div className="legend-item">
          <div
            className="legend-item--square"
            style={{
              backgroundColor: d.color
            }}
          />
          <span>{d.value} Sessions</span>
        </div>
      </div>
    );
  },
  byDate: (d, h, i) => {
    return (
      <div>
        <b>{i === 0 ? moment(h.id).format("MMM DD, YYYY") : ""}</b>
        <div className={`legend-item ${i === 0 ? "pt-2" : ""}`}>
          <div
            className="legend-item--square"
            style={{
              backgroundColor: d.color
            }}
          />
          <span>
            {i === 0 ? `${h.points[0].id}: ` : `${h.points[1].id}: `}
            <b> {d.value}</b>
          </span>
        </div>
      </div>
    );
  }
};

export default toolTip;
