const durationKeys = [
    {
        label: "Length (Time)",
        key: "durationRange"
    },
    {
        label: "Pct",
        key: "percentage"
    },
    {
        label: "Sessions",
        key: "sessionsct"
    }
];

export default durationKeys;
