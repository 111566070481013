import React from "react";
import ToggleSwitch from "views/components/ToggleSwitch/ToggleSwitch";

const BotFiltering = ({ active, onToggle, savedActive }) => {
    return (
        <div className="data-integration">
            <h3>Turn bot filtering on?</h3>

            <div className="data-integration__subheader-info">
                Enabling this function will remove all activity identified as a
                bot from the activity displayed in your WebTrax Account.
                Thomasnet's bot filtering includes activity on your own site,
                where the identification and removal of nonhuman activity is
                applied.
            </div>

            <ToggleSwitch
                text="Enable bot filtering in WebTrax?"
                active={active}
                onToggle={onToggle}
            />
        </div>
    );
};

export default BotFiltering;
