import React from "react";
import PageSteps from "views/sections/WelcomeScreen/PageSteps";
import BasicSettings from "views/components/BasicSettings/BasicSettings";

import SettingsTechnicalContainer from "containers/SettingsTechnicalContainer";

class Screen5 extends React.Component {
    state = { settings: {}, madeChange: false };

    render() {
        const { onNavPage } = this.props;
        const { settings, madeChange } = this.state;
        return (
            <SettingsTechnicalContainer>
                {({ user = {}, saveSettings, loading }) => {
                    if (loading) {
                        return null; //TODO: Add loading component
                    }

                    return (
                        <React.Fragment>
                            <header className="welcome-screen__header">
                                <h1>Review Default Technical Settings</h1>
                            </header>
                            <div className="welcome-screen__body">
                                <h3>Your Settings</h3>
                                <p>
                                    Information to check on this page include
                                    URL to your main index file and your time
                                    zone
                                </p>

                                <div className="welcome-screen-technical-settings">
                                    <BasicSettings
                                        noHeader
                                        urls={
                                            settings.urls ||
                                            (user.settings &&
                                                user.settings.urls)
                                        }
                                        email={
                                            settings.email ||
                                            (user.settings &&
                                                user.settings.email)
                                        }
                                        timezoneOffset={
                                            settings.timezoneOffset ||
                                            (user.settings &&
                                                user.settings.timezoneOffset)
                                        }
                                        updateSetting={(f, v) => {
                                            this.setState({
                                                settings: {
                                                    ...this.state.settings,
                                                    [f]: v
                                                },
                                                madeChange: true
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            <footer className="welcome-screen__footer">
                                <PageSteps step={5} onNavClick={onNavPage} />
                                <button
                                    className="btn btn-primary btn-lg next-step-btn"
                                    onClick={() => {
                                        const newSettings = {
                                            ...user.settings,
                                            ...this.state.settings
                                        };

                                        delete newSettings["__typename"];
                                        delete newSettings["dateImplemented"];

                                        if (madeChange) {
                                            saveSettings({
                                                variables: {
                                                    settings: newSettings
                                                },
                                                optimisticResponse: {
                                                    __typename: "Mutation",
                                                    saveSettings: {
                                                        ...user,
                                                        settings: {
                                                            ...user.settings,
                                                            ...newSettings
                                                        }
                                                    }
                                                }
                                            });
                                        }

                                        this.props.onNextStep();
                                    }}
                                >
                                    Start Using WebTrax
                                </button>
                            </footer>
                        </React.Fragment>
                    );
                }}
            </SettingsTechnicalContainer>
        );
    }
}

export default Screen5;
