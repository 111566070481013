import React from "react";

import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";

const analyticsTableFactory = (content, prop) => {
  switch (true) {
    case prop === "topPages":
      return <ThomasIcon attribute="TOP_PAGES" />;

    case prop === "entryPages":
      return <ThomasIcon attribute="ENTRY_PAGES" />;

    case prop === "exitPages":
      return <ThomasIcon attribute="EXIT_PAGES" />;

    case prop === "url":
      const fullWithParams =
        content.node[prop] +
        (content.node.params ? `?${content.node.params}` : "");
      return (
        <a
          href={`http://${content.node["domain"]}${fullWithParams}`}
          title={fullWithParams}
          target="_blank"
          rel="noopener noreferrer"
        >
          {fullWithParams}
          <ThomasIcon attribute="EXTERNAL_LINK" hideToolTip />
        </a>
      );

    case prop === "pageUrl":
      return (
        <React.Fragment>
          <div className="table-cell__with-icons">{content.node[prop]}</div>
          <ThomasIcon attribute="EXTERNAL_LINK" customClass="icon--sm" />
        </React.Fragment>
      );

    case prop === "funnelAnalysis":
      return (
        <React.Fragment>
          <div className="table-cell__with-icons">View </div>
          <ThomasIcon
            attribute="FUNNEL_ANALYSIS"
            customClass="icon_funnel-analysis"
            hideToolTip
          />
        </React.Fragment>
      );

    case prop === "openDetails":
      return <div className="table-cell__with-icons">Details </div>;

    case prop === "bounceRate" || prop === "percentage":
      return `${(Math.round(content.node[prop] * 10) / 10).toFixed(1)} %`;

    case prop === "depth":
      return (
        <React.Fragment>
          {content.node[prop]} {content.node[prop] === 1 ? "Page" : "Pages"}
        </React.Fragment>
      );

    case prop === "durationRange":
      return (
        // This logic is to match old wt logic.
        // 0a30, 61a180, etc.
        <React.Fragment>{content.node[prop]}</React.Fragment>
      );

    case prop === "sessionsCount" ||
      prop === "pageCount" ||
      prop === "entryCount" ||
      prop === "exitCount" ||
      prop === "bounces":
      return content.node[prop].toLocaleString();

    default:
      return content.node[prop];
  }
};

export default analyticsTableFactory;
