import React from "react";
import TextBoxAdder from "views/components/TextBoxAdder/TextBoxAdder";
import DropDown from "views/components/DropDown/DropDown";
import "./BasicSettings.css";

import zones from "./__mocks__/timezones";

const BasicSettings = ({ urls, timezoneOffset, updateSetting, noHeader }) => (
  <div className="basic-settings">
    {!noHeader && <h3>Your Settings</h3>}

    <div className="basic-settings__subheader-info">
      In order for WebTrax to collect data on your site you must include at a
      minimum your company URLs in both of the following formats: <br />{" "}
      https://www.yourcompanyurl.com and https://yourcompanyname.com (If you
      have alternate URLs enter them each on a new line)
    </div>
    <TextBoxAdder
      items={urls}
      updateItems={urls => {
        updateSetting("urls", urls);
      }}
      placeholder={[
        "Enter url here (e.g. https://yourcompanyname.com)",
        "Enter company url here (e.g. https://www.yourcompanyurl.com)"
      ]}
    />

    <div className="basic-settings__half-row">
      {/* <TextBox
                label="Email"
                value={email}
                onChange={text => {
                    updateSetting("email", text);
                }}
            /> */}
      <DropDown
        label="Your Time Zone"
        items={zones}
        customClass="basic-settings--timezone"
        onChange={data => {
          updateSetting("timezoneOffset", data.value);
        }}
        initValue={timezoneOffset}
      />
    </div>

    {/* <div className="basic-settings__half-row">
            <DropDown
                title={"50"}
                label="Default # Sessions To Display"
                items={[25, 50, 100]}
                onChange={data => updateSetting("defaultSessionsCount", data)}
                initValue={defaultSessionsCount}
            />
        </div> */}
  </div>
);

export default BasicSettings;
