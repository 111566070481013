import React from "react";
import { Link } from "react-router-dom";
import RouterLink from "views/components/RouterLink/RouterLink";

class Reset extends React.Component {
  constructor() {
    super();
    this.state = {
      password: "",
      confirmpassword: "",
      confirmationcode: "",
      localerror: ""
    };
  }

  render() {
    const {
      email,
      onConfirmForgotPassword,
      loading,
      error,
      clearError,
      resetPasswordValidation
    } = this.props;
    const { localerror } = this.state;
    return (
      <React.Fragment>
        <h2 className="text-center pt-0">WebTrax sign in</h2>
        <p className="text-center">
          Need to create an account? <Link to="/login">Request Access</Link>
        </p>
        <div className="form-bg form-short">
          {(error || localerror) && (
            <p
              className="alert alert-danger hide"
              id="errMsg"
              style={{ display: "block" }}
            >
              {localerror ? localerror : error}
            </p>
          )}
          <p className="text-center">
            <b>{email}</b>
            <br />
            <RouterLink
              urlClickHandler={() => {
                clearError();
                return `/login`;
              }}
            >
              {({ onExecuteLink }) => {
                return (
                  <span
                    className="forgot-password-link"
                    onClick={onExecuteLink}
                  >
                    Not You?
                  </span>
                );
              }}
            </RouterLink>
          </p>
          <div className="form-group" style={{ position: "relative" }}>
            <label htmlFor="exampleInputPassword1">
              Enter the confirmation code
            </label>
            <input
              className="form-control"
              placeholder="Confirmation Code"
              value={this.state.confirmationcode}
              onChange={e =>
                this.setState({
                  confirmationcode: e.target.value.trim()
                })
              }
            />
          </div>
          <div className="form-group" style={{ position: "relative" }}>
            <label htmlFor="exampleInputPassword1">Enter your password</label>
            <input
              name="password"
              type="password"
              className={`form-control ${localerror ? "is-invalid" : ""}`}
              placeholder="Password"
              value={this.state.password}
              onChange={e =>
                this.setState({
                  password: e.target.value,
                  localerror: localerror
                    ? e.target.value === this.state.confirmpassword
                      ? ""
                      : "Passwords do not match"
                    : localerror
                })
              }
            />
          </div>
          <div className="form-group" style={{ position: "relative" }}>
            <label htmlFor="exampleInputPassword1">Confirm your password</label>
            <input
              name="confirmpassword"
              type="password"
              className={`form-control ${localerror ? "is-invalid" : ""}`}
              placeholder="Confirm Password"
              value={this.state.confirmpassword}
              onChange={e =>
                this.setState({
                  confirmpassword: e.target.value,
                  localerror: localerror
                    ? e.target.value === this.state.password
                      ? ""
                      : "Passwords do not match"
                    : localerror
                })
              }
            />
          </div>

          <div className="form-group flex-end-btn">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                const resetValid = resetPasswordValidation(
                  this.state.confirmationcode,
                  this.state.password,
                  this.state.confirmpassword
                );
                resetValid &&
                  onConfirmForgotPassword(
                    email,
                    this.state.password,
                    this.state.confirmationcode
                  );
              }}
            >
              {loading ? (
                <div className="spinner loading-white">
                  <div className="rect1" />
                  <div className="rect2" />
                  <div className="rect3" />
                  <div className="rect4" />
                  <div className="rect5" />
                </div>
              ) : (
                "Confirm"
              )}
            </button>
          </div>

          <p
            className="alert alert-success"
            id="errMsg"
            style={{ display: "block" }}
          >
            Password reset instructions have been emailed to you.
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default Reset;
