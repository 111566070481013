const countries = [
    { value: "US", label: "United States", targetType: "Country" },
    { value: "AF", label: "Afghanistan", targetType: "Country" },
    { value: "AL", label: "Albania", targetType: "Country" },
    { value: "DZ", label: "Algeria", targetType: "Country" },
    { value: "AS", label: "American Samoa", targetType: "Country" },
    { value: "AD", label: "Andorra", targetType: "Country" },
    { value: "AO", label: "Angola", targetType: "Country" },
    { value: "AI", label: "Anguilla", targetType: "Country" },
    { value: "AQ", label: "Antarctica", targetType: "Country" },
    { value: "AG", label: "Antigua and Barbuda", targetType: "Country" },
    { value: "AR", label: "Argentina", targetType: "Country" },
    { value: "AM", label: "Armenia", targetType: "Country" },
    { value: "AW", label: "Aruba", targetType: "Country" },
    { value: "AU", label: "Australia", targetType: "Country" },
    { value: "AT", label: "Austria", targetType: "Country" },
    { value: "AZ", label: "Azerbaijan", targetType: "Country" },
    { value: "BS", label: "Bahamas", targetType: "Country" },
    { value: "BH", label: "Bahrain", targetType: "Country" },
    { value: "BD", label: "Bangladesh", targetType: "Country" },
    { value: "BB", label: "Barbados", targetType: "Country" },
    { value: "BY", label: "Belarus", targetType: "Country" },
    { value: "BE", label: "Belgium", targetType: "Country" },
    { value: "BZ", label: "Belize", targetType: "Country" },
    { value: "BJ", label: "Benin", targetType: "Country" },
    { value: "BM", label: "Bermuda", targetType: "Country" },
    { value: "BT", label: "Bhutan", targetType: "Country" },
    { value: "BO", label: "Bolivia", targetType: "Country" },
    { value: "BA", label: "Bosnia and Herzegovina", targetType: "Country" },
    { value: "BW", label: "Botswana", targetType: "Country" },
    { value: "BV", label: "Bouvet Island", targetType: "Country" },
    { value: "BR", label: "Brazil", targetType: "Country" },
    {
        value: "IO",
        label: "British Indian Ocean Territory",
        targetType: "Country"
    },
    { value: "VG", label: "British Virgin Islands", targetType: "Country" },
    { value: "BN", label: "Brunei Darussalam", targetType: "Country" },
    { value: "BG", label: "Bulgaria", targetType: "Country" },
    { value: "BF", label: "Burkina Faso", targetType: "Country" },
    { value: "BI", label: "Burundi", targetType: "Country" },
    { value: "KH", label: "Cambodia", targetType: "Country" },
    { value: "CM", label: "Cameroon", targetType: "Country" },
    { value: "CA", label: "Canada", targetType: "Country" },
    { value: "CV", label: "Cape Verde", targetType: "Country" },
    { value: "KY", label: "Cayman Islands", targetType: "Country" },
    { value: "CF", label: "Central African Republic", targetType: "Country" },
    { value: "TD", label: "Chad", targetType: "Country" },
    { value: "CL", label: "Chile", targetType: "Country" },
    { value: "CN", label: "China", targetType: "Country" },
    { value: "CX", label: "Christmas Island", targetType: "Country" },
    { value: "CC", label: "Cocos (Keeling) Islands", targetType: "Country" },
    { value: "CO", label: "Colombia", targetType: "Country" },
    { value: "KM", label: "Comoros", targetType: "Country" },
    { value: "CG", label: "Congo", targetType: "Country" },
    { value: "CK", label: "Cook Islands", targetType: "Country" },
    { value: "CR", label: "Costa Rica", targetType: "Country" },
    { value: "CI", label: "Cote D'Ivoire", targetType: "Country" },
    { value: "HR", label: "Croatia (Hrvatska)", targetType: "Country" },
    { value: "CU", label: "Cuba", targetType: "Country" },
    { value: "CY", label: "Cyprus", targetType: "Country" },
    { value: "CZ", label: "Czech Republic", targetType: "Country" },
    {
        value: "NF",
        label: "Democratic People's Republic of Korea",
        targetType: "Country"
    },
    {
        value: "CD",
        label: "Democratic Republic of Congo",
        targetType: "Country"
    },
    { value: "DK", label: "Denmark", targetType: "Country" },
    { value: "DJ", label: "Djibouti", targetType: "Country" },
    { value: "DM", label: "Dominica", targetType: "Country" },
    { value: "DO", label: "Dominican Republic", targetType: "Country" },
    { value: "EC", label: "Ecuador", targetType: "Country" },
    { value: "EG", label: "Egypt", targetType: "Country" },
    { value: "SV", label: "El Salvador", targetType: "Country" },
    { value: "GQ", label: "Equatorial Guinea", targetType: "Country" },
    { value: "ER", label: "Eritrea", targetType: "Country" },
    { value: "EE", label: "Estonia", targetType: "Country" },
    { value: "ET", label: "Ethiopia", targetType: "Country" },
    {
        value: "FK",
        label: "Falkland Islands (Malvinas)",
        targetType: "Country"
    },
    { value: "FO", label: "Faroe Islands", targetType: "Country" },
    {
        value: "FM",
        label: "Federated States of Micronesia",
        targetType: "Country"
    },
    { value: "FJ", label: "Fiji", targetType: "Country" },
    { value: "FI", label: "Finland", targetType: "Country" },
    { value: "FR", label: "France", targetType: "Country" },
    { value: "GF", label: "French Guiana", targetType: "Country" },
    { value: "PF", label: "French Polynesia", targetType: "Country" },
    {
        value: "TF",
        label: "French Southern Territories",
        targetType: "Country"
    },
    { value: "GA", label: "Gabon", targetType: "Country" },
    { value: "GM", label: "Gambia", targetType: "Country" },
    { value: "GE", label: "Georgia", targetType: "Country" },
    { value: "DE", label: "Germany", targetType: "Country" },
    { value: "GH", label: "Ghana", targetType: "Country" },
    { value: "GI", label: "Gibraltar", targetType: "Country" },
    { value: "GR", label: "Greece", targetType: "Country" },
    { value: "GL", label: "Greenland", targetType: "Country" },
    { value: "GD", label: "Grenada", targetType: "Country" },
    { value: "GP", label: "Guadeloupe", targetType: "Country" },
    { value: "GU", label: "Guam", targetType: "Country" },
    { value: "GT", label: "Guatemala", targetType: "Country" },
    { value: "GN", label: "Guinea", targetType: "Country" },
    { value: "GW", label: "Guinea-Bissau", targetType: "Country" },
    { value: "GY", label: "Guyana", targetType: "Country" },
    { value: "HT", label: "Haiti", targetType: "Country" },
    {
        value: "HM",
        label: "Heard Island and MacDonald Islands",
        targetType: "Country"
    },
    { value: "HN", label: "Honduras", targetType: "Country" },
    { value: "HK", label: "Hong Kong", targetType: "Country" },
    { value: "HU", label: "Hungary", targetType: "Country" },
    { value: "IS", label: "Iceland", targetType: "Country" },
    { value: "IN", label: "India", targetType: "Country" },
    { value: "ID", label: "Indonesia", targetType: "Country" },
    { value: "IQ", label: "Iraq", targetType: "Country" },
    { value: "IE", label: "Ireland", targetType: "Country" },
    { value: "IR", label: "Islamic Republic of Iran", targetType: "Country" },
    { value: "IL", label: "Israel", targetType: "Country" },
    { value: "IT", label: "Italy", targetType: "Country" },
    { value: "JM", label: "Jamaica", targetType: "Country" },
    { value: "JP", label: "Japan", targetType: "Country" },
    { value: "JO", label: "Jordan", targetType: "Country" },
    { value: "KZ", label: "Kazakhstan", targetType: "Country" },
    { value: "KE", label: "Kenya", targetType: "Country" },
    { value: "KI", label: "Kiribati", targetType: "Country" },
    { value: "KR", label: "Korea", targetType: "Country" },
    { value: "KW", label: "Kuwait", targetType: "Country" },
    { value: "KG", label: "Kyrgyzstan", targetType: "Country" },
    { value: "LA", label: "Laos", targetType: "Country" },
    { value: "LV", label: "Latvia", targetType: "Country" },
    { value: "LB", label: "Lebanon", targetType: "Country" },
    { value: "LS", label: "Lesotho", targetType: "Country" },
    { value: "LR", label: "Liberia", targetType: "Country" },
    { value: "LY", label: "Libyan Arab Jamahiriya", targetType: "Country" },
    { value: "LI", label: "Liechtenstein", targetType: "Country" },
    { value: "LT", label: "Lithuania", targetType: "Country" },
    { value: "LU", label: "Luxembourg", targetType: "Country" },
    { value: "MO", label: "Macao", targetType: "Country" },
    { value: "MK", label: "Macedonia", targetType: "Country" },
    { value: "MG", label: "Madagascar", targetType: "Country" },
    { value: "MW", label: "Malawi", targetType: "Country" },
    { value: "MY", label: "Malaysia", targetType: "Country" },
    { value: "MV", label: "Maldives", targetType: "Country" },
    { value: "ML", label: "Mali", targetType: "Country" },
    { value: "MT", label: "Malta", targetType: "Country" },
    { value: "MH", label: "Marshall Islands", targetType: "Country" },
    { value: "MQ", label: "Martinique", targetType: "Country" },
    { value: "MR", label: "Mauritania", targetType: "Country" },
    { value: "MU", label: "Mauritius", targetType: "Country" },
    { value: "YT", label: "Mayotte", targetType: "Country" },
    { value: "MX", label: "Mexico", targetType: "Country" },
    { value: "MD", label: "Moldova", targetType: "Country" },
    { value: "MC", label: "Monaco", targetType: "Country" },
    { value: "MN", label: "Mongolia", targetType: "Country" },
    { value: "MS", label: "Montserrat", targetType: "Country" },
    { value: "MA", label: "Morocco", targetType: "Country" },
    { value: "MZ", label: "Mozambique", targetType: "Country" },
    { value: "MM", label: "Myanmar", targetType: "Country" },
    { value: "NA", label: "Namibia", targetType: "Country" },
    { value: "NR", label: "Nauru", targetType: "Country" },
    { value: "NP", label: "Nepal", targetType: "Country" },
    { value: "NL", label: "Netherlands", targetType: "Country" },
    { value: "AN", label: "Netherlands Antilles", targetType: "Country" },
    { value: "NC", label: "New Caledonia", targetType: "Country" },
    { value: "NZ", label: "New Zealand", targetType: "Country" },
    { value: "NI", label: "Nicaragua", targetType: "Country" },
    { value: "NE", label: "Niger", targetType: "Country" },
    { value: "NG", label: "Nigeria", targetType: "Country" },
    { value: "NU", label: "Niue", targetType: "Country" },
    { value: "MP", label: "Northern Mariana Islands", targetType: "Country" },
    { value: "NO", label: "Norway", targetType: "Country" },
    { value: "OM", label: "Oman", targetType: "Country" },
    { value: "PK", label: "Pakistan", targetType: "Country" },
    { value: "PW", label: "Palau", targetType: "Country" },
    { value: "PA", label: "Panama", targetType: "Country" },
    { value: "PG", label: "Papua New Guinea", targetType: "Country" },
    { value: "PY", label: "Paraguay", targetType: "Country" },
    { value: "PE", label: "Peru", targetType: "Country" },
    { value: "PH", label: "Philippines", targetType: "Country" },
    { value: "PN", label: "Pitcairn", targetType: "Country" },
    { value: "PL", label: "Poland", targetType: "Country" },
    { value: "PT", label: "Portugal", targetType: "Country" },
    { value: "PR", label: "Puerto Rico", targetType: "Country" },
    { value: "QA", label: "Qatar", targetType: "Country" },
    { value: "RE", label: "Reunion", targetType: "Country" },
    { value: "RO", label: "Romania", targetType: "Country" },
    { value: "RU", label: "Russian Federation", targetType: "Country" },
    { value: "RW", label: "Rwanda", targetType: "Country" },
    { value: "SH", label: "Saint Helena", targetType: "Country" },
    { value: "KN", label: "Saint Kitts and Nevis", targetType: "Country" },
    { value: "LC", label: "Saint Lucia", targetType: "Country" },
    { value: "PM", label: "Saint Pierre and Miquelon", targetType: "Country" },
    {
        value: "VC",
        label: "Saint Vincent and the Grenadines",
        targetType: "Country"
    },
    { value: "WS", label: "Samoa", targetType: "Country" },
    { value: "SM", label: "San Marino", targetType: "Country" },
    { value: "ST", label: "Sao Tome and Principe", targetType: "Country" },
    { value: "SA", label: "Saudi Arabia", targetType: "Country" },
    { value: "SN", label: "Senegal", targetType: "Country" },
    { value: "SC", label: "Seychelles", targetType: "Country" },
    { value: "SL", label: "Sierra Leone", targetType: "Country" },
    { value: "SG", label: "Singapore", targetType: "Country" },
    { value: "SK", label: "Slovakia", targetType: "Country" },
    { value: "SI", label: "Slovenia", targetType: "Country" },
    { value: "SB", label: "Solomon Islands", targetType: "Country" },
    { value: "SO", label: "Somalia", targetType: "Country" },
    { value: "ZA", label: "South Africa", targetType: "Country" },
    { value: "GS", label: "South Georgia and...", targetType: "Country" },
    { value: "ES", label: "Spain", targetType: "Country" },
    { value: "LK", label: "Sri Lanka", targetType: "Country" },
    { value: "SD", label: "Sudan", targetType: "Country" },
    { value: "SR", label: "Suriname", targetType: "Country" },
    { value: "SJ", label: "Svalbard and Jan Mayen", targetType: "Country" },
    { value: "SZ", label: "Swaziland", targetType: "Country" },
    { value: "SE", label: "Sweden", targetType: "Country" },
    { value: "CH", label: "Switzerland", targetType: "Country" },
    { value: "SY", label: "Syrian Arab Republic", targetType: "Country" },
    { value: "TW", label: "Taiwan", targetType: "Country" },
    { value: "TJ", label: "Tajikistan", targetType: "Country" },
    { value: "TH", label: "Thailand", targetType: "Country" },
    { value: "TG", label: "Togo", targetType: "Country" },
    { value: "TK", label: "Tokelau", targetType: "Country" },
    { value: "TO", label: "Tonga", targetType: "Country" },
    { value: "TT", label: "Trinidad and Tobago", targetType: "Country" },
    { value: "TN", label: "Tunisia", targetType: "Country" },
    { value: "TR", label: "Turkey", targetType: "Country" },
    { value: "TM", label: "Turkmenistan", targetType: "Country" },
    { value: "TC", label: "Turks and Caicos Islands", targetType: "Country" },
    { value: "TV", label: "Tuvalu", targetType: "Country" },
    { value: "UG", label: "Uganda", targetType: "Country" },
    { value: "UA", label: "Ukraine", targetType: "Country" },
    { value: "AE", label: "United Arab Emirates", targetType: "Country" },
    { value: "GB", label: "United Kingdom", targetType: "Country" },
    {
        value: "TZ",
        label: "United Republic of Tanzania",
        targetType: "Country"
    },
    {
        value: "UM",
        label: "United States Minor Outlying Islands",
        targetType: "Country"
    },
    {
        value: "VI",
        label: "United States Virgin Islands",
        targetType: "Country"
    },
    { value: "UY", label: "Uruguay", targetType: "Country" },
    { value: "UZ", label: "Uzbekistan", targetType: "Country" },
    { value: "VU", label: "Vanuatu", targetType: "Country" },
    { value: "VA", label: "Vatican", targetType: "Country" },
    { value: "VE", label: "Venezuela", targetType: "Country" },
    { value: "VN", label: "Vietnam", targetType: "Country" },
    { value: "WF", label: "Wallis and Futuna", targetType: "Country" },
    { value: "EH", label: "Western Sahara", targetType: "Country" },
    { value: "YE", label: "Yemen", targetType: "Country" },
    { value: "ZM", label: "Zambia", targetType: "Country" },
    { value: "ZW", label: "Zimbabwe", targetType: "Country" }
];

export default countries;
