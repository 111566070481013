const referrersKeys = [
    {
        label: "Referrer",
        key: "url",
        sort: {
            desc: [
                {
                    orderBy: "ref",
                    direction: "DESC"
                },
                {
                    orderBy: "sessionsCount",
                    direction: "DESC"
                }
            ],
            asc: [
                {
                    orderBy: "ref",
                    direction: "ASC"
                },
                {
                    orderBy: "sessionsCount",
                    direction: "DESC"
                }
            ]
        }
    },
    {
        label: "Sessions",
        key: "sessionsCount",
        sort: {
            desc: [
                {
                    orderBy: "sessionsCount",
                    direction: "DESC"
                },
                {
                    orderBy: "ref",
                    direction: "ASC"
                }
            ],
            asc: [
                {
                    orderBy: "sessionsCount",
                    direction: "ASC"
                },
                {
                    orderBy: "ref",
                    direction: "ASC"
                }
            ]
        }
    },
    {
        label: "Avg. Duration",
        key: "avgDuration",
        sort: {
            desc: [
                {
                    orderBy: "avgDuration",
                    direction: "DESC"
                },
                {
                    orderBy: "sessionsCount",
                    direction: "DESC"
                },
                {
                    orderBy: "ref",
                    direction: "ASC"
                }
            ],
            asc: [
                {
                    orderBy: "avgDuration",
                    direction: "ASC"
                },
                {
                    orderBy: "sessionsCount",
                    direction: "DESC"
                },
                {
                    orderBy: "ref",
                    direction: "ASC"
                }
            ]
        }
    },
    {
        label: "Avg. Pages",
        key: "avgPages",
        sort: {
            desc: [
                {
                    orderBy: "avgPages",
                    direction: "DESC"
                },
                {
                    orderBy: "sessionsCount",
                    direction: "DESC"
                },
                {
                    orderBy: "ref",
                    direction: "ASC"
                }
            ],
            asc: [
                {
                    orderBy: "avgPages",
                    direction: "ASC"
                },
                {
                    orderBy: "sessionsCount",
                    direction: "DESC"
                },
                {
                    orderBy: "ref",
                    direction: "ASC"
                }
            ]
        }
    },
    {
        label: "Bounce Rate",
        key: "bounceRate",
        sort: {
            desc: [
                {
                    orderBy: "bounceRate",
                    direction: "DESC"
                },
                {
                    orderBy: "sessionsCount",
                    direction: "DESC"
                },
                {
                    orderBy: "ref",
                    direction: "ASC"
                }
            ],
            asc: [
                {
                    orderBy: "bounceRate",
                    direction: "ASC"
                },
                {
                    orderBy: "sessionsCount",
                    direction: "DESC"
                },
                {
                    orderBy: "ref",
                    direction: "ASC"
                }
            ]
        }
    },
    {
        label: "Top Pages",
        key: "topPages"
    },
    {
        label: "Entry Pages",
        key: "entryPages"
    },
    {
        label: "Exit Pages",
        key: "exitPages"
    }
];

export default referrersKeys;
