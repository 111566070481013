const mapSalesToRange = annualSales => {
    const floatS = parseFloat(annualSales);

    switch (true) {
        case floatS < 1000000:
            return "Under $1 Million";
        case floatS >= 1000000 && floatS < 5000000:
            return "$1 Million - $5 Million";
        case floatS >= 5000000 && floatS < 10000000:
            return "$5 Million - $10 Million";
        case floatS >= 10000000 && floatS < 25000000:
            return "$10 Million - $25 Million";
        case floatS >= 25000000 && floatS < 50000000:
            return "$25 Million - $50 Million";
        case floatS >= 50000000 && floatS < 100000000:
            return "$50 Million - $100 Million";
        case floatS >= 100000000 && floatS < 250000000:
            return "$100 Million - $250 Million";
        case floatS >= 250000000 && floatS < 500000000:
            return "$250 Million - $500 Million";
        case floatS >= 500000000 && floatS < 1000000000:
            return "$500 Million - $1 Billion";
        case floatS >= 1000000000 && floatS < 2500000000:
            return "$1 Billion - $2.5 Billion";
        case floatS >= 2500000000 && floatS < 5000000000:
            return "$2.5 Billion - $5 Billion";
        case floatS >= 5000000000:
            return "Over $5 Billion";
        default:
            return "";
    }
};

const REVENUERANGE_LABEL_MAP = {
    "$1 - $1M": "Under $1 Million",
    "$1M - $5M": "$1 Million - $5 Million",
    "$5M - $10M": "$5 Million - $10 Million",
    "$10M - $25M": "$10 Million - $25 Million",
    "$25M - $50M": "$25 Million - $50 Million",
    "$50M - $100M": "$50 Million - $100 Million",
    "$100M - $250M": "$100 Million - $250 Million",
    "$250M - $500M": "$250 Million - $500 Million",
    "$500M - $1B": "$500 Million - $1 Billion",
    "$1B - $2.5B": "$1 Billion - $2.5 Billion",
    "$2.5B - $5B": "$2.5 Billion - $5 Billion",
    "Over $5B": "Over $5 Billion"
};

const mapRevenueRangeToLabel = range => {
    const DEFAULT_LABEL = "";

    if (REVENUERANGE_LABEL_MAP.hasOwnProperty(range)) {
        return REVENUERANGE_LABEL_MAP[range];
    }

    return DEFAULT_LABEL;
};

const RevenueRange = ({ salary, revenueRange }) => {
    if (salary) {
        return mapSalesToRange(salary);
    }

    return mapRevenueRangeToLabel(revenueRange);
};

export default RevenueRange;
