import React from "react";

import "./HelpMenu.css";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import VideoCard from "views/components/VideoCard/VideoCard";
import iconOrders from "data/attributesOrder";
import { AppContext } from "views/components/App/app-context";

// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_navbar_sticky

// window.onscroll = function() {myFunction()};

// var navbar = document.getElementById("navbar");
// var sticky = navbar.offsetTop;

// function myFunction() {
//   if (window.pageYOffset >= sticky) {
//     navbar.classList.add("sticky")
//   } else {
//     navbar.classList.remove("sticky");
//   }
// }

const HelpMenu = () => {
    return (
        <AppContext.Consumer>
            {({ helpActive, hideForProd }) => (
                <React.Fragment>
                    {!helpActive && (
                        <ThomasIcon
                            attribute="HELP"
                            customClass="help-img"
                            openHelpMenu
                        />
                    )}

                    <div className={`help-menu ${helpActive ? "active" : ""}`}>
                        <div className="help-menu--header">
                            <ThomasIcon attribute="HELP" />
                            <div>WebTrax Help</div>
                            <div>
                                <ThomasIcon
                                    attribute="CLOSE"
                                    openHelpMenu
                                    customClass="help-menu--close"
                                />
                            </div>
                        </div>

                        {!hideForProd && (
                            <div className="help-menu--video">
                                <VideoCard />
                            </div>
                        )}

                        <div className="help-menu--icons">
                            <div className="help-menu--section-title">
                                Understanding Session Highlights
                            </div>
                            <div className="help-menu--icons-rows">
                                {Object.keys(iconOrders).map(ic => (
                                    <ThomasIcon
                                        key={ic}
                                        attribute={ic}
                                        hideToolTip
                                        showName
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </AppContext.Consumer>
    );
};

export default HelpMenu;
