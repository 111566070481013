import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import "./ChartCard.scss";
import React, { PureComponent } from "react";
import ChartHeroLoader from "views/sections/ChartHero/ChartHeroLoader";
import moment from "moment";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;
    const date = `${moment(payload.value).format("M/DD")}`;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {date}
        </text>
      </g>
    );
  }
}

const PDFRechartsCard = ({
  customToolTip,
  customClass,
  title,
  loading,
  data
}) => {
  return (
    <div className={` ${customClass}`}>
      <div
        className="title-and-legends-wrapper"
        style={{ paddingLeft: "1.7rem" }}
      >
        <h5 className="chart-legends">
          <div>
            <ThomasIcon attribute="LEGEND_CHIP_IMPRESSIONS" raw /> Impressions
          </div>
          <div>
            <ThomasIcon attribute="LEGEND_CHIP_CLICKS" raw /> Clicks
          </div>
        </h5>
      </div>

      {loading ? (
        <ChartHeroLoader customClass="depth-length-loader" />
      ) : (
        <ResponsiveContainer>
          <LineChart
            data={data}
            width={600}
            margin={{
              top: 20,
              right: 5,
              bottom: 50,
              left: 5
            }}
          >
            <CartesianGrid
              strokeDasharray="7 7"
              vertical={false}
            ></CartesianGrid>
            <XAxis
              dataKey="date"
              type="category"
              allowDuplicatedCategory={false}
              height={60}
              tick={<CustomizedAxisTick />}
              interval="preserveStartEnd"
              tickLine={false}
            />
            <YAxis yAxisId="left" allowDecimals={false} />
            <YAxis yAxisId="right" orientation="right" allowDecimals={false} />
            <Tooltip />
            <Line
              yAxisId="left"
              type="linear"
              dataKey="Impressions"
              stroke="#1b5070"
              strokeWidth={3}
              activeDot={{ r: 8 }}
              fill="#1b5070"
              isAnimationActive={false}
            />
            <Line
              yAxisId="right"
              type="linear"
              dataKey="clicks"
              stroke="#3cb4e8"
              strokeWidth={3}
              fill="#3cb4e8"
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default PDFRechartsCard;
