import React from "react";
import terms from "./terms";

class TermsAndConditions extends React.Component {
    constructor() {
        super();
        this.state = {
            bottom: false
        };
    }

    render() {
        const { bottom } = this.state;
        return (
            <React.Fragment>
                <div className="tac-header">
                    <b>Terms of use</b>
                </div>

                <div
                    className="terms-and-conditions"
                    onScroll={e => {
                        this.setState({
                            bottom:
                                e.target.scrollHeight - e.target.scrollTop <=
                                e.target.clientHeight + 20
                        });
                    }}
                >
                    {terms}
                </div>

                <div className="tac-accepter">
                    {!bottom && !this.props.accepted ? (
                        <label>
                            <span>
                                Scroll to the bottom to accept the terms of
                                service
                            </span>
                        </label>
                    ) : (
                        <React.Fragment>
                            <input
                                type="checkbox"
                                value={this.props.accepted}
                                onChange={this.props.onAccept}
                            />
                            <label>
                                <span>I agree to the terms of service</span>
                            </label>
                        </React.Fragment>
                    )}

                    {this.props.earlyPress &&
                        !this.props.accepted && (
                            <div className="tac-accept-notifier">
                                You need to accept the terms of service to
                                continue
                            </div>
                        )}
                </div>
            </React.Fragment>
        );
    }
}

export default TermsAndConditions;
