import React from "react";
import ThomasTable from "views/components/ThomasTable/ThomasTable";
import TableSectionContainer from "containers/TableSectionContainer";

import { NavLink } from "react-router-dom";

import { AppContext } from "views/components/App/app-context";

const TableSection = ({
    audienceDashboardButton,
    tableCols,
    contentFactory,
    mock,
    link,
    gClass,
    page
}) => {
    return (
        <React.Fragment>
            <TableSectionContainer page={page}>
                {({ data, hasNextPage, onLoadMore, loading }) => (
                    <ThomasTable
                        data={mock ? mock : data}
                        hasNextPage={hasNextPage}
                        tableCols={tableCols}
                        contentFactory={contentFactory}
                        onLoadMore={onLoadMore}
                        gClass={gClass}
                        link={link}
                        loading={loading}
                    />
                )}
            </TableSectionContainer>

            <div className="audience-buttons">
                {audienceDashboardButton && (
                    <NavLink to="/audience">
                        <button className="btn-primary btn-lg">
                            View Your Audience Dashboard
                        </button>
                    </NavLink>
                )}
                <NavLink to="/sessions">
                    <button className="btn-primary btn-lg">
                        View All Sessions
                    </button>
                </NavLink>

                <AppContext.Consumer>
                    {({ toggleHelp }) => (
                        <div style={{ cursor: "pointer" }} onClick={toggleHelp}>
                            View Legend
                        </div>
                    )}
                </AppContext.Consumer>
            </div>
        </React.Fragment>
    );
};

export default TableSection;
