import React from "react";
import RouterLink from "views/components/RouterLink/RouterLink";

class Legacy extends React.Component {
  constructor() {
    super();
    this.state = { password: "" };

    this.refPassword = React.createRef();
  }

  componentDidMount() {
    this.refPassword.current.focus();
  }

  // add lifecycle method to redirect user to /login page if first point of entry is not /login. Do this because {email} is blank on top of 'Not You?' and it looks weird. =(

  render() {
    const {
      onSignIn,
      email,
      loading,
      error,
      clearError,
      renderFormValidationErrors
    } = this.props;

    return (
      <React.Fragment>
        <h2 className="text-center pt-0">WebTrax sign in</h2>
        <p className="text-center">
          It looks like you've entered a WebTrax username. Please enter the
          password associated with this account.
        </p>
        <div className="form-bg form-short">
          {error && (
            <p
              className="alert alert-danger hide"
              id="errMsg"
              style={{ display: "block" }}
            >
              {error}
            </p>
          )}

          <form
            onSubmit={e => {
              e.preventDefault();
              if ((this.state.password || "").trim().length === 0) {
                renderFormValidationErrors("Please enter a valid password");
              } else {
                onSignIn(email, this.state.password);
              }
            }}
          >
            <p className="text-center">
              <b>{email}</b>
              <br />
              <RouterLink
                urlClickHandler={() => {
                  clearError();
                  return `/login`;
                }}
              >
                {({ onExecuteLink }) => {
                  return (
                    <span
                      className="forgot-password-link"
                      onClick={onExecuteLink}
                    >
                      Not You?
                    </span>
                  );
                }}
              </RouterLink>
            </p>
            <div
              className="form-group"
              style={{
                position: "relative"
              }}
            >
              <input
                ref={this.refPassword}
                type="password"
                name="wtpassword"
                className="form-control"
                placeholder="Enter your password"
                value={this.state.password}
                onChange={e => {
                  this.setState({
                    password: e.target.value
                  });
                }}
              />
            </div>

            <div className="form-group flex-end-btn">
              <button type="submit" className="btn btn-primary">
                {loading ? (
                  <div className="spinner loading-white">
                    <div className="rect1" />
                    <div className="rect2" />
                    <div className="rect3" />
                    <div className="rect4" />
                    <div className="rect5" />
                  </div>
                ) : (
                  "Sign In"
                )}
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default Legacy;
