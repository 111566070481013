import React from "react";

const BgLeftImage = () => (
    <div
        className="reg-page__secondary"
        style={{
            backgroundImage: `url(${
                process.env.PUBLIC_URL
            }/img/bg-request-webtraxs.jpg)`
        }}
    >
        <a
            style={{
                display: "flex",
                justifyContent: "center"
            }}
            href="https://thomasnet.com"
        >
            <img
                className="bg-image-logo"
                src="https://cdn40.thomasnet.com/img40/thomas-logo-blue.svg"
                alt="Thomas Logo"
            />
        </a>

        <p className="bg-image-text">
            Thomas WebTrax is being made available for free for companies who
            qualify for inclusion in the Thomas Network.
            <br />
            <br />
            <a href="https://business.thomasnet.com/webtrax/" className="pt-2">
                <button className="btn btn-primary">Learn More</button>
            </a>
        </p>
    </div>
);

export default BgLeftImage;
