const browsersKeys = [
    {
        label: "Browser/ OS",
        key: "browserIndex",
        sort: {
            desc: [
                {
                    orderBy: "browserIndex",
                    direction: "DESC"
                },
                {
                    orderBy: "sessionsct",
                    direction: "DESC"
                }
            ],
            asc: [
                {
                    orderBy: "browserIndex",
                    direction: "ASC"
                },
                {
                    orderBy: "sessionsct",
                    direction: "DESC"
                }
            ]
        }
    },
    {
        label: "Pct",
        key: "percentage",
        sort: {
            desc: [
                {
                    orderBy: "sessionsct",
                    direction: "DESC"
                },
                {
                    orderBy: "browserIndex",
                    direction: "ASC"
                }
            ],
            asc: [
                {
                    orderBy: "sessionsct",
                    direction: "ASC"
                },
                {
                    orderBy: "browserIndex",
                    direction: "ASC"
                }
            ]
        }
    },
    {
        label: "Sessions",
        key: "sessionsct",
        sort: {
            desc: [
                {
                    orderBy: "sessionsct",
                    direction: "DESC"
                },
                {
                    orderBy: "browserIndex",
                    direction: "ASC"
                }
            ],
            asc: [
                {
                    orderBy: "sessionsct",
                    direction: "ASC"
                },
                {
                    orderBy: "browserIndex",
                    direction: "ASC"
                }
            ]
        }
    }
];

export default browsersKeys;
