import React from "react";

const LoadingThomasTable = () => {
    const loadingRows = [];
    for (let i = 0; i < 25; i++) {
        loadingRows.push(
            <div
                key={i}
                className="thomas-table--row thomas-table__grid analytics-table analytics-toppages"
            >
                <div className="animated-background row-loader">
                    <div className="background-masker header-top" />
                </div>
            </div>
        );
    }
    return (
        <section className="thomas-table table">
            <div className="thomas-table--header thomas-table__grid analytics-table analytics-toppages">
                <div className="thomas-table--title">Page</div>
                <div className="thomas-table--title">Funnel Analysis</div>
                <div className="thomas-table--title">Count</div>
            </div>
            {loadingRows}
        </section>
    );
};

export default LoadingThomasTable;
