import React from "react";
import { Link } from "react-router-dom";
const REGEX_EMAIL = /^\S+@\S+$/g;

class LegacySignIn extends React.Component {
    constructor() {
        super();
        this.state = { email: "", error: "" };

        this.refEmail = React.createRef();

        this.checkForValidEmail = this.checkForValidEmail.bind(this);
    }

    checkForValidEmail() {
        if (!this.state.email.match(REGEX_EMAIL)) {
            this.setState({
                error: "Please enter a valid email"
            });
            this.nextPage("login/legacy");
        } else {
            this.props.onContinue(this.state.email);
        }
    }

    componentDidMount() {
        this.refEmail.current.focus();
    }

    render() {
        const { wtusername } = this.props;
        const { error } = this.state;
        return (
            <React.Fragment>
                <h2 className="text-center pt-0">WebTrax sign in</h2>
                <p className="text-center">
                    Please associate your Thomasnet User account with this
                    WebTrax username.
                </p>
                <div className="form-bg form-short">
                    {error && (
                        <p
                            className="alert alert-danger hide"
                            id="errMsg"
                            style={{ display: "block" }}
                        >
                            {error}
                        </p>
                    )}

                    <p className="text-center">
                        <b>{wtusername}</b>
                    </p>

                    <div
                        className="form-group"
                        style={{
                            position: "relative"
                        }}
                    >
                        <label htmlFor="exampleInputEmail1">
                            Please associate an email with your account:
                        </label>
                        <input
                            name="email"
                            autoComplete="username"
                            className="form-control"
                            placeholder="Enter your email to get started"
                            value={this.state.email}
                            ref={this.refEmail}
                            onChange={e =>
                                this.setState({
                                    email: e.target.value
                                })
                            }
                            onKeyPress={e => {
                                if (e.keyCode || e.which === 13) {
                                    this.checkForValidEmail();
                                }
                            }}
                        />
                        <Link
                            className="forgot-password-link"
                            to="/login/create"
                        >
                            Need to create an account?
                        </Link>
                    </div>

                    <div className="form-group flex-end-btn">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.checkForValidEmail}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LegacySignIn;
