import * as React from "react";
import moment from "moment";
import CampaignActivity from "./CampaignActivity/CampaignActivity";
import CampaignContainer from "containers/CampaignContainer";
import PieChart from "views/components/PieChart/PieChart";
import ChartCard from "views/components/ChartCard/ChartCard";
import RechartsCard from "views/components/ChartCard/RechartsCard";
import VisitorLocation from "views/components/VisitorLocation/VisitorLocation";
import CampaignTopCountCardPie from "views/components/TopCountCard/CampaignTopCountCardPie";
import BlueprintTable from "views/components/BlueprintTable/BlueprintTable";
import mockLegend from "views/components/ChartCard/mockLegend";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
// import { PDFContainer } from "containers/PDFContainer";
import DateRangeContainer from "containers/DateRangeContainer";
import { Spinner } from "views/components/Loading/Spinner";
import PDFExport from "views/components/ThomasIcon/ThomasIcon";
import PageUtility from "views/sections/PageUtility/PageUtility";
import visitor from "src/data/visitor";

import { uniqBy } from "lodash";

export type downloadPdfFunction = () => any;

const mockHeadings = [
  {
    key: "companyName",
    title: "Organization"
  },
  {
    key: "industry",
    title: "Industry"
  },
  {
    key: "revenueRange",
    title: "Revenue Range"
  },
  {
    key: "employeeRange",
    title: "Company Size"
  },
  {
    key: "city",
    title: "City"
  },
  {
    key: "state",
    title: "State"
  }
];

// Both are in days
const TIMEFRAME_FOR_WEEKS = 20;
const TIMEFRAME_FOR_MONTHS = 120;

interface Props {
  campaignId: any;
}

const ViewCampaign = ({ campaignId }: Props) => {
  return (
    <>
      <DateRangeContainer>
        {({ startDate, endDate }: any) => {
          return (
            <>
              <PageUtility
                startDate={startDate}
                endDate={endDate}
                campaignId={campaignId}
              />
              <section className="page-content page-content--single-column">
                <div className="container" style={{ padding: "3rem 2rem" }}>
                  <CampaignContainer
                    campaignId={campaignId}
                    queryKey="campaign-basics"
                  >
                    {({ campaign, loading }: any) => {
                      const {
                        campaignName,
                        companyInfo,
                        verticalName,
                        startDate,
                        endDate,
                        status,
                        campaignActivity,
                        creatives
                      } = campaign;

                      const name = companyInfo && companyInfo.name;
                      const verticalNameDisplay = `Premium Vertical Sponsorship`;

                      return (
                        <>
                          <header
                            className="page-header"
                            style={{ justifyContent: "flex-start" }}
                          >
                            <h1 className="page-header__header">{name}</h1>
                            <div
                              className="page-header__active"
                              style={{ paddingRight: 0 }}
                            >
                              |
                            </div>
                            <div className="page-header__active">{status}</div>
                            <p className="page-header__subheader" />
                          </header>
                          <CampaignActivity
                            verticalName={`${verticalNameDisplay}: ${verticalName}`}
                            loading={loading}
                            campaignDuration={`${moment(startDate).format(
                              "L"
                            )} - ${moment(endDate).format("L")}`}
                            adImpressions={
                              (campaignActivity &&
                                campaignActivity.adImpressions) ||
                              0
                            }
                            clicks={
                              (campaignActivity && campaignActivity.clicks) || 0
                            }
                            pvsCreatives={creatives}
                          />
                        </>
                      );
                    }}
                  </CampaignContainer>

                  {/* <section className="campaigns_card-group"> */}
                  <div className="campaigns_card-group">
                    <div className="card content-card campaigns-card__impressions-and-clicks">
                      <CampaignContainer
                        campaignId={campaignId}
                        queryKey="campaign-activity"
                      >
                        {({ campaign, loading }: any) => {
                          const { campaignActivity } = campaign;
                          const activityByDate =
                            campaignActivity && campaignActivity.activityByDate;
                          activityByDate &&
                            activityByDate.map((cs: any) => {
                              cs.Impressions = cs.adImpressions;
                            });

                          return (
                            <RechartsCard
                              title="Impressions & Clicks"
                              customClass="impressions custom-session-graph--wrapper"
                              data={activityByDate}
                              loading={loading}
                              customToolTip="byDate"
                            />
                          );
                        }}
                      </CampaignContainer>
                    </div>
                    {false && (
                      <div className="card content-card campaigns-card">
                        <div className="campaign-header">
                          <CampaignContainer
                            campaignId={campaignId}
                            queryKey="campaign-locations"
                          >
                            {({ campaign, onUpdate, loading }: any) => {
                              const { visitorLocations = [] } = campaign;

                              return (
                                <>
                                  <VisitorLocation
                                    center={{ lat: 38, lng: -95 }}
                                    zoom={3}
                                    title="US Visitor Locations"
                                    data={visitorLocations}
                                    onUpdate={onUpdate}
                                    international={false}
                                    customClass={"campaigns-dash-map"}
                                  />
                                  <p className="visitor-pins-disclaimer">
                                    Pins displayed represent visitors with known
                                    geography.
                                  </p>
                                </>
                              );
                            }}
                          </CampaignContainer>
                        </div>
                      </div>
                    )}

                    <div className="card content-card campaigns-card">
                      <div className="campaign-header">
                        <CampaignContainer
                          campaignId={campaignId}
                          queryKey="visitors-by-device"
                        >
                          {({ campaign, loading }: any) => {
                            const { visitorsByDevice = [] } = campaign;
                            return (
                              <CampaignTopCountCardPie
                                link={false}
                                showFraction={false}
                                loading={false}
                                data={visitorsByDevice}
                                title="Audience by Device"
                                customPieProps={{}}
                              />
                            );
                          }}
                        </CampaignContainer>
                      </div>
                    </div>
                  </div>

                  {false && (
                    <div className="campaigns_card-group--right">
                      <div className="card content-card campaigns-card">
                        <div className="campaign-header">
                          <h4>Audience By Industry</h4>
                          <CampaignContainer
                            campaignId={campaignId}
                            queryKey="visitors-by-industry"
                          >
                            {({ campaign, loading }: any) => {
                              const { visitorsByIndustry = [] } = campaign;

                              return (
                                <PieChart data={visitorsByIndustry} legend />
                              );
                            }}
                          </CampaignContainer>
                        </div>
                      </div>

                      <div className="card content-card campaigns-card">
                        <div className="campaign-header">
                          <h4>Audience By Revenue Range</h4>
                          <CampaignContainer
                            campaignId={campaignId}
                            queryKey="visitors-by-revenue"
                          >
                            {({ campaign, loading }: any) => {
                              const { visitorsByRevenueRange = [] } = campaign;

                              return (
                                <PieChart
                                  data={visitorsByRevenueRange}
                                  legend
                                />
                              );
                            }}
                          </CampaignContainer>
                        </div>
                      </div>

                      <div className="card content-card campaigns-card">
                        <div className="campaign-header">
                          <h4>Audience By Company Size</h4>
                          <CampaignContainer
                            campaignId={campaignId}
                            queryKey="visitors-by-employee-range"
                          >
                            {({ campaign, loading }: any) => {
                              const { visitorsByEmployeeRange = [] } = campaign;

                              return (
                                <PieChart
                                  data={visitorsByEmployeeRange}
                                  legend
                                />
                              );
                            }}
                          </CampaignContainer>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* </section> */}
                  {true && (
                    <CampaignContainer
                      campaignId={campaignId}
                      queryKey="top-visitors"
                    >
                      {({ campaign, loading }: any) => {
                        const { topVisitors = [] } = campaign;

                        const [clickers, nonclickers] = topVisitors.reduce(
                          ([clickers, nonclickers]: any, curr: any) => {
                            if (curr.clicks > 0) {
                              clickers.push({
                                ...curr,
                                companyName: () => (
                                  <div className="campaigns-dash-table__company">
                                    <ThomasIcon attribute="CAMPAIGN" />
                                    {curr.organization}
                                  </div>
                                )
                              });
                            } else {
                              nonclickers.push({
                                ...curr,
                                companyName: curr.organization
                              });
                            }

                            return [clickers, nonclickers];
                          },
                          [[], []]
                        );

                        nonclickers.sort((a: any, b: any) => {
                          return b.organization - a.organization;
                        });

                        const sortedData = clickers
                          .sort((a: any, b: any) => {
                            let textA = a.organization.toUpperCase();
                            let textB = b.organization.toUpperCase();
                            return textA < textB ? -1 : textA > textB ? 1 : 0;
                          })
                          .concat(
                            nonclickers.sort((a: any, b: any) => {
                              let textA = a.organization.toUpperCase();
                              let textB = b.organization.toUpperCase();
                              return textA < textB ? -1 : textA > textB ? 1 : 0;
                            })
                          );

                        const uniqueDate = uniqBy(sortedData, "organization");

                        console.log(uniqueDate);

                        return (
                          uniqueDate.length > 0 && (
                            <>
                              <h1 className="section-header__header">
                                Most Identifiable Campaign Audience
                              </h1>
                              <p className="section-header__subheader">
                                Sample of identifiable organizations that viewed
                                this ad campaign
                                <br />
                                <small>
                                  Displaying <b>1 to {uniqueDate.length}</b> of{" "}
                                  {uniqueDate.length} results
                                </small>
                              </p>

                              <BlueprintTable
                                data={uniqueDate}
                                headings={mockHeadings}
                                onPageUpdate={() => {
                                  console.log("updatingPage");
                                }}
                                customClass="advanced-table content-card"
                              />
                            </>
                          )
                        );
                      }}
                    </CampaignContainer>
                  )}
                </div>
              </section>
            </>
          );
        }}
      </DateRangeContainer>
    </>
  );
};

export default ViewCampaign;
