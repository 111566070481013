const singleDurationKeys = [
    {
        label: "Visitor",
        key: "visitorName"
    },
    {
        label: "Referrer",
        key: "referrer"
    },
    {
        label: "Date",
        key: "date"
    },
    {
        label: "Time",
        key: "time"
    },
    {
        label: "Duration",
        key: "duration"
    },
    {
        label: "Pages",
        key: "pageViews"
    },
    {
        label: "Visitor Info",
        key: "openDetails"
    }
];

export default singleDurationKeys;
