import React from "react";
import CopyrightFooter from "../Registration/CopyrightFooter";
import SiteHeader from "views/layouts/SiteHeader/SiteHeader";

const NoClaims = () => (
  <React.Fragment>
    <SiteHeader />
    <div
      style={{
        width: "100vw",
        height: "calc(100vh - 9rem)",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "white"
      }}
    >
      <div style={{ margin: "auto 15rem", alignSelf: "center" }}>
        <h2>
          This Thomasnet user profile is not linked to any Thomas WebTrax
          accounts.
        </h2>
        <p>
          If you are a Thomas client, please contact your Certified Thomas
          Partner for assistance, or the Client Relations Help Desk.
        </p>
        <p>
          If you are not a Thomas client, you must register your company for{" "}
          <a href="/register">WebTrax.</a>
        </p>
      </div>
    </div>
    <div style={{ backgroundColor: "white" }}>
      <CopyrightFooter />
    </div>
  </React.Fragment>
);

export default NoClaims;
