import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PageSteps from "views/sections/WelcomeScreen/PageSteps";

const Screen2 = ({ user, onNextStep, onNavPage }) => {
  const acctid = (user && user.account && user.account.id) || "none";
  return (
    <React.Fragment>
      <header className="welcome-screen__header">
        <h1>Add Analytics Code to Your Site</h1>
      </header>
      <div className="welcome-screen__body">
        <h3>
          Please implement this asychronous tracking code across your website
        </h3>
        <div className="welcome-screen–analytics">
          <p>
            To begin, click the clipboard icon to copy the "JavaScript tracking
            snippet", paste the snippet near the top of the {`<head>`} tag and
            before any other script or CSS tags. We recommend adding it to your
            website template so that it automatically goes into each page of
            your site.
          </p>

          <div className="card--codeblock">
            {`<!-- Begin WebTrax -->`}
            <br />
            {`<script type="text/javascript"> var wto = wto || []; wto.push(['setWTID', '${acctid}']); wto.push(['webTraxs']);(function() {
                        var wt = document.createElement('script');
                        wt.src = document.location.protocol + '//www.webtraxs.com/wt.php';
                        wt.type = 'text/javascript';
                        wt.async = true;
                        var s = document.getElementsByTagName('script')[0];
                        s.parentNode.insertBefore(wt, s);
                        })();</script><noscript><img src="https://www.webtraxs.com/webtraxs.php?id=${acctid}&st=img" alt="" /></noscript>`}
            <br />
            {`<!-- End WebTrax -->`}

            <CopyToClipboard
              text={`<!-- Begin WebTrax --><script type="text/javascript"> var wto = wto || []; wto.push(['setWTID', '${acctid}']); wto.push(['webTraxs']);(function() {
                        var wt = document.createElement('script');
                        wt.src = document.location.protocol + '//www.webtraxs.com/wt.php';
                        wt.type = 'text/javascript';
                        wt.async = true;
                        var s = document.getElementsByTagName('script')[0];
                        s.parentNode.insertBefore(wt, s);
                        })();</script><noscript><img src="https://www.webtraxs.com/webtraxs.php?id=${acctid}&st=img" alt="" /></noscript><!-- End WebTrax -->`}
            >
              <svg
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                className="icon copy-tagging-code"
              >
                <title>ico-copy-clipboard</title>
                <path
                  d="M15.222 2c-1.227 0-2.236 1.028-2.236 2.28v.976h-1.248l-.798 1.302h8.565l-.799-1.302h-1.248v-.977C17.458 3.028 16.45 2 15.222 2zm0 1.302c.542 0 .959.425.959.977v.977h-1.917v-.977c0-.552.416-.977.958-.977zM5 6.907V29a1 1 0 0 0 1 1h18.444a1 1 0 0 0 1-1v-2.581h-10.86c-.335 0-.64-.31-.64-.652V12.093a.67.67 0 0 1 .58-.651h9.643a.64.64 0 0 1 .449.183l.828.844V6.907a1 1 0 0 0-1-1h-3.841l.589.957c.123.2.13.452.018.66a.637.637 0 0 1-.557.336H9.792a.637.637 0 0 1-.558-.336.662.662 0 0 1 .018-.66l.59-.957H6a1 1 0 0 0-1 1zm10.222 6.837v10.372a1 1 0 0 0 1 1H27a1 1 0 0 0 1-1v-6.163h-4.472c-.335 0-.64-.31-.64-.65v-4.559h-6.666a1 1 0 0 0-1 1zm8.945-.735v3.642h3.574l-3.574-3.642zm-6.479 3.642h.03a.616.616 0 0 1 .06 0h3.194c.338-.005.649.307.649.651 0 .344-.311.656-.649.651h-3.194c-.335.025-.655-.27-.679-.61-.024-.34.255-.668.589-.692zm0 2.605h.03a.616.616 0 0 1 .06 0h7.666c.338-.005.65.307.65.651 0 .344-.312.656-.65.651h-7.666c-.335.024-.655-.27-.679-.61-.024-.341.255-.668.589-.692zm0 2.604h.03a.616.616 0 0 1 .06 0h7.666c.338-.004.65.307.65.652 0 .344-.312.656-.65.65h-7.666c-.335.025-.655-.269-.679-.61-.024-.34.255-.667.589-.692z"
                  fill="currentColor"
                  fillRule="nonzero"
                />
              </svg>
            </CopyToClipboard>
          </div>

          <p>
            If WebTrax tracking code is already implemented on your site it is
            not necessary to add the above code again.
          </p>

          {/* <p>
                        <b>Alternative async tracking snippets</b>
                        <br />
                        While the JavaScript tracking snippet described above
                        ensures the script will be loaded and executed
                        asynchronously on all browsers, it has the disadvantage
                        of not allowing modern browsers to preload the script.
                    </p> */}
        </div>
      </div>
      <footer className="welcome-screen__footer">
        <PageSteps step={2} onNavClick={onNavPage} />
        <button
          className="btn btn-primary btn-lg next-step-btn"
          onClick={onNextStep}
        >
          Next
        </button>
      </footer>
    </React.Fragment>
  );
};

export default Screen2;
