import React from "react";

import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import RevenueRange from "views/components/RevenueRange/RevenueRange";

import iconOrders from "data/attributesOrder";

const audienceTableFactory = (content, prop) => {
  switch (true) {
    case prop === "title":
      const attributesWithSort = content.node.attributes
        ? content.node.attributes.map(a => {
            return { attr: a, sortNum: iconOrders[a] };
          })
        : null;

      return (
        <React.Fragment>
          <div>{content.node[prop]}</div>
          <div className="thomas-table--icons">
            {attributesWithSort &&
              attributesWithSort
                .sort((a, b) => a.sortNum - b.sortNum)
                .map(k => <ThomasIcon key={k.attr} attribute={k.attr} />)}
          </div>
        </React.Fragment>
      );
    case prop === "companyAnnualSales":
      return <RevenueRange salary={content.node[prop]} />;

    default:
      return content.node[prop];
  }
};

export default audienceTableFactory;
