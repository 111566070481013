import React from "react";
import moment from "moment";

import "./FunnelAnalysis.css";
import TopCountCard from "views/components/TopCountCard/TopCountCard";
import TopReferrers from "./TopReferrers";

const FunnelAnalysis = ({ data, query, referrers, searchTerms }) => {
    return (
        <div className="funnel-analysis">
            {data && (
                <div className="funnel-analysis--summary">
                    <div className="funnel-analysis--summary-item">
                        Date Range:{" "}
                        <span>
                            {moment(query.startDate).format("MMM D, YYYY")} -{" "}
                            {moment(query.endDate).format("MMM D, YYYY")}
                        </span>
                    </div>
                    <div className="funnel-analysis--summary-item">
                        Page ID: <span>{data.id}</span>
                    </div>
                    <div className="funnel-analysis--summary-item">
                        Page Requests: <span>{data.requestsCount}</span>
                    </div>
                    <div className="funnel-analysis--summary-item">
                        Page Sessions: <span>{data.sessionsCount}</span>
                    </div>
                    <div className="funnel-analysis--summary-item">
                        Entries: <span>{data.entryCount}</span>
                    </div>
                    <div className="funnel-analysis--summary-item">
                        Exits: <span>{data.exitCount}</span>
                    </div>
                    <div className="funnel-analysis--summary-item">
                        Bounces:{" "}
                        <span>
                            {`${data.bounces} (${(
                                (data.bounces * 100) /
                                (data.entryCount === 0 ? 1 : data.entryCount)
                            ).toFixed(1)} %)`}
                        </span>
                    </div>
                </div>
            )}

            <div className="funnel-analysis--searchterms">
                <div className="funnel-analysis--sessions">
                    Search Term/Category
                    <span>Sessions</span>
                </div>
                <TopCountCard
                    customClass="funnel-analysis-top-card"
                    data={searchTerms && searchTerms.edges}
                    itemProp="term"
                    valueProp="termct"
                    showMoreLimit={5}
                />
            </div>

            <TopReferrers
                title="Top Referrers"
                data={referrers && referrers.edges}
                total={data.sessionsCount}
            />
        </div>
    );
};

export default FunnelAnalysis;
