import React from "react";

const year = new Date().getFullYear();

const CopyrightFooter = () => (
  <p className="text-center font-size-xs mt-0">
    &copy; {year} Thomas Publishing Company. All rights reserved. See
    <a href="https://www.thomasnet.com/terms.html">
      Terms &amp; Conditions
    </a>{" "}
    and <a href="https://www.thomasnet.com/privacy.html">Privacy Statement.</a>
    <br />
    ThomasNet is A Registered Trademark Of{" "}
    <a href="https://www.thomasnet.com">Thomas Publishing Company.</a>
  </p>
);

export default CopyrightFooter;
