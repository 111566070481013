import React from "react";
import { DropdownList, Multiselect } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";

import "./DropDown.css";

class DropDown extends React.Component {
    constructor() {
        super();
        this.state = { open: false };
    }
    render() {
        const {
            title,
            label,
            subLabel,
            disabled,
            items,
            onChange,
            customClass,
            multi,
            groupBy = null,
            groupComponent = null,
            itemComponent,
            initValue,
            value
        } = this.props;
        const { open } = this.state;

        return (
            <div className={`${customClass ? customClass : ""}`}>
                {label && (
                    <div className="wt-textbox__label">
                        {label}{" "}
                        {subLabel && (
                            <div className="wt-textbox__sublabel">
                                ({subLabel})
                            </div>
                        )}
                    </div>
                )}

                {multi ? (
                    <Multiselect
                        className={`thomas-dropdown`}
                        data={items}
                        groupBy={groupBy}
                        value={null}
                        placeholder={title}
                        textField="label"
                        onChange={value => onChange(value)}
                        disabled={disabled}
                        filter={(item, value) =>
                            Object.values(item).some(
                                i =>
                                    i &&
                                    (i + "")
                                        .toLowerCase()
                                        .indexOf(value.toLowerCase()) > -1
                            )
                        }
                    />
                ) : (
                    <DropdownList
                        className={`thomas-dropdown`}
                        data={items}
                        groupBy={groupBy}
                        groupComponent={groupComponent}
                        itemComponent={itemComponent}
                        placeholder={title}
                        textField="label"
                        defaultValue={initValue}
                        value={value}
                        onChange={value => {
                            onChange(value);
                        }}
                        disabled={disabled}
                        filter={items.length > 10 ? "contains" : null}
                        open={open}
                        onToggle={() => {
                            this.setState({ open: !open });
                        }}
                    />
                )}
            </div>
        );
    }
}

export default DropDown;
