import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import SessionQueryContainer from "containers/SessionQueryContainer";

const MY_QUERY = gql`
    query getBothSessionsByDate(
        $query: SessionQueryInput
        $targetedQuery: SessionQueryInput
    ) {
        allSessions: sessionsByDate(query: $query) {
            sessions {
                ydt
                sessionsCount
            }
            totalCount
            avgDuration
            avgPages
        }

        targetedSessions: sessionsByDate(query: $targetedQuery) {
            sessions {
                ydt
                sessionsCount
            }
        }
    }
`;

const matchDates = (keys, sessions) => {
    return keys.map(key => {
        const s = sessions.find(s => s.ydt === key);

        return (
            s || {
                sessionsCount: 0,
                ydt: key
            }
        );
    });
};

const CharHeroContainer = ({ children, page, ...props }) => (
    <SessionQueryContainer page={page}>
        {({ query }) => {
            const targetedQuery = Object.assign({ useAllTargets: true }, query);

            return (
                <Query query={MY_QUERY} variables={{ query, targetedQuery }}>
                    {({
                        error,
                        loading,
                        data: { allSessions = {}, targetedSessions = {} } = {}
                    }) => {
                        if (error) {
                            console.error(error);
                            return null;
                        }

                        const allSessionsData = allSessions.sessions || [];
                        const targetedSessionsData = matchDates(
                            allSessionsData.map(s => s.ydt),
                            targetedSessions.sessions || []
                        );

                        return (
                            children &&
                            children({
                                ...props,
                                loading,
                                data: {
                                    allSessions: allSessionsData,
                                    targetedSessions: targetedSessionsData,
                                    totalCount: allSessions.totalCount,
                                    avgDuration: allSessions.avgDuration,
                                    avgPages: allSessions.avgPages
                                }
                            })
                        );
                    }}
                </Query>
            );
        }}
    </SessionQueryContainer>
);

export default CharHeroContainer;
