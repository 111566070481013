const exitPagesKeys = [
    {
        label: "Page",
        key: "url",
        sort: {
            desc: [
                {
                    orderBy: "url",
                    direction: "DESC"
                },
                {
                    orderBy: "exitCount",
                    direction: "DESC"
                }
            ],
            asc: [
                {
                    orderBy: "url",
                    direction: "ASC"
                },
                {
                    orderBy: "exitCount",
                    direction: "DESC"
                }
            ]
        }
    },
    {
        label: "Exits",
        key: "exitCount",
        sort: {
            desc: [
                {
                    orderBy: "exitCount",
                    direction: "DESC"
                },
                {
                    orderBy: "url",
                    direction: "ASC"
                }
            ],
            asc: [
                {
                    orderBy: "exitCount",
                    direction: "ASC"
                },
                {
                    orderBy: "url",
                    direction: "ASC"
                }
            ]
        }
    }
];

export default exitPagesKeys;
