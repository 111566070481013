import React from "react";

import "./TableRowDetails.css";

const TableRowDetails = ({ title, data, onClose, detailsComponent }) => {
    return (
        <div className="table-row-details">
            <div className="table-row-details--header">
                {title}
                {/* <div className="table-row-details--close" onClick={onClose}>
                    X
                </div> */}
            </div>
            <div className="table-row-details--content">
                {detailsComponent ? (
                    detailsComponent(data)
                ) : (
                    <div className="table-details--rows">
                        <div className="table-details--row">
                            <b>Caller Id:</b>
                            <div>(832) 209-1944</div>
                        </div>
                        <div className="table-details--row">
                            <b>Caller Duration:</b>
                            <div>00:01:22</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TableRowDetails;
