import { TypeNames, FilterTypes } from "./constants";

class Page {
    constructor({ id, activeFilters = [], useAllTargets = false }) {
        Object.assign(this, {
            id,
            activeFilters,
            useAllTargets,
            __typename: TypeNames.SESSION_CONFIG_TYPE_NAME
        });
    }
}

class Filter {
    constructor(props) {
        Object.assign(this, {
            ...props,
            shortLabel: props.shortLabel || props.label,
            targetType: props.targetType || null,
            __typename: TypeNames.ACTIVE_FILTERS_TYPE_NAME,
            value: props.value || props.label
        });
    }
}

const AddAllTargetsFilter = new Filter({
    value: "ALL_TARGETS",
    shortLabel: "All Targets",
    filterType: FilterTypes.TARGET,
    label: "All Targets"
});

export { Filter, Page, AddAllTargetsFilter };

const defaultActiveFilters = {
    all: [],
    thomasnet: [
        // new Filter({
        //     label: "Thomasnet",
        //     value: "www.thomasnet.com",
        //     filterType: FilterTypes.base
        // }),
        new Filter({
            value: "THOMASNET_ACTIVITY",
            filterType: FilterTypes.BASE,
            label: "ThomasNet Activity"
        })
    ],
    targeted: [
        // new Filter({
        // 	value: "HIGH_VALUE_PAGE",
        // 	filterType: FilterTypes.ACTION,
        // 	label: "Viewed High Value Page"
        // })
    ],
    navigator: [
        // new Filter({
        //     value: "KEYWORD_SEARCH",
        //     shortLabel: "Keyword/Part Number Search",
        //     filterType: "ACTION",
        //     label: "Keyword/Part Number Search"
        // }),
        // new Filter({
        //     value: "ADVANCED_SEARCH",
        //     shortLabel: "Advanced Searches/Attribute Column Sorts",
        //     filterType: "ACTION",
        //     label: "Advanced Searches/Attribute Column Sorts"
        // }),
        // new Filter({
        //     value: "PAGE_FAVORITED",
        //     shortLabel: "Page Saved to Favorites",
        //     filterType: "ACTION",
        //     label: "Page Saved to Favorites"
        // }),
        // new Filter({
        //     value: "CAD",
        //     shortLabel: "CAD viewed",
        //     filterType: "ACTION",
        //     label: "CAD viewed"
        // }),
        // new Filter({
        //     value: "COMPARED_ITEMS",
        //     shortLabel: "Compared Item",
        //     filterType: "ACTION",
        //     label: "Compared Item"
        // }),
        // new Filter({
        //     value: "PAGE_EMAILED",
        //     shortLabel: "Page Emailed",
        //     filterType: "ACTION",
        //     label: "Page Emailed"
        // }),
        // new Filter({
        //     value: "PAGE_PRINTED",
        //     shortLabel: "Printed Page",
        //     filterType: "ACTION",
        //     label: "Printed Page"
        // }),
        // new Filter({
        //     value: "PRODUCT_VIEW",
        //     shortLabel: "Product Service View",
        //     filterType: "ACTION",
        //     label: "Product Service View"
        // })
        // new Filter({
        //     value: "CATALOG_ACTIVITY",
        //     shortLabel: "CATALOG_ACTIVITY",
        //     filterType: "ACTION",
        //     label: "CATALOG_ACTIVITY"
        // }),
        new Filter({
            value: "CATALOG_ACTIVITY",
            shortLabel: "CATALOG_ACTIVITY",
            filterType: "BASE",
            label: "CATALOG_ACTIVITY"
        })
    ]
};

const pages = [
    new Page({ id: "all" }),
    new Page({
        id: "targeted",
        activeFilters: defaultActiveFilters.targeted,
        useAllTargets: true
    }),
    new Page({
        id: "thomasnet",
        activeFilters: defaultActiveFilters.thomasnet
    }),
    new Page({
        id: "navigator",
        activeFilters: defaultActiveFilters.navigator
    }),

    new Page({ id: "audience-all" }),
    new Page({
        id: "audience-targeted",
        activeFilters: defaultActiveFilters.targeted,
        useAllTargets: true
    }),
    new Page({
        id: "audience-thomasnet",
        activeFilters: defaultActiveFilters.thomasnet
    }),
    new Page({
        id: "audience-navigator",
        activeFilters: defaultActiveFilters.navigator
    }),
    new Page({ id: "analytics-all" })
];

export default pages;
