import React from "react";
import { logOut } from "auth/utils";
import "./SiteHeader.scss";

const $ = window.$;

class SiteHeader extends React.Component {
  componentDidMount() {
    var $menu = $(".site-nav-container, .site-aside"),
      $menulink = $(".menu-link"),
      $siteWrap = $(".site-wrap");

    $menulink.click(function(e) {
      e.preventDefault();
      $menulink.toggleClass("active");
      $menu.toggleClass("active");
      $siteWrap.toggleClass("nav-active");
    });
  }

  render() {
    const { thomasLogo, hideLogOut } = this.props;

    return (
      <header className="site-header" role="banner" data-thdoc="S1">
        <div className="site-header__global">
          <div className="container container--site-header__global">
            <span
              id="mobile-drop-down"
              className="site-header__mobile-icons-menu "
            >
              <a
                href="#menu"
                className="nav__ico-link sh-ico-menu menu-link"
                id="mobile-drop-down"
              >
                <img
                  src="https://cdn40.thomasnet.com/img40/ico-nav.svg"
                  alt=""
                />
              </a>
            </span>
            <a
              href={thomasLogo ? "https://www.thomasnet.com/" : "/"}
              className={`site-header__logo ${thomasLogo ? "" : "wt-logo"}`}
            >
              {!thomasLogo ? (
                <img
                  src={process.env.PUBLIC_URL + "/img/webtrax-logo-blue.svg"}
                  alt="Thomas Logo"
                />
              ) : (
                <img
                  src={process.env.PUBLIC_URL + "/img/thomas-logo.png"}
                  alt="Thomas Logo"
                  style={{ width: "200px" }}
                />
              )}
            </a>

            <div className="site-nav-container ">
              <div className="snc-header">
                <a href="#close" className="close-menu menu-link">
                  +
                </a>
              </div>
            </div>

            {!hideLogOut && (
              <p onClick={() => logOut("/")} className="logout-button">
                Log out
              </p>
            )}

            <a href="#menu" className="site-nav-container-screen menu-link">
              &nbsp;
            </a>
          </div>
        </div>
      </header>
    );
  }
}

export default SiteHeader;
