import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import "./ResultsTimeline.css";
import "./LoadingDots.css";
import ResultsTimelineCard from "./ResultsTimelineCard";
import ResultsTimelineLoadingCard from "./ResultsTimelineLoadingCard";

const ResultsTimelineDate = ({ prevDate, currentDate }) => {
    const prevDateMoment = moment(prevDate);
    const currentDateMoment = moment(currentDate);

    if (currentDateMoment.isBefore(prevDateMoment, "day")) {
        return (
            <div className="results-timeline--date">
                {currentDateMoment.format("MMM D, YYYY")}
            </div>
        );
    }

    return null;
};

const ResultsTimeline = ({
    sessions = {},
    isVisitorSession,
    onLoadMore,
    onClearAllFilters,
    loading,
    startDate,
    page
}) => {
    const { edges = [], pageInfo = {} } = sessions;
    return (
        <div className="results-timeline">
            <header className="section-header results-timeline--header">
                <div className="flex-col">
                    <h3 className="section-header__secondary">
                        Recent Sessions
                    </h3>
                    {!isVisitorSession &&
                        (loading ? (
                            <div className="dotter">
                                <div className="dot1" />
                                <div className="dot2" />
                                <div className="dot3" />
                                <div className="dot4" />
                            </div>
                        ) : (
                            <div className="results-timeline--date">
                                {edges && edges.length > 0
                                    ? moment(edges[0].node.visitDate).format(
                                          "MMM D, YYYY"
                                      )
                                    : moment(startDate).format("MMM D, YYYY")}
                            </div>
                        ))}
                </div>
            </header>

            {edges.map((edge, i, arr) => (
                <React.Fragment key={edge.cursor}>
                    {i > 0 &&
                        !isVisitorSession && (
                            <ResultsTimelineDate
                                prevDate={arr[i - 1].node.visitDate}
                                currentDate={edge.node.visitDate}
                            />
                        )}

                    <ResultsTimelineCard
                        session={edge.node}
                        isVisitorSession={isVisitorSession}
                    />
                </React.Fragment>
            ))}

            {!loading &&
                (!edges || edges.length === 0) && (
                    <div className="card">
                        <p className="empty-search-results">
                            {page === "all" ? (
                                <React.Fragment>
                                    We're sorry, you do not have any sessions
                                    that match these filters.{" "}
                                    <span
                                        onClick={() => {
                                            onClearAllFilters();
                                        }}
                                    >
                                        Clear applied filters
                                    </span>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {`We’re sorry, you do not have any ${page} sessions that match. `}
                                    <Link to="/sessions">
                                        View all Sessions
                                    </Link>
                                </React.Fragment>
                            )}
                        </p>
                    </div>
                )}

            {loading && (
                <React.Fragment>
                    <ResultsTimelineLoadingCard />
                    <ResultsTimelineLoadingCard />
                    <ResultsTimelineLoadingCard />
                    <ResultsTimelineLoadingCard />
                    <ResultsTimelineLoadingCard />
                </React.Fragment>
            )}

            {pageInfo.hasNextPage && (
                <footer className="section-footer">
                    <button
                        className="btn btn-primary btn-lg"
                        onClick={() => {
                            onLoadMore(edges[edges.length - 1].cursor);
                        }}
                    >
                        View More Sessions
                    </button>
                </footer>
            )}
        </div>
    );
};

export default ResultsTimeline;
