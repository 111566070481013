const detailTableCols = hidePageType => {
    var myarr = [
        { label: "", prop: "id", key: "id", width: "5%" },
        {
            label: "Date/Time",
            prop: "timestamp",
            key: "timestamp",
            width: "12%"
        },
        { label: "Where", prop: "where", key: "where", width: "12%" },
        { label: "Page Viewed", prop: "pageUrl", key: "pageUrl", width: "44%" }
    ];

    if (!hidePageType) {
        myarr.push({
            label: "Page Type",
            prop: "pageType",
            key: "pageType",
            width: "12%"
        });
    }

    myarr.push({
        label: "Action",
        prop: "actions",
        key: "actions",
        width: "15%"
    });

    return myarr;
};

export default detailTableCols;
