const visitorKeys = [
    { key: "companyName", label: "Company Name" },
    { key: "companyDomain", label: "Domain" },
    { key: "location", label: "Location" },
    { key: "zip", label: "Zip Code" },
    { key: "country", label: "Country" },
    { key: "industry", label: "Industry" },
    { key: "subIndustry", label: "Sub Industry" },
    { key: "b2bOb2c", label: "B2B or B2C" },
    { key: "audience", label: "Audience" },
    { key: "revenue", label: "Revenue" },
    { key: "employeeRange", label: "Employee Range" },
    { key: "fortune1", label: "Fortune 1000" },
    { key: "fortune2", label: "Fortune 2000" }
];

export default visitorKeys;
