const states = [
    {
        label: "Alabama",
        value: "AL",
        targetType: "State"
    },
    {
        label: "Alaska",
        value: "AK",
        targetType: "State"
    },
    {
        label: "Arizona",
        value: "AZ",
        targetType: "State"
    },
    {
        label: "Arkansas",
        value: "AR",
        targetType: "State"
    },
    {
        label: "California",
        value: "CA",
        targetType: "State"
    },
    {
        label: "Colorado",
        value: "CO",
        targetType: "State"
    },
    {
        label: "Connecticut",
        value: "CT",
        targetType: "State"
    },
    {
        label: "Delaware",
        value: "DE",
        targetType: "State"
    },
    {
        label: "District of Columbia",
        value: "DC",
        targetType: "State"
    },
    {
        label: "Florida",
        value: "FL",
        targetType: "State"
    },
    {
        label: "Georgia",
        value: "GA",
        targetType: "State"
    },
    {
        label: "Hawaii",
        value: "HI",
        targetType: "State"
    },
    {
        label: "Idaho",
        value: "ID",
        targetType: "State"
    },
    {
        label: "Illinois",
        value: "IL",
        targetType: "State"
    },
    {
        label: "Indiana",
        value: "IN",
        targetType: "State"
    },
    {
        label: "Iowa",
        value: "IA",
        targetType: "State"
    },
    {
        label: "Kansas",
        value: "KS",
        targetType: "State"
    },
    {
        label: "Kentucky",
        value: "KY",
        targetType: "State"
    },
    {
        label: "Louisiana",
        value: "LA",
        targetType: "State"
    },
    {
        label: "Maine",
        value: "ME",
        targetType: "State"
    },
    {
        label: "Maryland",
        value: "MD",
        targetType: "State"
    },
    {
        label: "Massachusetts",
        value: "MA",
        targetType: "State"
    },
    {
        label: "Michigan",
        value: "MI",
        targetType: "State"
    },
    {
        label: "Minnesota",
        value: "MN",
        targetType: "State"
    },
    {
        label: "Mississippi",
        value: "MS",
        targetType: "State"
    },
    {
        label: "Missouri",
        value: "MO",
        targetType: "State"
    },
    {
        label: "Montana",
        value: "MT",
        targetType: "State"
    },
    {
        label: "Nebraska",
        value: "NE",
        targetType: "State"
    },
    {
        label: "Nevada",
        value: "NV",
        targetType: "State"
    },
    {
        label: "New Hampshire",
        value: "NH",
        targetType: "State"
    },
    {
        label: "New Jersey",
        value: "NJ",
        targetType: "State"
    },
    {
        label: "New Mexico",
        value: "NM",
        targetType: "State"
    },
    {
        label: "New York",
        value: "NY",
        targetType: "State"
    },
    {
        label: "North Carolina",
        value: "NC",
        targetType: "State"
    },
    {
        label: "North Dakota",
        value: "ND",
        targetType: "State"
    },
    {
        label: "Ohio",
        value: "OH",
        targetType: "State"
    },
    {
        label: "Oklahoma",
        value: "OK",
        targetType: "State"
    },
    {
        label: "Oregon",
        value: "OR",
        targetType: "State"
    },
    {
        label: "Pennsylvania",
        value: "PA",
        targetType: "State"
    },
    {
        label: "Rhode Island",
        value: "RI",
        targetType: "State"
    },
    {
        label: "South Carolina",
        value: "SC",
        targetType: "State"
    },
    {
        label: "South Dakota",
        value: "SD",
        targetType: "State"
    },
    {
        label: "Tennessee",
        value: "TN",
        targetType: "State"
    },
    {
        label: "Texas",
        value: "TX",
        targetType: "State"
    },
    {
        label: "Utah",
        value: "UT",
        targetType: "State"
    },
    {
        label: "Vermont",
        value: "VT",
        targetType: "State"
    },
    {
        label: "Virginia",
        value: "VA",
        targetType: "State"
    },
    {
        label: "Washington",
        value: "WA",
        targetType: "State"
    },
    {
        label: "West Virginia",
        value: "WV",
        targetType: "State"
    },
    {
        label: "Wisconsin",
        value: "WI",
        targetType: "State"
    },
    {
        label: "Wyoming",
        value: "WY",
        targetType: "State"
    },
    {
        label: "Guam",
        value: "GU",
        targetType: "State"
    },
    {
        label: "Midway Islands",
        value: "QM",
        targetType: "State"
    },
    {
        label: "Puerto Rico",
        value: "PR",
        targetType: "State"
    },
    {
        label: "U.S. Virgin Islands",
        value: "VI",
        targetType: "State"
    },
    {
        label: "Alberta",
        value: "AB",
        targetType: "State"
    },
    {
        label: "British Columbia",
        value: "BC",
        targetType: "State"
    },
    {
        label: "Manitoba",
        value: "MB",
        targetType: "State"
    },
    {
        label: "New Brunswick",
        value: "NB",
        targetType: "State"
    },
    {
        label: "Newfoundland and Labrador",
        value: "NL",
        targetType: "State"
    },
    {
        label: "Northwest Territories",
        value: "NT",
        targetType: "State"
    },
    {
        label: "Nova Scotia",
        value: "NS",
        targetType: "State"
    },
    {
        label: "Nunavut",
        value: "NU",
        targetType: "State"
    },
    {
        label: "Ontario",
        value: "ON",
        targetType: "State"
    },
    {
        label: "Prince Edward Island",
        value: "PE",
        targetType: "State"
    },
    {
        label: "Quebec",
        value: "QC",
        targetType: "State"
    },
    {
        label: "Saskatchewan",
        value: "SK",
        targetType: "State"
    },
    {
        label: "Yukon",
        value: "YT",
        targetType: "State"
    }
];

export default states;
