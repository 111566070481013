var zones = [
	{
		offset: "GMT-12:00",
		value: "-12",
		label: "Etc/GMT-12"
	},
	{
		offset: "GMT-11:00",
		value: "-11",
		label: "Etc/GMT-11"
	},
	{
		offset: "GMT-11:00",
		value: "-11",
		label: "Pacific/Midway"
	},
	{
		offset: "GMT-10:00",
		value: "-10",
		label: "America/Adak"
	},
	{
		offset: "GMT-09:00",
		value: "-9",
		label: "America/Anchorage"
	},
	{
		offset: "GMT-09:00",
		value: "-9",
		label: "Pacific/Gambier"
	},
	{
		offset: "GMT-08:00",
		value: "-8",
		label: "America/Dawson_Creek"
	},
	{
		offset: "GMT-08:00",
		value: "-8",
		label: "America/Ensenada"
	},
	{
		offset: "GMT-08:00",
		value: "-8",
		label: "America/Los_Angeles"
	},
	{
		offset: "GMT-07:00",
		value: "-7",
		label: "America/Chihuahua"
	},
	{
		offset: "GMT-07:00",
		value: "-7",
		label: "America/Denver"
	},
	{
		offset: "GMT-06:00",
		value: "-6",
		label: "America/Belize"
	},
	{
		offset: "GMT-06:00",
		value: "-6",
		label: "America/Cancun"
	},
	{
		offset: "GMT-06:00",
		value: "-6",
		label: "America/Chicago"
	},
	{
		offset: "GMT-06:00",
		value: "-6",
		label: "Chile/EasterIsland"
	},
	{
		offset: "GMT-05:00",
		value: "-5",
		label: "America/Bogota"
	},
	{
		offset: "GMT-05:00",
		value: "-5",
		label: "America/Havana"
	},
	{
		offset: "GMT-05:00",
		value: "-5",
		label: "America/New_York"
	},
	{
		offset: "GMT-04:30",
		value: "-4",
		label: "America/Caracas"
	},
	{
		offset: "GMT-04:00",
		value: "-4",
		label: "America/Campo_Grande"
	},
	{
		offset: "GMT-04:00",
		value: "-4",
		label: "America/Glace_Bay"
	},
	{
		offset: "GMT-04:00",
		value: "-4",
		label: "America/Goose_Bay"
	},
	{
		offset: "GMT-04:00",
		value: "-4",
		label: "America/Santiago"
	},
	{
		offset: "GMT-04:00",
		value: "-4",
		label: "America/La_Paz"
	},
	{
		offset: "GMT-03:00",
		value: "-3",
		label: "America/Argentina/Buenos_Aires"
	},
	{
		offset: "GMT-03:00",
		value: "-3",
		label: "America/Montevideo"
	},
	{
		offset: "GMT-03:00",
		value: "-3",
		label: "America/Araguaina"
	},
	{
		offset: "GMT-03:00",
		value: "-3",
		label: "America/Godthab"
	},
	{
		offset: "GMT-03:00",
		value: "-3",
		label: "America/Miquelon"
	},
	{
		offset: "GMT-03:00",
		value: "-3",
		label: "America/Sao_Paulo"
	},
	{
		offset: "GMT-03:30",
		value: "-3",
		label: "America/St_Johns"
	},
	{
		offset: "GMT-02:00",
		value: "-2",
		label: "America/Noronha"
	},
	{
		offset: "GMT-01:00",
		value: "-1",
		label: "Atlantic/Cape_Verde"
	},
	{
		offset: "GMT",
		value: "0",
		label: "Europe/Belfast"
	},
	{
		offset: "GMT",
		value: "0",
		label: "Africa/Abidjan"
	},
	{
		offset: "GMT",
		value: "0",
		label: "Europe/Dublin"
	},
	{
		offset: "GMT",
		value: "0",
		label: "Europe/Lisbon"
	},
	{
		offset: "GMT",
		value: "0",
		label: "Europe/London"
	},
	{
		offset: "UTC",
		value: "0",
		label: "UTC"
	},
	{
		offset: "GMT+01:00",
		value: "+1",
		label: "Africa/Algiers"
	},
	{
		offset: "GMT+01:00",
		value: "+1",
		label: "Africa/Windhoek"
	},
	{
		offset: "GMT+01:00",
		value: "+1",
		label: "Atlantic/Azores"
	},
	{
		offset: "GMT+01:00",
		value: "+1",
		label: "Atlantic/Stanley"
	},
	{
		offset: "GMT+01:00",
		value: "+1",
		label: "Europe/Amsterdam"
	},
	{
		offset: "GMT+01:00",
		value: "+1",
		label: "Europe/Belgrade"
	},
	{
		offset: "GMT+01:00",
		value: "+1",
		label: "Europe/Brussels"
	},
	{
		offset: "GMT+02:00",
		value: "+2",
		label: "Africa/Cairo"
	},
	{
		offset: "GMT+02:00",
		value: "+2",
		label: "Africa/Blantyre"
	},
	{
		offset: "GMT+02:00",
		value: "+2",
		label: "Asia/Beirut"
	},
	{
		offset: "GMT+02:00",
		value: "+2",
		label: "Asia/Damascus"
	},
	{
		offset: "GMT+02:00",
		value: "+2",
		label: "Asia/Gaza"
	},
	{
		offset: "GMT+02:00",
		value: "+2",
		label: "Asia/Jerusalem"
	},
	{
		offset: "GMT+03:00",
		value: "+3",
		label: "Africa/Addis_Ababa"
	},
	{
		offset: "GMT+03:00",
		value: "+3",
		label: "Asia/Riyadh89"
	},
	{
		offset: "GMT+03:00",
		value: "+3",
		label: "Europe/Minsk"
	},
	{
		offset: "GMT+03:30",
		value: "+3",
		label: "Asia/Tehran"
	},
	{
		offset: "GMT+04:00",
		value: "+4",
		label: "Asia/Dubai"
	},
	{
		offset: "GMT+04:00",
		value: "+4",
		label: "Asia/Yerevan"
	},
	{
		offset: "GMT+04:00",
		value: "+4",
		label: "Europe/Moscow"
	},
	{
		offset: "GMT+04:30",
		value: "+4",
		label: "Asia/Kabul"
	},
	{
		offset: "GMT+05:00",
		value: "+5",
		label: "Asia/Tashkent"
	},
	{
		offset: "GMT+05:30",
		value: "+5",
		label: "Asia/Kolkata"
	},
	{
		offset: "GMT+05:45",
		value: "+5",
		label: "Asia/Katmandu"
	},
	{
		offset: "GMT+06:00",
		value: "+6",
		label: "Asia/Dhaka"
	},
	{
		offset: "GMT+06:00",
		value: "+6",
		label: "Asia/Yekaterinburg"
	},
	{
		offset: "GMT+06:30",
		value: "+6",
		label: "Asia/Rangoon"
	},
	{
		offset: "GMT+07:00",
		value: "+7",
		label: "Asia/Bangkok"
	},
	{
		offset: "GMT+07:00",
		value: "+7",
		label: "Asia/Novosibirsk"
	},
	{
		offset: "GMT+08:00",
		value: "+8",
		label: "Etc/GMT+8"
	},
	{
		offset: "GMT+08:00",
		value: "+8",
		label: "Asia/Hong_Kong"
	},
	{
		offset: "GMT+08:00",
		value: "+8",
		label: "Asia/Krasnoyarsk"
	},
	{
		offset: "GMT+08:00",
		value: "+8",
		label: "Australia/Perth"
	},
	{
		offset: "GMT+08:45",
		value: "+8",
		label: "Australia/Eucla"
	},
	{
		offset: "GMT+09:00",
		value: "+9",
		label: "Asia/Irkutsk"
	},
	{
		offset: "GMT+09:00",
		value: "+9",
		label: "Asia/Seoul"
	},
	{
		offset: "GMT+09:00",
		value: "+9",
		label: "Asia/Tokyo"
	},
	{
		offset: "GMT+09:30",
		value: "+9",
		label: "Australia/Adelaide"
	},
	{
		offset: "GMT+09:30",
		value: "+9",
		label: "Australia/Darwin"
	},
	{
		offset: "GMT+09:30",
		value: "+9",
		label: "Pacific/Marquesas"
	},
	{
		offset: "GMT+10:00",
		value: "+10",
		label: "Etc/GMT+10"
	},
	{
		offset: "GMT+10:00",
		value: "+10",
		label: "Australia/Brisbane"
	},
	{
		offset: "GMT+10:00",
		value: "+10",
		label: "Australia/Hobart"
	},
	{
		offset: "GMT+10:00",
		value: "+10",
		label: "Asia/Yakutsk"
	},
	{
		offset: "GMT+10:30",
		value: "+10",
		label: "Australia/Lord_Howe"
	},
	{
		offset: "GMT+11:00",
		value: "+11",
		label: "Asia/Vladivostok"
	},
	{
		offset: "GMT+11:30",
		value: "+11",
		label: "Pacific/Norfolk"
	},
	{
		offset: "GMT+12:00",
		value: "+12",
		label: "Etc/GMT+12"
	},
	{
		offset: "GMT+12:00",
		value: "+12",
		label: "Asia/Anadyr"
	},
	{
		offset: "GMT+12:00",
		value: "+12",
		label: "Asia/Magadan"
	},
	{
		offset: "GMT+12:00",
		value: "+12",
		label: "Pacific/Auckland"
	},
	{
		offset: "GMT+12:45",
		value: "+12",
		label: "Pacific/Chatham"
	},
	{
		offset: "GMT+13:00",
		value: "+13",
		label: "Pacific/Tongatapu"
	},
	{
		offset: "GMT+14:00",
		value: "+14",
		label: "Pacific/Kiritimati"
	}
];

export default zones;
