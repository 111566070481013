import React from "react";
import { Link } from "react-router-dom";

import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import FormatDuration from "views/components/FormatDuration";

const referrersFactory = (content, prop) => {
    switch (true) {
        case prop === "url":
            return (
                <Link
                    to={`/sessions?ref=${
                        content.node[prop] === "" ? "DTS" : content.node[prop]
                    }`}
                >
                    {content.node[prop] === ""
                        ? "Direct To Site"
                        : content.node[prop]}
                </Link>
            );

        case prop === "sessionsCount":
            return content.node[prop].toLocaleString();

        case prop === "avgDuration":
            return FormatDuration(content.node[prop]);

        case prop === "avgPages":
            return (Math.round(content.node[prop] * 10) / 10).toFixed(1);

        case prop === "bounceRate":
            return `${(Math.round(content.node[prop] * 10) / 10).toFixed(1)} %`;

        case prop === "topPages":
            return (
                <Link to={`/analytics/toppages?ref=${content.node["url"]}`}>
                    <ThomasIcon attribute="TOP_PAGES" hideToolTip />
                </Link>
            );

        case prop === "entryPages":
            return (
                <Link to={`/analytics/entrypages?ref=${content.node["url"]}`}>
                    <ThomasIcon attribute="ENTRY_PAGES" hideToolTip />
                </Link>
            );

        case prop === "exitPages":
            return (
                <Link to={`/analytics/exitpages?ref=${content.node["url"]}`}>
                    <ThomasIcon attribute="EXIT_PAGES" hideToolTip />
                </Link>
            );

        default:
            return content.node[prop];
    }
};

export default referrersFactory;
