import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import SessionQueryContainer from "containers/SessionQueryContainer";

const LOCATION_QUERY = gql`
  query get_locations(
    $query: SessionQueryInput
    $bounds: [Float]!
    $zoom: Int!
  ) {
    sessionsInfo(query: $query) {
      locations(bounds: $bounds, zoom: $zoom) {
        geometry {
          coordinates
        }
        properties {
          name
          cluster
          clusterId
          pointCount
        }
      }
    }
  }
`;

class LocationsContainer extends React.Component {
  state = {
    skip: true,
    variables: null
  };

  render() {
    const { children, page, ...props } = this.props;

    return (
      <SessionQueryContainer page={page}>
        {({ query }) => {
          return (
            <Query
              query={LOCATION_QUERY}
              skip={this.state.skip}
              variables={{ query, ...this.state.variables }}
            >
              {({ error, data: { sessionsInfo = {} } = {} }) => {
                if (error) {
                  console.error("LocationsContainer:", error);
                }

                const onUpdate = ({ bounds, zoom }) => {
                  if (bounds && zoom && query) {
                    this.setState({
                      skip: false,
                      variables: {
                        bounds: [
                          bounds.nw.lng,
                          bounds.se.lat,
                          bounds.ne.lng,
                          bounds.nw.lat
                        ],
                        zoom
                      }
                    });
                  }
                };

                return (
                  children &&
                  children({
                    ...props,
                    data: sessionsInfo.locations || [],
                    onUpdate
                  })
                );
              }}
            </Query>
          );
        }}
      </SessionQueryContainer>
    );
  }
}

export default LocationsContainer;
