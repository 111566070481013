import React from "react";
import DropDown from "views/components/DropDown/DropDown";
import { uniqBy, uniqWith, intersectionBy } from "lodash";
import { AddAllTargetsFilter } from "data/pages";

import { targetLabels, targetsTotal } from "./targetsData";

import "./ResultsFilter.css";
import ResultsFiltersActive from "./ResultsFiltersActive";

const defaultState = {
  referrer: null,
  target: null,
  action: null,
  search: "",
  filtersDisplay: true
};

class ResultsFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = defaultState;
  }

  reset() {
    this.setState(defaultState);
  }

  handleApplyClick = () => {
    if (typeof this.props.onApplyClick === "function") {
      this.props.onApplyClick(this.state);
    }

    this.reset();
  };

  handleDropdownChange = key => {
    return value => {
      if (key === "target") {
        this.setState({
          [key]: { ...value, filterType: "TARGET" }
        });
      } else {
        this.setState({
          [key]: value
        });
      }
    };
  };

  handleSearchChange = e => {
    this.setState({ search: e.target.value });
  };

  toggleFiltersDisplay = () => {
    this.setState({ filtersDisplay: !this.state.filtersDisplay });
  };

  render() {
    const {
      filters: { referrers = [], targets = [], actions = [] },
      activeFilters,
      onFilterRemove,
      onFiltersRemove
    } = this.props;

    const myrefs = referrers.map(r => {
      return r.value === ""
        ? {
            filterType: "REFERRER",
            label: "Direct to Site",
            shortLabel: "Direct to Site",
            targetType: null,
            value: "Direct to Site"
          }
        : r;
    });

    const { filtersDisplay } = this.state;

    const haveActiveFilters =
      activeFilters &&
      (activeFilters.referrers.length > 0 ||
        activeFilters.targets.length > 0 ||
        activeFilters.actions.length > 0 ||
        activeFilters.searches.length > 0);

    const targetsGroup = ({ item }) =>
      item === "TARGET"
        ? "Your Target Audience"
        : "Additional Available Targets";

    const targetsListItems = ({ item }) => {
      return item.targetType === "label" ? (
        <span className="rw-subheader">{item.label}</span>
      ) : (
        <span className="rw-subheader-item">{item.label}</span>
      );
    };

    const disabledTargets = uniqBy(
      intersectionBy(targets, activeFilters.targets, "value").concat(
        intersectionBy(targetsTotal, activeFilters.targets, "value")
      ),
      "value"
    )
      .concat(targetLabels.sizes)
      .concat(targetLabels.revenues)
      .concat(targetLabels.industries)
      .concat(targetLabels.countries)
      .concat(targetLabels.states);

    return (
      <div className="results-filter">
        <div className="results-filter__options session-detail-row">
          <div
            className={`${
              haveActiveFilters ? "row-content " : ""
            }results-filter--content`}
          >
            <h4>
              <div
                className="filterby--label"
                onClick={this.toggleFiltersDisplay}
              >
                FILTER BY:
                <span
                  className={`results-filter--show-filters_button ${
                    filtersDisplay ? "" : "active"
                  }`}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/img/arrow.svg"}
                    alt="arrow"
                  />
                </span>
              </div>
            </h4>
            <div
              className={`results-filter--content-filters ${
                filtersDisplay ? "active" : ""
              }`}
            >
              <DropDown
                title="Referrer"
                customClass="results-filter--item filter-referer"
                items={myrefs}
                onChange={this.handleDropdownChange("referrer")}
                value={this.state.referrer}
                disabled={intersectionBy(
                  myrefs,
                  activeFilters.referrers,
                  "value"
                )}
              />
              <DropDown
                title="Your Targets"
                customClass="results-filter--item filter-target"
                items={uniqWith(
                  [AddAllTargetsFilter].concat(targets).concat(targetsTotal),
                  (a, b) => a.value === b.value && a.targetType === b.targetType
                )}
                groupBy="filterType"
                groupComponent={targetsGroup}
                itemComponent={targetsListItems}
                onChange={this.handleDropdownChange("target")}
                value={this.state.target}
                disabled={disabledTargets}
              />
              <DropDown
                title="Specific Actions"
                customClass="results-filter--item filter-action"
                items={actions}
                onChange={this.handleDropdownChange("action")}
                value={this.state.action}
                disabled={intersectionBy(
                  actions,
                  activeFilters.actions,
                  "value"
                )}
              />
              <div className="results-filter--item filter-search">
                <label className="results-filter__options_search-label">
                  Visitor Name or Search Term
                </label>
                <input
                  className="form-control"
                  onChange={this.handleSearchChange}
                  value={this.state.search}
                />
              </div>
              <button
                onClick={this.handleApplyClick}
                className="btn btn-primary results-filter--item filter-apply"
              >
                Apply
              </button>
            </div>
          </div>
        </div>

        {haveActiveFilters && (
          <ResultsFiltersActive
            activeFilters={activeFilters}
            onFilterRemove={onFilterRemove}
            onFiltersRemove={onFiltersRemove}
          />
        )}
      </div>
    );
  }
}

export default ResultsFilter;
