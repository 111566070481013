import React from "react";

class Icon {
  img?: string;
  icon: () => JSX.Element = () => <></>;
  name?: string;
  color?: string;
}

export enum Attribute {
  HIGH_VALUE_AUDIENCE = "HIGH_VALUE_AUDIENCE",
  HIGH_VALUE_PAGE = "HIGH_VALUE_PAGE",
  THOMASNET_ACTIVITY = "THOMASNET_ACTIVITY",
  ASSET_DOWNLOAD = "ASSET_DOWNLOAD",
  PHONE_CALL = "PHONE_CALL",
  RFI = "RFI",
  SHORTLISTED = "SHORTLISTED",
  CLIENT_SITE_ACTIVITY = "CLIENT_SITE_ACTIVITY",
  KEYWORD_SEARCH = "KEYWORD_SEARCH",
  ADVANCED_SEARCH = "ADVANCED_SEARCH",
  PAGE_EMAILED = "PAGE_EMAILED",
  PAGE_FAVORITED = "PAGE_FAVORITED",
  COMPARED_ITEMS = "COMPARED_ITEMS",
  CAD = "CAD",
  PRODUCT_VIEW = "PRODUCT_VIEW",
  PAGE_PRINTED = "PAGE_PRINTED",
  THOMAS_REGISTERED_USER = "THOMAS_REGISTERED_USER",
  TOP_PAGES = "TOP_PAGES",
  ENTRY_PAGES = "ENTRY_PAGES",
  EXIT_PAGES = "EXIT_PAGES",
  FUNNEL_ANALYSIS = "FUNNEL_ANALYSIS",
  EXTERNAL_LINK = "EXTERNAL_LINK",
  OPEN = "OPEN",
  COLLAPSE = "COLLAPSE",
  CLOSE = "CLOSE",
  HELP = "HELP",
  ARROW_RIGHT = "ARROW_RIGHT",
  ARROW_UP = "ARROW_UP",
  CHECKMARK = "CHECKMARK",
  EDIT = "EDIT",
  FORMS = "FORMS",
  CAMPAIGN = "CAMPAIGN",
  ARROW_DOWN = "ARROW_DOWN",
  PDF_EXPORT = "PDF_EXPORT",
  CALENDAR = "CALENDAR",
  LEGEND_CHIP_IMPRESSIONS = "LEGEND_CHIP_IMPRESSIONS",
  LEGEND_CHIP_CLICKS = "LEGEND_CHIP_CLICKS"
}

export const IconMap = new Map<Attribute, Icon>([
  [
    Attribute.HIGH_VALUE_AUDIENCE,
    {
      img: process.env.PUBLIC_URL + "/img/ico-high-value-firmographic.svg",
      icon: () => (
        <path
          d="M2 16C2 8.268 8.268 2 16 2c7.731 0 14 6.268 14 14s-6.269 14-14 14C8.268 30 2 23.732 2 16zm13.785-.064c.739-.864 1.146-2.02 1.146-3.254 0-2.51-1.703-4.551-3.797-4.551-2.094 0-3.797 2.042-3.797 4.551 0 1.234.407 2.39 1.146 3.254.714.836 1.656 1.296 2.65 1.296.996 0 1.937-.46 2.652-1.296zm.257 1.77a3.79 3.79 0 0 1-.337.312c-.052.043-.103.088-.157.129-.066.05-.134.095-.202.141-.055.038-.109.076-.165.11-.07.043-.142.081-.214.12-.057.03-.112.061-.169.089-.075.036-.151.065-.228.096-.057.023-.113.048-.171.068-.082.028-.164.05-.248.073-.054.015-.108.033-.164.046-.093.021-.187.034-.281.049-.048.007-.094.018-.142.023a3.952 3.952 0 0 1-.862 0c-.046-.007-.091-.017-.137-.024-.095-.014-.191-.028-.285-.05-.055-.011-.107-.03-.16-.044-.085-.024-.17-.045-.252-.074-.057-.02-.11-.044-.167-.067-.078-.03-.156-.061-.232-.097-.056-.027-.11-.058-.166-.088-.073-.039-.147-.077-.218-.12-.055-.034-.108-.072-.162-.109-.069-.046-.137-.092-.204-.143-.053-.04-.104-.085-.156-.129a4.617 4.617 0 0 1-.19-.165c-.051-.048-.1-.098-.15-.149-.039-.04-.08-.077-.12-.12-2.463.74-4.087 2.787-4.23 4.535l14.52.001c-.077-.954-.596-1.997-1.452-2.868a6.535 6.535 0 0 0-2.778-1.667c-.038.043-.08.08-.12.121zm9.91-2.156a1 1 0 0 0 1-1v-.012a1 1 0 0 0-1-1h-1.915v-2.016a1 1 0 0 0-1-1h-.08a1 1 0 0 0-1 1v2.016h-1.914a1 1 0 0 0-1 1v.012a1 1 0 0 0 1 1h1.915v2.016a1 1 0 0 0 1 1h.08a1 1 0 0 0 1-1V15.55h1.914z"
          fill="currentColor"
          fillRule="nonzero"
        />
      ),
      name: "Targeted Audience",
      color: "icon-red"
    }
  ],
  [
    Attribute.HIGH_VALUE_PAGE,
    {
      img: process.env.PUBLIC_URL + "/img/ico-high-value-page-view.svg",
      icon: () => (
        <path
          d="M2 16C2 8.268 8.268 2 16 2c7.731 0 14 6.268 14 14s-6.269 14-14 14C8.268 30 2 23.732 2 16zm5.085-2.567l8.37 11.029a1 1 0 0 0 1.593 0l8.37-11.03H7.084zm18.332-1.662L21.44 8.33a1 1 0 0 0-.655-.244h-9.078a1 1 0 0 0-.656.245L7.085 11.77h18.332z"
          fill="currentColor"
          fillRule="nonzero"
        />
      ),
      name: "High Value Page Viewed",
      color: "icon-red font-hv-page"
    }
  ],
  [
    Attribute.THOMASNET_ACTIVITY,
    {
      img: process.env.PUBLIC_URL + "/img/ico-thomas-activity-in-session.svg",
      icon: () => (
        <g fill="currentColor" fillRule="evenodd">
          <path
            d="M16 2c7.732 0 14 6.268 14 14s-6.268 14-14 14C8.27 30 2 23.732 2 16S8.269 2 16 2zm-5.07 7.614h9.292V6.2H10.93v3.414zm0 12.671h3.098v-9.257H10.93v9.257zm6.194 0h3.098v-9.257h-3.098v9.257zm-6.188 1.81v.357h.377v1.123h.372v-1.123h.376v-.358h-1.125zm2.333 0v.539h-.419v-.54h-.38v1.481h.38v-.584h.419v.584h.38v-1.48h-.38zm1.409 1.504c.364 0 .623-.274.623-.766 0-.494-.255-.762-.62-.762-.364 0-.623.273-.623.766s.255.762.62.762zm2.03-.024h.35v-1.48h-.338l-.165.395c-.085.2-.146.362-.169.446a4.146 4.146 0 0 0-.169-.446l-.165-.396h-.342v1.481h.35v-.353c0-.146-.002-.28-.008-.394.04.11.11.282.156.388l.176.41.177-.41c.046-.106.115-.277.155-.388a8.169 8.169 0 0 0-.008.394v.353zm1.682 0h.403l-.488-1.48h-.376l-.487 1.48h.399l.073-.26h.403l.073.26zm1.301.024c.323 0 .555-.14.555-.479 0-.277-.15-.391-.488-.48-.172-.045-.22-.07-.22-.125 0-.053.027-.09.138-.09.136 0 .255.05.374.139l.18-.322a.824.824 0 0 0-.535-.171c-.324 0-.53.188-.53.468 0 .296.156.393.48.476.18.046.229.08.229.135 0 .07-.043.095-.175.095a.763.763 0 0 1-.393-.148l-.181.322c.14.101.35.18.566.18zm1.37-1.372h.319v-.13h-.802v.13h.32v.758h.163v-.758zm1.261.744h.133v-.875h-.144l-.198.466a2.345 2.345 0 0 0-.06.15c-.013-.036-.041-.1-.063-.15l-.202-.466h-.139v.875h.128v-.462c0-.053-.002-.13-.003-.154.01.025.036.088.058.137l.216.493.219-.493.058-.137c-.002.025-.003.1-.003.154v.462z"
            fillRule="nonzero"
          />
          <path d="M14.682 24.428c-.153 0-.239.138-.239.405 0 .266.09.408.243.408.152 0 .238-.138.238-.404 0-.267-.09-.409-.242-.409M18.205 24.899c-.048-.194-.07-.276-.088-.378-.017.102-.039.186-.087.378l-.026.099h.227l-.026-.1z" />
        </g>
      ),
      name: "ThomasNet Activity in Session"
    }
  ],
  [
    Attribute.ASSET_DOWNLOAD,
    {
      img: process.env.PUBLIC_URL + "/img/ico-asset-download-view.svg",
      icon: () => (
        <path
          d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm8.532-13.13h-1.654c-.245 0-.35.105-.35.35v3.436H9.612V17.22c0-.245-.105-.35-.35-.35H7.608c-.245 0-.446.105-.446.35v5.472c0 .245.2.413.446.413h16.924c.245 0 .445-.168.445-.413v-1.655-3.817c0-.245-.2-.35-.445-.35zm-9.798-9.99v4.74H10.98l5.09 6.363 5.09-6.362h-3.754V6.88c0-.245-.264-.382-.509-.382h-1.654c-.245 0-.51.137-.51.382z"
          fill="currentColor"
          fillRule="evenodd"
        />
      ),
      name: "Asset Download / View",
      color: "icon-green"
    }
  ],
  [
    Attribute.PHONE_CALL,
    {
      img: process.env.PUBLIC_URL + "/img/ico-phone-call.svg",
      icon: () => (
        <path
          d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zM11.355 7.453a1.76 1.76 0 0 0-2.244 0L6.866 9.787c-3.86 3.86 11.491 19.301 15.441 15.351l2.244-2.244a1.572 1.572 0 0 0 0-2.244l-2.244-2.245a1.572 1.572 0 0 0-2.244 0l-1.796 1.796c-2.603-1.167-5.206-3.77-6.463-6.464l1.795-1.795c.629-.539.629-1.616 0-2.155l-2.244-2.334zm6.104-.359c3.681.988 6.464 3.86 7.451 7.451.18.718 1.347.449 1.167-.27-1.077-4.039-4.219-7.27-8.258-8.348-.808-.18-1.078.988-.36 1.167zm-.718 2.783a7.754 7.754 0 0 1 5.387 5.386c.269.808 1.346.45 1.167-.269A8.89 8.89 0 0 0 17.01 8.71c-.719-.18-.988.987-.27 1.167z"
          fill="currentColor"
          fillRule="evenodd"
        />
      ),
      name: "Phone Call",
      color: "icon-green"
    }
  ],
  [
    Attribute.RFI,
    {
      img: process.env.PUBLIC_URL + "/img/ico-submitted-RFI.svg",
      icon: () => (
        <path
          d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zM6.3 14.522l2.084-2.114 7.454-5.175 7.555 5.152 2.057 2.503v-3.005a2 2 0 0 0-.862-1.645l-7.575-5.24a2 2 0 0 0-2.276 0l-7.575 5.24a2 2 0 0 0-.862 1.645v2.639zm.121 6.605l4.39-2.99-4.39-2.992v5.982zm19.218 1.68l-5.155-3.566-3.881 2.712a1 1 0 0 1-1.146 0l-3.882-2.712-5.154 3.566v.297a1 1 0 0 0 1 1H24.64a1 1 0 0 0 1-1v-.297zm0-7.662l-4.391 2.991 4.39 2.991v-5.982zm-9.489-2.588a1.292 1.292 0 1 0 0-2.584 1.292 1.292 0 0 0 0 2.584zM14.858 14.1v5.815h2.585V14.1h-2.585z"
          fill="currentColor"
          fillRule="evenodd"
        />
      ),
      name: "Submitted RFI",
      color: "icon-green"
    }
  ],
  [
    Attribute.SHORTLISTED,
    {
      img: process.env.PUBLIC_URL + "/img/ico-saved-to-shortlist.svg",
      icon: () => (
        <g fill="currentColor" fillRule="evenodd">
          <path d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm-9.052-6.74h18.148c.385 0 .698-.31.698-.694V8.694A.696.696 0 0 0 25.096 8H6.948a.696.696 0 0 0-.698.694v13.872c0 .383.312.694.698.694z" />
          <path d="M8.344 21.18v-11.1H23.7v11.1H8.344zm7.935-8.341a.5.5 0 0 0 .5-.5v-.402a.5.5 0 0 0-.5-.5h-6.04a.5.5 0 0 0-.5.5v.402a.5.5 0 0 0 .5.5h6.04zm0 2.774a.5.5 0 0 0 .5-.5v-.401a.5.5 0 0 0-.5-.5h-6.04a.5.5 0 0 0-.5.5v.401a.5.5 0 0 0 .5.5h6.04zm6.584-3.322a.405.405 0 0 0-.568.039l-5.012 5.713-1.793-.973a.418.418 0 0 0-.457.039.41.41 0 0 0-.067.58l1.904 2.38a.866.866 0 0 0 1.4-.069l.063-.096 4.604-7.095a.398.398 0 0 0-.074-.518z" />
        </g>
      ),
      name: "Shortlisted",
      color: "icon-green"
    }
  ],
  [
    Attribute.CLIENT_SITE_ACTIVITY,
    {
      img:
        process.env.PUBLIC_URL +
        "/img/ico-client-website-activity-in-session.svg",
      icon: () => (
        <path
          d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm6.874-8.083c1.303-1.467 2.078-3.422 2.2-5.5h-3.3c-.041 1.63-.407 2.974-.978 4.278.734.326 1.426.733 2.078 1.222zm-5.297 2.974c1.793-.325 3.464-1.14 4.727-2.363a10.616 10.616 0 0 0-1.875-1.06c-.692 1.304-1.67 2.445-2.852 3.423zm-5.785-3.463c-.652.326-1.263.651-1.875 1.059a8.71 8.71 0 0 0 4.727 2.363 10.958 10.958 0 0 1-2.852-3.422zm-4.645-4.97c.123 2.037.897 3.992 2.2 5.5a10.694 10.694 0 0 1 2.078-1.223c-.57-1.344-.936-2.647-.977-4.277H7.147zm2.241-6.316c-1.303 1.467-2.118 3.464-2.241 5.501h3.342c.04-1.222.366-2.935.977-4.279a10.686 10.686 0 0 1-2.078-1.222zm5.256-2.933c-1.793.285-3.423 1.14-4.685 2.323.57.407 1.222.773 1.874 1.059.693-1.264 1.63-2.445 2.81-3.382zm5.745 3.422c.652-.326 1.304-.652 1.874-1.1-1.264-1.181-2.893-2.037-4.686-2.281 1.142.937 2.119 2.077 2.812 3.381zm-3.871 5.012h4.4c-.04-1.222-.366-2.77-.937-3.993-1.1.366-2.241.61-3.463.651v3.342zm0 4.074c1.222.082 2.404.285 3.463.692.57-1.222.897-2.321.937-3.951h-4.4v3.26zm0 .815v4.115c1.222-.938 2.363-2.119 3.137-3.504-.977-.367-1.915-.57-3.137-.61zm-.815 0c-1.222.04-2.159.244-3.137.61.775 1.345 1.915 2.567 3.137 3.505v-4.115zm0-4.074h-4.44c.081 1.63.366 2.73.936 3.951 1.1-.407 2.282-.652 3.504-.692v-3.26zm0-4.157c-1.222-.04-2.362-.243-3.463-.65-.57 1.222-.896 2.77-.936 3.992h4.399v-3.342zm0-.815V7.453c-1.222.897-2.362 2.119-3.097 3.464.978.326 1.875.529 3.097.57zm.815-4.033v4.033c1.222-.04 2.12-.203 3.096-.57-.733-1.344-1.874-2.525-3.096-3.463zm8.556 8.19c-.122-2.037-.937-4.034-2.2-5.5-.652.488-1.344.896-2.078 1.221.57 1.304.937 3.057.978 4.279h3.3z"
          fill="currentColor"
          fillRule="evenodd"
        />
      ),
      name: "Your Website Activity in Session"
    }
  ],
  [
    Attribute.KEYWORD_SEARCH,
    {
      img:
        process.env.PUBLIC_URL +
        "/img/ico-navigator-keyword-part-num-search.svg",
      icon: () => (
        <path
          d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm4.122-15.13a5.277 5.277 0 0 1-5.28 5.28 5.277 5.277 0 0 1-5.28-5.28 5.277 5.277 0 0 1 5.28-5.28 5.277 5.277 0 0 1 5.28 5.28zm3 7.086l-2.834-2.835c.973-1.25 1.501-2.75 1.501-4.28a6.95 6.95 0 0 0-6.947-6.946 6.95 6.95 0 0 0-6.947 6.947 6.95 6.95 0 0 0 6.947 6.947 6.803 6.803 0 0 0 4.28-1.5l2.834 2.834a.8.8 0 0 0 .583.25.8.8 0 0 0 .584-.25.806.806 0 0 0 0-1.167z"
          fill="currentColor"
          fillRule="evenodd"
        />
      ),
      name: "Keyword / Part Number Search"
    }
  ],
  [
    Attribute.ADVANCED_SEARCH,
    {
      img:
        process.env.PUBLIC_URL + "/img/ico-navigator-adv-search-col-sort.svg",
      icon: () => (
        <path
          d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm5.166-15.16a.708.708 0 0 0 .703-.703.698.698 0 0 0-.208-.494l-4.922-4.921a.696.696 0 0 0-.493-.209.696.696 0 0 0-.494.21l-4.921 4.92a.696.696 0 0 0-.209.494c0 .385.318.703.703.703h9.84zm.495 4.01a.697.697 0 0 0 .208-.495.708.708 0 0 0-.704-.703h-9.84a.708.708 0 0 0-.703.703c0 .186.077.362.209.494l4.92 4.92a.698.698 0 0 0 .495.21.698.698 0 0 0 .493-.21l4.922-4.92z"
          fill="currentColor"
          fillRule="evenodd"
        />
      ),
      name: "Advanced Column Search/ Attribute Column Sort"
    }
  ],
  [
    Attribute.PAGE_EMAILED,
    {
      img: process.env.PUBLIC_URL + "/img/ico-navigator-page-emailed.svg",
      icon: () => (
        <path
          d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zM6.174 18.57l4.528-2.983-4.528-2.982v5.964zm19.82 1.675l-5.316-3.556-4.034 2.725a1 1 0 0 1-1.12 0l-4.034-2.725-5.316 3.556v.293a1 1 0 0 0 1 1h17.82a1 1 0 0 0 1-1v-.293zm-19.82-9.191l9.344 6.412a1 1 0 0 0 1.132 0l9.344-6.412v-.293a1 1 0 0 0-1-1H7.174a1 1 0 0 0-1 1v.293zm19.82 1.551l-4.529 2.982 4.529 2.982v-5.964z"
          fill="currentColor"
          fillRule="evenodd"
        />
      ),
      name: "Page Emailed"
    }
  ],
  [
    Attribute.PAGE_FAVORITED,
    {
      img:
        process.env.PUBLIC_URL +
        "/img/ico-navigator-page-saved-to-favorites.svg",
      icon: () => (
        <path
          d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm5.791-6.721V8.895a1 1 0 0 0-1-1H10.86a1 1 0 0 0-1 1v14.384a1 1 0 0 0 1.497.867l3.97-2.277a1 1 0 0 1 .996 0l3.97 2.277a1 1 0 0 0 1.498-.867z"
          fill="currentColor"
          fillRule="evenodd"
        />
      ),
      name: "Page Saved to Favorites"
    }
  ],
  [
    Attribute.COMPARED_ITEMS,
    {
      img: process.env.PUBLIC_URL + "/img/ico-navigator-compare-item.svg",
      icon: () => (
        <path
          d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm7.78-13.211h-5.191v-2.024c0-.602-.333-.764-.796-.36l-4.273 3.758c-.463.404-.45 1.066.011 1.47l4.257 3.758c.462.405.8.243.8-.36V20.89h5.192c.624 0 1.049-.43 1.049-1.032V17.94c0-.603-.425-1.151-1.049-1.151zm-6.549-4.914l-4.26-3.758c-.462-.404-.807-.242-.807.36v2.227H6.987c-.623 0-1.057.346-1.057.949v1.917c0 .601.434 1.235 1.057 1.235h5.177v1.939c0 .602.338.764.8.36l4.272-3.758c.462-.405.457-1.066-.005-1.47z"
          fill="currentColor"
          fillRule="evenodd"
        />
      ),
      name: "Compared Items"
    }
  ],
  [
    Attribute.CAD,
    {
      img: process.env.PUBLIC_URL + "/img/ico-navigator-CAD.svg",
      icon: () => (
        <g fill="none" fillRule="evenodd">
          <path
            d="M15.951 29.902C8.246 29.902 2 23.656 2 15.951S8.246 2 15.951 2s13.951 6.246 13.951 13.951-6.246 13.951-13.951 13.951zm.065-25.086L6.883 8.85l4.127 1.996 4.859-1.963a.355.355 0 0 1 .304.002l4.916 1.961 4.032-1.996-9.105-4.034zm-5.537 14.581a.466.466 0 0 1-.031-.134v-6.95l-4.063-2.048v11.176l8.596 4.095V21.52l-4.346-1.953a.582.582 0 0 1-.072-.05.363.363 0 0 1-.084-.12zm4.76-3.478v-4.994l-3.269 1.584v4.994l3.269-1.584zm.768 1.296l-3.394 1.525 3.394 1.644L19.4 18.74l-3.393-1.525zm9.692 4.248V10.287l-4.063 2.048c0 4.639-.003 6.969-.005 6.99a.418.418 0 0 1-.039.125.383.383 0 0 1-.143.14l-4.432 1.953v4.015l8.682-4.095zm-5.66-8.954l-3.27-1.584v4.994l3.27 1.584v-4.994z"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      ),
      name: "CAD"
    }
  ],
  [
    Attribute.PRODUCT_VIEW,
    {
      img:
        process.env.PUBLIC_URL + "/img/ico-navigator-product-service-view.svg",
      name: "Product / Service View",
      icon: () => (
        <path
          d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm-1.805-9.642a4.717 4.717 0 1 1 3.61-8.716 4.717 4.717 0 0 1-3.61 8.716zm-3.818 3.667l1.61-1.546a7.64 7.64 0 0 0 2.269.94l.045 2.232a9.735 9.735 0 0 0 3.398 0l.045-2.232a7.678 7.678 0 0 0 2.269-.94l1.61 1.546a9.739 9.739 0 0 0 2.402-2.402l-1.546-1.61a7.662 7.662 0 0 0 .94-2.27l2.232-.044a9.744 9.744 0 0 0 0-3.398l-2.233-.045a7.664 7.664 0 0 0-.94-2.269l1.547-1.61a9.739 9.739 0 0 0-2.402-2.403l-1.61 1.547a7.682 7.682 0 0 0-2.27-.94L17.7 6.349a9.735 9.735 0 0 0-3.398 0l-.045 2.232a7.653 7.653 0 0 0-2.269.94l-1.61-1.546a9.739 9.739 0 0 0-2.403 2.402l1.547 1.61a7.64 7.64 0 0 0-.94 2.27l-2.232.044a9.744 9.744 0 0 0 0 3.398l2.232.045c.19.801.507 1.568.94 2.269l-1.546 1.609a9.745 9.745 0 0 0 2.402 2.403z"
          fill="currentColor"
          fillRule="evenodd"
        />
      )
    }
  ],
  [
    Attribute.PAGE_PRINTED,
    {
      img: process.env.PUBLIC_URL + "/img/ico-navigator-printed-page.svg",
      icon: () => (
        <svg
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
          className="icon"
        >
          <title>ico-flag-navigator-page-printed</title>
          <g fill="currentColor" fillRule="evenodd">
            <path d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zM11.866 7.717a1 1 0 0 0-1 1v1.551h10.347v-1.55a1 1 0 0 0-1-1h-8.347zm12.158 3.827H7.976a1 1 0 0 0-1 1v7.136a1 1 0 0 0 1 1h1.567v-3.392H22.38v3.392h1.645a1 1 0 0 0 1-1v-7.136a1 1 0 0 0-1-1zm-13.073 6.975v5.858h10.098V18.52H10.951z" />
            <path d="M23.79 13.996h-1.94V12.72h1.94z" />
          </g>
        </svg>
      ),
      name: "Printed Page"
    }
  ],

  [
    Attribute.THOMAS_REGISTERED_USER,
    {
      img: process.env.PUBLIC_URL + "/img/ico-thomas-registered-user.svg",
      icon: () => (
        <path
          d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm5.497-5.217a.772.772 0 0 0 .738-1.011l-1.884-5.878 4.981-3.652a.774.774 0 1 0-.455-1.399l-6.173-.024-1.933-5.866a.774.774 0 0 0-1.471 0l-1.931 5.864-6.176.027a.773.773 0 1 0-.455 1.399l4.98 3.647-1.884 5.882a.775.775 0 0 0 1.19.865l5.01-3.609 5.012 3.609a.766.766 0 0 0 .451.146z"
          fill="currentColor"
          fillRule="evenodd"
        />
      ),
      name: "Thomasnet.com Registered User",
      color: "icon-green"
    }
  ],
  [
    Attribute.TOP_PAGES,
    {
      img: process.env.PUBLIC_URL + "/img/ico-top-page.svg",
      icon: () => (
        <g fill="currentColor" fillRule="evenodd">
          <path d="M24.026 10.301c.267.26.497.614.688 1.06.19.447.286.856.286 1.228v16.072c0 .372-.134.688-.401.948s-.592.391-.974.391H4.375c-.382 0-.707-.13-.974-.39A1.275 1.275 0 0 1 3 28.66V6.34c0-.373.134-.689.401-.95.267-.26.592-.39.974-.39h12.833c.382 0 .802.093 1.26.279.46.186.822.41 1.09.67l4.468 4.352zM6 17v2h16v-2H6zm0 4v2h16v-2H6zm0 4v2h16v-2H6z" />
          <path d="M27.167 26V12.293c0-.644-.153-1.297-.437-1.962-.282-.659-.643-1.216-1.098-1.659l-4.469-4.353c-.451-.44-1.013-.785-1.677-1.055-.664-.269-1.311-.412-1.944-.412H7v-.513c0-.372.134-.688.401-.948S7.993 1 8.375 1h11.917c.382 0 .802.093 1.26.279.458.186.821.41 1.089.67l5.385 5.278c.267.26.497.614.688 1.06.19.447.286.856.286 1.228v15.146c0 .372-.134.688-.401.948s-.592.391-.974.391h-.458z" />
        </g>
      ),
      name: "Top Pages"
    }
  ],
  [
    Attribute.ENTRY_PAGES,
    {
      img: process.env.PUBLIC_URL + "/img/ico-entry-page.svg",
      icon: () => (
        <g fill="currentColor" fillRule="nonzero">
          <path d="M19.705 27.053h6.348V4.947h-6.348a1 1 0 0 1-1-1v-.814a1 1 0 0 1 1-1h8.162a1 1 0 0 1 1 1v25.734a1 1 0 0 1-1 1h-8.162a1 1 0 0 1-1-1v-.814a1 1 0 0 1 1-1z" />
          <path d="M3.61 13.92c-.382 0-.595.411-.595.792v2.576c0 .381.213.792.595.792h7.379v3.763a1 1 0 0 0 1.624.781l7.304-5.843a1 1 0 0 0 0-1.562l-7.304-5.843a1 1 0 0 0-1.624.78v3.764h-7.38z" />
        </g>
      ),
      name: "Entry Pages"
    }
  ],
  [
    Attribute.EXIT_PAGES,
    {
      img: process.env.PUBLIC_URL + "/img/ico-exit-page.svg",
      icon: () => (
        <g fill="currentColor" fillRule="nonzero">
          <path d="M11.434 13.92c-.381 0-.594.411-.594.792v2.576c0 .381.213.792.594.792h7.38v3.763a1 1 0 0 0 1.624.781l7.304-5.843a1 1 0 0 0 0-1.562l-7.304-5.843a1 1 0 0 0-1.625.78v3.764h-7.379z" />
          <path d="M11.948 27.134H5.599V5.028h6.349a1 1 0 0 0 1-1v-.814a1 1 0 0 0-1-1H3.786a1 1 0 0 0-1 1v25.734a1 1 0 0 0 1 1h8.162a1 1 0 0 0 1-1v-.814a1 1 0 0 0-1-1z" />
        </g>
      ),
      name: "Exit Pages"
    }
  ],
  [
    Attribute.FUNNEL_ANALYSIS,
    {
      img: process.env.PUBLIC_URL + "/img/ico-funnel-analysis.svg",
      icon: () => (
        <path
          d="M29.8 6.854L18.79 18.68a1 1 0 0 0-.268.682v6.843a1 1 0 0 1-.462.843l-2.967 1.896a1 1 0 0 1-1.538-.843v-8.742a1 1 0 0 0-.266-.678L2.334 6.823a1 1 0 0 1-.266-.679V5.04a1 1 0 0 1 1-1h26a1 1 0 0 1 1 1v1.134a1 1 0 0 1-.268.681z"
          fill="currentColor"
          fillRule="evenodd"
        />
      ),
      name: "Funnel Analysis"
    }
  ],
  [
    Attribute.EXTERNAL_LINK,
    {
      img: process.env.PUBLIC_URL + "/img/ico-external-link.svg",
      icon: () => (
        <g fill="currentColor" fillRule="evenodd">
          <path d="M4.003 5L4 8.004v15.992L4.004 27a1 1 0 0 0 1 1h21.992a1 1 0 0 0 1-1L28 23.997v-4.663l-3-3.318.005 8.984H6.995V7H16l-3.333-3-7.663.001a1 1 0 0 0-1 1z" />
          <path
            d="M18.255 16.228c-.279.26-.715.104-.975-.175l-1.756-1.883c-.26-.28-.385-.725-.106-.985l5.396-5.033L18.248 5.4a1 1 0 0 1 .656-1.679l9.327-.707a1 1 0 0 1 1.065 1.142l-1.357 9.254a1 1 0 0 1-1.72.537l-2.567-2.752-5.397 5.033z"
            fillRule="nonzero"
          />
        </g>
      ),
      name: "Go To",
      color: "icon-lightblue"
    }
  ],
  [
    Attribute.OPEN,
    {
      icon: () => (
        <path
          d="M12.615 12.615V3a1 1 0 0 1 1-1h4.77a1 1 0 0 1 1 1v9.615H29a1 1 0 0 1 1 1v4.77a1 1 0 0 1-1 1h-9.615V29a1 1 0 0 1-1 1h-4.77a1 1 0 0 1-1-1v-9.615H3a1 1 0 0 1-1-1v-4.77a1 1 0 0 1 1-1h9.615z"
          fill="currentColor"
          fillRule="evenodd"
        />
      )
    }
  ],
  [
    Attribute.COLLAPSE,
    {
      icon: () => (
        <path fill="#01334D" fillRule="evenodd" d="M5 13h22v5.45H5z" />
      ),
      img: process.env.PUBLIC_URL + "/img/ico-close.svg"
    }
  ],
  [
    Attribute.CLOSE,
    {
      icon: () => (
        <path
          d="M11.213 16L4.414 9.2a1 1 0 0 1 0-1.413l3.373-3.373a1 1 0 0 1 1.414 0l6.799 6.8 6.8-6.8a1 1 0 0 1 1.413 0l3.373 3.373a1 1 0 0 1 0 1.414L20.786 16l6.8 6.8a1 1 0 0 1 0 1.413l-3.373 3.373a1 1 0 0 1-1.414 0L16 20.786l-6.8 6.8a1 1 0 0 1-1.413 0l-3.373-3.373a1 1 0 0 1 0-1.414l6.8-6.799z"
          fill="currentColor"
          fillRule="evenodd"
        />
      )
    }
  ],
  [
    Attribute.HELP,
    {
      icon: () => (
        <path
          d="M21.096 15.227a6.54 6.54 0 0 1-2.262 1.975c-.515.344-.858.687-1.03 1.088-.115.258-.2.601-.258 1.002-.029.315-.315.545-.63.545h-2.777a.624.624 0 0 1-.63-.66c.057-.772.258-1.373.573-1.832.4-.544 1.116-1.202 2.147-1.918a3.987 3.987 0 0 0 1.26-1.23c.315-.488.458-1.06.458-1.719 0-.658-.172-1.202-.544-1.603-.372-.4-.859-.601-1.518-.601a1.99 1.99 0 0 0-1.345.487c-.23.2-.372.458-.458.801-.115.401-.487.659-.916.659l-2.577-.058a.558.558 0 0 1-.544-.6c.086-1.375.63-2.406 1.575-3.15 1.087-.83 2.49-1.26 4.265-1.26 1.89 0 3.379.487 4.467 1.432 1.087.945 1.632 2.233 1.632 3.865 0 1.03-.315 1.947-.888 2.777zm-3.579 8.703c0 .487-.4.888-.887.888h-2.262a.892.892 0 0 1-.888-.888v-1.46c0-.486.401-.887.888-.887h2.262c.487 0 .887.4.887.887v1.46zM16 2C8.27 2 2 8.27 2 16s6.27 14 14 14 14-6.27 14-14S23.73 2 16 2z"
          fill="currentColor"
          fillRule="nonzero"
        />
      ),
      color: "icon-green"
    }
  ],
  [
    Attribute.ARROW_RIGHT,
    {
      icon: () => (
        <path
          d="M18.895 6.306L17.783 7.43a1 1 0 0 0 .003 1.41l5.284 5.282H3a1 1 0 0 0-1 1v1.893a1 1 0 0 0 1 1h20.068L17.781 23.3a1 1 0 0 0 0 1.414l1.115 1.115a1 1 0 0 0 1.417-.003l8.988-9.054a1 1 0 0 0 0-1.409l-8.986-9.057a1 1 0 0 0-1.42 0z"
          fill="currentColor"
          fillRule="nonzero"
        />
      ),
      color: "icon-green"
    }
  ],
  [
    Attribute.ARROW_DOWN,
    {
      icon: () => (
        <path
          d="M3.879 3.803l11.878 3.736 12.527-3.78a1 1 0 0 1 1.14 1.481L16.916 25.612a1 1 0 0 1-1.704 0L2.726 5.28A1 1 0 0 1 3.88 3.803z"
          fill="currentColor"
          fillRule="evenodd"
        />
      )
    }
  ],
  [
    Attribute.CHECKMARK,
    {
      icon: () => (
        <path
          d="M27.604 3.372a1.155 1.155 0 0 0-1.628.112L11.614 19.958 6.477 17.15a1.19 1.19 0 0 0-1.503 1.784l5.457 6.865a2.473 2.473 0 0 0 4.014-.198l.179-.278L27.816 4.867a1.153 1.153 0 0 0-.212-1.495z"
          fill="currentColor"
          fillRule="nonzero"
        />
      ),
      color: "icon-green"
    }
  ],
  [
    Attribute.EDIT,
    {
      icon: () => (
        <path
          d="M5.857 20.878L19.868 6.866l.01-.009 5.265 5.265-14.011 14.012-.01.009-5.265-5.265zm-1.589 1.408l5.446 5.446-.015.004.006.005L2 30l2.259-7.705.005.006.004-.015zM28.091 5.008a3.106 3.106 0 0 1-.001 4.393l-.01.009-.777.777-.517.518-.01.01-5.49-5.491.786-.787.528-.527a3.106 3.106 0 0 1 4.392 0l1.099 1.098z"
          fill="currentColor"
          fillRule="nonzero"
        />
      )
    }
  ],
  [
    Attribute.ARROW_UP,
    {
      icon: () => (
        <path
          d="M27.921 26.197l-11.878-3.736-12.527 3.78a1 1 0 0 1-1.14-1.481L14.884 4.388a1 1 0 0 1 1.704 0L29.074 24.72a1 1 0 0 1-1.153 1.477z"
          fill="currentColor"
          fillRule="evenodd"
        />
      )
    }
  ],
  [
    Attribute.FORMS,
    {
      icon: () => (
        <path
          d="M11.75 2.63l-.677 1.703h9.854l-.676-1.702A1 1 0 0 0 19.32 2H12.68a1 1 0 0 0-.93.63zM6 6.334a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1V29a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V6.333zM10.667 12v2.333H13V12h-2.333zm4.666 0v2.333h6V12h-6zm-4.666 4.667V19H13v-2.333h-2.333zm4.666 0V19h6v-2.333h-6zm-4.666 4.666v2.334H13v-2.334h-2.333zm4.666 0v2.334h6v-2.334h-6z"
          fill="currentColor"
          fillRule="nonzero"
        />
      )
    }
  ],
  [
    Attribute.CAMPAIGN,
    {
      icon: () => (
        <path
          d="M4.399 15.623c.366-.158.833.063.966.441l1.8 4.854a.706.706 0 0 1-.467.914l.067-.032c-1.833.63-3.932-.283-4.566-2.017-.666-1.733.4-3.593 2.266-4.192-.133.063-.066.032-.066.032zM19.498 4.316s1.317-1.192 2.08 1.052c.312.842.798 2.21 1.422 4.033.277.841.59 1.718.867 2.524l.242.842c.104.316.243.631.347.982.798 2.279 1.7 4.839 2.08 5.96.763 2.21-1.005 2.105-1.005 2.105-2.843-.772-5.062-1.193-6.97-1.298l1.734 4.944a.574.574 0 0 1-.347.737l-2.565.911a.564.564 0 0 1-.728-.35l-2.116-6.102c-1.872.281-2.808.772-3.536.982l-.416.14c-.451.176-.971-.105-1.145-.56l-2.184-6.242c-.173-.456.104-.982.555-1.157l.416-.14c2.046-.772 5.444-1.193 11.269-9.363zm7.269 8.63c-.533.183-1.098-.121-1.286-.608-.188-.517.126-1.065.627-1.247l1.882-.609c.533-.182 1.097.122 1.285.609.188.517-.125 1.064-.627 1.247l-1.881.608zm1.099 3.794c-.682-.038-1.174-.651-1.136-1.303a1.226 1.226 0 0 1 1.287-1.15l1.477.116c.681.038 1.174.651 1.136 1.303a1.226 1.226 0 0 1-1.288 1.15l-1.476-.116zm-1.518-7.833c-.542.34-1.418.312-1.877-.085-.5-.369-.459-.965.125-1.277l1.21-.738c.542-.34 1.418-.312 1.876.085.5.37.46.965-.125 1.277l-1.21.738z"
          fill="#00334D"
          fillRule="nonzero"
        />
      ),
      name: "Visitor Clicked on Ad"
    }
  ],
  [
    Attribute.PDF_EXPORT,
    {
      icon: () => (
        <path
          d="M26.938 7.674c.291.278.541.656.75 1.134.208.478.312.916.312 1.314v17.2c0 .398-.146.736-.438 1.015a1.481 1.481 0 0 1-1.062.418h-21c-.417 0-.77-.14-1.063-.418A1.352 1.352 0 0 1 4 27.322V3.433c0-.398.146-.736.438-1.015A1.481 1.481 0 0 1 5.5 2h14c.417 0 .875.1 1.375.299.5.199.896.438 1.188.716l4.875 4.659zm-8.97 10.316c-.947-.736-1.708-1.92-2.28-3.553.124-.687.197-1.642.218-2.867v-.448c0-.05-.005-.09-.015-.119a.32.32 0 0 0 .062-.12c.094-.467.047-.806-.14-1.015-.126-.149-.308-.224-.547-.224h-.344c-.323 0-.542.2-.656.598-.136.497-.17 1.132-.102 1.903.068.772.227 1.565.477 2.382-.375 1.254-.917 2.558-1.625 3.911a36.028 36.028 0 0 1-1.672 2.942c0-.02-.01-.04-.031-.06-.094-.15-.214-.18-.36-.09-.885.448-1.573.928-2.062 1.441-.49.513-.782.968-.875 1.366-.042.259-.01.438.093.538.052.04.084.064.094.074l.375.18c.136.07.281.104.438.104.927 0 2.187-1.304 3.78-3.912 1.782-.587 3.824-1 6.126-1.239.656.328 1.333.592 2.031.791.698.2 1.297.299 1.797.299.677 0 1.047-.19 1.11-.567v-.015l.03-.03c.011-.01.016-.02.016-.03.146-.299.136-.557-.031-.776-.313-.488-1.234-.732-2.766-.732-.604 0-1.213.035-1.828.104a11.943 11.943 0 0 1-1.312-.836z"
          fill="currentColor"
          fillRule="evenodd"
        />
      ),
      name: "Export"
    }
  ],
  [
    Attribute.CALENDAR,
    {
      icon: () => (
        <g fill="currentColor" fillRule="evenodd">
          <path d="M12.522 16.616h2.882v-2.88h-2.882zM16.846 16.616h2.882v-2.88h-2.882zM21.169 16.616h2.882v-2.88h-2.882zM8.2 20.939h2.881v-2.88H8.2zM12.522 20.939h2.882v-2.88h-2.882zM16.846 20.939h2.882v-2.88h-2.882zM21.169 20.939h2.882v-2.88h-2.882zM8.2 25.262h2.881v-2.88H8.2zM12.522 25.262h2.882v-2.88h-2.882zM16.846 25.262h2.882v-2.88h-2.882z" />
          <path d="M20 3v2.5h-8.05V3a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2.5H4a1 1 0 0 0-1 1V29a1 1 0 0 0 1 1h24a1 1 0 0 0 1-1V6.5a1 1 0 0 0-1-1h-1.95V3a1 1 0 0 0-1-1H21a1 1 0 0 0-1 1zm2 5h2V4h-2v4zM8 8h2V4H8v4zM6 27h20V11.327H6V27z" />
        </g>
      ),
      name: "Export"
    }
  ],
  [
    Attribute.LEGEND_CHIP_IMPRESSIONS,
    {
      icon: () => (
        <svg
          width="25px"
          height="40px"
          viewBox="0 0 12 12"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="master"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="TND-220-Legacy-Ad-Reporting"
              transform="translate(-543.000000, -1322.000000)"
              fill="#03405F"
            >
              <g id="Group-41" transform="translate(303.000000, 68.000000)">
                <g id="graph" transform="translate(11.000000, 1217.000000)">
                  <g id="Group-2" transform="translate(20.000000, 32.000000)">
                    <g
                      id="Group-17"
                      transform="translate(209.000000, 0.000000)"
                    >
                      <g id="Group-3">
                        <polygon
                          id="Rectangle-Copy-7"
                          points="0 5 12 5 12 17 0 17"
                        ></polygon>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      ),
      name: "Legend"
    }
  ],
  [
    Attribute.LEGEND_CHIP_CLICKS,
    {
      icon: () => (
        <svg
          width="25px"
          height="40px"
          viewBox="0 0 12 12"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="master"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="TND-220-Legacy-Ad-Reporting"
              transform="translate(-543.000000, -1322.000000)"
              fill="#3cb4e8"
            >
              <g id="Group-41" transform="translate(303.000000, 68.000000)">
                <g id="graph" transform="translate(11.000000, 1217.000000)">
                  <g id="Group-2" transform="translate(20.000000, 32.000000)">
                    <g
                      id="Group-17"
                      transform="translate(209.000000, 0.000000)"
                    >
                      <g id="Group-3">
                        <polygon
                          id="Rectangle-Copy-7"
                          points="0 5 12 5 12 17 0 17"
                        ></polygon>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      ),
      name: "Legend"
    }
  ]
]);
