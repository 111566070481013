const depthKeys = [
    {
        label: "# Pages",
        key: "depth"
    },
    {
        label: "Pct",
        key: "percentage"
    },
    {
        label: "Sessions",
        key: "sessionsct"
    }
];

export default depthKeys;
