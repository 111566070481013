import React from "react";
import ThomasTable from "views/components/ThomasTable/ThomasTable";
import actionKeys from "views/pages/Home/data/actionsKeys";
import hvActionsTableFactory from "utils/tableFactories/hvActionsTableFactory";
import RfiSubmission from "views/components/ResultsTimeline/RfiSubmission/RfiSubmission";
import detailsKeys from "views/sections/TableSection/detailsKeys";
import PhoneCallContainer from "containers/PhoneCallContainer";
import SessionInfo from "views/components/SessionInfo/SessionInfo";
import InLineRfiDetail from "views/sections/TableSection/InLineRfiDetail";
import Pagination from "views/components/Pagination/Pagination";
import { Export } from "views/components/Export/Export";

//Sample
// import hvActions from "views/pages/Home/data/hvActions";

const actionDetailsTitle = type => {
  if (type === "RFI")
    return <div className="rfi-details--header">RFI Detail</div>;
  else if (type === "PHONE_CALL") return "Thomasnet: Phone Inquiry Detail";
  else if (type === "ASSET_DOWNLOAD") return "Asset Download Detail";
  else return "Details";
};

const phoneClassification = {
  lead: "Sales Inquiry",
  solicitation: "Solicitation",
  other: "Other Inquiry",
  unclassified: "Unclassified"
};

const PhoneFilter = ({ name, value, cb, label, amount }) => (
  <div className="hvActions-filters--phone">
    <input name={name} type="checkbox" checked={value} onChange={cb} />
    <label className={`hvActions--${name}`}>
      <span>{`${label ? label : phoneClassification[name]}`} </span>
    </label>
  </div>
);

class HvActionsTableSection extends React.Component {
  constructor(props) {
    super(props);

    const initShow = props.showAll || false;

    this.state = {
      filters: {
        lead: true,
        other: initShow,
        solicitation: initShow,
        unclassified: initShow
      },
      showAll: false,
      currentPage: 1
    };

    this.setFilter = this.setFilter.bind(this);
    this.setAllFilters = this.setAllFilters.bind(this);
  }

  getActiveFiltersArray() {
    return Object.keys(this.state.filters).filter(f => this.state.filters[f]);
  }

  setFilter(e) {
    const target = e.target;
    const value = target.checked;
    const name = target.name;

    const { showAll } = this.state;

    this.setState(
      {
        showAll: showAll ? (value ? showAll : false) : showAll,
        filters: {
          ...this.state.filters,
          [name]: value
        }
      },
      () => {
        this.props.onFilterUpdate &&
          this.props.onFilterUpdate(this.getActiveFiltersArray());
      }
    );
  }

  setAllFilters(e) {
    // const value = e.target.checked;
    this.setState(
      {
        filters: {
          lead: true,
          other: true,
          solicitation: true,
          unclassified: true
        },
        showAll: true
      },
      () => {
        this.props.onFilterUpdate &&
          this.props.onFilterUpdate(this.getActiveFiltersArray());
      }
    );
  }

  updatePage(page, onUpdate) {
    this.setState(
      {
        currentPage: page
      },
      onUpdate(page)
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate
    ) {
      this.setState({
        currentPage: 1
      });
    }
  }

  render() {
    const {
      data,
      user,
      startDate,
      endDate,
      vettedFilters,
      showLink,
      hideFilters,
      hidePagination,
      hideEmptyComponent,
      onUpdate,
      totalPages,
      filteredActivitiesCount
    } = this.props;

    const { currentPage } = this.state;
    const { lead, other, solicitation, unclassified } = this.state.filters;

    const filteredData = (data.edges || []).map(e => ({
      ...e.node,
      key: e.cursor
    }));

    return (
      <React.Fragment>
        {filteredData.length > 0 && hideEmptyComponent && (
          <div className="section-header--with-export">
            <h2>Thomas RFIs &amp; Phone Calls</h2>
            <div className="results-control">
              <div className="results-control__utility-icons">
                <Export
                  query={{
                    startDate,
                    endDate,
                    vettedFilters
                  }}
                  downloadType="HIGH_VALUE_ACTIVITIES"
                />
              </div>
            </div>
          </div>
        )}
        {!hideFilters && (
          <section
            className="session-detail card"
            style={{ marginBottom: "2rem" }}
          >
            <SessionInfo
              title={"Actions"}
              total={filteredActivitiesCount}
              startDate={startDate}
              endDate={endDate}
              showBorder
            />
            <div className="hvActions-filters">
              <p>VIEW BY TYPE </p>
              <PhoneFilter name="lead" value={lead} cb={this.setFilter} />

              <PhoneFilter name="other" value={other} cb={this.setFilter} />
              <PhoneFilter
                name="solicitation"
                value={solicitation}
                cb={this.setFilter}
              />
              <PhoneFilter
                name="unclassified"
                value={unclassified}
                cb={this.setFilter}
              />
            </div>
          </section>
        )}

        {filteredData.length > 0 ? (
          <>
            {!hideEmptyComponent && (
              <div className="results-control">
                <div className="results-control__utility-icons">
                  <Export
                    query={{
                      startDate,
                      endDate,
                      vettedFilters
                    }}
                    downloadType="HIGH_VALUE_ACTIVITIES"
                  />
                </div>
              </div>
            )}

            <ThomasTable
              tableCols={actionKeys}
              data={filteredData}
              contentModifier={user}
              contentFactory={hvActionsTableFactory}
              gClass={"hvActions"}
              link={false}
              onLoadMore={() => {}}
              openKey="timestamp"
              detailsTitle={actionDetailsTitle}
              showAllLink={showLink}
              detailsComponent={d => {
                return d.actionType === "PHONE_CALL" ? (
                  <PhoneCallContainer
                    page="sessions-actions"
                    callid={d.calltracking ? d.calltracking : d.mirrorsite}
                    source={d.source}
                  >
                    {({ data, loading }) => {
                      const mergedData = {
                        ...data,
                        summary: d.subject,
                        classification: phoneClassification[d.vetted]
                      };
                      return (
                        <RfiSubmission
                          noTitle
                          loading={loading}
                          formSubmissions={[mergedData]}
                          customKeys={detailsKeys[d.actionType]}
                          customClass="hvActions-rfi"
                        />
                      );
                    }}
                  </PhoneCallContainer>
                ) : (
                  <InLineRfiDetail data={[d]} customClass="hvActions-rfi" />
                );
              }}
            />
          </>
        ) : (
          hideEmptyComponent || (
            <div className="card">
              <p className="empty-search-results">
                <React.Fragment>
                  There is no data available for your selection,{" "}
                  <span onClick={this.setAllFilters}>Select All</span>
                </React.Fragment>
              </p>
            </div>
          )
        )}

        {!hidePagination && (
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onUpdate={p => this.updatePage(p, onUpdate)}
          />
        )}
      </React.Fragment>
    );
  }
}

export default HvActionsTableSection;
