import React from "react";
import Pagination from "views/components/Pagination/Pagination";
import ThomasIcon from "views/components/ThomasIcon/ThomasIcon";
import "./BlueprintTable.scss";

interface TableRow {
  [key: string]: any;
}
interface TableHeading {
  key: string;
  title: string;
  sort?: string;
}

interface Props {
  data: Array<TableRow>;
  headings: Array<TableHeading>;
  onPageUpdate: Function;
  customClass?: string;
  onSort?: Function;
  sort?: any;
  search?: any;
  pagination?: Boolean;
  totalPages?: Number;
  currentPage?: Number;
  currentSortDir?: String;
  currentSortField?: String;
}

interface State {
  sort: string;
}

class BlueprintTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sort: ""
    };
  }

  render() {
    const {
      data,
      headings,
      onPageUpdate,
      customClass,
      pagination,
      totalPages,
      currentPage,
      currentSortField,
      currentSortDir,
      onSort
    } = this.props;
    return (
      <div className={`blueprint-table ${customClass ? customClass : ""}`}>
        <table className="table">
          <thead>
            <tr>
              {headings.map(tableHead => {
                return tableHead.sort ? (
                  <th
                    scope="col"
                    key={tableHead.key}
                    onClick={() => {
                      console.log("sorting by", tableHead);
                      onSort && onSort(tableHead.key);
                    }}
                  >
                    {tableHead.title}
                    {tableHead.key === currentSortField && (
                      <div
                        className={`icon-arrow-${
                          currentSortDir === "desc" ? "down" : "up"
                        }`}
                      >
                        <ThomasIcon
                          attribute={
                            currentSortDir === "desc"
                              ? "ARROW_DOWN"
                              : "ARROW_UP"
                          }
                          raw
                        />
                      </div>
                    )}
                  </th>
                ) : (
                  <th scope="col" key={tableHead.key}>
                    {tableHead.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((tableRow, index) => {
              return (
                <tr key={tableRow.key || tableRow.id || index}>
                  {headings.map(heading => {
                    if (typeof tableRow[heading.key] === "function") {
                      return (
                        <td key={heading.key}>{tableRow[heading.key]()}</td>
                      );
                    }
                    return <td key={heading.key}>{tableRow[heading.key]}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {pagination && (
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onUpdate={(p: number) => onPageUpdate(p)}
          />
        )}
      </div>
    );
  }
}

export default BlueprintTable;
