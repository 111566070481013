import React from "react";

const Alert = () => (
  <div
    className="alert alert-warning"
    style={{
      display: "flex",
    }}
  >
    <i
      className="icon icon-block--sm"
      style={{
        paddingTop: "0.3em",
        paddingLeft: "0"
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"></path>
      </svg>
    </i>
    <div>
      <strong>
        We identified and fixed an issue regarding your Thomas referred
        sessions.
      </strong>
      <br />
      Many sessions referred to your website from Thomas between
      11/20/2023-1/8/2024 were not identified as Thomas referred sessions in
      reports. All other time periods are unaffected and will accurately reflect
      Thomas referred sessions.
    </div>
  </div>
);

export default Alert;
