import React from "react";

import "./SessionDepthDetails.css";

import visitorKeys from "./data/visitorKeys";
import visitorInfo from "./data/visitorInfo";

const SessionDepthDetails = () => {
    return (
        <div className="session-depth-details">
            <div className="session-depth--visitor-information">
                <div>
                    {visitorKeys.slice(0, 4).map(k => (
                        <div
                            key={k.key}
                            className="sd-visitor-information--row"
                        >
                            <div className="title">{k.label}:</div>
                            <span>{visitorInfo[k.key]}</span>
                        </div>
                    ))}
                </div>

                <div>
                    {visitorKeys.slice(4, 8).map(k => (
                        <div
                            key={k.key}
                            className="sd-visitor-information--row"
                        >
                            <div className="title">{k.label}:</div>
                            <span>{visitorInfo[k.key]}</span>
                        </div>
                    ))}
                </div>

                <div>
                    {visitorKeys.slice(8, 13).map(k => (
                        <div
                            key={k.key}
                            className="sd-visitor-information--row"
                        >
                            <div className="title">{k.label}:</div>
                            <span>{visitorInfo[k.key]}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div
                className="table-row-details--header"
                style={{ marginTop: "1rem" }}
            >
                Session Path Details
            </div>

            <div className="session-path-details">
                <div className="session-path-details--row">
                    <span />
                    <b>DATE/TIME</b>
                    <b>PAGE VIEWED</b>
                    <b>ACTIONS</b>
                </div>
                <div className="session-path-details--row">
                    <div className="sd-pageNum">1</div>
                    <div className="sd-dateTime">05/23/2018 23:33:51</div>
                    <div className="sd-pageUrl">
                        /gages-for-precise-measurement-of-elastic/
                    </div>
                    <div className="sd-action">action</div>
                </div>
                <div className="session-path-details--row">
                    <div className="sd-pageNum">2</div>
                    <div className="sd-dateTime">05/23/2018 23:33:51</div>
                    <div className="sd-pageUrl">
                        /gages-for-precise-measurement-of-elasticfdsafasdf/
                    </div>
                    <div className="sd-action">action</div>
                </div>
                <div className="session-path-details--row">
                    <div className="sd-pageNum">3</div>
                    <div className="sd-dateTime">05/23/2018 23:33:51</div>
                    <div className="sd-pageUrl">/gages-fc/</div>
                    <div className="sd-action">action</div>
                </div>
            </div>
        </div>
    );
};

export default SessionDepthDetails;
