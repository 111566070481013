import moment from "moment";

function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
}

const detailsKeys = {
  PHONE_CALL: [
    {
      value: "callerPhone",
      label: "Caller ID",
      format: val => formatPhoneNumber(val)
    },
    // { value: "callerName", label: "" },
    // { value: "callerLocation", label: "" },
    { value: "dateTime", label: "Received" },
    {
      value: "targetPhone",
      label: "Target Phone",
      format: val => formatPhoneNumber(val)
    },
    {
      value: "duration",
      label: "Call Duration"
    },
    { value: "classification", label: "Classification" },
    { value: "summary", label: "Summary" },
    { value: "permanentFile", label: "Call Recording" }
  ],
  RFI: [
    {
      value: "timestamp",
      label: "Submission Time",
      format: val => moment(val).format("MMM D, YYYY hh:mm A")
    },
    {
      value: "name",
      label: "Name"
    },
    {
      value: "email",
      label: "Email Address"
    },
    {
      value: "subject",
      label: "RFI Subject"
    }
  ]
};

export default detailsKeys;
