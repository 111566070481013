import React from "react";

import "./VideoCard.css";

const VideoCard = () => (
    <div className="video-grid-item">
        <div className="vgi--video">
            <img src="/img/ico-play-video-button.svg" alt="play" />
        </div>
        <div className="vgi--title">
            Understanding the WebTrax Home Dashboard
        </div>
        <div className="vgi--subtitle">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen...
        </div>
    </div>
);

export default VideoCard;
