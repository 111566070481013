import React from "react";
import SiteNav from "./SiteNav";
import SiteAsideCompany from "./SiteAsideCompany";

class SiteAside extends React.Component {
    constructor() {
        super();
        this.state = {
            active: "/"
        };
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.location === this.state.active) {
            return false;
        }
        return true;
    }

    render() {
        const { nav, company, location, user } = this.props;
        const { active } = this.state;

        return (
            <aside className="site-aside">
                {company && <SiteAsideCompany company={company} />}
                <nav className="side-nav">
                    {nav && (
                        <SiteNav
                            active={
                                active === location.pathname
                                    ? active
                                    : location.pathname
                            }
                            subnav={nav}
                            level={1}
                            updateNav={k => this.setState({ active: k })}
                            user={user}
                        />
                    )}
                </nav>
            </aside>
        );
    }
}

export default SiteAside;
