import cookie from "react-cookies";

const THOMASNET_COOKIE_OPTIONS = { path: "/", domain: ".thomasnet.com" };

const calculateClockDrift = () => {
  const now = Math.floor(new Date() / 1000);
  const issueTime = window.localStorage.getItem("token_issue_time");

  return now - issueTime;
};

const logOut = (homepath) => {
  cookie.remove("tinid", THOMASNET_COOKIE_OPTIONS);
  cookie.remove("core_x", THOMASNET_COOKIE_OPTIONS);
  cookie.remove("core_u", THOMASNET_COOKIE_OPTIONS);

  //Remove refresh token from thomasnet SSO
  Object.keys(cookie.loadAll()).forEach((key) => {
    if (
      key.startsWith(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_CLIENT_ID}`
      )
    ) {
      cookie.remove(key, {
        ...THOMASNET_COOKIE_OPTIONS,
      });
      cookie.remove(key, {
        ...THOMASNET_COOKIE_OPTIONS,
        secure: true,
      });
    }
  });

  //Making sure the cookies are really killed.
  cookie.remove("core_x");
  cookie.remove("core_u");
  window.localStorage.removeItem("access_token");
  window.localStorage.removeItem("id_token");
  window.localStorage.removeItem("refresh_token");
  window.localStorage.removeItem("token_issue_time");
  window.localStorage.removeItem("token_expiration_time");
  window.localStorage.removeItem("clock_drift");
  window.localStorage.removeItem("wtid");
  window.location.href = homepath;
};

const setAuthTokens = ({
  accessToken,
  idToken,
  refreshToken,
  issueTime,
  expirationTime,
}) => {
  window.localStorage.setItem("access_token", accessToken);
  window.localStorage.setItem("id_token", idToken);
  window.localStorage.setItem("refresh_token", refreshToken);
  window.localStorage.setItem("token_issue_time", issueTime);
  window.localStorage.setItem("token_expiration_time", expirationTime);
  window.localStorage.setItem("clock_drift", calculateClockDrift());
};

const getAuthTokens = () => {
  let refreshToken = null;
  try {
    const lastAuthUser = cookie.load(
      `CognitoIdentityServiceProvider.${process.env.REACT_APP_CLIENT_ID}.LastAuthUser`
    );

    refreshToken = cookie.load(
      `CognitoIdentityServiceProvider.${
        process.env.REACT_APP_CLIENT_ID
      }.${encodeURIComponent(lastAuthUser)}.refreshToken`
    );
  } catch (e) {
    console.error("AUTH - getAuthTokens error: ", e);
  }
  return {
    refreshToken,
  };
};

const setAuthCookies = ({ tinid, coreX, coreU }) => {
  cookie.save("tinid", tinid, THOMASNET_COOKIE_OPTIONS);
  cookie.save("core_x", coreX, THOMASNET_COOKIE_OPTIONS);
  cookie.save("core_u", coreU, THOMASNET_COOKIE_OPTIONS);
};

const getAuthCookies = () => {
  return {
    tinid: cookie.load("tinid"),
    coreX: cookie.load("core_x"),
    coreU: cookie.load("core_u"),
  };
};

const isValid = () => {
  const now = Math.floor(new Date() / 1000);
  const expirationTime = window.localStorage.getItem("token_expiration_time");
  const clockDrift = window.localStorage.getItem("clock_drift");

  if (!clockDrift || !expirationTime) {
    return false;
  }

  return now - parseInt(clockDrift) < parseInt(expirationTime);
};

export {
  isValid,
  setAuthTokens,
  getAuthTokens,
  setAuthCookies,
  getAuthCookies,
  logOut,
};
