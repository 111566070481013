import React from "react";
import JobDisciplineOptions from "views/components/Login/JobDisciplineOptions";
import authService from "services/authServices";

const requiredPropMap = {
  firstName: "First Name",
  lastName: "Last Name",
  jobFunction: "Job Function",
  jobLevel: "Job Level",
  industry: "Industry",
  companyName: "Company Name",
  country: "Country",
  email: "Email",
  password: "Password"
};

function isNumber(InString) {
  if (InString.length === 0) return false;
  var RefString = "1234567890";
  for (var Count = 0; Count < InString.length; Count++) {
    var TempChar = InString.substring(Count, Count + 1);
    if (RefString.indexOf(TempChar, 0) === -1) return false;
  }
  return true;
}
function isValidPword(InString) {
  var hasnum = 0;
  if (InString.length < 6 || InString.length > 20) {
    return false;
  } // Password must be at least 6 and not longer than 20 chars

  var pwRefString =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
  for (var Count = 0; Count < InString.length; Count++) {
    var TempChar = InString.substring(Count, Count + 1);
    if (pwRefString.indexOf(TempChar) === -1) {
      return false;
    }
    if (isNumber(TempChar)) {
      hasnum++;
    }
  }
  if (hasnum < 1) {
    return false;
  } // Password must contain a number
  return true;
}

class CreateThomasAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: props.email,
      firstName: "",
      lastName: "",
      jobFunction: "",
      jobDiscipline: "",
      jobLevel: "",
      industry: "",
      companyName: "",
      companyUrl: "",
      country: "",
      zipCode: "",
      tiu: true,
      ien: true,
      password: "",
      noError: true,
      anyEmpty: true,
      validPword: false,
      pressedSubmit: false
    };

    this.onRegister = this.onRegister.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }

  handleInputChange(e) {
    const target = e.target;

    const anyEmpty = Object.keys(requiredPropMap)
      .filter(field =>
        field === target.name ? target.value === "" : this.state[field] === ""
      )
      .some(i => i !== "");
    const validPword = isValidPword(
      target.name === "password" ? target.value : this.state.password
    );

    this.setState({
      [target.name]: target.value,
      validPword,
      anyEmpty,
      noError: this.state.pressedSubmit ? !anyEmpty && validPword : true
    });
  }

  handleCheckBoxChange(e) {
    const target = e.target;

    this.setState({
      [target.name]: target.checked
    });
  }

  onRegister() {
    const { anyEmpty, validPword } = this.state;
    if (anyEmpty || !validPword) {
      this.setState({
        noError: false,
        pressedSubmit: true
      });
    } else {
      this.setState(
        {
          noError: true,
          pressedSubmit: true
        },
        this.props.onSignUp({ ...this.state })
      );
    }
  }

  submitHandler() {
    authService
      .signUp(
        this.inputs.email,
        this.inputs.firstName,
        this.inputs.lastName,
        this.inputs.password
      )
      .then(res => console.info(res))
      .catch(err => console.error(err));
  }

  render() {
    const {
      email,
      firstName,
      lastName,
      jobFunction,
      jobDiscipline,
      jobLevel,
      industry,
      companyName,
      companyUrl,
      country,
      zipCode,
      tiu,
      ien,
      password,
      noError,
      anyEmpty,
      validPword
    } = this.state;

    const { header, buttonText } = this.props;
    return (
      <React.Fragment>
        {header && header()}

        <div className="form-bg">
          {!noError && (
            <p
              className="alert alert-danger hide"
              id="errMsg"
              style={{ display: "block" }}
            >
              {anyEmpty && (
                <React.Fragment>
                  <b>Missing Required Fields:</b>
                  <br />
                  {Object.keys(requiredPropMap)
                    .filter(field => this.state[field] === "")
                    .map(f => requiredPropMap[f])
                    .join(", ")}
                </React.Fragment>
              )}
              {anyEmpty && !validPword && (
                <React.Fragment>
                  <br />
                  <br />
                </React.Fragment>
              )}
              {!validPword && (
                <React.Fragment>
                  <b>Invalid Login Credentials</b>
                  <br />
                  Passwords may only contain alphanumeric characters, must
                  contain a number, must be at least 6 characters long, and must
                  be no longer than 20 characters
                </React.Fragment>
              )}
            </p>
          )}
          <div>
            <div className="cols-2 mt-0 mb-0 clearfix">
              <div className="form-group">
                <label className="" htmlFor="firstName">
                  First Name *
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  value={firstName}
                  maxLength="100"
                  className={`form-control ${
                    !noError && firstName === "" ? "is-invalid" : ""
                  }`}
                  data-validation="required"
                  type="text"
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="form-group">
                <label className="" htmlFor="lastName">
                  Last Name *
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  value={lastName}
                  maxLength="100"
                  className={`form-control ${
                    !noError && lastName === "" ? "is-invalid" : ""
                  }`}
                  data-validation="required"
                  type="text"
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="form-group">
                <label className="" htmlFor="jobFunction">
                  Job Function *
                </label>
                <select
                  name="jobFunction"
                  id="jobFunction"
                  className={`form-control ${
                    !noError && jobFunction === "" ? "is-invalid" : ""
                  }`}
                  data-validation="required"
                  value={jobFunction}
                  onChange={this.handleInputChange}
                >
                  <option value="">- Select -</option>
                  <option value="engineering">Engineering / Design</option>
                  <option value="supply">
                    Supply Chain / Procurement / Logistics
                  </option>
                  <option value="manufacturing">
                    Manufacturing / Operations
                  </option>
                  <option value="management">General Management</option>
                  <option value="sales">Sales &amp; Marketing</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div className="form-group">
                <label className="" htmlFor="jobDiscipline">
                  Discipline
                </label>
                <select
                  name="jobDiscipline"
                  id="jobDiscipline"
                  className="form-control"
                  disabled=""
                  value={jobDiscipline}
                  onChange={this.handleInputChange}
                >
                  <option value="">- Select -</option>
                  <JobDisciplineOptions jobFunction={jobFunction} />
                </select>
              </div>

              <div className="form-group">
                <label className="" htmlFor="jobLevel">
                  Job Level *
                </label>
                <select
                  name="jobLevel"
                  id="jobLevel"
                  className={`form-control ${
                    !noError && jobLevel === "" ? "is-invalid" : ""
                  }`}
                  value={jobLevel}
                  onChange={this.handleInputChange}
                >
                  <option value="">- Select -</option>
                  <option value="executive">Executive</option>
                  <option value="director">Director</option>
                  <option value="manager">Manager</option>
                  <option value="individual">Individual Contributor</option>
                  <option value="owner">Owner</option>
                </select>
              </div>

              <div className="form-group">
                <label className="" htmlFor="industry">
                  Industry *
                </label>
                <select
                  name="industry"
                  id="industry"
                  className={`form-control ${
                    !noError && industry === "" ? "is-invalid" : ""
                  }`}
                  data-validation="required"
                  value={industry}
                  onChange={this.handleInputChange}
                >
                  <option value="">- Select -</option>
                  <option value="Aerospace &amp; Defense">
                    Aerospace &amp; Defense
                  </option>
                  <option value="Agriculture &amp; Forestry">
                    Agriculture &amp; Forestry
                  </option>
                  <option value="Automotive">Automotive</option>
                  <option value="Business Services">Business Services</option>
                  <option value="Construction">Construction</option>
                  <option value="Distribution&#44; Wholesale&#44; Retail">
                    Distribution&#44; Wholesale&#44; Retail
                  </option>
                  <option value="Education">Education</option>
                  <option value="Electrical Equipment">
                    Electrical Equipment
                  </option>
                  <option value="Electronics">Electronics</option>
                  <option value="Engineering &amp; Technical Services">
                    Engineering &amp; Technical Services
                  </option>
                  <option value="Food&#44; Beverage&#44; Tobacco">
                    Food&#44; Beverage&#44; Tobacco
                  </option>
                  <option value="Government &amp; Military">
                    Government &amp; Military
                  </option>
                  <option value="Machinery">Machinery</option>
                  <option value="Manufacturing (not specified)">
                    Manufacturing (not specified)
                  </option>
                  <option value="Medical &amp; Healthcare">
                    Medical &amp; Healthcare
                  </option>
                  <option value="Metals - Raw&#44; Formed&#44; Fabricated">
                    Metals - Raw&#44; Formed&#44; Fabricated
                  </option>
                  <option value="Mining&#44; Oil &amp; Gas&#44; Quarrying">
                    Mining&#44; Oil &amp; Gas&#44; Quarrying
                  </option>
                  <option value="Paper&#44; Paper Products &amp; Printing">
                    Paper&#44; Paper Products &amp; Printing
                  </option>
                  <option value="Plastics &amp; Rubber">
                    Plastics &amp; Rubber
                  </option>
                  <option value="Textiles&#44; Apparel&#44; Leather">
                    Textiles&#44; Apparel&#44; Leather
                  </option>
                  <option value="Transportation &amp; Logistics">
                    Transportation &amp; Logistics
                  </option>
                  <option value="Utilities &amp; Telecommunications">
                    Utilities &amp; Telecommunications
                  </option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group">
                <label className="" htmlFor="companyName">
                  Company Name *
                </label>
                <input
                  id="companyName"
                  name="companyName"
                  value={companyName}
                  onChange={this.handleInputChange}
                  maxLength="100"
                  className={`form-control ${
                    !noError && companyName === "" ? "is-invalid" : ""
                  }`}
                  data-validation="required"
                  type="text"
                />
              </div>

              <div className="form-group">
                <label className="" htmlFor="companyUrl">
                  Company Website
                </label>
                <input
                  id="companyUrl"
                  name="companyUrl"
                  value={companyUrl}
                  onChange={this.handleInputChange}
                  maxLength="100"
                  className="form-control"
                  type="text"
                />
              </div>

              <div className="form-group">
                <label className="" htmlFor="country">
                  Country *
                </label>
                <select
                  name="country"
                  id="country"
                  className={`form-control chkloc ${
                    !noError && country === "" ? "is-invalid" : ""
                  }`}
                  data-locid="ucloc"
                  data-validation="required"
                  value={country}
                  onChange={this.handleInputChange}
                >
                  <option value="">- Select -</option>
                  <option value="US">United States</option>
                  <option value="AF">Afghanistan</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AS">American Samoa</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AQ">Antarctica</option>
                  <option value="AG">Antigua and Barbuda</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AW">Aruba</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaijan</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrain</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BY">Belarus</option>
                  <option value="BE">Belgium</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BM">Bermuda</option>
                  <option value="BT">Bhutan</option>
                  <option value="BO">Bolivia</option>
                  <option value="BA">Bosnia and Herzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BV">Bouvet Island</option>
                  <option value="BR">Brazil</option>
                  <option value="IO">British Indian Ocean Territory</option>
                  <option value="VG">British Virgin Islands</option>
                  <option value="BN">Brunei Darussalam</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="KH">Cambodia</option>
                  <option value="CM">Cameroon</option>
                  <option value="CA">Canada</option>
                  <option value="CV">Cape Verde</option>
                  <option value="KY">Cayman Islands</option>
                  <option value="CF">Central African Republic</option>
                  <option value="TD">Chad</option>
                  <option value="CL">Chile</option>
                  <option value="CN">China</option>
                  <option value="CX">Christmas Island</option>
                  <option value="CC">Cocos (Keeling) Islands</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comoros</option>
                  <option value="CG">Congo</option>
                  <option value="CK">Cook Islands</option>
                  <option value="CR">Costa Rica</option>
                  <option value="CI">Cote D'Ivoire</option>
                  <option value="HR">Croatia (Hrvatska)</option>
                  <option value="CU">Cuba</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="NF">
                    Democratic People's Republic of Korea
                  </option>
                  <option value="CD">Democratic Republic of Congo</option>
                  <option value="DK">Denmark</option>
                  <option value="DJ">Djibouti</option>
                  <option value="DM">Dominica</option>
                  <option value="DO">Dominican Republic</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egypt</option>
                  <option value="SV">El Salvador</option>
                  <option value="GQ">Equatorial Guinea</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="ET">Ethiopia</option>
                  <option value="FK">Falkland Islands (Malvinas)</option>
                  <option value="FO">Faroe Islands</option>
                  <option value="FM">Federated States of Micronesia</option>
                  <option value="FJ">Fiji</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="GF">French Guiana</option>
                  <option value="PF">French Polynesia</option>
                  <option value="TF">French Southern Territories</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="DE">Germany</option>
                  <option value="GH">Ghana</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GR">Greece</option>
                  <option value="GL">Greenland</option>
                  <option value="GD">Grenada</option>
                  <option value="GP">Guadeloupe</option>
                  <option value="GU">Guam</option>
                  <option value="GT">Guatemala</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HM">Heard Island and MacDonald Islands</option>
                  <option value="HN">Honduras</option>
                  <option value="HK">Hong Kong</option>
                  <option value="HU">Hungary</option>
                  <option value="IS">Iceland</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Ireland</option>
                  <option value="IR">Islamic Republic of Iran</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italy</option>
                  <option value="JM">Jamaica</option>
                  <option value="JP">Japan</option>
                  <option value="JO">Jordan</option>
                  <option value="KZ">Kazakhstan</option>
                  <option value="KE">Kenya</option>
                  <option value="KI">Kiribati</option>
                  <option value="KR">Korea</option>
                  <option value="KW">Kuwait</option>
                  <option value="KG">Kyrgyzstan</option>
                  <option value="LA">Laos</option>
                  <option value="LV">Latvia</option>
                  <option value="LB">Lebanon</option>
                  <option value="LS">Lesotho</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libyan Arab Jamahiriya</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MO">Macao</option>
                  <option value="MK">Macedonia</option>
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldives</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MH">Marshall Islands</option>
                  <option value="MQ">Martinique</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="YT">Mayotte</option>
                  <option value="MX">Mexico</option>
                  <option value="MD">Moldova</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="MS">Montserrat</option>
                  <option value="MA">Morocco</option>
                  <option value="MZ">Mozambique</option>
                  <option value="MM">Myanmar</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NL">Netherlands</option>
                  <option value="AN">Netherlands Antilles</option>
                  <option value="NC">New Caledonia</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NU">Niue</option>
                  <option value="MP">Northern Mariana Islands</option>
                  <option value="NO">Norway</option>
                  <option value="OM">Oman</option>
                  <option value="PK">Pakistan</option>
                  <option value="PW">Palau</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua New Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Peru</option>
                  <option value="PH">Philippines</option>
                  <option value="PN">Pitcairn</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="QA">Qatar</option>
                  <option value="RE">Reunion</option>
                  <option value="RO">Romania</option>
                  <option value="RU">Russian Federation</option>
                  <option value="RW">Rwanda</option>
                  <option value="SH">Saint Helena</option>
                  <option value="KN">Saint Kitts and Nevis</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="PM">Saint Pierre and Miquelon</option>
                  <option value="VC">Saint Vincent and the Grenadines</option>
                  <option value="WS">Samoa</option>
                  <option value="SM">San Marino</option>
                  <option value="ST">Sao Tome and Principe</option>
                  <option value="SA">Saudi Arabia</option>
                  <option value="SN">Senegal</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SB">Solomon Islands</option>
                  <option value="SO">Somalia</option>
                  <option value="ZA">South Africa</option>
                  <option value="GS">South Georgia and...</option>
                  <option value="ES">Spain</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="SD">Sudan</option>
                  <option value="SR">Suriname</option>
                  <option value="SJ">Svalbard and Jan Mayen</option>
                  <option value="SZ">Swaziland</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="SY">Syrian Arab Republic</option>
                  <option value="TW">Taiwan</option>
                  <option value="TJ">Tajikistan</option>
                  <option value="TH">Thailand</option>
                  <option value="TG">Togo</option>
                  <option value="TK">Tokelau</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad and Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turkey</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TC">Turks and Caicos Islands</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UG">Uganda</option>
                  <option value="UA">Ukraine</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="GB">United Kingdom</option>
                  <option value="TZ">United Republic of Tanzania</option>
                  <option value="UM">
                    United States Minor Outlying Islands
                  </option>
                  <option value="VI">United States Virgin Islands</option>
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VA">Vatican</option>
                  <option value="VE">Venezuela</option>
                  <option value="VN">Vietnam</option>
                  <option value="WF">Wallis and Futuna</option>
                  <option value="EH">Western Sahara</option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </select>
              </div>
              <div className="form-group">
                <label className="" htmlFor="zipCode" id="lblZip">
                  Zip/Postal Code
                </label>
                <input
                  id="zipCode"
                  name="zipCode"
                  maxLength="15"
                  className="form-control"
                  type="text"
                  value={zipCode}
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="form-group">
                <label className="" htmlFor="email">
                  Email Address *
                </label>
                <input
                  id="email"
                  name="email"
                  value={email}
                  onChange={this.handleInputChange}
                  maxLength="100"
                  autoComplete="email"
                  className={`form-control ${
                    !noError && email === "" ? "is-invalid" : ""
                  }`}
                  data-validation="required"
                  type="email"
                />
              </div>
              <div className="form-group">
                <label className="" htmlFor="password">
                  Password *
                </label>
                <input
                  id="password"
                  name="password"
                  className={`pword form-control ${
                    !noError && !isValidPword(password) ? "is-invalid" : ""
                  }`}
                  value={password}
                  onChange={this.handleInputChange}
                  maxLength="30"
                  autoComplete="new-password"
                  data-validation="required"
                  type="password"
                  aria-autocomplete="list"
                />
              </div>
            </div>
            <div className="input-row optin subs" />

            <div className="form-check optin subs mb-0">
              <input name="xtsubs" id="xtsubs" value="1" type="hidden" />
              <input name="xtemail" id="xtemail" value="" type="hidden" />
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  checked={tiu}
                  onChange={this.handleCheckBoxChange}
                  name="tiu"
                  id="newslettersub"
                  type="checkbox"
                />
                Yes, I want the <i>Thomas Industry Update</i> newsletter.{" "}
              </label>
            </div>

            <div className="form-check optin mb-3">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  onChange={this.handleCheckBoxChange}
                  name="ien"
                  id="ien"
                  checked={ien}
                  type="checkbox"
                />
                Yes, I want more information on <i>Industrial Equipment News</i>{" "}
                (IEN).
              </label>
            </div>

            <div className="form-group text-center mb-0">
              <div className="button-spinner-wrap">
                {/* <div className="spinner">
                                <div className="rect1" />
                                <div className="rect2" />
                                <div className="rect3" />
                                <div className="rect4" />
                                <div className="rect5" />
                            </div> */}
                <button
                  className="btn btn-primary btn-lg"
                  onClick={this.onRegister}
                >
                  {this.props.loading ? (
                    <div className="spinner loading-white">
                      <div className="rect1" />
                      <div className="rect2" />
                      <div className="rect3" />
                      <div className="rect4" />
                      <div className="rect5" />
                    </div>
                  ) : (
                    buttonText || <React.Fragment>Next &rarr;</React.Fragment>
                  )}
                </button>
              </div>
            </div>

            <input type="hidden" id="ORIGIN" name="ORIGIN" value="" />
            <input type="hidden" id="adcode" name="adcode" value="regform" />
            <input type="hidden" id="corptandc" name="corptandc" value="y" />
            <input type="hidden" id="cro_test" name="cro_test" value="" />
            <input type="hidden" id="email2" name="email2" value="" />
            <input type="hidden" id="frequency" name="frequency" value="day" />
            <input type="hidden" id="origin" name="origin" value="main" />
            <input type="hidden" id="origin_2" name="origin_2" value="" />
            <input type="hidden" id="pword2" name="pword2" value="" />
            <input type="hidden" id="rt" name="rt" value="" />
            <input type="hidden" id="rwid" name="rwid" value="" />
            <input type="hidden" id="subopts" name="subopts" value="YID|IEN" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateThomasAccount;
