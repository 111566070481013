import React from "react";
import SiteHeader from "views/layouts/SiteHeader/SiteHeader";
import SiteFooter from "views/layouts/SiteFooter/SiteFooter";
import SiteContent from "views/layouts/SiteContent/SiteContent";
import SiteAside from "views/layouts/SiteAside/SiteAside";
import { withRouter } from "react-router";
import { AppContext } from "./app-context";
import ErrorBoundary from "../../../errorBoundary";
import moment from "moment-timezone";
import WelcomeScreen from "views/sections/WelcomeScreen/WelcomeScreen";
import { CSSTransition } from "react-transition-group";
import "./App.css";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.toggleHelp = () => {
            this.setState(state => ({
                helpActive: !state.helpActive
            }));
        };

        moment.tz.setDefault(`America/New_York`);

        // State also contains the updater function so it will
        // be passed down into the context provider
        this.state = {
            helpActive: false,
            toggleHelp: this.toggleHelp,
            hideForDemo: false,
            hideForProd: true
        };
    }

    render() {
        const { nav, user, account, loading, error } = this.props;

        return (
            <React.Fragment>
                <CSSTransition
                    in={loading || error}
                    classNames="wt-loading"
                    timeout={500}
                    enter={false}
                    unmountOnExit
                >
                    <div className="webtrax-loading">
                        <div className="webtrax-loading-logo">
                            <img
                                src="/img/webtrax-logo-blue.svg"
                                alt="Thomas For Industry Logo"
                            />
                        </div>
                    </div>
                </CSSTransition>

                {!loading && (
                    <React.Fragment>
                        <WelcomeScreen user={user} />

                        <div className="site-wrap logged-out layout-aside">
                            <SiteHeader />

                            <ErrorBoundary>
                                <AppContext.Provider value={this.state}>
                                    <SiteNavWithRouter
                                        nav={nav}
                                        user={user}
                                        company={account}
                                    />

                                    <SiteContent user={user} />
                                </AppContext.Provider>
                                <SiteFooter />
                            </ErrorBoundary>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const SiteNavWithRouter = withRouter(SiteAside);

export default App;
